import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getMachine } from 'lib/selectors/getMachine';
import { bugsnag } from 'lib/external/bugsnag';
import { Button, theme } from '@m12s/component-library';

import { H3, Small } from 'components/Text';
import ErrorMachine from 'graphics/ErrorMachine';
import { Root, Content, ColumnLeft, ColumnRight, Details } from './styled';

const parseErrorMessages = (error) => {
  const messages = [];
  const { error: genericError, message, networkError } = error;
  /* Generic js error */
  if (genericError) {
    messages.push(genericError.message);
    return messages;
  }
  /* Apollo error */
  if (message) {
    messages.push(message);
  }
  if (networkError) {
    if (networkError.response) {
      messages.push(networkError.response.url);
    }
    if (networkError.result) {
      messages.push(networkError.result.message);
    }
  }
  if (!messages.length) {
    messages.push(JSON.stringify(error));
  }
  return messages;
};

const ErrorPage = ({ error = new Error('Unknown Error'), nonreporting }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const machine = useSelector(getMachine);
  const errorRedirect = machine.id
    ? `/machine/${machine.id}/`
    : '/switch-machines';
  const errorMessages = parseErrorMessages(error);

  useEffect(() => {
    if (bugsnag && !nonreporting) {
      bugsnag.notify(error);
    }
  }, [error, nonreporting]);

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Content>
          <ColumnLeft>
            <H3>{t('An error has occurred')}</H3>
            <Small
              link
              onClick={() => {
                return setShowDetails(!showDetails);
              }}
              style={{ padding: '1.5rem 0' }}
            >
              {t(`${showDetails ? 'Hide' : 'Show'} Details`)}
            </Small>
            <Details show={showDetails}>
              {errorMessages.map((msg) => {
                return (
                  <div key={msg}>
                    <Small>{msg}</Small>
                  </div>
                );
              })}
              <Small></Small>
            </Details>
            <br />
            <br />
            <Button
              onClick={() => {
                window.location.href = errorRedirect;
              }}
            >
              {t(`Return to Main`)}
            </Button>
          </ColumnLeft>
          <ColumnRight>
            <ErrorMachine />
          </ColumnRight>
        </Content>
      </Root>
    </ThemeProvider>
  );
};

export default ErrorPage;
