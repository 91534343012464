import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppNav } from 'components/AppNav';
import { actionToggleTraining } from 'lib/actions';
import { Card, Icon, palette } from '@m12s/component-library';
import { chevronRight } from 'lib/icons';
import tabletImage from 'graphics/tablet.jpg';
import { H2, H3, H5 } from 'components/Text';
import { Logo } from 'components/Logo';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { Sections, SectionsLegacy } from './constants';
import { TOCLayout, Description, Links, Img } from './styled';

const TableOfContents = ({ setSection }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const sections = useMemo(() => {
    return areActivitiesEnabled ? Sections : SectionsLegacy;
  }, [areActivitiesEnabled]);

  return (
    <>
      <AppNav
        onClose={() => {
          return dispatch(actionToggleTraining({ toggleTraining: false }));
        }}
      >
        <H5 color={palette.Yellow400} style={{ textTransform: 'capitalize' }}>
          {t('operator training')}
        </H5>
      </AppNav>
      <TOCLayout>
        <Description>
          <div>
            <Logo width="16rem" />
            <br />
            <Img src={tabletImage} />
            <H3 normal color={palette.Green600}>
              {t('quick-start')}
            </H3>
            <H2 style={{ textTransform: 'uppercase' }}>
              {t('operator guide')}
            </H2>
          </div>
        </Description>
        <Links>
          {sections.map((section, index) => {
            return (
              <Card
                color={null}
                key={section.title}
                type="pill"
                icon={<Icon icon={chevronRight} />}
                onClick={() => {
                  return setSection(index);
                }}
              >
                <H5 style={{ textTransform: 'capitalize' }}>{section.title}</H5>
              </Card>
            );
          })}
        </Links>
      </TOCLayout>
    </>
  );
};

export { TableOfContents };
