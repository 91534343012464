import { useState, useEffect } from 'react';
import { useInterval } from 'lib/hooks';

const Countdown = ({ from, then }) => {
  const [countdown, setCountdown] = useState(from);

  useInterval(() => {
    if (countdown > 0) {
      setCountdown(countdown - 1);
    }
  }, 1000);

  useEffect(() => {
    if (countdown === 0) then();
  }, [countdown, then]);

  return countdown;
};

export { Countdown };
