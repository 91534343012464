import styled from 'styled-components';
import { Icon, palette } from '@m12s/component-library';

import { chevronRight } from 'lib/icons';

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  width: 100%;
  background-color: ${palette.White};
  box-shadow: inset 0px -1px 0px #edf2f7;
`;

const Separator = styled(Icon).attrs({
  color: palette.Grey500,
  icon: chevronRight,
})`
  margin: 0 1rem;
`;

export { NavWrapper, Separator };
