import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { OverlayButton, OverlayContainer } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { times, questionCircle } from 'lib/icons';
import { PartsGoalChart } from 'components/charts';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { DefaultLoading } from 'components/Loading';
import { Root, Header, Chart } from './styled';
import { Key } from './Key';

const PartsGoal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  if (arePartCountBucketsLoading) return <DefaultLoading />;

  return (
    <OverlayContainer>
      <PauseDetailsOverlayContainer>
        <Root id="parts-goal-page">
          <Header>
            <Key />
            <OverlayButton
              open={open}
              onToggle={(state) => {
                return setOpen(state);
              }}
              openLabel={_startCase(t('learn more about this metric'))}
              closeLabel={_startCase(t('close overlay'))}
              openIcon={questionCircle}
              closeIcon={times}
            >
              <p>
                {t(
                  'Parts Goal is a metric that compares the number of parts actually made to the number of parts that should have been made within a time period.'
                )}
              </p>
              <p>
                {t(
                  'We use the Expected Part Rate to determine the number of parts that should be produced within an hour. Expected Part Rate is set up in the Operation Performance Standards and reflects the total time expected for processing the parts, including machine setup, teardown and setup for the next part, also known as "button-to-button" production time. Operation Performance Goals, like Parts Goal, show how close you are to the Expected Part Rate.'
                )}
              </p>
            </OverlayButton>
          </Header>
          <Chart>
            <PartsGoalChart />
          </Chart>
        </Root>
        <PauseDetailsToggle />
      </PauseDetailsOverlayContainer>
    </OverlayContainer>
  );
};

export { PartsGoal };
