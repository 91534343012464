import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _startCase from 'lodash/startCase';
import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { Loading } from 'components/Loading';
import {
  WheelLabel,
  WheelSublabel,
} from '@m12s/component-library';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { formatValue } from 'lib/utils/text';

import NoGoal from 'graphics/NoGoal';
import { NoJobIcon, PartCountPerfStatusIcon, Faded, OpRunOverrideRejectLabel } from 'components/TabsCommon';

import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getRejectedParts } from 'lib/selectors/getRejectedParts';

import { LoadingPartBuckets } from '../Widgets';
import { createStatusIcon } from '../Widgets';



export const WheelContentStandard = () => {
  const { t } = useTranslation();

  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const isPaused = useSelector(getIsPaused);
  const isPausedLabel = _startCase(isPaused ? t('operation paused') : t('no operation running'))
  let wheelLabels = (
    <>
      <WheelLabel>
        <NoJobIcon />
      </WheelLabel>
      <WheelSublabel>
        <Faded>{isPausedLabel}</Faded>
      </WheelSublabel>
    </>
  )


  const openActivitySetHasNoStandards = useSelector(getOpenActivitySetHasNoStandards);
  const hasOpenActivity = useSelector(getHasOpenActivity);
  if (hasOpenActivity && openActivitySetHasNoStandards) {
    wheelLabels = (
      <>
      <WheelLabel>
        <NoGoal width="3rem" style={{ marginBottom: '0.25rem' }} />
      </WheelLabel>
        <WheelSublabel>{t('No Operation Standards')}</WheelSublabel>
      </>
    )
  }

  const { isBehind, cyclesDiff } = useSelector(getScopePartsGoal);
  const diffLabel = formatValue(Math.abs(cyclesDiff.toFixed(2)))

  if (hasOpenActivity) {
    wheelLabels = (
      <>
        <WheelLabel>{diffLabel}</WheelLabel>
        <WheelSublabel>
          {_startCase(isBehind ? t('cycles behind') : t('cycles ahead'))}
        </WheelSublabel>
      </>
    )
  }

  const rejectCount = useSelector(getRejectedParts);

  if (arePartCountBucketsLoading) { return <LoadingPartBuckets /> }
  return (
    <>
      {wheelLabels}
      <WheelLabel secondary>{rejectCount || '-'}</WheelLabel>
      <WheelSublabel>{t('Rejects')}</WheelSublabel>
    </>
  );
};


export const WheelContentOpRunOverride = ({ appearance, fillMode }) => {
  const { t } = useTranslation();
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const isPaused = useSelector(getIsPaused);
  const { isAtGoal, isBehind, partsDiff } = useSelector(getScopePartsGoal);

  const statusIcon = createStatusIcon(isAtGoal, isBehind)

  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  if (arePartCountBucketsLoading) {
    return <Loading size="32px" fontSize="1rem" text={t('Loading')} />;
  }

  if (hasOpenActivity) {
    if (openActivitySetHasNoStandards) {
      return (
        <>
          <WheelLabel>
            <NoGoal width="3rem" style={{ marginBottom: '0.25rem' }} />
          </WheelLabel>
          <WheelLabel secondary>
            <Faded style={{ fontSize: '1.4rem' }}>
              {t('No Operation Standards')}
            </Faded>
          </WheelLabel>
          <OpRunOverrideRejectLabel />
        </>
      );
    }


    const absPartCount = Math.abs(partsDiff);
    return (
      <>
        <WheelLabel>
          <PartCountPerfStatusIcon
            icon={statusIcon}
            appearance={appearance}
            fillMode={fillMode}
          />
        </WheelLabel>
        {isAtGoal ? (
          <WheelLabel secondary>{t('At Goal')}</WheelLabel>
        ) : (
          <WheelLabel secondary>
            {t(`{{absPartCount}} ${isBehind ? 'Behind' : 'Ahead'}`, {
              absPartCount,
            })}
          </WheelLabel>
        )}
        <OpRunOverrideRejectLabel />
      </>
    );
  }

  const OpLabel = isPaused ? t('Operation Paused') : t('No Operation Running')
  return (
    <>
      <WheelLabel>
        <NoJobIcon style={{ marginBottom: '0', fontSize: '2.75rem', }} />
      </WheelLabel>
      <WheelLabel secondary>
        <Faded style={{ fontSize: '1.4rem' }}>{OpLabel}</Faded>
      </WheelLabel>
      <OpRunOverrideRejectLabel />
    </>
  );
};

