import { createSelector } from 'reselect';
import _takeWhile from 'lodash/takeWhile';

import { getLatestActivitySetActivities } from './getLatestActivitySetActivities';
import { getCheckIsSetupActivityTypeRef } from './getCheckIsSetupActivityTypeRef';

// This returns all activities since you entered setup mode.
export const getCurrentSetupModeActivities = createSelector(
  [getLatestActivitySetActivities, getCheckIsSetupActivityTypeRef],
  (activities, checkIsSetupActivityTypeRef) => {
    if (!activities || !activities.length) {
      return null;
    }
    // activities are ordered recent first at this point
    return _takeWhile(activities, (act) => {
      return checkIsSetupActivityTypeRef(act.activityTypeRef);
    })
      .slice()
      .reverse();
  }
);
