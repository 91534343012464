import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getAllActivityRefs } from 'lib/selectors/getAllActivityRefs';

const getLatestActivityRef = createSelector(
  [getAllActivityRefs],
  (allActivityRefs) => {
    return _get(allActivityRefs, '0', null);
  }
);

export { getLatestActivityRef };
