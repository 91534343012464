import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, palette } from '@m12s/component-library';
import { H3, P } from 'components/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Empty = ({ icon, title, subtitle }) => {
  return (
    <Container>
      <Icon
        style={{ marginBottom: '1rem' }}
        color={palette.Grey400}
        icon={icon}
        size="3.5rem"
      />
      <H3 color={palette.Grey500}>{title}</H3>
      <P color={palette.Grey500} medium>
        {subtitle}
      </P>
    </Container>
  );
};

Empty.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export { Empty };
