import { REST_URI } from 'lib/appConfig';
import { getAuthHeaders } from './helpers';
import TokenStore from './TokenStore';

let jwt = null;
let refreshInterval = null;
const apiKey = new TokenStore('apikey');
const presetMachineToken = new TokenStore('presetMachine');

const REFETCH_DURATION = 1000 * 60 * 4.5;

const parseJwt = (token) => {
  const base64Payload = token.split('.')[1];
  const payload = window.atob(base64Payload);
  return JSON.parse(payload.toString());
};

const fetchJwt = async () => {
  const url = `${REST_URI}/operator-view/user-token?graphql=1`;
  const key = apiKey.get();
  if (!key) {
    return null;
  }
  const response = await fetch(url, { headers: getAuthHeaders(key) });
  if (!response.ok) {
    if (response.status === 401) {
      clear(true);
    }
  }
  if (!refreshInterval) {
    refreshInterval = setInterval(fetchJwt, REFETCH_DURATION);
  }
  jwt = await response.json();
  return jwt;
};

const getJwt = async () => {
  if (jwt) {
    const { exp } = parseJwt(jwt);

    if (exp < new Date().getTime()) {
      return jwt;
    }
  }

  return fetchJwt();
};

const clear = (force = false) => {
  const key = apiKey.get();
  if (key) {
    if (key.startsWith('at') || force) {
      apiKey.clear();
    }
  }

  jwt = null;
  presetMachineToken.clear();
};

const getApiKey = () => {
  return apiKey.get();
};

const setApiKey = (value) => {
  return apiKey.set(value);
};

const clearJwt = () => {
  jwt = null;
};

export default {
  clear,
  getJwt,
  getApiKey,
  setApiKey,
  clearJwt,
};
