import styled from 'styled-components';
import { H5 } from 'components/Text';

const HeaderText = styled(H5).attrs({ shadow: true })`
  & + & {
    margin-left: 0.625rem;
  }
`;

export { HeaderText };
