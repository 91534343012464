import { createSelector } from 'reselect';
import { getStatusIntervals } from 'lib/selectors/getStatusIntervals';
import { diff, isAfter, toISO } from 'lib/utils/date';
import { getIntervalSetsIntersection, chopBefore } from 'lib/utils/intervals';
import { getHeavyUpdate } from './getUpdate';
import { getScopeScheduledActivityIntervals } from './getScopeScheduledActivityIntervals';
import { getEffectiveCurrentCycleStart } from './getEffectiveCurrentCycleStart';

const getCurrentCycleActiveStatusIntervals = createSelector(
  [getEffectiveCurrentCycleStart, getStatusIntervals],
  (effectiveCurrentCycleStart, statusIntervals) => {
    const filteredIntervals = statusIntervals.filter((event) => {
      return (
        event.label === 'ACTIVE' &&
        (!event.end || isAfter(event.end, effectiveCurrentCycleStart))
      );
    });

    return chopBefore(filteredIntervals, effectiveCurrentCycleStart);
  }
);

// calculate machine active time during scheduled periods
// of current cycle (last part was made)
const getScheduledCurrentCycleActiveTimeMs = createSelector(
  [
    getCurrentCycleActiveStatusIntervals,
    getScopeScheduledActivityIntervals,
    getHeavyUpdate,
  ],
  (activeIntervals, scheduledIntervals) => {
    if (!activeIntervals.length || !scheduledIntervals.length) {
      return 0;
    }
    return getIntervalSetsIntersection(
      activeIntervals,
      scheduledIntervals
    ).reduce((totalActive, curr) => {
      return (
        totalActive + diff(curr.end || toISO(), curr.start, 'milliseconds')
      );
    }, 0);
  }
);

export { getScheduledCurrentCycleActiveTimeMs };
