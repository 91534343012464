import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import _startCase from 'lodash/startCase';
import _values from 'lodash/values';
import _get from 'lodash/get';
import { ControlBarButton, ControlBar, palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';

import MainLayout from 'components/Layouts/MainLayout';
import { CategoryCard } from 'components/CategoryCard';
import { CategoryHeader } from 'components/CategoryHeader';
import { CategorizeHeader } from 'components/CategorizeHeader';
import { check, exclamationCircle } from 'lib/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionClearDowntimeFlyup } from 'lib/actions';
import { getActiveUncategorizedInterval } from 'lib/selectors/getActiveUncategorizedInterval';
import { getStatusIntervalsLoaded } from 'lib/selectors/getStatusIntervals';
import { getDowntimeFlyup } from 'lib/selectors/getDowntimeFlyup';
import { Empty } from 'components/Empty';
import { Grid, PriorCategory, Dark } from './styled';
import { InfoNav } from './InfoNav';

const DowntimeReasonSelector = ({
  annotationType,
  downtimes,
  node,
  onBack,
  onNext,
  onSelect,
  splitting,
  forced,
  returnPath,
}) => {
  const { t } = useTranslation();
  const priorCategory = _get(
    _values(downtimes),
    [0, 'annotationType', 'name'],
    ''
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const activeUncategorizedDowntime = !!useSelector(
    getActiveUncategorizedInterval
  );
  const statusIntervalsLoaded = useSelector(getStatusIntervalsLoaded);
  const downtimeFlyup = useSelector(getDowntimeFlyup);

  useEffect(() => {
    if (
      downtimeFlyup.triggered &&
      statusIntervalsLoaded &&
      !activeUncategorizedDowntime
    ) {
      dispatch(actionClearDowntimeFlyup());
      history.push(returnPath);
    }
  }, [
    activeUncategorizedDowntime,
    statusIntervalsLoaded,
    downtimeFlyup,
    dispatch,
    history,
    returnPath,
  ]);

  return (
    <MainLayout.Column id="categorize-step">
      <CategorizeHeader
        selected={downtimes}
        handleOnBack={onBack}
        splitting={splitting}
        hideBack={!node.path().length && forced && !!node.children.length}
        hideClose={forced && !!node.children.length}
        returnPath={returnPath}
      />
      {priorCategory && !splitting ? (
        <PriorCategory>
          <Dark>{`${_startCase(t('currently'))}`}</Dark>
          {priorCategory}
        </PriorCategory>
      ) : (
        <InfoNav />
      )}
      <CategoryHeader path={node.path()} />
      <MainLayout.Container direction="column" bgColor={palette.Grey100}>
        <Grid>
          {_orderBy(node.children, 'name').map((child) => {
            const { children, data: ownAnnotationType, name } = child;

            return (
              <CategoryCard
                {...ownAnnotationType}
                name={name}
                hasNested={children.length}
                key={ownAnnotationType.id}
                onClick={() => {
                  return onSelect(child);
                }}
              />
            );
          })}
        </Grid>
        {!node.children.length && (
          <Empty
            icon={exclamationCircle}
            subtitle={t('No downtime reasons configured')}
          />
        )}
      </MainLayout.Container>
      <ControlBar>
        <ControlBarButton
          icon={check}
          onClick={() => {
            return onNext();
          }}
          disabled={!annotationType}
        >
          {_startCase(t('done categorizing'))}
        </ControlBarButton>
      </ControlBar>
    </MainLayout.Column>
  );
};

DowntimeReasonSelector.propTypes = {
  annotationType: PropTypes.object,
  downtimes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export { DowntimeReasonSelector };
