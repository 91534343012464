import { createSelector } from 'reselect';
import { getIsJobRunLoading } from 'lib/selectors/getJobRun';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';

const getIsLoadingJobRunASWrapper = createSelector(
  [
    getIsJobRunLoading,
    getIsInitialActivitySetPollLoading,
    getAreActivitiesEnabled,
  ],
  (isJobRunLoading, isInitialActivitySetPollLoading, areActivitiesEnabled) => {
    return areActivitiesEnabled
      ? isInitialActivitySetPollLoading
      : isJobRunLoading;
  }
);

export { getIsLoadingJobRunASWrapper };
