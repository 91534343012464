import styled, { keyframes, css } from 'styled-components';

const fade = keyframes`
    0% {
      opacity: .2;
    }
    50% {
      opacity: .6;
    }
    100% {
      opacity: .2;
    }
`;

export const loadingFade = css`
  animation: ${fade} 2s infinite;
  height: ${({ height }) => {
    return height;
  }};
  width: ${({ width }) => {
    return width;
  }};
`;

export const LoadingTextPlaceholder = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => {
    return theme.colors.loader.textPlaceholder;
  }};
  ${loadingFade}
`;
