import { createSelector } from 'reselect';
import _sumBy from 'lodash/sumBy';

import { getShiftDowntimeIntervals } from 'lib/selectors/getShiftDowntimeIntervals';

const getTotalShiftDowntime = createSelector(
  [getShiftDowntimeIntervals],
  (shiftDowntimeIntervals) => {
    if (!shiftDowntimeIntervals || shiftDowntimeIntervals.length <= 0) {
      return null;
    }

    return _sumBy(shiftDowntimeIntervals, (downtime) => {
      return downtime.duration;
    });
  }
);

export { getTotalShiftDowntime };
