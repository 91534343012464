import { createSelector } from 'reselect';
import { chopBefore, fallsWithinActivities } from 'lib/utils/intervals';
import { sumNetPartAdjustments } from 'lib/utils/parts';
import _sumBy from 'lodash/sumBy';
import { getCurrentSetupModeActivities } from './getCurrentSetupModeActivities';
import { getPartAdjustments } from './getPartAdjustments';
import { getAreActivitiesEnabled } from './getAreActivitiesEnabled';
import { getScopeNetPartsMade } from './getScopeNetPartsMade';
import { getShiftStart } from './getShiftStart';
import { getRejectedParts } from './getRejectedParts';
import { getScopeReadTimePCM } from './getScopeReadTimePCM';

const getShiftSetupRawParts = (store) => {
  return store.shiftSetupRawParts;
};

const getShiftSetupParts = createSelector(
  [getShiftSetupRawParts, getScopeReadTimePCM],
  (shiftSetupRawParts, scopeReadTimePCM) => {
    return shiftSetupRawParts * scopeReadTimePCM;
  }
);

const getShiftSetupPartAdjustments = createSelector(
  [getPartAdjustments, getCurrentSetupModeActivities, getShiftStart],
  (partAdjustments, currentSetupModeActivities, shiftStart) => {
    return partAdjustments.filter((adjustment) => {
      const shiftSetupActivites = chopBefore(
        currentSetupModeActivities,
        shiftStart,
        true
      );
      return fallsWithinActivities(
        adjustment.adjustmenttime,
        shiftSetupActivites
      );
    });
  }
);

const getShiftSetupPartsWithPartAdjustments = createSelector(
  [getShiftSetupParts, getShiftSetupPartAdjustments],
  (setupParts, setupPartAdjustments) => {
    return setupParts + sumNetPartAdjustments(setupPartAdjustments);
  }
);

const getNetShiftSetupParts = createSelector(
  [
    getShiftSetupPartsWithPartAdjustments,
    getScopeNetPartsMade,
    getAreActivitiesEnabled,
  ],
  (setupPartsWithPartAdjustments, scopeNetPartsMade, areActivitiesEnabled) => {
    if (!areActivitiesEnabled) {
      // jsg shim, will already be scoped as expected
      return scopeNetPartsMade;
    }
    return setupPartsWithPartAdjustments;
  }
);

const getShiftSetupRejectCount = createSelector(
  [getAreActivitiesEnabled, getShiftSetupPartAdjustments, getRejectedParts],
  (areActivitiesEnabled, setupPartAdjustments, rejectedParts) => {
    if (!areActivitiesEnabled) {
      // jsg shim, will already be scoped as expected
      return rejectedParts;
    }
    return _sumBy(setupPartAdjustments, 'scrapcount');
  }
);

export { getNetShiftSetupParts, getShiftSetupParts, getShiftSetupRejectCount };
