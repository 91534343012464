import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';
import _get from 'lodash/get';
import { getActivitySetsByRef } from 'lib/selectors/getActivitySetsByRef';
import { getLatestActivitySetRef } from 'lib/selectors/getLatestActivitySetRef';

const defaultLatestActivitySet = {};

const getLatestActivitySet = createDeepEqualitySelector(
  [getLatestActivitySetRef, getActivitySetsByRef],
  (latestActivitySetRef, activitySetsByRef) => {
    if (!latestActivitySetRef) {
      return defaultLatestActivitySet;
    }
    return _get(
      activitySetsByRef,
      latestActivitySetRef,
      defaultLatestActivitySet
    );
  }
);

export { getLatestActivitySet };
