import React, { useMemo } from 'react';
import {
  Wheel,
  WheelLabel,
  WheelSublabel,
  CounterBlock,
} from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { formatValue, formatPercentFromDecimal } from 'lib/utils/text';
import { isNumber } from 'lib/utils/validation';
import { getCompany } from 'lib/selectors/getCompany';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getUtilPageScopeUtilizationDecimal } from 'lib/selectors/getUtilPageScopeUtilizationDecimal';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getScopeNetPartCountHourlies } from 'lib/selectors/getScopeNetPartCountHourlies';

import {
  WheelLayout,
  ChartLabel,
  CounterLabel,
  WheelCounterPadding,
  WheelChartPadding,
} from 'components/WheelLayout';
import {
  UtilizationChart,
  CycleTimeChart,
  PartsMadeChart,
} from 'components/charts';
import { OperatorPill } from 'components/OperatorPill';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { LoadingTextPlaceholder } from 'components/LoadingTextPlaceholder';
import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';
import { Highlight } from './styled';

const LoadingCounterValue = styled(LoadingTextPlaceholder)`
  height: 2rem;
  width: 90%;
  border-radius: 2px;
`;

const Utilization = () => {
  const { t } = useTranslation();
  const scopeNetPartsMade = useSelector(getScopeNetPartsMade);
  const { expectedPartsNow } = useSelector(getScopePartsGoal);
  const machine = useSelector(getMachine);
  const company = useSelector(getCompany);
  const isOperatorTrackingEnabled = useSelector(getIsOperatorTrackingEnabled);
  const operator = useSelector(getCurrentOperator);
  const utilPageScopeUtilizationDecimal = useSelector(
    getUtilPageScopeUtilizationDecimal
  );
  const formattedUtilizationPct = formatPercentFromDecimal(
    utilPageScopeUtilizationDecimal
  );
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );

  const data = useSelector(getScopeNetPartCountHourlies);
  const calculateValue = (item, getItemStyle) => {
    return {
      value: [item.time, item.sum],
      itemStyle: getItemStyle(item),
    };
  };

  const failurePercent =
    machine.utilizationFailurePercent || company.failurePercentUtilization;

  const warningPercent =
    machine.utilizationWarningPercent || company.warningPercentUtilization;

  const utilizationPct = isNumber(utilPageScopeUtilizationDecimal)
    ? Math.round(utilPageScopeUtilizationDecimal * 100)
    : null;
  const appearance = useMemo(() => {
    return utilizationPct === null
      ? 'empty'
      : utilizationPct <= failurePercent
      ? 'failure'
      : utilizationPct <= warningPercent
      ? 'warning'
      : 'positive';
  }, [utilizationPct, failurePercent, warningPercent]);

  return (
    <PauseDetailsOverlayContainer>
      <WheelLayout
        id="utilization-page"
        topLeft={
          <WheelCounterPadding top left>
            <CounterBlock
              label={
                <CounterLabel loading={arePartCountBucketsLoading}>
                  {_startCase(t('you have made'))}
                </CounterLabel>
              }
              value={
                arePartCountBucketsLoading ? (
                  <LoadingCounterValue />
                ) : (
                  formatValue(scopeNetPartsMade)?.toLocaleString()
                )
              }
            />
          </WheelCounterPadding>
        }
        topRight={
          openActivitySetHasNoStandards ? (
            <WheelChartPadding>
              <ChartLabel
                tooltip={t(
                  'This area displays the number of Parts that have been produced by this Machine at specific times during the current shift.'
                )}
                loading={arePartCountBucketsLoading}
              >
                {_startCase(t('parts made'))}
              </ChartLabel>
              <PartsMadeChart data={data} calculateValue={calculateValue} />
            </WheelChartPadding>
          ) : (
            <WheelCounterPadding top right>
              <CounterBlock
                align="right"
                label={
                  <CounterLabel>
                    {_startCase(t('expected to have made'))}
                  </CounterLabel>
                }
                value={formatValue(expectedPartsNow)?.toLocaleString()}
              />
            </WheelCounterPadding>
          )
        }
        bottomLeft={
          <WheelChartPadding>
            <ChartLabel
              tooltip={t(
                'This area displays Machine Cycle Time information for the current shift. For more information, visit the Current Cycle Time tab and select "Learn more about this Metric".'
              )}
              tooltipDirection="top"
              loading={arePartCountBucketsLoading}
            >
              {_startCase(t('cycle time'))}
            </ChartLabel>
            <CycleTimeChart micro />
          </WheelChartPadding>
        }
        bottomRight={
          <WheelChartPadding>
            <ChartLabel
              tooltip={t(
                'This area displays the average Machine Utilization percentages at specific times during the current shift.'
              )}
            >
              {_startCase(t('utilization'))}
            </ChartLabel>
            <UtilizationChart />
          </WheelChartPadding>
        }
        wheel={
          <div>
            <Wheel
              appearance={appearance}
              shape="sharp"
              value={utilizationPct}
              fillMode="full"
              showProgress
              Contents={
                <>
                  <WheelLabel>
                    <Highlight appearance={appearance} shouldFill>
                      {formattedUtilizationPct}
                    </Highlight>
                  </WheelLabel>
                  <WheelSublabel>{_startCase(t('utilization'))}</WheelSublabel>
                </>
              }
            />
          </div>
        }
        login={
          isOperatorTrackingEnabled && (
            <OperatorPill operator={operator} machineId={machine.id} />
          )
        }
      />
      <PauseDetailsToggle />
    </PauseDetailsOverlayContainer>
  );
};

export { Utilization };
