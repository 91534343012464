import { createSelector } from 'reselect';

import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';

const getLatestOperatorRunEndAt = createSelector(
  [getLatestOperatorRun],
  (latestOperatorRun) => {
    return latestOperatorRun?.endAt;
  }
);

export { getLatestOperatorRunEndAt };
