export const createAction = (type) => {
  return (fn) => {
    function action(...params) {
      return { type, ...(fn ? fn(...params) : {}) };
    }

    action.type = type;

    return action;
  };
};
