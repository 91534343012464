import React from 'react';
import { palette } from '@m12s/component-library';

const NoOperators = ({ margin = 0, width = '70' }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 241 187"
      fill={palette.Grey400}
      style={{ margin }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M106.1,21.5v28.5h-4.4l15.8,12.5h42.4c1.2,3.9,2,8.2,2.3,12.5c0,6.8-1.9,13.2-5.1,18.7l9.9,7.8c4.8-7.7,7.7-16.8,7.7-26.5
		c-0.4-4.3-0.8-8.2-1.9-12.5h5.1c1.6,0,3.1-1.2,3.1-3.1V53c0-1.6-1.6-3.1-3.1-3.1h-3.1c0-19.9-13.7-36.7-32.4-41.7
		c-1.6-4.7-6.2-7.8-11.3-8.2h-12.5c-5.5,0.4-10.1,3.5-11.7,8.2c-12.4,3.4-22.7,12.2-28,23.7l10.2,8C91.8,31.8,97.9,25.1,106.1,21.5z
		 M143.5,21.5c10.9,5.1,18.3,16,18.7,28.5h-18.7V21.5z M118.6,12.5H131v37.4h-12.5V12.5z"
        />
        <path d="M114.7,123.8L79.6,96.1C86.1,110.2,99,120.6,114.7,123.8z" />
        <path
          d="M49.9,187.2c1.2-20.7,18.7-37.1,39.8-37.4c3,0,5.9,0.9,9.6,2.1c5.8,1.8,13.3,4.2,25.5,4.2c12.2,0,19.6-2.4,25.3-4.2l0.1,0
		l-12.1-9.6c-3.6,0.8-7.8,1.3-13.2,1.3c-10.5,0-16.7-2-22.2-3.7c-4.2-1.3-8-2.5-12.9-2.5c-28.9,0-52.3,23.8-52.3,52.7
		c0,5.5,4.3,9.7,9.8,9.7H202c2.3,0,4.5-0.8,6.3-2.1l-13.2-10.4H49.9z"
        />
        <path
          d="M248.6,189.5L9.1,0.8C7.5-0.4,5.6,0,4.4,1.2L0.5,6.2c-0.8,1.2-0.8,3.1,0.8,4.3l239.5,188.8c1.2,1.2,3.1,0.8,4.3-0.4
		l3.9-5.1C250.1,192.3,249.7,190.3,248.6,189.5z"
        />
      </g>
    </svg>
  );
};

export default NoOperators;
