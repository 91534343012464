import { createSelector } from 'reselect';
import { getLatestActivitySetClosedAt } from 'lib/selectors/getLatestActivitySetClosedAt';

const getIsLatestActivitySetClosed = createSelector(
  [getLatestActivitySetClosedAt],
  (latestActivitySetClosedAt) => {
    return !!latestActivitySetClosedAt;
  }
);

export { getIsLatestActivitySetClosed };
