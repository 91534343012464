import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _startCase from 'lodash/startCase';
import {
  WheelChartPadding,
  ChartLabel,
} from 'components/WheelLayout';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getScopeNetPartCountHourlies } from 'lib/selectors/getScopeNetPartCountHourlies';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';
import { PartsMadeChart } from 'components/charts';


export const CycleHistory = () => {
  const { t } = useTranslation();
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const data = useSelector(getScopeNetPartCountHourlies);
  const scopeReadTimePCM = useSelector(getScopeReadTimePCM);

  const calculateValue = (item, getItemStyle) => {
    const itemValue = item.sum / scopeReadTimePCM;
    const valueLabel = itemValue * 10 % 10 ? itemValue.toFixed(1) : itemValue;

    return {
      value: [item.time, valueLabel],
      itemStyle: getItemStyle(item),
    };
  }

  return (
    <WheelChartPadding>
      <ChartLabel
        tooltip={t(
          'This area displays the amount of Cycles that have been produced by this Machine at specific times during the current shift.'
        )}
        loading={arePartCountBucketsLoading}
      >
        {_startCase(t('Cycles'))}
      </ChartLabel>
      <PartsMadeChart 
        data={data}
        calculateValue={calculateValue}
      />
    </WheelChartPadding>
  )
}
