import styled from 'styled-components';
import {
  Button,
  palette,
  Checkbox as OriginalCheckbox,
} from '@m12s/component-library';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 4rem;
  width: 100%;
  background-color: ${palette.White};
  box-shadow: inset 0px -1px 0px #edf2f7;
  padding: 0 1.5rem;
`;

const Checkbox = styled(OriginalCheckbox)`
  margin-left: 2rem;

  @media (orientation: portrait) {
    margin: 0 1rem 0 0;
  }
`;

const SplitButton = styled(Button).attrs({ variant: 'primary' })`
  margin-left: auto;
`;

export { Wrapper, Checkbox, SplitButton };
