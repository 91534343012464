import { createSelector } from 'reselect';

const getConditionIntervals = (store) => {
  return store.alarms;
};

const getEarliestFault = createSelector(
  [getConditionIntervals],
  (conditions) => {
    return conditions
      .filter((condition) => {
        return condition.label === 'FAULT';
      })
      .sort((a, b) => {
        return a.start - b.start;
      })
      .shift();
  }
);

const getFaultStartAt = createSelector([getEarliestFault], (fault) => {
  return fault ? fault.start : null;
});

export { getFaultStartAt };
