import { createSelector } from 'reselect';

import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getLatestSetupActivityStartAt } from 'lib/selectors/getLatestSetupActivityStartAt';
import { getLatestSetupActivityEndAt } from 'lib/selectors/getLatestSetupActivityEndAt';
import { getClosedSetupAggMs } from 'lib/selectors/getClosedSetupAggMs';
import { getJobRun } from 'lib/selectors/getJobRun';
import { getJobRunASWrapper } from 'lib/selectors/getJobRunASWrapper';
import { getUnitDurationFromString, dateUTC } from 'lib/utils/date';

/**
 * uses agg of elapsed setup time to produce setup dates w/ negative start offset for duration ticker
 */
const getAdjustedSetupDurationDates = createSelector(
  [
    getAreActivitiesEnabled,
    getLatestActivitySet,
    getLatestSetupActivityStartAt,
    getLatestSetupActivityEndAt,
    getClosedSetupAggMs,
    getJobRun,
    getJobRunASWrapper,
  ],
  (
    areActivitiesEnabled,
    latestActivitySet,
    latestSetupActivityStartAt,
    latestSetupActivityEndAt,
    closedSetupAggMs,
    jobRun,
    jobRunASWrapper
  ) => {
    if (areActivitiesEnabled) {
      if (latestSetupActivityStartAt) {
        // if open, offset start of setup activity-in-progress (handle "live" end in components);
        // otherwise, offset end of last setup activity by closed setup time;
        const startAt = dateUTC(
          latestSetupActivityEndAt || latestSetupActivityStartAt
        );
        const adjustedSetupStartDate = dateUTC(startAt).subtract(
          closedSetupAggMs,
          'milliseconds'
        );
        const { expectedSetupDuration } = latestActivitySet;
        const expectedSetupDurationMs = getUnitDurationFromString(
          expectedSetupDuration,
          'milliseconds'
        );
        return {
          adjustedSetupStartDate: dateUTC(adjustedSetupStartDate),
          expectedSetupEndDate: dateUTC(adjustedSetupStartDate).add(
            expectedSetupDurationMs,
            'milliseconds'
          ),
        };
      }
    } else if (jobRun && jobRun.startDate) {
      const { expectedSetupDuration } = jobRunASWrapper;
      const expectedSetupDurationMs = getUnitDurationFromString(
        expectedSetupDuration,
        'milliseconds'
      );
      return {
        adjustedSetupStartDate: dateUTC(jobRun.startDate),
        expectedSetupEndDate: dateUTC(jobRun.startDate).add(
          expectedSetupDurationMs,
          'milliseconds'
        ),
      };
    }

    return {
      adjustedSetupStartDate: dateUTC(),
      expectedSetupEndDate: dateUTC(),
    };
  }
);

export { getAdjustedSetupDurationDates };
