import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _find from 'lodash/find';
import moment from 'moment';

import { formatDuration } from 'lib/utils/date';
import { Small } from 'components/Text';
import {
  Control,
  FilterSelect,
  Results,
  ResultsHeader,
  Filters,
} from 'components/Table';
import { SearchInput } from 'components/SearchInput';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';

const getOptions = (t) => {
  return {
    duration: [
      { label: _capitalize(t('all')), value: 'all' },
      { label: _capitalize(t('long')), value: 'long' },
    ],
    status: [
      { label: _capitalize(t('both')), value: 'both' },
      { label: _capitalize(t('uncategorized')), value: 'uncategorized' },
      { label: _capitalize(t('categorized')), value: 'categorized' },
    ],
    order: [
      { label: _capitalize(t('time')), value: 'timestamp' },
      { label: _capitalize(t('duration')), value: 'duration' },
    ],
    planned: [
      { label: _capitalize(t('both')), value: 'both' },
      { label: _capitalize(t('unplanned')), value: 'unplanned' },
      { label: _capitalize(t('planned')), value: 'planned' },
    ],
  };
};

const TableFilters = ({ intervals, filters, setFilters }) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return getOptions(t);
  }, [t]);
  const [search, setSearch] = useState(filters.search);
  const heavyUpdate = useSelector(getHeavyUpdate);

  const totalDowntime = useMemo(() => {
    return intervals.reduce((downtime, interval) => {
      const { start, end } = interval;
      let duration = 0;

      if (start && end) {
        duration = moment(end) - moment(start);
      } else if (start && !end) {
        duration = moment() - moment(start);
      }

      return downtime + duration / 1000;
    }, 0);
    /* Recalc on every interval change is too expensive
     Instead we specifically recalc every 10 seconds */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [heavyUpdate]);

  return (
    <>
      <Control>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFilters({ ...filters, search });
          }}
        >
          <SearchInput
            inputProps={{
              value: search,
              onChange: ({ target: { value } }) => {
                return setSearch(value);
              },
              placeholder: _capitalize(t('search by keyword')),
            }}
            onSubmit={() => {
              return setFilters({ ...filters, search });
            }}
          />
        </form>
      </Control>
      <Filters>
        <FilterSelect
          id="duration-filter"
          innerLabel
          label={_capitalize(t('duration'))}
          onChange={({ value }) => {
            return setFilters({ ...filters, duration: value });
          }}
          options={options.duration}
          value={_find(options.duration, { value: filters.duration })}
        />
        <FilterSelect
          id="status-filter"
          innerLabel
          label={_capitalize(t('status'))}
          onChange={({ value }) => {
            return setFilters({ ...filters, status: value });
          }}
          options={options.status}
          value={_find(options.status, { value: filters.status })}
        />
        <FilterSelect
          id="type-filter"
          innerLabel
          label={_capitalize(t('type'))}
          onChange={({ value }) => {
            return setFilters({ ...filters, planned: value });
          }}
          options={options.planned}
          value={_find(options.planned, { value: filters.planned })}
        />
        <FilterSelect
          id="sort-by-filter"
          innerLabel
          label={_capitalize(t('sort by'))}
          onChange={({ value }) => {
            return setFilters({ ...filters, order: value });
          }}
          options={options.order}
          value={_find(options.order, { value: filters.order })}
        />
      </Filters>
      <Results>
        <ResultsHeader>{_capitalize(t('results'))}</ResultsHeader>
        <div>
          <Small medium>{_capitalize(t('events'))}: </Small>
          <Small bold>{intervals.length}</Small>
        </div>
        <div>
          <Small medium>{_capitalize(t('total duration'))}: </Small>
          <Small bold>{formatDuration(totalDowntime)}</Small>
        </div>
      </Results>
    </>
  );
};

TableFilters.propTypes = {
  intervals: PropTypes.array.isRequired,
};

export { TableFilters };
