const baseWhere = {
  closedDate: { _is_null: true },
  status: { _nin: ['C', 'X', 'COMPLETED'] },
};

const getByJobWhereClause = (job, currentOrderOperationRef = 0) => {
  const jobSequence = job.operation;

  const conditions = [];

  if (job.name) {
    conditions.push({ partNumber: { _ilike: `%${job.name}%` } });
  }
  if (job.part) {
    conditions.push({ partNumber: { _ilike: `%${job.part}%` } });
  }

  return {
    ...baseWhere,
    workOrderOperationRef: { _neq: currentOrderOperationRef },
    ...(jobSequence ? { sequenceNumber: { _eq: jobSequence } } : {}),
    workOrder: {
      part: conditions.length > 0 ? { _or: conditions } : {},
    },
  };
};

export const getByPartsWhere = ({
  latestJob,
  isAPMEnabled,
  orderOperation,
}) => {
  const orderOperationRef = orderOperation?.workOrderOperationRef || 0;
  const partOperationRef = orderOperation?.partOperationRef;

  if (isAPMEnabled && !partOperationRef) {
    return getByJobWhereClause(latestJob, orderOperationRef);
  }

  if (!orderOperation) {
    return baseWhere;
  }

  return {
    ...baseWhere,
    sequenceNumber: { _eq: orderOperation.sequenceNumber },
    workOrderOperationRef: {
      _neq: orderOperationRef,
    },
    workOrder: {
      part: {
        partOperations: {
          partOperationRef: { _eq: partOperationRef },
        },
      },
    },
  };
};

export const searchOperations = (search, operations) => {
  if (!search || !operations) {
    return operations;
  }
  const toLowerCaseSearch = search.toLowerCase();
  return operations.filter(({ workOrder }) => {
    const partNumber = workOrder.partNumber.toLowerCase();
    const operationId = workOrder.workOrderId.toLowerCase();
    return (
      partNumber.includes(toLowerCaseSearch) ||
      operationId.includes(toLowerCaseSearch)
    );
  });
};

export const getHeaderText = ({
  currentWorkOrderOp,
  latestJob,
  hasOpenActivitySet,
  searchingByPart,
}) => {
  const currentPartOperation = currentWorkOrderOp?.partOperation;

  if (searchingByPart) {
    if (currentPartOperation) {
      return `Select a new order for "${currentPartOperation.partNumber} Op ${currentWorkOrderOp.sequenceNumber}"`;
    }
    if (hasOpenActivitySet && latestJob) {
      return `Select a new order for ${latestJob.name}`;
    }
  }
  if (hasOpenActivitySet) {
    return 'What are you working on?';
  }
  return "What's next?";
};
