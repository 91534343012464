/* eslint-disable no-shadow */
import Router from './router';

const router = new Router();

router
  .route('root', { path: '/' })
  .route('login', { path: 'login' })
  .route('switchMachines', { path: 'switch-machines' })
  .route('authorize', { path: '/authorize/mm/callback' })
  .route('machine', (route) => {
    route('/:id', (route) => {
      route('/');
      route('login');
      route('selectWorkflow', (route) => {
        route('/');
      });
      route('home', (route) => {
        route('/');
        route('tab', { path: ':tab' }, (route) => {
          route('/');
        });
      });
      route('rejectParts');
      route('StartActivityNewOperation');
      route('CurrentOperationInfo');
      route('switchActivities');
      route('categorize', (route) => {
        route('/');
      });
      route('selectJob');
    });
  });

const routes = router.compile();

export default routes;
