import { useRef, useEffect } from 'react';

const useInterval = (callback, delay, firstEvent) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /* eslint-disable consistent-return */
  useEffect(() => {
    const handler = (...args) => {
      return savedCallback.current(...args);
    };

    if (firstEvent) {
      handler();
    }

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => {
        return clearInterval(id);
      };
    }
  }, [delay, firstEvent]);
};

export { useInterval };
