import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import {
  PopupButtonGroup,
  Button,
  Icon,
  palette,
} from '@m12s/component-library';

import { exclamationCircle } from 'lib/icons';
import { P } from 'components/Text';
import {
  Popup,
  Header,
  HeaderText,
  Body,
  Overlay,
} from 'components/Popup/styled';
import { MoreAlertMessage } from 'components/Popup/MoreAlertMessage';
import { getPopupStackLength } from 'lib/selectors/getPopupStackLength';

const MessagePopupTriggered = ({ dismiss, message }) => {
  const { t } = useTranslation();
  const popupLen = useSelector(getPopupStackLength);

  return (
    <>
      <Overlay />
      <Popup id="message-popup" moreThanOnePopup={popupLen > 1}>
        <Header>
          <HeaderText>
            <Icon icon={exclamationCircle} />
            &nbsp;&nbsp;
            {_startCase(t('attention'))}
          </HeaderText>
        </Header>
        <Body>
          <P color={palette.Grey600}>
            {message}
            <br />
          </P>
          <br />
          <PopupButtonGroup>
            <Button onClick={dismiss} id="dismiss" variant="inferior">
              {_capitalize(t('dismiss'))}
            </Button>
          </PopupButtonGroup>
        </Body>
      </Popup>
      {popupLen > 1 && <MoreAlertMessage popupLen={popupLen} />}
    </>
  );
};

MessagePopupTriggered.propTypes = {
  dismiss: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export { MessagePopupTriggered };
