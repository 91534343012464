import React from 'react';
import _startCase from 'lodash/startCase';
import { palette, Modal, Icon } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';

import { timesCircle } from 'lib/icons';

import { Button, Title, IconWrapper } from './styled';

const SignOutModal = ({ currentOperator, operator, onClose, onYes, onNo }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={!!operator}
      onClose={onClose}
      header={
        <Title color={palette.White}>
          {t('Warning: Logging into this tablet will log out')} &quot;
          {currentOperator.name}
          &quot;
        </Title>
      }
      headerIcon={
        <IconWrapper onClick={onClose}>
          <Icon size="2rem" color={palette.Purple200} icon={timesCircle} />
        </IconWrapper>
      }
      details={
        <div>
          {t(
            'Logging into the tablet will automatically log out the Operator that is currently logged in. Would you like to continue?'
          )}
        </div>
      }
      buttons={
        <>
          <Button variant="tertiary" onClick={onNo}>
            {_startCase(t('no'))}
          </Button>
          <Button onClick={onYes}>{_startCase(t('yes'))}</Button>
        </>
      }
    ></Modal>
  );
};

export { SignOutModal };
