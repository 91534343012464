import { useEffect, useState } from 'react';

/* updates debounced value after specified delay -
  use this hook to perform side effects based on updates to returned value

  component ex:
  ----------------------------------------
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 750);
  const fetchOnDebouncedUpdate = useLazyQuery(GET_JOBS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      dispatch(actionSetJobs, res.data.jobs);
    },
  });
  useEffect(() => {
    fetchOnDebouncedUpdate({
      variables: { query: debouncedQuery },
    });
  }, [debouncedQuery]);
  ----------------------------------------

*/

const useDebounce = (value, delayMs = 250) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delayMs);
    // cancel timeout on value change, delay change, or unmount
    return () => {
      clearTimeout(handler);
    };
  }, [value, delayMs]);
  return debouncedValue;
};

export { useDebounce };
