import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ControlBarButton,
  ControlBar,
  palette,
  CardGrid,
} from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';

import { AppNav } from 'components/AppNav';
import { arrowLeft, check } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { H5 } from 'components/Text';
import { CategoryCard } from 'components/CategoryCard';

import { CategoryHeader } from 'components/CategoryHeader';
import { Root, TitleText, Body, InfoHeader, InfoIcon } from './styled';

const Step2 = ({
  parts,
  dispatch,
  machineId,
  node,
  onSelect,
  onBack,
  category,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Root>
      <AppNav
        onBack={onBack}
        onClose={() => {
          return history.push(Routes.machineIdPath(machineId));
        }}
      >
        <H5 color={palette.Yellow400} bold>
          {t('rejectParts|full')}
        </H5>
        <TitleText>
          {_startCase(t(`categorize rejects {{parts}} parts total`, { parts }))}
        </TitleText>
      </AppNav>
      <InfoHeader>
        <InfoIcon />
        {t(
          'Select at least one category (add subcategories to provide more detail). When you have finished click "Done Categorizing" to proceed.'
        )}
      </InfoHeader>
      <CategoryHeader path={node.path()} />
      <Body id="reject-parts-step2">
        <CardGrid>
          {node.children
            .sort((a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            .map((child) => {
              const {
                children,
                name,
                data: { color, id },
              } = child;

              return (
                <CategoryCard
                  color={color}
                  hasNested={children.length}
                  key={id}
                  name={name}
                  onClick={() => {
                    return onSelect(child);
                  }}
                />
              );
            })}
        </CardGrid>
      </Body>
      <ControlBar>
        <ControlBarButton
          id="edit-parts"
          onClick={() => {
            return dispatch({ type: 'PREVIOUS_STEP' });
          }}
          icon={arrowLeft}
        >
          {_startCase(t('edit reject count'))}
        </ControlBarButton>
        <ControlBarButton
          id="done-categorizing"
          onClick={() => {
            return dispatch({ type: 'NEXT_STEP' });
          }}
          icon={check}
          disabled={!category}
        >
          {_startCase(t('done categorizing'))}
        </ControlBarButton>
      </ControlBar>
    </Root>
  );
};

export { Step2 };
