import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { GET_USER, GET_LOCATION_REF } from 'lib/api/queries';
import TokenStore from 'lib/api/links/TokenStore';
import {
  actionSetUser,
  actionSetCompany,
  actionSetLastRefresh,
  actionSetLocationRef,
} from 'lib/actions';
import { useNetworkError } from 'lib/hooks';
import { ERROR_KEYS } from 'lib/constants';

function mapCompanyFields({
  enableTabletMachineSwitching,
  hideCategorizationClose,
  enableOperatorTracking,
  hideRemoveRejectedPartsCheckbox,
  ...company
}) {
  return {
    ...company,
    machineSwitching: !!enableTabletMachineSwitching,
    mandatoryCategorization: !!hideCategorizationClose,
    operatorTracking: !!enableOperatorTracking,
    hideRemoveRejectedPartsCheckbox: !!hideRemoveRejectedPartsCheckbox,
  };
}

function mapUserFields({ company, ...user }) {
  return {
    ...user,
    shouldReportAnalytics:
      !user.isRoot &&
      !(user.email || '').toLowerCase().includes('@machinemetrics.com'),
  };
}

const useAccount = () => {
  const dispatch = useDispatch();
  const userResponse = useQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ data }) => {
      const { loading, error } = userResponse;

      if (!data) {
        dispatch(actionSetUser({ loading, error }));
        dispatch(actionSetCompany({ loading, error }));
        return;
      }

      const company = mapCompanyFields(data.company);
      const user = mapUserFields(data);

      const userId = new TokenStore('userId');
      const userCompany = new TokenStore('company');

      if (company && user) {
        userId.set(user.id);
        userCompany.set(company.name);
      }

      if (company.timezone) {
        moment.tz.setDefault(company.timezone);
      }

      const storedCompanyLocaleToken = new TokenStore('companyLocale');
      const storedCompanyLocale = storedCompanyLocaleToken.get();
      if (company.locale && company.locale !== storedCompanyLocale) {
        storedCompanyLocaleToken.set(company.locale);
      }

      dispatch(actionSetUser({ user, loading, error }));
      dispatch(actionSetCompany({ company, loading, error }));
      dispatch(actionSetLastRefresh());
    },
  });

  useNetworkError(userResponse, ERROR_KEYS.ACCOUNT);

  useQuery(GET_LOCATION_REF, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(actionSetLocationRef(data.locations[0].locationRef));
    },
  });
};

export { useAccount };
