import { createSelector } from 'reselect';

import { MACHINE_STATUSES } from 'lib/constants';
import { getCurrentStatus } from 'lib/selectors/getCurrentStatus';

const getStatusLabel = createSelector([getCurrentStatus], (interval) => {
  const label = interval && interval.label;
  return MACHINE_STATUSES[label] || MACHINE_STATUSES.UNAVAILABLE;
});

export { getStatusLabel };
