import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _startCase from 'lodash/startCase';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getScopeNetCyclesFinished } from 'lib/selectors/getScopeNetCyclesFinished';
import { formatValue } from 'lib/utils/text';
import { CounterBlock } from '@m12s/component-library';
import { CounterLabel, WheelCounterPadding } from 'components/WheelLayout';
import { TextMedia } from 'pages/Machine/styled';
import { LoadingCounterValue } from './Widgets'


export const CyclesFinished = () => {
  const { t } = useTranslation();
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const scopeNetCyclesFinished = useSelector(getScopeNetCyclesFinished);

  return (
    <WheelCounterPadding top left>
      <CounterBlock
        label={
          <CounterLabel loading={arePartCountBucketsLoading}>
            <TextMedia min={40}>
              {_startCase(t('you have made'))}
            </TextMedia>
            <TextMedia max={40}>{_startCase(t('current'))}</TextMedia>
          </CounterLabel>
        }
        value={
          arePartCountBucketsLoading ? (
            <LoadingCounterValue />
          ) : (
              formatValue(scopeNetCyclesFinished)
            )
        }
      />
    </WheelCounterPadding>
  )
}
