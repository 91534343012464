import React, { useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import Routes from 'lib/utils/routes';
import { FullScreenLoading } from 'components/Loading';
import {
  SUBSCRIBE_CURRENT_EXECUTION_ALL_MACHINES,
  SUBSCRIBE_ALARMS,
  GET_DASHBOARD_MACHINE_DATA,
} from 'lib/api/queries';
import { MachinePicker } from 'components/MachinePicker';
import { getMachine } from 'lib/selectors/getMachine';
import { actionSwitchMachine } from 'lib/actions';
import TokenStore from 'lib/api/links/TokenStore';
import ErrorPage from 'pages/Error';
import { mapMachineFields } from 'lib/hooks/useMachine';
import { getHomeTab } from 'lib/selectors/getTabs';

const SwitchMachinesPage = () => {
  const homeTab = useSelector(getHomeTab);
  const presetMachineToken = new TokenStore('presetMachine');
  const switchDisabled = new TokenStore('switchDisabled');
  switchDisabled.set(false);
  const presetMachine = presetMachineToken.get();
  if (presetMachine) {
    window.location = Routes.machineIdHomeTabPath(presetMachine, homeTab);
  }
  const history = useHistory();
  const {
    loading: dashboardDataLoading,
    error: dashboardDataError,
    data: dashboardDataWrapper,
    refetch: dashboardDataRefetch,
  } = useQuery(GET_DASHBOARD_MACHINE_DATA, {
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  const {
    loading: machineLoading,
    error: machineError,
    data: executionMachineData,
  } = useSubscription(SUBSCRIBE_CURRENT_EXECUTION_ALL_MACHINES, {
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      dashboardDataRefetch();
    },
  });

  const loading = machineLoading || dashboardDataLoading;

  const { error: alarmError, data: alarmData } = useSubscription(
    SUBSCRIBE_ALARMS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const dispatch = useDispatch();
  const currentMachine = useSelector(getMachine);

  const machines = useMemo(() => {
    if (executionMachineData && dashboardDataWrapper?.dashboardMachineData) {
      const machineIdsByRef = dashboardDataWrapper.dashboardMachineData.machines.reduce(
        (accum, machine) => {
          return { ...accum, [machine.machineRef]: machine.id };
        },
        {}
      );

      return executionMachineData.currentExecution.map((data) => {
        const { machineRef, name, currentStates } = data;

        return {
          machine: {
            machineRef,
            name,
            machineId: machineIdsByRef[machineRef],
          },
          start: currentStates[0]?.start,
          value: currentStates[0]?.value,
        };
      });
    }
    return [];
  }, [dashboardDataWrapper, executionMachineData]);

  const changeMachine = ({ machine }) => {
    dispatch(actionSwitchMachine({ machine: mapMachineFields(machine) }));
    presetMachineToken.set(machine.machineId);
    history.push(Routes.machineIdHomeTabPath(machine.machineId, homeTab));
  };

  const alarms = useMemo(() => {
    if (!alarmData || !alarmData.machines) {
      return {};
    }
    return alarmData.machines.reduce((accum, curr) => {
      return { ...accum, [curr.machineRef]: curr.currentAlarms };
    }, {});
  }, [alarmData]);

  const dashboardDataMachineLookup = useMemo(() => {
    const dashboardMachines = _get(
      dashboardDataWrapper,
      ['dashboardMachineData', 'machines'],
      []
    );
    return dashboardMachines.reduce(
      (lookup, { id, setupSince, pausedJobName }) => {
        return {
          ...lookup,
          [id]: { isInSetup: !!setupSince, isPaused: !!pausedJobName },
        };
      },
      {}
    );
  }, [dashboardDataWrapper]);

  if (machineError || alarmError || dashboardDataError)
    return (
      <ErrorPage error={machineError || alarmError || dashboardDataError} />
    );

  if (loading)
    return (
      <FullScreenLoading
        loadingStates={{ currentExecutionAllMachines: loading }}
      />
    );

  return (
    <>
      <MachinePicker
        currentMachine={currentMachine}
        changeMachine={changeMachine}
        machines={machines}
        alarms={alarms}
        dashboardDataMachineLookup={dashboardDataMachineLookup}
      />
    </>
  );
};

export default SwitchMachinesPage;
