import { createSelector } from 'reselect';
import { getJobRun } from 'lib/selectors/getJobRun';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';

const getHasOpenJobRun = createSelector(
  [getJobRun, getAreActivitiesEnabled],
  (jobRun, areActivitiesEnabled) => {
    return !!(!areActivitiesEnabled && jobRun && jobRun.jobRunId);
  }
);

export { getHasOpenJobRun };
