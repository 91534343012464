import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingCircle } from '@m12s/component-library';
import { spinner } from 'lib/icons';
import { zLayer } from 'lib/utils/styled';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: ${zLayer.overlay};
`;

const LoadingCircleWrapper = styled.div`
  margin: ${({ margin }) => {
    return margin || '1rem';
  }};
`;

const Text = styled.div`
  padding: 0;
  margin: 0;
  line-height: 1.5rem;
  text-align: center;
  font-size: ${({ fontSize }) => {
    return fontSize || '1.25rem';
  }};
  color: ${({ theme }) => {
    return theme.colors.text.default;
  }};
`;

const Loading = ({ text, size = '4rem', margin, fontSize }) => {
  return (
    <Wrapper>
      <LoadingCircleWrapper margin={margin}>
        <LoadingCircle icon={spinner} size={size} />
      </LoadingCircleWrapper>
      <Text fontSize={fontSize}>{text}</Text>
    </Wrapper>
  );
};

Loading.propTypes = {
  text: PropTypes.node,
};

export default Loading;
