import { createSelector } from 'reselect';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';

const getHasOpenActivity = createSelector(
  [getAreActivitiesEnabled, getLatestActivity],
  (areActivitiesEnabled, latestActivity) => {
    return !!(
      areActivitiesEnabled &&
      latestActivity &&
      latestActivity.end === null
    );
  }
);

export { getHasOpenActivity };
