import _orderBy from 'lodash/orderBy';
import _keyBy from 'lodash/keyBy';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';

function getOrderedIds({
  entitiesById = {},
  idKey = 'id',
  orderKey,
  sortOrder = 'asc',
}) {
  const orderedEntities = _orderBy(entitiesById, [orderKey], sortOrder);
  return orderedEntities.map((entity) => {
    return entity[idKey];
  });
}

function getOrderedActivitySetRefs(activitySetsByRef) {
  return getOrderedIds({
    entitiesById: activitySetsByRef,
    idKey: 'activitySetRef',
    orderKey: 'closedAt',
    sortOrder: 'desc',
  });
}

function getOrderedActivityRefs(activitiesByRef) {
  return getOrderedIds({
    entitiesById: activitiesByRef,
    idKey: 'activityRef',
    orderKey: 'start',
    sortOrder: 'desc',
  });
}

function getOrderedJobIds(jobsById) {
  return getOrderedIds({
    entitiesById: jobsById,
    idKey: 'jobId',
    // match default order of operator-view/jobs query
    orderKey: 'name',
  });
}

function getNormalizedSetPollJobData({ setupTime, ...job }) {
  return {
    ...job,
    // endpoint sends as ms, but this is seconds in graphql schema and elsehwere in store
    // be sure to preserve null if present (represents job without expected setup time)
    setupTime: typeof setupTime === 'number' ? setupTime / 1000 : null,
  };
}

function getNormalizedActivitySetData(recentActivitySets = []) {
  const {
    activitySetsByRef,
    activitiesByRef,
    jobsById,
  } = recentActivitySets.reduce(
    (normalizedEntities, { job, activities, ...activitySet }) => {
      const { activitySetRef } = activitySet;
      return {
        activitySetsByRef: {
          ...normalizedEntities.activitySetsByRef,
          [activitySetRef]: activitySet,
        },
        activitiesByRef: {
          ...normalizedEntities.activitiesByRef,
          ..._keyBy(activities, 'activityRef'),
        },
        jobsById: {
          ...normalizedEntities.jobsById,
          ...(job?.jobId
            ? { [job.jobId]: getNormalizedSetPollJobData(job) }
            : {}),
        },
      };
    },
    { activitySetsbyRef: {}, activitiesbyRef: {}, jobsById: {} }
  );
  // intentionally overwriting
  return {
    activitySets: {
      byRef: activitySetsByRef,
      allRefs: getOrderedActivitySetRefs(activitySetsByRef),
    },
    activities: {
      byRef: activitiesByRef,
      allRefs: getOrderedActivityRefs(activitiesByRef),
    },
    jobs: {
      byId: jobsById,
      allIds: getOrderedJobIds(jobsById),
    },
  };
}

function setRecentActivitySetsData(state, activitySets) {
  const normalizedData = getNormalizedActivitySetData(activitySets);
  // note that we overwrite everything here on new poll data and don't merge it; this will need to change
  // when multiple sets on opdash are supported at a time, or if other components use job data from store
  return {
    ...state,
    recentActivitySetsPoll: {
      isInitialPollLoading: false,
      hasWriteLock: false,
    },
    activitySets: {
      byRef: normalizedData.activitySets.byRef,
      allRefs: normalizedData.activitySets.allRefs,
    },
    activities: {
      byRef: normalizedData.activities.byRef,
      allRefs: normalizedData.activities.allRefs,
    },
    jobs: {
      ...state.jobs,
      byId: normalizedData.jobs.byId,
      // there will only ever be one job at a time in store (for now), so this is ok
      allIds: Object.keys(normalizedData.jobs.byId),
    },
  };
}

function getSortedUniqueAnnotations(state, actionAnnotations) {
  const mergedAnnos = [...state.annotations.data, ...actionAnnotations];
  const uniqueAnnos = _uniqBy(mergedAnnos, (anno) => {
    return anno.id;
  });
  const sortedAnnos = _sortBy(uniqueAnnos, (anno) => {
    return anno.start;
  });
  return sortedAnnos;
}

export {
  setRecentActivitySetsData,
  getOrderedActivitySetRefs,
  getOrderedActivityRefs,
  getOrderedJobIds,
  getSortedUniqueAnnotations,
};
