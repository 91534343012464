/* eslint-disable no-console */

import { ApolloLink } from '@apollo/client';
import _some from 'lodash/some';
import _get from 'lodash/get';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { RetryLink } from '@apollo/client/link/retry';
import { bugsnag } from 'lib/external/bugsnag';
import { createLoginUrl } from 'lib/utils/request';
import graphqlLink, { socketConnection } from './graphql';
import restLink from './rest';
import session from './session';
import TokenStore from './TokenStore';

function logErrors(prefix, ...errors) {
  errors.forEach((error) => {
    console.error(prefix, error.message, error);
  });
}

async function notifyBugsnag(prefix, metaData, ...errors) {
  return Promise.all(
    errors.map(async (error) => {
      return bugsnag.notify(new Error(`${prefix}${error.message}`), (event) => {
        event.addMetadata('user', metaData);
      });
    })
  );
}

/* Without this RetryLink, subscriptions eventually disconnect after many JWT failures */
export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 3 * 60 * 1000,
    jitter: true,
  },
  attempts: {
    max: Infinity,
    retryIf: (error) => {
      return error.message.includes('JWTExpired');
    },
  },
});

const errorLink = onError(
  ({ forward, graphQLErrors, operation, networkError }) => {
    const userId = new TokenStore('userId').get();
    const userCompany = new TokenStore('company').get();
    const apiKey = new TokenStore('apikey').get();

    const errMetaData = { userId, company: userCompany };

    if (graphQLErrors) {
      logErrors('[GraphQL error]:', ...graphQLErrors);
      notifyBugsnag('[GraphQL error]:', errMetaData, ...graphQLErrors);
    }
    if (networkError) logErrors('[Network error]:', networkError);

    if (networkError) {
      const { statusCode, result } = networkError;
      if (statusCode === 401 || statusCode === 404) {
        const loginUrl = createLoginUrl();
        const force = statusCode === 401;
        session.clear(force);

        if (force && apiKey?.startsWith('op')) {
          notifyBugsnag('[Auth error]: ', errMetaData, networkError).then(
            () => {
              window.location = loginUrl;
            }
          );
        } else {
          window.location = loginUrl;
        }
        if (result.code === 'SoftAuthenticationError') {
          /* eslint-disable no-alert */
          alert(_get(result, 'message', 'There was an error logging in'));
        }
      }
    }

    const isGraphQLErr = _some(graphQLErrors || [], (err) => {
      const code = err.extensions ? err.extensions.code : null;
      return code === 'invalid-jwt';
    });

    const isSubscriptionErr =
      getMainDefinition(operation.query).operation === 'subscription' &&
      networkError;

    if (isGraphQLErr || isSubscriptionErr) {
      socketConnection.close(4205, 'Client Restart');
      session.clearJwt();
    }

    const isMutationErr =
      getMainDefinition(operation.query).operation === 'mutation';

    if (isMutationErr) {
      // bail so failed mutations do not retry by default when passed on to forward
      // https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
      // note that networkError may sometimes be undefined; this seems to be contingent on
      // err statusCode (see https://github.com/machinemetrics/OperatorView/pull/567 for context)
      return null;
    }

    return forward(operation);
  }
);

export { graphqlLink };
export default ApolloLink.from([retryLink, errorLink, restLink, graphqlLink]);
