import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import { TextMedia } from 'pages/Machine/styled';

import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';
import { CounterBlock } from '@m12s/component-library';
import { UtilizationChart } from 'components/charts';
import {
  ChartLabel,
  CounterLabel,
  WheelCounterPadding,
  WheelChartPadding,
} from 'components/WheelLayout';
import { formatValue } from 'lib/utils/text';

export const ExpectedItems = ({ value }) => {
  const { t } = useTranslation();

  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );

  return openActivitySetHasNoStandards ? (
    <WheelChartPadding>
      <ChartLabel
        tooltip={t(
          'This area displays the average Machine Utilization percentages at specific times during the current shift.'
        )}
      >
        {_startCase(t('utilization'))}
      </ChartLabel>
      <UtilizationChart />
    </WheelChartPadding>
  ) : (
    <WheelCounterPadding top right>
      <CounterBlock
        label={
          <CounterLabel>
            <TextMedia min={40}>
              {_startCase(t('expected to have made'))}
            </TextMedia>
            <TextMedia max={40}>{_startCase(t('expected'))}</TextMedia>
          </CounterLabel>
        }
        value={formatValue(value)?.toLocaleString()}
        align="right"
      />
    </WheelCounterPadding>
  );
};
