import _partition from 'lodash/partition';
import { createSelector } from 'reselect';

import { getStatusIntervals } from 'lib/selectors/getStatusIntervals';

const getPartitionedStatusIntervals = createSelector(
  [getStatusIntervals],
  (intervals) => {
    return _partition(intervals, { label: 'ACTIVE' });
  }
);

export { getPartitionedStatusIntervals };
