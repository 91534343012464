import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import TokenStore from 'lib/api/links/TokenStore';

const Done = ({ loadError }) => {
  const redirectStore = new TokenStore('redirectTo');
  const redirectTo = redirectStore.get() || '/switch-machines';
  redirectStore.clear();

  return loadError ? (
    <Redirect
      to={{
        pathname: '/login',
        state: { loadError },
      }}
    />
  ) : (
    <Redirect to={redirectTo} />
  );
};

Done.propTypes = {
  loadError: PropTypes.object,
};

export default Done;
