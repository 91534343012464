import { createSelector } from 'reselect';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getCurrentWorkOrderOp } from './getCurrentWorkOrderOp';

const getCurrentPartOperationRef = createSelector(
  [getHasOpenActivitySet, getCurrentWorkOrderOp],
  (hasOpenActivitySet, currentWorkOrderOp) => {
    if (hasOpenActivitySet && currentWorkOrderOp) {
      return currentWorkOrderOp.partOperationRef;
    }
    return null;
  }
);

export { getCurrentPartOperationRef };
