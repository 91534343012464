import styled from 'styled-components';
import { Icon, Button } from '@m12s/component-library';

import { H5 } from 'components/Text';

const Title = styled(H5)`
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
`;

const IconWrapper = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

const CloseIcon = styled(Icon)`
  font-size: 2.5rem;
  line-height: 2.5rem;
`;

const ModalButton = styled(Button)`
  flex: 1;
  height: 3rem;
  margin: 0;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export { CloseIcon, ModalButton as Button, Title, IconWrapper };
