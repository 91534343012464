/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import {
  Timetable,
  Thead,
  Th,
  Tbody,
  Td,
  palette,
  Tag,
} from '@m12s/component-library';

import { formatDuration } from 'lib/utils/date';
import { DurationTicker } from 'components/DurationTicker';
import { P, Small } from 'components/Text';
import { ErrorInline } from 'components/ErrorInline';
import {
  Cell,
  Category,
  Empty,
  LoadingTd,
  LoadingText,
  LoadingTbody,
  Label,
} from '../Table';

const TableRow = ({ start, end, description, label, duration, t }) => {
  const startDate = moment(start);

  return (
    <>
      <Td>
        <Cell>
          <Small bold>{startDate.format('L')}</Small>
          <Small>{startDate.format('LT')}</Small>
        </Cell>
      </Td>
      <Td>
        <Cell>
          <Label semibold>{description}</Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {label && (
              <Category
                color={label === 'FAULT' ? palette.Red500 : palette.Orange500}
              >
                {t(label.toLowerCase()).toUpperCase()}{' '}
              </Category>
            )}
            {!end && <Tag>{t('Active')}</Tag>}
          </div>
        </Cell>
      </Td>
      <Td align="right">
        <Cell>
          <P>
            {end ? (
              formatDuration(duration)
            ) : (
              <DurationTicker startDate={moment(start)} />
            )}
          </P>
        </Cell>
      </Td>
    </>
  );
};

const Table = ({ alarms, loading, error, onLoadMore }) => {
  const { t } = useTranslation();

  return (
    <Timetable templateColumns="minmax(6.5rem, 0.5fr) 5fr minmax(10.5rem, 0.5fr)">
      <Thead>
        <Th>{_capitalize(t('date'))}</Th>
        <Th>{_capitalize(t('details'))}</Th>
        <Th align="right">{_capitalize(t('duration'))}</Th>
      </Thead>
      {error ? (
        <ErrorInline
          title={t('There was an error loading the alarm list')}
          {...error}
          reload
        />
      ) : loading && alarms.length === 0 ? (
        <LoadingTbody
          rows={20}
          row={
            <>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd align="right">
                <LoadingText />
                <LoadingText />
              </LoadingTd>
            </>
          }
        />
      ) : alarms.length === 0 ? (
        <Empty variant="noResults" />
      ) : (
        <Tbody
          onLoadMore={onLoadMore}
          loadMoreText={_startCase(t('load more'))}
        >
          {alarms.map((alarm) => {
            return (
              <TableRow
                {...alarm}
                key={`${alarm.start}${alarm.label}${alarm.sequence}`}
                t={t}
              />
            );
          })}
        </Tbody>
      )}
    </Timetable>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      duration: PropTypes.number,
      label: PropTypes.string,
      sequence: PropTypes.number,
    })
  ),
};

export { Table };
