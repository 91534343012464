import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { palette } from '@m12s/component-library';

import { AppNav } from 'components/AppNav';
import { HeaderLayout, HeaderText } from 'components/AppNav/styled';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

const Nav = ({ handleOnClose, handleOnBack, jobName }) => {
  const { t } = useTranslation();

  const laborTicketEnabled = useIsLaborTicketFlow();
  const workOrderFlowEnabled = useIsWorkOrderFlow();

  return (
    <AppNav onClose={handleOnClose} onBack={handleOnBack}>
      <HeaderLayout>
        {!(laborTicketEnabled || workOrderFlowEnabled) && (
          <HeaderText bold color={palette.Yellow400}>
            {`${t('Operation')}: ${jobName}`}
          </HeaderText>
        )}
        <HeaderText semibold color={palette.White}>
          {t('Select Activity')}
        </HeaderText>
      </HeaderLayout>
    </AppNav>
  );
};

Nav.propTypes = {
  jobName: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnBack: PropTypes.func.isRequired,
};

export { Nav };
