import styled from 'styled-components';
import { palette, Button } from '@m12s/component-library';

import { Text } from 'components/Text';
import { Input } from 'components/NumberInput/styled';

const Body = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.5rem;
  max-width: 55rem;
  width: 100%;

  & > * {
    margin-bottom: 2.625rem;
  }
`;

const ReminderContainer = styled.div`
  background-color: ${palette.Purple100};
  border: 0.065rem solid ${palette.Purple200};
  width: 95%;
  margin-bottom: 1rem;
`;

const ReminderIconTextContainer = styled.div`
  color: ${palette.Purple600};
  margin: 1rem;
  display: flex;
  flex-direction: row;
`;

const ReminderText = styled(Text)`
  color: ${palette.Purple600};
  font-size: 0.75rem;
  margin: auto;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.White};
  border: 0.065rem solid ${palette.Grey300};
  width: 95%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const GroupDataContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 90%;
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const EachRowData = styled.div`
  margin-right: 1rem;
  flex-basis: 33%;
  overflow-wrap: anywhere;
  max-height: 11rem;
  overflow: hidden;
`;

const DataColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const EachColumnData = styled.div``;

const DataTitle = styled.div`
  color: ${palette.Grey600};
  font-size: ${({ heavyFontWeight }) => {
    return heavyFontWeight ? '1rem' : '0.75rem';
  }};
  font-weight: ${({ heavyFontWeight }) => {
    return heavyFontWeight ? 700 : 500;
  }};
`;

const SetupPerformanceTitle = styled(DataTitle)`
  color: ${palette.Grey600};
  font-size: 0.75rem;
  font-weight: 500;
`;

const DisplayData = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 1.75rem;
  color: ${({ partPerformancePositive, partPerformanceNegative }) => {
    if (partPerformancePositive) {
      return `${palette.Green600}`;
    }
    if (partPerformanceNegative) {
      return `${palette.Red600}`;
    }
    return `${palette.Grey700}`;
  }};
  align-items: center;
`;

const SetupPerformanceData = styled(DisplayData)`
  color: ${({ setupPerformancePositive }) => {
    if (setupPerformancePositive) {
      return `${palette.Green600}`;
    }
    return `${palette.Red600}`;
  }};
`;

const PartsInputGroup = styled.div`
  margin-top: 0.5rem;
`;

const GoodPartsInput = styled(Input)`
  height: 3rem;
  background: ${palette.Grey100};
  font-size: 1.75rem;
  text-align: left;
  color: ${palette.Grey700};
  margin-right: 0.625rem;
`;

const PlusMinusButton = styled(Button)`
  border: 0.0125rem solid ${palette.Grey400};
  background-color: ${palette.White};
  color: ${palette.Grey400};
  margin-right: 0.5rem;
  height: 3rem;
  width: 3rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 3.5rem;
  font-size: 0.875rem;
`;

const CheckboxText = styled.div`
  margin: auto;
`;

export {
  Body,
  ReminderContainer,
  ReminderIconTextContainer,
  ReminderText,
  DataContainer,
  GroupDataContainer,
  DataRow,
  EachRowData,
  DataColumn,
  EachColumnData,
  DataTitle,
  DisplayData,
  SetupPerformanceData,
  GoodPartsInput,
  PlusMinusButton,
  SetupPerformanceTitle,
  CheckboxContainer,
  CheckboxText,
  PartsInputGroup,
};
