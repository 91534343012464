import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';

import { Wrapper, Checkbox, SplitButton } from './styled';

const FiltersHeader = ({ filters, setFilters, split }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Checkbox
        id="uncategorized-checkbox"
        inputProps={{
          checked: filters.uncategorized,
          onChange: (evt) => {
            return setFilters({
              ...filters,
              uncategorized: evt.target.checked,
            });
          },
        }}
      >
        {_startCase(t('only uncategorized'))}
      </Checkbox>
      <Checkbox
        id="long-events-checkbox"
        inputProps={{
          checked: filters.long,
          onChange: (evt) => {
            return setFilters({ ...filters, long: evt.target.checked });
          },
        }}
      >
        {_startCase(t('only long events'))}
      </Checkbox>
      {split && (
        <SplitButton onClick={split} id="split-downtime">
          {_startCase(t('split current event'))}
        </SplitButton>
      )}
    </Wrapper>
  );
};

FiltersHeader.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  split: PropTypes.func,
};

export { FiltersHeader, Checkbox };
