import { createSelector } from 'reselect';
import { takeLatest } from 'lib/utils/date';

import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getIsInitialShiftPollLoading } from 'lib/selectors/getIsInitialShiftPollLoading';
import { getLatestActivitySetStartAt } from 'lib/selectors/getLatestActivitySetStartAt';
import { getLatestActivityEndAt } from 'lib/selectors/getLatestActivityEndAt';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getIsLatestActivitySetClosed } from 'lib/selectors/getIsLatestActivitySetClosed';
import { getMaxScopeQueryStart } from 'lib/selectors/getMaxScopeQueryStart';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getShiftEnd } from 'lib/selectors/getShiftEnd';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';
import { getStartOfNextShopDay } from 'lib/selectors/getStartOfNextShopDay';

const defaultScope = {
  startDate: null,
  endDate: null,
};

const getStandardScope = createSelector(
  [
    getIsInitialActivitySetPollLoading,
    getIsInitialShiftPollLoading,
    getStartOfCurrentShopDay,
    getStartOfNextShopDay,
    getLatestActivitySetStartAt,
    getLatestActivityEndAt,
    getHasOpenActivitySet,
    getIsLatestActivitySetClosed,
    getMaxScopeQueryStart,
    checkIsLatestShiftActive,
    getShiftStart,
    getShiftEnd,
  ],
  (
    isInitialActivitySetPollLoading,
    isInitialShiftPollLoading,
    startOfCurrentShopDay,
    startOfNextShopDay,
    latestActivitySetStartAt,
    latestActivityEndAt,
    hasOpenActivitySet,
    isLatestActivitySetClosed,
    maxScopeQueryStart,
    isLatestShiftActive,
    shiftStart,
    shiftEnd
  ) => {
    const haveScopeCalcDepsLoaded = !!(
      !isInitialActivitySetPollLoading &&
      !isInitialShiftPollLoading &&
      startOfCurrentShopDay &&
      startOfNextShopDay
    );
    if (haveScopeCalcDepsLoaded) {
      // if not within an activity set and machine does not have an active shift,
      // include shop day as a valid scope change event
      const shouldUseShopDayScope = !!(
        !hasOpenActivitySet && !isLatestShiftActive
      );
      const startDate = takeLatest(
        shouldUseShopDayScope ? startOfCurrentShopDay : maxScopeQueryStart,
        shiftStart || startOfCurrentShopDay,
        latestActivitySetStartAt || maxScopeQueryStart,
        // only consider latest activity end when latest set has been closed (ie no job running)
        isLatestActivitySetClosed ? latestActivityEndAt : maxScopeQueryStart,
        maxScopeQueryStart
      );
      return {
        startDate,
        // fallback to "end" of current shop day / start of next shop day
        // in the event that this machine has no active shift runs
        endDate: isLatestShiftActive ? shiftEnd : startOfNextShopDay,
      };
    }
    // subscriber is responsible for handling loading/invalid scope state
    // be sure to check for falsey vals and show loading, bail out, etc if
    // scope start and/or scope end are dependencies
    return defaultScope;
  }
);

export { getStandardScope };
