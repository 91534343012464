import {
  isSame,
  takeLatest,
  takeEarliest,
  isAfter,
  isBefore,
  isSameOrAfter,
  isSameOrBefore,
} from 'lib/utils/date';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _first from 'lodash/first';
import _update from 'lodash/update';
import _takeWhile from 'lodash/takeWhile';
import _dropWhile from 'lodash/dropWhile';
import _dropRightWhile from 'lodash/dropRightWhile';
import _takeRightWhile from 'lodash/takeRightWhile';
import { MACHINE_STATUSES } from 'lib/constants';

// chopBefore and chopAfter both assume:
// intervals are non-overlapping
// sorted by "earliest" first (start ASC)
// intervals use ISO formatted dt's (for string comparison)
// rangeStart is ISO str

function chopAfter(intervals, rangeEnd, fromRight) {
  if (!rangeEnd) return intervals || [];

  const keepers = fromRight
    ? _dropRightWhile(intervals, ({ start }) => {
        return isSameOrAfter(start, rangeEnd);
      })
    : _takeWhile(intervals, ({ start }) => {
        return isBefore(start, rangeEnd);
      });

  const overlap = _last(keepers);

  if (overlap && (!overlap.end || isAfter(overlap.end, rangeEnd))) {
    _update(keepers, keepers.length - 1, (interval) => {
      return { ...interval, end: rangeEnd };
    });
  }

  return keepers;
}

function chopBefore(intervals, rangeStart, fromRight) {
  if (!rangeStart) return [];

  const keepers = fromRight
    ? _takeRightWhile(intervals, (interval) => {
        return !interval.end || isAfter(interval.end, rangeStart);
      })
    : _dropWhile(intervals, (interval) => {
        return isSameOrBefore(interval.end, rangeStart);
      });

  const overlap = _first(keepers);

  if (overlap && isBefore(overlap.start, rangeStart)) {
    _update(keepers, 0, (interval) => {
      return { ...interval, start: rangeStart };
    });
  }

  return keepers;
}

function filterOutZeroDuration(intervals) {
  return intervals.filter((interval) => {
    return interval.end !== interval.start;
  });
}

function sortIntervalsByStart(a, b) {
  if (!a.end) return 1;

  if (!b.end) return -1;

  return a.start > b.start ? 1 : -1;
}

function joinIntervalSets(firstSet, secondSet) {
  if (!firstSet || !firstSet.length) {
    return secondSet;
  }
  if (!secondSet || !secondSet.length) {
    return firstSet;
  }

  const firstHalf = chopAfter(firstSet, _get(secondSet, [0, 'start']));

  if (!firstHalf.length) {
    return secondSet;
  }

  if (
    _last(firstHalf).label === 'IDLE' &&
    _first(secondSet).label === 'IDLE' &&
    isSame(_last(firstHalf).end, _first(secondSet).start)
  ) {
    const throwaway = secondSet.shift();
    _last(firstHalf).end = throwaway.end;
  }

  return [...firstHalf, ...secondSet];
}

function createStatusIntervalsFromExecution(executionIntervals) {
  return filterOutZeroDuration(executionIntervals)
    .sort(sortIntervalsByStart)
    .reduce((accum, curr) => {
      const lastInterval = accum.length ? accum[accum.length - 1] : {};

      if (
        curr.label === 'ACTIVE' ||
        curr.label === 'UNAVAILABLE' ||
        !accum.length ||
        lastInterval.label !== 'IDLE' ||
        lastInterval.end !== curr.start
      ) {
        return [
          ...accum,
          {
            ...curr,
            label: _get(MACHINE_STATUSES, curr.label, 'idle').toUpperCase(),
          },
        ];
      }
      return _update(accum, accum.length - 1, (interval) => {
        return { ...interval, end: curr.end };
      });
    }, []);
}

function mergeContiguousDowntimeIntervals(statusIntervals) {
  return statusIntervals.reduce((accum, curr) => {
    const lastInterval = accum.length ? accum[accum.length - 1] : {};

    if (curr.start !== lastInterval.end) {
      return [...accum, curr];
    }
    return _update(accum, accum.length - 1, (interval) => {
      return { ...interval, end: curr.end };
    });
  }, []);
}

function addExtendedDowntime(lastNonIdleInterval, downtimeIntervals) {
  if (!downtimeIntervals || !downtimeIntervals.length || !lastNonIdleInterval) {
    return;
  }

  if (
    lastNonIdleInterval.end &&
    lastNonIdleInterval.end < _first(downtimeIntervals).start &&
    _first(downtimeIntervals).label === 'IDLE'
  ) {
    _first(downtimeIntervals).start = lastNonIdleInterval.end;
  }
}

// assumes interval sets are:
// - already sorted by start time ASC
// - use { start, end } signature
// - are comprised of non-overlapping intervals
// null ends are valid here (on end of interval sets only), as it indicates the final
// interval is open ... the invocation context is responsible for truncating end if
// necessary, but this utility will get the intersection if only one set is open, and
// return an open ended intersection (null on final intersecting interval end) if both
// sets are open ended

function getIntervalSetsIntersection(setA = [], setB = []) {
  let ptrA = 0;
  let ptrB = 0;
  const intersectingIntervals = [];
  while (ptrA < setA.length && ptrB < setB.length) {
    const startA = setA[ptrA].start;
    const startB = setB[ptrB].start;
    const endA = setA[ptrA].end;
    const endB = setB[ptrB].end;
    const startIntersect = takeLatest(startA, startB);
    // null / open ends will be filtered out in takeEarliest, so we need
    // to explicitly set here when both ends are open
    const endIntersect = takeEarliest(endA, endB) || null;
    if (startIntersect < endIntersect || endIntersect === null) {
      intersectingIntervals.push({
        start: startIntersect,
        end: endIntersect,
      });
    }
    const isEndAClosed = endA !== null;
    const isEndBClosed = endB !== null;
    const isEndBOpen = !isEndBClosed;
    const areBothEndsClosed = isEndAClosed && isEndBClosed;
    const isEndABeforeEndB =
      (areBothEndsClosed && endA < endB) || (isEndAClosed && isEndBOpen);
    if (isEndABeforeEndB) {
      ptrA += 1;
    } else {
      ptrB += 1;
    }
  }
  return intersectingIntervals;
}

const fallsWithinActivities = (ts, activities) => {
  if (!activities) {
    return false;
  }
  return !!activities.find((activity) => {
    return activity.start < ts && (!activity.end || activity.end > ts);
  });
};

export {
  chopBefore,
  chopAfter,
  filterOutZeroDuration,
  sortIntervalsByStart,
  joinIntervalSets,
  createStatusIntervalsFromExecution,
  addExtendedDowntime,
  mergeContiguousDowntimeIntervals,
  getIntervalSetsIntersection,
  fallsWithinActivities,
};
