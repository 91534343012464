import { createSelector } from 'reselect';
import { getWorkOrderManagement } from './getWorkOrderManagement';

const getPendingActivityTypeRef = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement?.pendingActivityTypeRef;
  }
);

export { getPendingActivityTypeRef };
