import _startCase from 'lodash/startCase';
import React from 'react';
import { CounterBlock, ProgressBar, palette } from '@m12s/component-library';
import { formatValueWithinLimit } from 'lib/utils/text';
import { useTranslation } from 'react-i18next';
import { Container, Quantity, Description, NoWrap } from './styled';

const PartsRequiredMeter = ({ partsMadeForJob, partsRequired }) => {
  const { t } = useTranslation();
  const progressPercentage = (partsMadeForJob / partsRequired) * 100 || 0;
  const partsMadeDisplay = formatValueWithinLimit(partsMadeForJob, 10000000);
  const partsRequiredDisplay = formatValueWithinLimit(partsRequired, 10000000);
  const longText = (partsMadeDisplay + partsRequiredDisplay).length > 15;

  return (
    <Container>
      <CounterBlock
        align="left"
        label={
          <div>
            {_startCase(t('operation total'))}{' '}
            <NoWrap>({_startCase(t('all machines'))})</NoWrap>
          </div>
        }
        value={
          <>
            <Quantity shrink={longText}>
              {partsMadeDisplay} / {partsRequiredDisplay}
            </Quantity>
            <ProgressBar
              value={progressPercentage}
              height="0.5rem"
              sharpEdge
              stripeColor={
                progressPercentage <= 100 ? palette.Purple600 : palette.Red700
              }
              color={
                progressPercentage <= 100 ? palette.Purple500 : palette.Red600
              }
            />
            <Description>
              {_startCase(t('quantity produced'))} /{' '}
              {_startCase(t('quantity required'))}
            </Description>
          </>
        }
      />
    </Container>
  );
};

export { PartsRequiredMeter };
