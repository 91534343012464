import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { DurationTicker } from 'components/DurationTicker';
import { getJobStatus } from 'lib/selectors/getJobStatus';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';
import { getAdjustedSetupDurationDates } from 'lib/selectors/getAdjustedSetupDurationDates';
import { getLatestSetupActivity } from 'lib/selectors/getLatestSetupActivity';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getScopeNetCyclesFinished } from 'lib/selectors/getScopeNetCyclesFinished';

import {
  TextMedia,
  LoadingStatTabLabel,
  LoadingStatTabSecondaryLabel,
} from '../styled';
import { DURATION_FORMAT, DURATION_OPTIONS } from './constants';

export const CycleCountTab = () => {
  const { t } = useTranslation();

  const jobStatus = useSelector(getJobStatus);
  const isPaused = useSelector(getIsPaused);
  const hasOpenActivitySetInSetup = useSelector(getHasOpenActivitySetInSetup);
  const latestSetupActivity = useSelector(getLatestSetupActivity);
  const { adjustedSetupStartDate } = useSelector(getAdjustedSetupDurationDates);

  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const scopeNetCyclesFinished = useSelector(getScopeNetCyclesFinished);

  return hasOpenActivitySetInSetup || jobStatus === 'setup' ? (
    <StatTab
      id="cycle-count-tab"
      value="cycleCount"
      label={t('Setup')}
      secondaryLabel={
        adjustedSetupStartDate && (
          <DurationTicker
            startDate={adjustedSetupStartDate}
            endDateOverride={isPaused ? latestSetupActivity.end : null}
            format={DURATION_FORMAT}
            formatOptions={{ ...DURATION_OPTIONS, trim: 'small' }}
          />
        )
      }
    />
  ) : (
    <StatTab
      id="cycle-count-tab"
      value="cycleCount"
      label={
        <LoadingStatTabLabel loading={arePartCountBucketsLoading}>
          <TextMedia max={60}>{_startCase(t('Cycles'))}</TextMedia>
          <TextMedia min={60}>{_startCase(t('cycle count'))}</TextMedia>
        </LoadingStatTabLabel>
      }
      secondaryLabel={
        arePartCountBucketsLoading ? (
          <LoadingStatTabSecondaryLabel />
        ) : (
          scopeNetCyclesFinished || '-'
        )
      }
    />
  );
};
