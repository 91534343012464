import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  palette,
  Checkbox,
  Button,
  LoadingCircle,
} from '@m12s/component-library';
import _capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import { spinner } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';

import { SearchInput } from 'components/SearchInput';
import { H5 } from 'components/Text';

import {
  GET_MACHINE_SCHEDULE,
  GET_WORK_ORDER_OPERATIONS,
} from 'lib/api/queries';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getCurrentPartOperationRef } from 'lib/selectors/getCurrentPartOperationRef';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getMachine } from 'lib/selectors/getMachine';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { actionSetDefaultManualEntry } from 'lib/actions';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';
import EmptyMessage from './EmptyMessage';

import { getHeaderText, getByPartsWhere } from './helpers';

const StyledButton = styled(Button)`
  width: fit-content;
  margin: 0 auto 2rem auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:disabled {
    opacity: 0.3;
    :hover {
      cursor: not-allowed;
    }
  }
`;

const HeaderText = styled(H5)`
  width: 100%;
  text-align: left;
  padding-left: 1.5rem;
  font-weight: 500;
  margin-left: -8rem;
`;

const OnlyMachineCheckbox = ({ handleCheck, checked }) => {
  return (
    <Checkbox
      inputProps={{
        id: 'searchAllOpenWorkOps',
        name: 'searchAllOpenWorkOps',
        checked,
        onChange: handleCheck,
      }}
      labelProps={{
        style: {
          fontSize: '0.875rem',
        },
      }}
    >
      Only show work scheduled for this machine
    </Checkbox>
  );
};

OnlyMachineCheckbox.propTypes = {
  handleCheck: PropTypes.func,
  checked: PropTypes.bool,
};

const LoadMoreButton = ({ handleClick, disabled, loading }) => {
  return (
    <StyledButton onClick={handleClick} disabled={disabled} rounded>
      {loading && (
        <LoadingCircle icon={spinner} size="20px" color={palette.White} />
      )}
      Load more
    </StyledButton>
  );
};

LoadMoreButton.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const provideFallbackForCycleTimeMs = (operations) => {
  return operations.map((operation) => {
    const partOperationCycleTimeMs =
      operation.matchingPartOperations[0]?.cycleTimeMs || 0;
    return {
      ...operation,
      // if no operation cycleTimeMs, use cycleTimeMs on partOperation
      cycleTimeMs: operation.cycleTimeMs || partOperationCycleTimeMs,
    };
  });
};

const useSelectOperationFlow = ({ setManualModalOpen, setNewRunModalOpen }) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const productionScheduleEnabled =
    flags[FLAG_KEYS.PRODUCTION_SCHEDULE_ENABLED];
  const isLaborTicketFlow = useIsLaborTicketFlow();

  const [search, setSearch] = useState('');
  const searchWhereClause = {
    _or: [
      { workOrderId: { _ilike: `%${search}%` } },
      { workOrder: { partNumber: { _ilike: `%${search}%` } } },
    ],
  };

  const [orderOperations, setOrderOperations] = useState(null);
  const [allOrderOperations, setAllOrderOperations] = useState(null);

  const [nextStep, setNextStep] = useState(null);
  const [onlyMachineChecked, setOnlyMachineChecked] = useState(true);

  const [pageLoading, setPageLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const machine = useSelector(getMachine);
  const latestJob = useSelector(getLatestJob);
  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const partOperationRef = useSelector(getCurrentPartOperationRef);
  const currentOrderOperation = useSelector(getCurrentWorkOrderOp);
  const currentOrderOperationRef = currentOrderOperation?.workOrderOperationRef;

  const [schedule, setSchedule] = useState(null);

  const isUpdate = !!queryParams.updateWorkOrderId;
  const isSearchAll = !!allOrderOperations;
  const isSearchingByPart =
    !productionScheduleEnabled &&
    (nextStep === 'all' || nextStep === 'allOperationsByPart') &&
    (partOperationRef || isAPMEnabled);

  // INITIAL LOAD FOR SCHEDULE  - IF productionScheduleEnabled
  useQuery(GET_MACHINE_SCHEDULE, {
    variables: {
      machineId: machine.id,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !productionScheduleEnabled,
    onCompleted: ({ machineSchedule }) => {
      if (machineSchedule.schedule) {
        const machineValues = Object.values(machineSchedule.schedule)[0];
        const formatted = machineValues.upcoming.map((orderOp) => {
          return {
            ...orderOp,
            closedDate: null,
            finishQuantity: orderOp.quantityRequired,
            scheduledStartDate: orderOp.scheduledStartAt,
            sequenceNumber: orderOp.sequence,
            workOrder: {
              lot: orderOp.lot,
              partNumber: orderOp.partNumber,
              split: orderOp.split,
              workOrderId: orderOp.workOrderId,
              dueDate: orderOp.workOrderDueDate,
            },
            workOrderId: orderOp.workOrderId,
            workOrderOperationRef: orderOp.workOrderOperationRef,
          };
        });

        setSchedule(formatted);
        setOrderOperations(formatted);
        setPageLoading(false);
        setNextStep('all');
      }
    },
  });

  // INITIAL LOAD FOR LOADING BY PART - IF NOT productionScheduleEnabled
  useQuery(GET_WORK_ORDER_OPERATIONS, {
    fetchPolicy: 'no-cache',
    skip: productionScheduleEnabled,
    variables: {
      limit: 50,
      where: {
        ...getByPartsWhere({
          orderOperation: currentOrderOperation,
          isAPMEnabled,
          latestJob,
        }),
        resourceId: { _in: machine.erpResources },
      },
    },
    onCompleted: (data) => {
      if (isAPMEnabled) {
        setNextStep('allOperationsByPart');
      } else {
        setNextStep('all');
      }
      if (data.erpWorkOrderOperations) {
        const withCycleTimeMsFallback = provideFallbackForCycleTimeMs(
          data.erpWorkOrderOperations
        );
        setOrderOperations(withCycleTimeMsFallback);
      }
      setPageLoading(false);
    },
  });

  // SET INITIAL NEXT STEP
  useEffect(() => {
    if (machine.id) {
      if (!productionScheduleEnabled && (isAPMEnabled || partOperationRef)) {
        return setNextStep('allOperationsByPart');
      }
      return setNextStep('all');
    }
    return null;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [productionScheduleEnabled]);

  const [
    requestOrderOperations,
    { variables: { limit = 50 } = {}, error, loading },
  ] = useLazyQuery(GET_WORK_ORDER_OPERATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res.erpWorkOrderOperations) {
        const withCycleTimeMsFallback = provideFallbackForCycleTimeMs(
          res.erpWorkOrderOperations
        );

        setAllOrderOperations(withCycleTimeMsFallback);
      }
      setPageLoading(false);
      setLoadingMore(false);
    },
  });

  const queries = {
    all: ({ queryLimit = 50, searchClause = searchWhereClause } = {}) => {
      return requestOrderOperations({
        variables: {
          where: {
            ...searchClause,
            closedDate: { _is_null: true },
            status: { _nin: ['C', 'X', 'COMPLETED'] },
            workOrderOperationRef: { _neq: currentOrderOperationRef },
          },
          limit: queryLimit,
        },
      });
    },
    manual: () => {},
    allOperationsByPart: ({
      queryLimit = 50,
      searchClause = searchWhereClause,
    } = {}) => {
      return requestOrderOperations({
        variables: {
          where: {
            ...searchClause,
            ...getByPartsWhere({
              orderOperation: currentOrderOperation,
              isAPMEnabled,
              latestJob,
            }),
          },
          limit: queryLimit,
        },
      });
    },
  };

  const debouncedRequestAllWorkOps = debounce((value) => {
    const newSearch = {
      _or: [
        { workOrderId: { _ilike: `%${value}%` } },
        { workOrder: { partNumber: { _ilike: `%${value}%` } } },
      ],
    };

    if (isAPMEnabled && nextStep === 'all' && !productionScheduleEnabled) {
      return queries.allOperationsByPart({
        searchClause: newSearch,
      });
    }
    return queries.all({
      searchClause: newSearch,
    });
  }, 850);

  const handleCheck = () => {
    if (onlyMachineChecked) {
      setPageLoading(true);
      if (nextStep === 'all' || nextStep === 'manual') {
        queries.all();
      } else {
        queries.allOperationsByPart();
      }
    } else {
      setAllOrderOperations(null);
    }
    setOnlyMachineChecked(!onlyMachineChecked);
  };

  const SearchMoreButtons = {
    all: () => {
      return (
        <Button
          onClick={() => {
            setOnlyMachineChecked(false);
            setPageLoading(true);
            queries.all();
            setNextStep('manual');
          }}
        >
          Search across all orders
        </Button>
      );
    },
    manual: () => {
      return (
        <Button
          onClick={() => {
            dispatch(actionSetDefaultManualEntry(search || ''));
            if (isUpdate) {
              return setManualModalOpen(true);
            }
            if (!hasOpenActivitySet) {
              return history.push(
                `${Routes.machineIdSelectJobPath(machine.id)}?manual=true`
              );
            }
            return setNewRunModalOpen(true);
          }}
        >
          Enter Manually
        </Button>
      );
    },
    allOperationsByPart: () => {
      return (
        <Button
          onClick={() => {
            setOnlyMachineChecked(false);
            setPageLoading(true);
            queries.allOperationsByPart();
            setNextStep('all');
          }}
        >
          Search across all machines
        </Button>
      );
    },
  };

  const SearchMoreButton = SearchMoreButtons[nextStep];
  const headerText = getHeaderText({
    currentOrderOperation,
    latestJob,
    hasOpenActivitySet,
    searchingByPart: isSearchingByPart,
  });

  const Components = {
    Header: <HeaderText>{headerText}</HeaderText>,
    EmptyContent: (
      <EmptyMessage
        loading={pageLoading}
        error={error}
        search={search}
        matchingPartOperation={isSearchingByPart}
        SearchButton={
          !(isLaborTicketFlow && nextStep === 'manual')
            ? SearchMoreButton
            : null
        }
      />
    ),
    SearchInput: (
      <SearchInput
        loading={loading && !pageLoading}
        inputProps={{
          value: search,
          onChange: ({ target: { value } }) => {
            if (isSearchAll) {
              debouncedRequestAllWorkOps(value);
            }
            setSearch(value);
          },
          placeholder: _capitalize(t('search by keyword')),
        }}
      />
    ),
    Checkbox: (
      <OnlyMachineCheckbox
        handleCheck={handleCheck}
        checked={onlyMachineChecked}
      />
    ),
    LoadMoreButton: !isSearchAll ? (
      <></>
    ) : (
      <LoadMoreButton
        handleClick={(event) => {
          event.currentTarget.focus();
          setLoadingMore(true);
          if (nextStep === 'all') {
            return queries.allOperationsByPart({ queryLimit: limit + 50 });
          }
          return queries.all({ queryLimit: limit + 50 });
        }}
        disabled={loadingMore || limit > allOrderOperations?.length}
        loading={loadingMore}
      />
    ),
  };

  return {
    pageLoading,
    operations: allOrderOperations || orderOperations,
    searchAll: isSearchAll,
    search,
    error,
    schedule,
    Components,
  };
};

useSelectOperationFlow.propTypes = {
  setManualModalOpen: PropTypes.func,
  setNewRunModalOpen: PropTypes.func,
};

export default useSelectOperationFlow;
