import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ControlBarButton,
  ControlBar,
  palette,
  TextArea,
  Radio,
  Checkbox,
} from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';

import { AppNav } from 'components/AppNav';
import { check } from 'lib/icons';
import { H5 } from 'components/Text';
import Routes from 'lib/utils/routes';

import { CategoryHeader } from 'components/CategoryHeader';
import { Root, TitleText, Body, InnerBody, Group } from './styled';

const Step3 = ({
  parts,
  type,
  typeEnabled,
  notes,
  category,
  removeFromGoodParts,
  dispatch,
  machineId,
  onSubmit,
  showRemoveFromGoodParts,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Root id="reject-parts-step3">
      <AppNav
        onBack={() => {
          return dispatch({ type: 'PREVIOUS_STEP' });
        }}
        onClose={() => {
          return history.push(Routes.machineIdPath(machineId));
        }}
      >
        <H5 color={palette.Yellow400} bold>
          {t('rejectParts|full')}
        </H5>
        <TitleText>
          {_startCase(t(`add details {{parts}} parts total`, { parts }))}
        </TitleText>
      </AppNav>
      <CategoryHeader path={category.name.split('|')} />
      <Body>
        <InnerBody>
          <Group>
            <Radio
              disabled={!typeEnabled && type !== 'scrap'}
              inputProps={{
                value: 'scrap',
                id: 'scrap',
                checked: type === 'scrap',
                onChange: () => {
                  return dispatch({ type: 'SET_TYPE', value: 'scrap' });
                },
              }}
            >
              {t('Scrap')}
            </Radio>
            <Radio
              disabled={!typeEnabled && type !== 'nonconform'}
              inputProps={{
                value: 'nonconform',
                id: 'nonconform',
                checked: type === 'nonconform',
                onChange: () => {
                  return dispatch({ type: 'SET_TYPE', value: 'nonconform' });
                },
              }}
            >
              {t('Non-Conforming')}
            </Radio>
          </Group>
          <Group>
            {showRemoveFromGoodParts && (
              <Checkbox
                inputProps={{
                  id: 'removeFromGoodParts',
                  name: 'removeFromGoodParts',
                  checked: removeFromGoodParts,
                  onChange: () => {
                    return dispatch({
                      type: 'SET_REMOVE_FROM_GOOD_PARTS',
                      value: !removeFromGoodParts,
                    });
                  },
                }}
              >
                {t('Remove From Good Parts')}
              </Checkbox>
            )}
          </Group>
          <TextArea
            id="notes"
            expand
            placeholder={_startCase(t('add any additional notes here'))}
            value={notes}
            onChange={(evt) => {
              return dispatch({
                type: 'SET_NOTES',
                value: evt.target.value,
              });
            }}
          />
        </InnerBody>
      </Body>
      <ControlBar>
        <ControlBarButton
          id="submit-rejects"
          backgroundColor={palette.Green600}
          textColor={palette.White}
          icon={check}
          iconColor={palette.White}
          onClick={onSubmit}
          disabled={!parts || !type || !category}
        >
          {_startCase(t('submit rejects'))}
        </ControlBarButton>
      </ControlBar>
    </Root>
  );
};

export { Step3 };
