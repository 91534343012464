import React from 'react';
import styled from 'styled-components';
import {
  Notification,
  StatusNotification,
  toast,
} from '@m12s/component-library';
import {
  exclamationTriangle,
  check,
  exclamationTriangleSolid,
  checkCircleSolid,
} from 'lib/icons';

const PreWrap = styled.span`
  white-space: pre-wrap;
`;

const throwErrorToast = (message) => {
  return toast(
    <Notification
      icon={exclamationTriangle}
      text={<PreWrap>{message}</PreWrap>}
      status="error"
    />,
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 8000,
    }
  );
};

const throwSuccessToast = (message) => {
  return toast(
    <Notification
      icon={check}
      text={<PreWrap>{message}</PreWrap>}
      status="success"
    />,
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    }
  );
};

const throwStatusToast = (message, status) => {
  return toast(
    <StatusNotification
      icon={status === 'primary' ? checkCircleSolid : exclamationTriangleSolid}
      text="Status Update"
      subtext={<PreWrap>{message}</PreWrap>}
      status={status}
    />,
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 30 * 60 * 1000,
    }
  );
};

export { throwErrorToast, throwSuccessToast, throwStatusToast };
