import styled from 'styled-components';
import { palette } from '@m12s/component-library';

const Root = styled.div`
  display: flex;
`;

const KeyItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: ${palette.Grey600};
  font-size: 0.875rem;
  > div {
    margin-right: 0.5rem;
  }
`;

const Line = styled.div`
  width: 1.5rem;
  border-bottom: solid 2px ${palette.Grey300};
  height: 0px;
`;

const Circle = styled.div`
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  border-radius: 0.75rem;
  background-color: ${palette.Grey300};
`;

export { Root, KeyItem, Line, Circle };
