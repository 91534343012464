import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import { getShouldShowWorkOrderIdField } from 'lib/selectors/getShouldShowWorkOrderIdField';
import {
  Icon,
  palette,
  Timetable,
  Thead,
  Th,
  Tbody,
  Td,
} from '@m12s/component-library';

import { formatDuration, formatCycleTime } from 'lib/utils/date';
import { chevronRight } from 'lib/icons';
import { H5, H6 } from 'components/Text';
import { Empty, LoadingTd, LoadingText, LoadingTbody } from 'components/Table';

import { ErrorInline } from 'components/ErrorInline';
import { usePortraitMode } from 'lib/hooks';
import { getMachine } from 'lib/selectors/getMachine';
import {
  Cell,
  EmptyWrapper,
  ButtonWrapper,
  CreateOperationButton,
} from './styled';
import { CreateOperationModal } from './CreateOperationModal';
import { AddWorkOrderModal } from './AddWorkOrderModal';

const DURATION_FORMAT = 'h[h] m[m]';

const getExpectedTimeDisplay = (job, display) => {
  switch (display) {
    case 'part':
      return formatCycleTime(job.expectedPartTime / 1000);
    case 'cycle':
    default:
      return formatCycleTime((job.expectedPartTime * job.actualParts) / 1000);
  }
};

const JobTable = ({
  jobs,
  onLoadMore,
  loading,
  isLoadingMore,
  error,
  dispatch,
  nextStep,
  filter,
}) => {
  const { t } = useTranslation();
  const machine = useSelector(getMachine);
  const allowWorkOrder = useSelector(getShouldShowWorkOrderIdField);
  const portraitMode = usePortraitMode();
  const [openAddWorkOrderModal, setOpenAddWorkOrderModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const onJobClick = (job, workOrderId = null) => {
    dispatch({
      type: 'SELECT_JOB',
      job,
      workOrderId,
    });
    nextStep();
  };

  const selectJob = (job) => {
    if (allowWorkOrder) {
      setOpenAddWorkOrderModal(true);
      return setSelectedJob(job);
    }
    return onJobClick(job);
  };

  const closeAddWorkOrderModal = () => {
    setOpenAddWorkOrderModal(false);
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <Timetable
      templateColumns={
        portraitMode ? 'auto 10rem 8rem 2rem' : 'auto 13rem 9rem 3rem'
      }
    >
      <Thead>
        <Th>
          <Cell paddingLeft>{_startCase(t('operation name'))}</Cell>
        </Th>
        <Th align="center">
          {`${_startCase(t('expected cycle'))} | ${_startCase(t('part time'))}`}
        </Th>
        <Th align="center">{_startCase(t('expected setup'))}</Th>
        <Th />
      </Thead>
      {loading ? (
        <LoadingTbody
          rows={20}
          row={
            <>
              <LoadingTd>
                <LoadingText />
              </LoadingTd>
              <LoadingTd align="right">
                <LoadingText />
              </LoadingTd>
              <LoadingTd align="right">
                <LoadingText />
              </LoadingTd>
              <LoadingTd align="right"></LoadingTd>
            </>
          }
        />
      ) : error ? (
        <ErrorInline
          title={t('There was an error loading the operations list')}
          {...error}
          reload
        />
      ) : jobs.length === 0 ? (
        <>
          <EmptyWrapper>
            <Empty variant="noResults" />
          </EmptyWrapper>
          {machine?.operatorCreateOperation ? (
            <ButtonWrapper
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <CreateOperationButton>
                {t('Create operation', { filter })}
              </CreateOperationButton>
            </ButtonWrapper>
          ) : null}
          {openModal && (
            <CreateOperationModal
              filter={filter}
              onClickCloseModal={() => {
                setOpenModal(false);
              }}
            />
          )}
        </>
      ) : (
        <Tbody
          onLoadMore={onLoadMore}
          loadMoreText={_startCase(t('load more'))}
          isLoadingMore={isLoadingMore}
        >
          {jobs.map((job) => {
            return (
              <Fragment key={job.jobId}>
                <Td
                  onClick={() => {
                    return selectJob(job);
                  }}
                >
                  <Cell paddingLeft>
                    <H5>{job.displayName}</H5>
                  </Cell>
                </Td>
                <Td
                  align="right"
                  onClick={() => {
                    return selectJob(job);
                  }}
                >
                  <Cell center>
                    <H6>
                      {getExpectedTimeDisplay(job, 'cycle')}
                      {job.actualParts !== 1 &&
                        ` | ${getExpectedTimeDisplay(job, 'part')}`}
                    </H6>
                  </Cell>
                </Td>
                <Td
                  align="right"
                  onClick={() => {
                    return selectJob(job);
                  }}
                >
                  <Cell center>
                    {job.setupTime ? (
                      <H6>{formatDuration(job.setupTime, DURATION_FORMAT)}</H6>
                    ) : job.setupTime === 0 ? (
                      <H6 muted>{_capitalize(t('none'))}</H6>
                    ) : (
                      <H6 muted>{_capitalize(t('no setup'))}</H6>
                    )}
                  </Cell>
                </Td>
                <Td
                  align="right"
                  onClick={() => {
                    return selectJob(job);
                  }}
                >
                  <Cell paddingRight>
                    <Icon
                      icon={chevronRight}
                      size="0.75rem"
                      color={palette.Grey400}
                    />
                  </Cell>
                </Td>
              </Fragment>
            );
          })}
          {openAddWorkOrderModal && (
            <AddWorkOrderModal
              handleOnClose={closeAddWorkOrderModal}
              selectedJob={selectedJob}
              onJobClick={onJobClick}
            />
          )}
        </Tbody>
      )}
    </Timetable>
  );
};

export { JobTable };
