import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PillWrapper = styled(Link)`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  text-decoration: none;
  top: 100%;
  width: 100%;
  padding: 0.625rem;

  @media (orientation: landscape) and (max-height: 575px) {
    padding: 0;
  }

  @media (orientation: landscape) and (max-height: 540px) {
    position: relative;
    padding: 1rem;
  }
`;

export { PillWrapper };
