import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from '@m12s/component-library';

import { EmptyWithGraphic } from 'components/Empty/EmptyWithGraphic';

import EdgeDevice from 'graphics/EdgeDevice';
import ErrorMachine from 'graphics/ErrorMachine';
import { exclamationTriangle } from 'lib/icons';

const IconContainer = styled.div`
  margin-bottom: 1rem;
`;

const ErrorIcon = styled(Icon)`
  font-size: 6rem;
`;

const getLabelFor = (t, status) => {
  switch (status) {
    case 'isEdgeUnavailable':
      return {
        text: t('edgeUnavailableText'),
        subtext: t('edgeUnavailableSubtext'),
        icon: <EdgeDevice />,
      };
    case 'isMachineUnavailable':
      return {
        text: t('machineUnavailableText'),
        subtext: t('machineUnavailableSubtext'),
        icon: <ErrorMachine width={200} height={200} />,
      };
    case 'isNetworkUnavailable':
      return {
        text: t('networkUnavailableText'),
        subtext: t('networkUnavailableSubtext'),
        icon: <ErrorIcon icon={exclamationTriangle} />,
      };
    default:
      return {
        text: 'Error',
        icon: <ErrorIcon icon={exclamationTriangle} />,
      };
  }
};

const DisconnectedErrorPage = ({ errorStatus }) => {
  const { t } = useTranslation();

  const { text, subtext, icon } = getLabelFor(t, errorStatus);

  return (
    <>
      <EmptyWithGraphic
        Graphic={<IconContainer>{icon}</IconContainer>}
        text={text}
        subtext={subtext}
      />
    </>
  );
};

export default DisconnectedErrorPage;
