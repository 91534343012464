import { createSelector } from 'reselect';
import { getStandardScope } from 'lib/selectors/getStandardScope';
import { getOpRunOverrideScope } from 'lib/selectors/getOpRunOverrideScope';
import { getShouldUseOpRunOverrideScope } from 'lib/selectors/getShouldUseOpRunOverrideScope';

const getGlobalScope = createSelector(
  [getStandardScope, getOpRunOverrideScope, getShouldUseOpRunOverrideScope],
  (standardScope, opRunOverrideScope, shouldUseOpRunOverrideScope) => {
    if (shouldUseOpRunOverrideScope) {
      return opRunOverrideScope;
    }
    return standardScope;
  }
);

export { getGlobalScope };
