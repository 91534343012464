import React from 'react';
import PropTypes from 'prop-types';
import { Icon, palette, Card } from '@m12s/component-library';

import { P } from 'components/Text';
import { chevronRightRegular } from 'lib/icons';

const CategoryCard = ({ name, hasNested, ...props }) => {
  return (
    <Card
      icon={
        hasNested ? (
          <Icon icon={chevronRightRegular} color={palette.Grey400} />
        ) : null
      }
      {...props}
    >
      <P semibold>{name}</P>
    </Card>
  );
};

CategoryCard.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
};

export { CategoryCard };
