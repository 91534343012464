import styled from 'styled-components';
import _get from 'lodash/get';

const Highlight = styled.span`
  ${({ theme, shouldFill, appearance }) => {
    return (
      !shouldFill &&
      `
    color: ${_get(
      theme,
      ['widgets', 'wheel', 'default', appearance, 'ringStroke'],
      'none'
    )};
  `
    );
  }}
`;

export { Highlight };
