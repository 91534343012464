import { createSelector } from 'reselect';

import _get from 'lodash/get';
import { getCurrentWorkOrderOp } from './getCurrentWorkOrderOp';

const calculateWorkOrderOpPartDuration = (workOrderOp) => {
  const cycleTimeMs = _get(workOrderOp, 'cycleTimeMs');
  const quantityPerPart = _get(workOrderOp, 'quantityPerPart');

  if (!cycleTimeMs) {
    return 0;
  }

  return cycleTimeMs / (quantityPerPart || 1);
};

const getCurrentWorkOrderOpPartDuration = createSelector(
  [getCurrentWorkOrderOp],
  (currentWorkOrderOp) => {
    return calculateWorkOrderOpPartDuration(currentWorkOrderOp);
  }
);

export { getCurrentWorkOrderOpPartDuration, calculateWorkOrderOpPartDuration };
