import { createSelector } from 'reselect';
import {
  getActivitiesStatus,
  getIsActivitiesStatusLoading,
} from 'lib/selectors/getActivitiesStatus';

const getAreActivitiesEnabled = createSelector(
  [getIsActivitiesStatusLoading, getActivitiesStatus],
  (isActivitiesStatusLoading, activitiesStatus) => {
    if (isActivitiesStatusLoading) {
      return false;
    }
    return activitiesStatus === 'active';
  }
);

export { getAreActivitiesEnabled };
