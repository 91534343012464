import { createSelector } from 'reselect';
import { getLatestActivitySetActivities } from 'lib/selectors/getLatestActivitySetActivities';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getAggClosedActivitiesMs } from 'lib/utils/activities';
import { getProductionActivityTypeRefs } from './getProductionActivityTypeRefs';
import { getCheckIsProductionActivityTypeRef } from './getCheckIsProductionActivityTypeRef';

/*
  note that this selector returns agg for ENTIRE set, not just those within
  scope... this is an important distinction for when we need entire production agg (ie
  showing production in progress in bar); if you need scoped activity agg ms, use the
  scoped agg selectors
*/

const getClosedProductionAggMs = createSelector(
  [
    getLatestActivitySetActivities,
    getIsInitialActivitySetPollLoading,
    getProductionActivityTypeRefs,
    getCheckIsProductionActivityTypeRef,
  ],
  (
    latestActivitySetActivities,
    isInitialActivitySetPollLoading,
    productionActivityTypeRefs,
    checkIsProductionActivityTypeRef
  ) => {
    if (
      isInitialActivitySetPollLoading ||
      (productionActivityTypeRefs && !productionActivityTypeRefs.length) ||
      (latestActivitySetActivities && !latestActivitySetActivities.length)
    ) {
      return 0;
    }
    return getAggClosedActivitiesMs({
      activities: latestActivitySetActivities,
      filterCb: ({ activityTypeRef }) => {
        return checkIsProductionActivityTypeRef(activityTypeRef);
      },
    });
  }
);

export { getClosedProductionAggMs };
