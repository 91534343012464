import { createSelector } from 'reselect';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { ACTIVITY_MODES } from 'lib/constants';

export const getHasOpenActivitySetInSetup = createSelector(
  [getHasOpenActivitySet, getLatestActivityMode],
  (hasOpenActivitySet, latestActivityMode) => {
    return !!(
      hasOpenActivitySet && latestActivityMode === ACTIVITY_MODES.SETUP
    );
  }
);
