import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const H5 = WithProps(styled.h5`
  ${includeBaseStyle}
  font-size: 1.25rem;
  line-height: 1.5rem;
`);

H5.defaultProps = {
  ...H5.defaultProps,
  defaultWeight: 'bold',
};

export { H5 };
