import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getShiftEnd } from 'lib/selectors/getShiftEnd';

const checkIsLatestShiftActive = createSelector(
  [getShiftStart, getShiftEnd],
  (shiftStart, shiftEnd) => {
    if (!shiftStart || !shiftEnd) {
      return false;
    }
    const isShiftActive = moment.utc().isBetween(
      shiftStart,
      shiftEnd,
      undefined,
      '[)' // start inclusive / end exclusive
    );
    return isShiftActive;
  }
);

export { checkIsLatestShiftActive };
