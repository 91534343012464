import React, { useEffect, useState } from 'react';
import {
  palette,
  Modal,
  Icon,
  Button,
  LoadingCircle,
} from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _capitalize from 'lodash/capitalize';
import { spinner, timesCircle } from 'lib/icons';
import { P, H5, Small } from 'components/Text';

import styled from 'styled-components';
import { NumberInput } from 'components/NumberInput';
import { getNetSetupParts, getSetupParts } from 'lib/selectors/getSetupParts';
import { useMutation } from '@apollo/client';
import { CREATE_PART_ADJUSTMENT } from 'lib/api/mutations';
import { actionCreatePartAdjustmentSuccess } from 'lib/actions';
import { getMachine } from 'lib/selectors/getMachine';
import {
  getNetShiftSetupParts,
  getShiftSetupParts,
  getShiftSetupRejectCount,
} from 'lib/selectors/getShiftSetupParts';
import { getSetupModeSpansShift } from 'lib/selectors/getSetupModeSpansShift';

const Title = styled(H5)`
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
`;

const IconWrapper = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

const ModalButton = styled(Button)`
  height: 3rem;
`;

const PartAdjusterBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 1rem;
`;

const AdjustSetupPartsModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const machine = useSelector(getMachine);

  const netShiftSetupParts = useSelector(getNetShiftSetupParts);
  const netSetupParts = useSelector(getNetSetupParts);
  const setupModeSpansShift = useSelector(getSetupModeSpansShift);
  const initialParts = setupModeSpansShift ? netShiftSetupParts : netSetupParts;

  const detectedSetupParts = useSelector(getSetupParts);
  const detectedShiftSetupParts = useSelector(getShiftSetupParts);
  const shiftSetupRejectCount = useSelector(getShiftSetupRejectCount);
  const detectedParts = setupModeSpansShift
    ? detectedShiftSetupParts
    : detectedSetupParts;
  const detectedPartsMinusRejects = detectedParts - shiftSetupRejectCount;

  const [adjustment, setAdjustment] = useState(0);

  useEffect(() => {
    setAdjustment(0);
  }, [open]);

  const [createPartAdjustment, { loading }] = useMutation(
    CREATE_PART_ADJUSTMENT,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ createPartAdjustmentResponse }) => {
        dispatch(
          actionCreatePartAdjustmentSuccess(createPartAdjustmentResponse)
        );
        setAdjustment(0);
        onClose();
      },
    }
  );

  const submit = () => {
    createPartAdjustment({
      variables: {
        input: {
          count: adjustment,
          machine_id: machine.id,
        },
      },
    });
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      header={
        <Title color={palette.White}>
          {t(
            'Adjust the part count to ensure that the parts detected matches the number of parts made'
          )}
        </Title>
      }
      headerIcon={
        <IconWrapper onClick={onClose}>
          <Icon size="2rem" color={palette.Purple200} icon={timesCircle} />
        </IconWrapper>
      }
      headerStyles={{
        background: `linear-gradient(180deg, ${palette.Purple500} 0%, #9272DB 100%)`,
      }}
      details={
        <PartAdjusterBox>
          <P
            bold
            color={palette.Purple500}
            align="center"
            style={{ marginBottom: '0.5rem' }}
          >
            {t('Total Parts')}
          </P>
          <NumberInput
            value={initialParts + adjustment}
            min={0}
            max={detectedPartsMinusRejects}
            onChange={(parts) => {
              setAdjustment(parts - initialParts);
            }}
          />
          <P
            color={palette.Grey600}
            align="center"
            style={{ margin: '0.5rem', fontSize: '0.875rem' }}
          >
            {t('Our system detected Parts during setup', {
              count: detectedPartsMinusRejects,
            })}
          </P>
          <Small align="left" style={{ margin: '1rem' }}>
            {t(
              'Sometimes the number of parts detected will differ from the actual parts made in setup. Please adjust this total to reflect your part total, removing any empty cycles or parts that were counted incorrectly.'
            )}
            &nbsp;
            <span style={{ color: palette.Red600 }}>
              {t(
                'If you have made parts that need to be rejected, please use the reject parts button instead.'
              )}
            </span>
          </Small>
        </PartAdjusterBox>
      }
      buttons={
        <>
          <ModalButton
            variant="inferior"
            onClick={() => {
              return onClose();
            }}
          >
            {_capitalize(t('cancel'))}
          </ModalButton>
          <ModalButton
            disabled={adjustment === 0 || loading}
            onClick={() => {
              return submit();
            }}
          >
            {loading && (
              <LoadingCircle
                size="1.25rem"
                icon={spinner}
                color="white"
                style={{ display: 'inline', marginRight: '0.5rem' }}
              />
            )}
            {t('Confirm')}
          </ModalButton>
        </>
      }
    />
  );
};

export { AdjustSetupPartsModal };
