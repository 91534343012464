import { createSelector } from 'reselect';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeEnd } from 'lib/selectors/getScopeEnd';
import { add, getStartOf } from 'lib/utils/date';

const defaultKeys = [];

const getHourlyIntervalKeys = (scopeStart, scopeEnd) => {
  let hourTicker = scopeStart;
  const hourlyIntervalKeys = [];
  while (hourTicker < scopeEnd) {
    hourlyIntervalKeys.push(hourTicker);
    hourTicker = getStartOf(hourTicker, 'hour');
    hourTicker = add(hourTicker, 1, 'hour');
  }
  return hourlyIntervalKeys;
};

const getScopeHourlyIntervalKeys = createSelector(
  [getScopeStart, getScopeEnd],
  (scopeStart, scopeEnd) => {
    if (!scopeStart || !scopeEnd) {
      return defaultKeys;
    }
    return getHourlyIntervalKeys(scopeStart, scopeEnd);
  }
);

export { getScopeHourlyIntervalKeys };
