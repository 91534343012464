import { createSelector } from 'reselect';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getUnitDurationFromString, dateUTC } from 'lib/utils/date';
import { getLatestProductionActivityStartAt } from './getLatestProductionActivityStartAt';
import { getLatestProductionActivityEndAt } from './getLatestProductionActivityEndAt';
import { getClosedProductionAggMs } from './getClosedProductionAggMs';

/**
 * uses agg of elapsed production time to produce production dates w/ negative start offset for duration ticker
 */
const getAdjustedProductionDurationDates = createSelector(
  [
    getLatestActivitySet,
    getLatestProductionActivityStartAt,
    getLatestProductionActivityEndAt,
    getClosedProductionAggMs,
  ],
  (
    latestActivitySet,
    latestProductionActivityStartAt,
    latestProductionActivityEndAt,
    closedProductionAggMs
  ) => {
    if (latestProductionActivityStartAt) {
      // if open, offset start of production activity-in-progress (handle "live" end in components);
      // otherwise, offset end of last production activity by closed production time;
      const startAt = dateUTC(
        latestProductionActivityEndAt || latestProductionActivityStartAt
      );
      const adjustedProductionStartDate = dateUTC(startAt).subtract(
        closedProductionAggMs,
        'milliseconds'
      );
      const { expectedProductionDuration } = latestActivitySet;
      const expectedProductionDurationMs = getUnitDurationFromString(
        expectedProductionDuration,
        'milliseconds'
      );
      return {
        adjustedProductionStartDate: dateUTC(adjustedProductionStartDate),
        expectedProductionEndDate: dateUTC(adjustedProductionStartDate).add(
          expectedProductionDurationMs,
          'milliseconds'
        ),
      };
    }

    return {
      adjustedProductionStartDate: dateUTC(),
      expectedProductionEndDate: dateUTC(),
    };
  }
);

export { getAdjustedProductionDurationDates };
