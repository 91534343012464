import { useSelector } from 'react-redux';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { now, toISO } from 'lib/utils/date';
import { TEMP_REFS } from 'lib/constants';

const { ACTIVITY_REF: optimisticActivityRef } = TEMP_REFS;

const useCreateActivityRequestParams = () => {
  const latestActivitySet = useSelector(getLatestActivitySet);

  return (activityTypeRef) => {
    const nowISO = toISO(now().valueOf());
    return {
      variables: {
        input: {
          activitySetRef: latestActivitySet.activitySetRef,
          activityTypeRef,
          startAt: nowISO,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createActivityResponse: {
          __typename: 'Activity',
          activity: {
            activityRef: optimisticActivityRef, // dummy placeholder to be overwritten by server
            activitySetRef: latestActivitySet.activitySetRef,
            activityTypeRef,
            start: nowISO,
            end: null,
            updatedAt: nowISO,
            jobScheduleGroupId: null,
          },
        },
      },
    };
  };
};

export { useCreateActivityRequestParams };
