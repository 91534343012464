import styled, { css } from 'styled-components';
import { zLayer } from 'lib/utils/styled';

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  ${({ direction }) => {
    return (
      direction &&
      css`
        flex-direction: ${direction};
      `
    );
  }}
  overflow: auto;
  ${({ bgColor }) => {
    return css`
      background-color: ${bgColor};
    `;
  }}

  ${({ alignItems }) => {
    return css`
      align-items: ${alignItems};
    `;
  }}

  ${({ shadow }) => {
    return (
      shadow &&
      css`
        box-shadow: inset 0px -1px 0px #edf2f7;
      `
    );
  }}

  ${({ overlay }) => {
    return (
      overlay &&
      css`
        position: relative;
        z-index: ${zLayer.overlay};
      `
    );
  }}

  ${({ portraitDirection }) => {
    return (
      portraitDirection &&
      css`
        @media (orientation: portrait) {
          flex-direction: ${portraitDirection};
        }
      `
    );
  }}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

export default { Container, Column, Row };
