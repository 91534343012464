import { createSelector } from 'reselect';
import { getLatestSetupActivity } from 'lib/selectors/getLatestSetupActivity';

const getLatestSetupActivityStartAt = createSelector(
  [getLatestSetupActivity],
  ({ start }) => {
    return start || null;
  }
);

export { getLatestSetupActivityStartAt };
