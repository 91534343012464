import React from 'react';
import styled, { css } from 'styled-components';
import { palette, Select, Button } from '@m12s/component-library';
import { Text, P, H6 } from 'components/Text';
import { zLayer } from 'lib/utils/styled';

const Label = styled(H6)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Category = styled(Text)`
  letter-spacing: 0.015em;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: ${({ theme }) => {
    return theme.fontWeights.bold;
  }};
  margin-right: 0.5rem;

  ${({ color }) => {
    return (
      color &&
      css`
        color: ${color};
      `
    );
  }}
`;

const Cell = styled.div`
  position: relative;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  white-space: nowrap;
  max-width: 100%;
`;

const Column = styled.div`
  background: ${palette.Grey100};
  flex: ${(props) => {
    return props.flex === undefined ? 1 : props.flex;
  }};
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const FilterColumn = styled(Column)`
  @media (orientation: portrait) {
    flex: unset;
  }
`;

const TableColumn = styled(Column)`
  position: relative;
  z-index: ${zLayer.lowered};
  @media (orientation: landscape) {
    padding-left: 0;
    flex: 3;
  }
  @media (orientation: portrait) {
    padding-top: 0;
    max-height: 75%;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  @media (orientation: portrait) {
    flex-direction: row;
  }
`;

const Control = styled.div`
  & + & {
    margin-top: 0.5rem;
  }

  @media (orientation: portrait) {
    flex-grow: 1;
    & + & {
      margin-left: 0.5rem;
      margin-top: 0;
    }
  }
`;

const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FilterSelect = ({ ...props }) => {
  return (
    <Control
      onTouchEnd={(e) => {
        return e.preventDefault();
      }}
    >
      <Select innerLabel isSearchable={false} {...props} />
    </Control>
  );
};

const Tag = styled.span`
  width: fit-content;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => {
    return theme.fontWeights.medium;
  }};
  line-height: 1;
  letter-spacing: 0.015em;
  color: ${({ color }) => {
    return color;
  }};
  border-radius: 2px;
  border: 1px solid
    ${({ color }) => {
      return color;
    }};
  padding: 0.5rem;
`;

const Results = styled.div`
  margin-top: 1.125rem;

  @media (orientation: portrait) {
    display: flex;
    div {
      margin-left: 1rem;
    }
  }
`;

const ResultsHeader = (props) => {
  return <P color={palette.Purple500} {...props} bold />;
};

const LoadMore = styled(Button)`
  margin-top: 1rem;
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 0.5rem;
  }
  > ${H6} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DividerRow = styled.div`
  grid-column: 1 / span 4;
  background-color: ${palette.Grey100};
  padding: 0.375rem 1rem;
  color: ${palette.Grey600};
  font-weight: 600;
  font-size: 0.75rem;
  position: sticky;
  top: 0;
  z-index: ${zLayer.menu};
`;

export {
  Control,
  Category,
  CategoryTitle,
  Cell,
  Column,
  DividerRow,
  EmptyContainer,
  FilterSelect,
  Filters,
  FilterColumn,
  Results,
  ResultsHeader,
  TableColumn,
  Tag,
  LoadMore,
  Label,
};
