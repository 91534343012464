import React from 'react';
import styled from 'styled-components';
import { HeaderButton } from 'components/AppNav/styled';
import { times, arrowRight, arrowLeft } from 'lib/icons';
import { CardGrid, palette } from '@m12s/component-library';

/* Main */

const Frame = styled.div`
  background-color: ${palette.Grey200};
  min-height: 100vh;
`;

/* Table of Contents */

const TOCLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.33;
  margin: 1rem 0 2.5rem;
`;

const Links = styled(CardGrid)`
  grid-template-columns: repeat(2, 1fr);
  flex: 0.66;
`;

const Img = styled.img`
  @media (orientation: landscape) {
    margin: 5rem 0 1rem;
  }
`;

/* Section */

const LoadingBackground = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: ${palette.Grey200};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const ButtonSquare = styled.div`
  border-radius: 0;
  position: absolute;
  top: 0;
  width: calc(100vw / 17);
  height: calc(100vw / 17);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (orientation: portrait) {
    width: calc(100vw / 15.5);
    height: calc(100vw / 15.5);
    svg {
      font-size: 1rem;
    }
  }
`;

const ButtonRound = styled.div`
  border-radius: 2rem;
  position: absolute;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.Purple400};
`;

const Buttons = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

const CloseButton = ({ onBack }) => {
  return (
    <ButtonSquare style={{ top: '0rem', right: '0rem' }} variant="secondary">
      <HeaderButton
        icon={times}
        iconColor={palette.White}
        onClick={onBack}
        alignRight
      />
    </ButtonSquare>
  );
};

const NextButton = ({ onBack }) => {
  return (
    <ButtonRound style={{ bottom: '1rem', right: '1rem' }} variant="secondary">
      <HeaderButton
        icon={arrowRight}
        iconColor={palette.White}
        onClick={onBack}
      />
    </ButtonRound>
  );
};

const BackButton = ({ onBack }) => {
  return (
    <ButtonRound style={{ bottom: '1rem', left: '1rem' }} variant="secondary">
      <HeaderButton
        icon={arrowLeft}
        iconColor={palette.White}
        onClick={onBack}
      />
    </ButtonRound>
  );
};

export {
  Frame,
  TOCLayout,
  Description,
  Links,
  Img,
  LoadingBackground,
  ButtonSquare,
  ButtonRound,
  Buttons,
  CloseButton,
  NextButton,
  BackButton,
};
