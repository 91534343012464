import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ControlBarButton } from '@m12s/component-library';
import { stop, pause, play, sync, conveyorBeltAlt } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { Production, Setup } from 'lib/customIcons';

import useSubmitLaborTicket from 'components/LaborTicket/useSubmitLaborTicket';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

import { CONTROL_ACTIONS, LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getHasActivityToSwitchTo } from 'lib/selectors/getHasActivityToSwitchTo';
import { getHasThreeOrMoreActivities } from 'lib/selectors/getActivityTypes';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import {
  getCanCloseLaborTicket,
  getCanOpenLaborTicket,
} from 'lib/selectors/getCanOpenLaborTicket';
import { actionOpenLaborTicketModal } from 'lib/actions';
import { getProductionActivityTypeRefs } from 'lib/selectors/getProductionActivityTypeRefs';
import { getSetupActivityTypeRefs } from 'lib/selectors/getSetupActivityTypeRefs';

import { MobileIcon, ResponsiveText } from './styled';

const { RESUME, STOP, SWITCH_TO_PRODUCTION, SWITCH_TO_SETUP } = CONTROL_ACTIONS;

const ActivitiesJobControlBar = ({
  setControlAction,
  history,
  machine,
  hasOpenActivitySet,
  isPaused,
  hasOpenSetupActivity,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const laborTicketEnabled = useIsLaborTicketFlow();
  const requestLaborTicketAction = useSubmitLaborTicket();
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);
  const canOpenLaborTicket = useSelector(getCanOpenLaborTicket);

  const hasActivityToSwitchTo = useSelector(getHasActivityToSwitchTo);
  const latestActivityMode = useSelector(getLatestActivityMode);
  const hasThreeOrMoreActivities = useSelector(getHasThreeOrMoreActivities);
  const productionActivityTypes = useSelector(getProductionActivityTypeRefs);
  const setupActivityTypes = useSelector(getSetupActivityTypeRefs);

  if (hasOpenActivitySet) {
    return (
      <>
        <ControlBarButton
          id="stop-job"
          icon={stop}
          iconSize="2rem"
          fitToContent
          onClick={() => {
            if (laborTicketEnabled && canCloseLaborTicket) {
              return dispatch(
                actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.STOP_RUN)
              );
            }
            return setControlAction(STOP);
          }}
        ></ControlBarButton>
        {isPaused && (
          <ControlBarButton
            id="resume-job"
            icon={play}
            onClick={() => {
              return setControlAction(RESUME);
            }}
            iconSize="2rem"
            fitToContent
          />
        )}
        {hasOpenSetupActivity && (
          <ControlBarButton
            id="pause-job"
            icon={pause}
            onClick={() => {
              return history.push({
                pathname: Routes.machineIdSwitchActivitiesPath(machine.id),
                state: {
                  controlAction: 'pause',
                },
              });
            }}
            iconSize="2rem"
            fitToContent
          />
        )}

        {hasActivityToSwitchTo && hasThreeOrMoreActivities && (
          <ControlBarButton
            id="switch-activity"
            onClick={() => {
              return history.push(
                Routes.machineIdSwitchActivitiesPath(machine.id)
              );
            }}
          >
            <MobileIcon icon={sync} />
            <ResponsiveText
              full={t('switchActivity|full')}
              medium={t('switchActivity|medium')}
              short={t('switchActivity|short')}
            />
          </ControlBarButton>
        )}
        {hasActivityToSwitchTo &&
          !hasThreeOrMoreActivities &&
          latestActivityMode === 'production' && (
            <ControlBarButton
              id="enter-setup"
              onClick={async () => {
                if (isPaused) {
                  return setControlAction(SWITCH_TO_SETUP);
                }
                if (laborTicketEnabled && canOpenLaborTicket) {
                  if (canCloseLaborTicket) {
                    return dispatch(
                      actionOpenLaborTicketModal(
                        LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                        { pendingActivityTypeRef: setupActivityTypes[0] }
                      )
                    );
                  }

                  return requestLaborTicketAction({
                    action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                    opts: {
                      pendingActivityTypeRef: setupActivityTypes[0],
                    },
                  });
                }
                return history.push(
                  Routes.machineIdSwitchActivitiesPath(machine.id)
                );
              }}
            >
              <Setup />
              <ResponsiveText
                full={t('enterSetup|full')}
                medium={t('enterSetup|medium')}
                short={t('enterSetup|short')}
              />
            </ControlBarButton>
          )}
        {!hasThreeOrMoreActivities && latestActivityMode === 'setup' && (
          <ControlBarButton
            id="enter-production"
            onClick={() => {
              if (isPaused) {
                return setControlAction(SWITCH_TO_PRODUCTION);
              }

              if (laborTicketEnabled && canOpenLaborTicket) {
                if (canCloseLaborTicket) {
                  return dispatch(
                    actionOpenLaborTicketModal(
                      LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                      { pendingActivityTypeRef: productionActivityTypes[0] }
                    )
                  );
                }
                return requestLaborTicketAction({
                  action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                  opts: {
                    pendingActivityTypeRef: productionActivityTypes[0],
                  },
                });
              }
              return history.push(
                Routes.machineIdSwitchActivitiesPath(machine.id)
              );
            }}
          >
            <Production />
            <ResponsiveText
              full={t('enterProduction|full')}
              medium={t('enterProduction|medium')}
              short={t('enterProduction|short')}
            />
          </ControlBarButton>
        )}
      </>
    );
  }

  return (
    <ControlBarButton
      id="select-job"
      onClick={() => {
        return history.push(Routes.machineIdSelectJobPath(machine.id));
      }}
    >
      <MobileIcon icon={conveyorBeltAlt} />
      <ResponsiveText
        full={t('selectOperation|full')}
        medium={t('selectOperation|medium')}
        short={t('selectOperation|short')}
      />
    </ControlBarButton>
  );
};

export { ActivitiesJobControlBar };
