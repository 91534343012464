import React from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from '@m12s/component-library';

import { Small } from 'components/Text';
import { questionCircle } from 'lib/icons';
import { InfoNavWrapper, InfoNavIcon } from './styled';

const InfoNav = () => {
  const { t } = useTranslation();

  return (
    <InfoNavWrapper>
      <InfoNavIcon icon={questionCircle} color={palette.Blue300} />
      <Small medium color={palette.White}>
        {t(
          'Select at least one category (add subcategories to provide more detail). When you have finished click "Done Categorizing" to proceed.'
        )}
      </Small>
    </InfoNavWrapper>
  );
};

export { InfoNav };
