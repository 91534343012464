import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import _startCase from 'lodash/startCase';

import { Button, Icon, palette } from '@m12s/component-library';
import { Small } from 'components/Text';

import { exclamationTriangle } from 'lib/icons';
import { getHFLockedout } from 'lib/selectors/getHFLockedout';

import {
  Popup,
  Header,
  HeaderText,
  Body,
  Overlay,
  Title,
  Description,
} from 'components/Popup/styled';

const PopupTitle = styled(Title)`
  width: 20rem;
`;

const WarningHeader = styled(Header)`
  background-color: ${palette.Red600};
`;

const HFLockout = () => {
  const { t } = useTranslation();
  const hfLockedOut = useSelector(getHFLockedout);
  const [showPopup, setShowPopup] = useState(hfLockedOut);

  useEffect(() => {
    if (hfLockedOut) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [hfLockedOut]);

  return (
    <>
      {showPopup && (
        <>
          <Overlay />
          <Popup id="lockout-popup">
            <WarningHeader>
              <HeaderText>
                <Icon icon={exclamationTriangle} />
                &nbsp;&nbsp;
                {t('High Frequency Lockout')}
              </HeaderText>
            </WarningHeader>
            <Body>
              <PopupTitle>
                {t(
                  'This machine has been locked out of streaming high frequency data.'
                )}
              </PopupTitle>
              <Description>
                <Small>
                  {t(
                    'Machinemetrics is experiencing a degraded connection to this machine due to an issue on the Fanuc control. Please restart the machine control to re-enable Tool Monitoring.'
                  )}
                </Small>
              </Description>
              <Button
                onClick={() => {
                  setShowPopup(false);
                }}
                style={{ marginTop: '1rem' }}
                variant="tertiary"
              >
                {_startCase(t('dismiss'))}
              </Button>
            </Body>
          </Popup>
        </>
      )}
    </>
  );
};

export { HFLockout };
