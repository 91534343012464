import React from 'react';
import { CardGrid, palette } from '@m12s/component-library';
import Downshift from 'downshift';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppNav } from 'components/AppNav';
import { SearchInput } from 'components/SearchInput';
import { H5 } from 'components/Text';
import { MachineCard } from 'components/MachineCard';

import { Root, FormRoot, Grid, Inputs } from './styled';

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.touchEnd:
    case Downshift.stateChangeTypes.clickItem:
      return state;
    default:
      return changes;
  }
}

const MachinePicker = ({
  machines,
  currentMachine,
  changeMachine,
  alarms,
  dashboardDataMachineLookup,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Root>
      <AppNav
        onClose={() => {
          return history.goBack();
        }}
      >
        <H5 color={palette.Yellow400} bold>
          {currentMachine ? currentMachine.name : t('No Machine')}
        </H5>
      </AppNav>
      <Downshift
        isOpen
        stateReducer={stateReducer}
        itemToString={(item) => {
          return item ? item.name : '';
        }}
      >
        {({ getInputProps, getMenuProps, inputValue, getRootProps }) => {
          return (
            <FormRoot>
              <Inputs>
                <SearchInput
                  rootProps={getRootProps({}, { suppressRefError: true })}
                  inputProps={getInputProps({
                    onBlur: (fn) => {
                      return fn;
                    },
                  })}
                />
              </Inputs>
              <Grid>
                <CardGrid {...getMenuProps()}>
                  {machines
                    .filter((item) => {
                      return item.machine.name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase());
                    })
                    .sort((a, b) => {
                      return a.machine.name.localeCompare(
                        b.machine.name,
                        undefined,
                        {
                          numeric: true,
                          sensitivity: 'base',
                        }
                      );
                    })
                    .map((item) => {
                      const dashboardMachineData =
                        dashboardDataMachineLookup[item.machine.machineId];
                      const isInSetup = dashboardMachineData
                        ? dashboardMachineData.isInSetup
                        : false;
                      const isPaused = dashboardMachineData
                        ? dashboardMachineData.isPaused
                        : false;
                      return (
                        <MachineCard
                          key={item.machine.machineRef}
                          machine={item}
                          onClick={() => {
                            return changeMachine(item);
                          }}
                          alarm={alarms[item.machine.machineRef]}
                          isInSetup={isInSetup}
                          isPaused={isPaused}
                        >
                          {item.machine.name}
                        </MachineCard>
                      );
                    })}
                </CardGrid>
              </Grid>
            </FormRoot>
          );
        }}
      </Downshift>
    </Root>
  );
};

export { MachinePicker };
