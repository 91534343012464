import { createSelector } from 'reselect';
import { chopBefore } from 'lib/utils/intervals';

import { diff, toISO } from 'lib/utils/date';
import { getScopeScheduledActivityIntervals } from './getScopeScheduledActivityIntervals';
import { getEffectiveCurrentCycleStart } from './getEffectiveCurrentCycleStart';
import { getHeavyUpdate } from './getUpdate';

// calculate machine active time during scheduled periods
// of current cycle (last part was made)
const getScopeCurrentCycleTimeMs = createSelector(
  [
    getScopeScheduledActivityIntervals,
    getEffectiveCurrentCycleStart,
    getHeavyUpdate,
  ],
  (scheduledIntervals, effectiveCurrentCycleStart) => {
    const currentCycleIntervals = chopBefore(
      scheduledIntervals,
      effectiveCurrentCycleStart
    );
    return currentCycleIntervals.reduce((accum, interval) => {
      return (
        accum + diff(interval.end || toISO(), interval.start, 'milliseconds')
      );
    }, 0);
  }
);

export { getScopeCurrentCycleTimeMs };
