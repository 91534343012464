import React, { useMemo } from 'react';
import moment from 'moment';
import { palette } from '@m12s/component-library';
import { useDispatch, useSelector } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';
import { getUtilPageScopeStart } from 'lib/selectors/getUtilPageScopeStart';
import { getUtilPageScopeEnd } from 'lib/selectors/getUtilPageScopeEnd';
import { getCompany } from 'lib/selectors/getCompany';
import { getUtilPageScopeStatusIntervals } from 'lib/selectors/getUtilPageScopeStatusIntervals';
import { LONG_SCOPE_HOURS } from 'lib/constants';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import useRemPx from 'lib/hooks/useRemPx';
import { formatTime } from 'lib/utils/date';
import {
  getLastGenerated,
  getUtilizationHourlies,
} from 'lib/selectors/getUtilizationHourlies';
import { actionSetUtilizationHourlies } from 'lib/actions';
import { updatedSlices } from './utilizationSlices';
import { Chart } from './Chart';

const UtilizationChart = () => {
  const remPx = useRemPx();
  const dispatch = useDispatch();
  const utilPageScopeStart = useSelector(getUtilPageScopeStart);
  const utilPageScopeEnd = useSelector(getUtilPageScopeEnd);
  const company = useSelector(getCompany);
  const utilPageScopeStatusIntervals = useSelector(
    getUtilPageScopeStatusIntervals
  );
  const heavyUpdate = useSelector(getHeavyUpdate);
  const slices = useSelector(getUtilizationHourlies);
  const lastGenerated = useSelector(getLastGenerated);

  useMemo(() => {
    const updated = updatedSlices(
      utilPageScopeStatusIntervals,
      lastGenerated,
      slices,
      utilPageScopeStart,
      utilPageScopeEnd
    );
    dispatch(actionSetUtilizationHourlies(updated));
    /* Recalc on every interval change is too expensive
     Instead we specifically recalc every 10 seconds */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [utilPageScopeStart, utilPageScopeEnd, heavyUpdate, dispatch]);

  const machine = useSelector(getMachine);

  const longScope =
    moment(utilPageScopeEnd).diff(utilPageScopeStart, 'hours') >
    LONG_SCOPE_HOURS;

  const failurePercent =
    machine.utilizationFailurePercent || company.failurePercentUtilization;

  const warningPercent =
    machine.utilizationWarningPercent || company.warningPercentUtilization;

  const option = {
    xAxis: {
      type: 'category',
      axisLabel: {
        margin: remPx / 2,
        formatter: (label) => {
          return formatTime(label);
        },
        fontSize: remPx / 1.75,
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        interval: 0,
        lineStyle: {
          color: palette.Grey200,
        },
      },
    },
    yAxis: {
      interval: 25,
      min: 0,
      max: 100,
      axisLabel: {
        margin: remPx * 1.5,
        formatter: (value) => {
          return `${value}%`;
        },
        fontSize: remPx / 1.75,
      },
    },
    series: [
      {
        type: 'bar',
        animation: false,
        data: slices.map(({ time, utilization }) => {
          return [time, utilization];
        }),
        label: {
          fontSize: remPx / 1.75,
          formatter: ({ value, dataIndex }) => {
            if (!longScope) return value[1];

            if (dataIndex % 2 !== 0) return value[1];

            return '';
          },
        },
        fontSize: remPx / 2,
        barWidth: slices.length > 12 ? remPx / 2 : remPx / 1.25,
      },
    ],
    itemStyle: {
      color: palette.Grey300,
    },
    visualMap: {
      show: false,
      pieces: [
        {
          gte: 0,
          lte: failurePercent,
          color: palette.Red600,
        },
        {
          gt: failurePercent,
          lte: warningPercent,
          color: palette.Orange300,
        },
        {
          gt: warningPercent,
          color: palette.Grey300,
        },
      ],
    },
  };

  if (!utilPageScopeStart) {
    // prevent renders for invalid scope
    return null;
  }

  return <Chart option={option} />;
};

export { UtilizationChart };
