import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const Small = WithProps(styled.span`
  ${includeBaseStyle}
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.005em;
`);

export { Small };
