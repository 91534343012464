import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { bugsnag } from 'lib/external/bugsnag';
import { getUserLoading, getUser } from 'lib/selectors/getUser';
import { getCompany } from 'lib/selectors/getCompany';
import { FullScreenLoading } from 'components/Loading';
import { getTabConfigLoading } from 'lib/selectors/getTabs';
import { getMachine } from 'lib/selectors/getMachine';
import { useAccount } from './useAccount';
import { useTabsConfig } from './useTabConfig';

const AccountProvider = ({ children }) => {
  useAccount();
  useTabsConfig();
  const tabConfigLoading = useSelector(getTabConfigLoading);
  const userLoading = useSelector(getUserLoading);
  const ldClient = useLDClient();
  const user = useSelector(getUser);
  const company = useSelector(getCompany);
  const machine = useSelector(getMachine);
  const userId = _get(user, 'id');
  const userRole = _get(user, 'role');
  const companyId = _get(company, 'id');
  const machineRef = _get(machine, 'machineRef');
  useEffect(() => {
    (async () => {
      const havePropsLoaded = !!(userId && userRole && companyId && machineRef);
      if (ldClient && havePropsLoaded) {
        try {
          await ldClient.waitForInitialization();
          const isOperator = userRole === 'operator';
          // not necessary to track contexts by unique session token as we will treat all operators at a given
          // company the same when it comes to feature flags. since the token is baked into the operator
          // user id (ie `operator:${companyId}:${sessionToken}`), we need to use an alternative key for the user context
          // here - it should be sufficient to just pass the company id prefixed by `operator:` - that way, we can treat all
          // company operators as a single unique user context. this will help keep our MAU / MCI count down in launchdarkly and
          // reduce the chances of hitting our MAU / MCI cap
          const userKey = isOperator ? `operator:${companyId}` : userId;
          await ldClient?.identify({
            kind: 'multi',
            user: {
              kind: 'user',
              key: userKey,
              role: userRole,
            },
            company: {
              kind: 'company',
              key: companyId,
            },
            machine: {
              kind: 'machine',
              key: String(machineRef),
            },
          });
        } catch (err) {
          // shut down ldClient and notify bugsnag
          err.message = `LaunchDarkly client error: ${err.message}`;
          await Promise.all([bugsnag?.notify(err), ldClient?.close()]);
        }
      }
      return null;
    })();
  }, [ldClient, userId, userRole, companyId, machineRef]);

  return userLoading || tabConfigLoading ? <FullScreenLoading /> : children;
};

AccountProvider.propTypes = {
  children: PropTypes.any,
};

export { AccountProvider };
