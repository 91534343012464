import { createSelector } from 'reselect';

export const getActivityTypesWithArchived = (state) => {
  return state.activityTypes.data;
};

export const getActivityTypes = createSelector(
  [getActivityTypesWithArchived],
  (activityTypesWithArchived) => {
    return activityTypesWithArchived.filter(({ archivedAt }) => {
      return !archivedAt;
    });
  }
);

export const getAreActivityTypesLoading = (state) => {
  return !!state.activityTypes.loading;
};

export const getHasThreeOrMoreActivities = createSelector(
  [getActivityTypes],
  (activityTypes) => {
    return activityTypes.length > 2;
  }
);
