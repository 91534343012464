import { createSelector } from 'reselect';
import { getUtilPageScopeActiveStatusIntervals } from 'lib/selectors/getUtilPageScopeActiveStatusIntervals';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { diff, toISO } from 'lib/utils/date';

const getUtilPageScopeTimeInCycleMs = createSelector(
  [getUtilPageScopeActiveStatusIntervals, getHeavyUpdate],
  (
    intervals,
    _heavyUpdate // eslint-disable-line no-unused-vars
  ) => {
    return intervals.reduce((totalActive, curr) => {
      const intervalDurationMs = diff(
        curr.end || toISO(), // handle open interval
        curr.start,
        'milliseconds'
      );
      return totalActive + intervalDurationMs;
    }, 0);
  }
);

export { getUtilPageScopeTimeInCycleMs };
