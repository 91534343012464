import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';
import { Popup } from './styled';

const TotalPopupNumberContainer = styled(Popup)`
  height: 2.5rem;
  width: 21.5rem;
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  margin-left: 1rem;
`;

const TotalPopupNumberTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 1rem;
  font-weight: 700;
`;

const PopupText = styled.div`
  margin-left: 0.5rem;
  color: ${palette.Grey600};
`;

const PopupNumber = styled.div`
  background-color: ${palette.Red600};
  color: ${palette.White};
  font-weight: 700;
  border-radius: 2.5rem;
  height: 1.25rem;
  width: 1.5rem;
  text-align: center;
  margin: 0.1rem 0 0 0.5rem;
  font-size: 0.9rem;
`;

const MoreAlertMessage = ({ popupLen }) => {
  const { t } = useTranslation();
  return (
    <TotalPopupNumberContainer zIndex={zLayer.acrossAppPopup}>
      <TotalPopupNumberTextWrap>
        <PopupText>{t('Plus')}</PopupText>{' '}
        <PopupNumber>{popupLen - 1}</PopupNumber>
        <PopupText>{t('more alerts')}</PopupText>
      </TotalPopupNumberTextWrap>
    </TotalPopupNumberContainer>
  );
};

export { MoreAlertMessage };
