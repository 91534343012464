import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';

import { dateUTC, toISO } from 'lib/utils/date';
import link from './links';

const cache = new InMemoryCache();

function parseDate(date) {
  if (!date) return null;
  return toISO(dateUTC(date));
}

const client = new ApolloClient({
  link,
  cache,
  resolvers: {
    Count: {
      eventTime: (event) => {
        return parseDate(event.originalEventTime);
      },
    },
    StateInterval: {
      start: (interval) => {
        return parseDate(interval.originalStart);
      },
      end: (interval) => {
        return parseDate(interval.originalEnd);
      },
    },
    StateIntervalType: {
      start: (interval) => {
        return parseDate(interval.originalStart);
      },
      end: (interval) => {
        return parseDate(interval.originalEnd);
      },
    },
    CurrentState: {
      start: (interval) => {
        return parseDate(interval.originalStart);
      },
    },
    ConditionInterval: {
      start: (interval) => {
        return parseDate(interval.originalStart);
      },
      end: (interval) => {
        return parseDate(interval.originalEnd);
      },
    },
    CurrentCondition: {
      start: (interval) => {
        return parseDate(interval.originalStart);
      },
    },
    OperatorRun: {
      start: (operatorRun) => {
        return parseDate(operatorRun.originalStart);
      },
      end: (operatorRun) => {
        return parseDate(operatorRun.originalEnd);
      },
    },
  },
});

export { client };
