import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import { useNetworkError } from 'lib/hooks';
import { ERROR_KEYS } from 'lib/constants';

import { getAnnotationTypes } from 'lib/selectors/getAnnotationTypes';
import { GET_ANNOTATION_TYPES } from 'lib/api/queries';
import { Tree } from 'lib/utils/tree';
import { actionSetAnnotationTypes } from 'lib/actions';
import { getMachine } from 'lib/selectors/getMachine';

const useAnnotationTypes = () => {
  const dispatch = useDispatch();
  const annotationTypes = useSelector(getAnnotationTypes);
  const machine = useSelector(getMachine);
  const requestAnnotationTypes = useQuery(GET_ANNOTATION_TYPES, {
    fetchPolicy: 'no-cache',
    variables: {
      machineId: machine.id,
    },
    skip: !machine.id || annotationTypes.length > 0,
    onCompleted: ({ AnnotationType }) => {
      dispatch(actionSetAnnotationTypes(AnnotationType));
    },
  });
  const { loading, error } = requestAnnotationTypes;

  const tree = useMemo(() => {
    if (!annotationTypes || annotationTypes.length === 0) return new Tree();

    return Tree.parse(annotationTypes);
  }, [annotationTypes]);

  const [node, setNode] = useState(tree);

  useEffect(() => {
    return setNode(tree);
  }, [tree]);

  useNetworkError(requestAnnotationTypes, ERROR_KEYS.ANNOTATION);

  return { error, loading, node, setNode, tree };
};

export { useAnnotationTypes };
