import { createSelector } from 'reselect';
import _last from 'lodash/last';
import { toISO } from 'lib/utils/date';
import { getScopePartCountEvents } from 'lib/selectors/getScopePartCountEvents';

const getScopeCurrentCycleStart = createSelector(
  [getScopePartCountEvents],
  (scopePartCountEvents) => {
    if (scopePartCountEvents.length === 0) {
      return null;
    }
    return toISO(_last(scopePartCountEvents).eventTime);
  }
);

export { getScopeCurrentCycleStart };
