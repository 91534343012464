import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInterval } from 'lib/hooks';
import { isAfter, add, now } from 'lib/utils/date';
import { getLastRefresh } from 'lib/selectors/getLastRefresh';
import { actionSetPopupStack } from 'lib/actions';
import { getCompany } from 'lib/selectors/getCompany';

/* TESTING
  Waiting until midnight to test this is not realistic. 
  
  Comments are added below to have this popup show when there is a change in minute rather than a change in day.

  In order to test the full functionality of the popup, including the 'cancel' button, we must use data from the store, and test in a way that checkForRefresh only returns true when there is some change in a time boundary.  Therefore we cannot hard-code too much for testing purposes.

  I recommend testing with your system clock open. The popup will show soon after the clock enters a new minute.  If you hit cancel,  the popup will show 15 seconds later.  If you let the popup refresh your app, the popup will not show again until you enter a new minute.

  In real life (not with testing variables), the popup will show soon after you cross into a new day, and if you press 'cancel' the popup will be shown again an hour later.
*/

const JCI_COMPANY_ID = 'f1a2a527-0580-47f2-8c98-403743258163'; // Johnson Controls International - York Division Hattiesburg MS

// comment for testing
const watchInterval =
  60 * 60 * 1000 + Math.floor(Math.random() * 2 * 60 * 1000);

// uncomment for testing
// const watchInterval = 15000;

const useUpdateWatcher = () => {
  const dispatch = useDispatch();
  const lastRefresh = useSelector(getLastRefresh);
  const company = useSelector(getCompany);

  const checkForRefresh = useCallback(() => {
    // comment for testing
    const currentDay = now()
      .startOf('day')
      .toISOString();

    // uncomment for testing
    // const currentDay = now()
    //   .startOf('minute')
    //   .toISOString();

    // for testing also must change actionSetLastRefresh in reducers/index.js to use .startOf('minute')
    let shouldRefresh = lastRefresh && lastRefresh !== currentDay;

    if (company.id === JCI_COMPANY_ID) {
      // Refresh at 3am, 27 hours after the startOfDay for the prior refresh
      shouldRefresh = isAfter(now(), add(lastRefresh, 27, 'hours'));
    }

    if (shouldRefresh) {
      dispatch(
        actionSetPopupStack({
          params: {
            popupType: 'update',
            order: 1,
          },
        })
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [lastRefresh]);

  useInterval(checkForRefresh, watchInterval);
};

export { useUpdateWatcher };
