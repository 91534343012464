import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';
import { getIsInitialShiftPollLoading } from 'lib/selectors/getIsInitialShiftPollLoading';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';
import { getStartOfNextShopDay } from 'lib/selectors/getStartOfNextShopDay';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getShiftEnd } from 'lib/selectors/getShiftEnd';
import { getTokenScope } from 'lib/selectors/getTokenScope';
import { AnnotationsSocket } from 'lib/api/websockets/AnnotationsSocket';
import { actionSetAnnotations } from 'lib/actions';
import { getDowntimeStartDate } from 'lib/selectors/getDowntimeStartDate';

const useAnnotations = () => {
  const dispatch = useDispatch();
  const machine = useSelector(getMachine);
  const isInitialShiftPollLoading = useSelector(getIsInitialShiftPollLoading);
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const startOfCurrentShopDay = useSelector(getStartOfCurrentShopDay);
  const startOfNextShopDay = useSelector(getStartOfNextShopDay);
  const shiftStart = useSelector(getShiftStart);
  const shiftEnd = useSelector(getShiftEnd);
  const tokenScope = useSelector(getTokenScope);
  const downtimeStartDate = useSelector(getDowntimeStartDate);

  useEffect(() => {
    if (
      !machine.id ||
      isInitialShiftPollLoading ||
      !startOfCurrentShopDay ||
      !startOfNextShopDay
    ) {
      return () => {};
    }

    const socket = new AnnotationsSocket(
      machine.id,
      downtimeStartDate ||
        (isLatestShiftActive ? shiftStart : startOfCurrentShopDay),
      isLatestShiftActive ? shiftEnd : startOfNextShopDay
    );
    const onData = (data) => {
      return data && dispatch(actionSetAnnotations(data));
    };

    socket.subscribe(onData);

    return () => {
      return socket.unsubscribe(onData);
    };
  }, [
    machine,
    dispatch,
    isLatestShiftActive,
    isInitialShiftPollLoading,
    startOfCurrentShopDay,
    startOfNextShopDay,
    shiftStart,
    shiftEnd,
    tokenScope,
    downtimeStartDate,
  ]);
};

export { useAnnotations };
