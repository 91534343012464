import { createSelector } from 'reselect';
import { getWorkOrderManagement } from './getWorkOrderManagement';

const getLaborTicketModalOpen = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement.laborTicketModalOpen;
  }
);

export { getLaborTicketModalOpen };
