import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'lib/utils/routes';
import { pencilRegular, lightPlusCircle, times } from 'lib/icons';

import MainLayout from 'components/Layouts/MainLayout';
import { Small } from 'components/Text';
import { ControlBarButton, ControlBar } from '@m12s/component-library';
import { AddWorkOrderModal } from 'components/JobTable/AddWorkOrderModal';

import { calculateWorkOrderOpPartDuration } from 'lib/selectors/getCurrentWorkOrderOpPartDuration';
import { formatDuration, formatCycleTime } from 'lib/utils/date';
import { getHasThreeOrMoreActivities } from 'lib/selectors/getActivityTypes';
import { getShouldShowWorkOrderIdField } from 'lib/selectors/getShouldShowWorkOrderIdField';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getLatestJobId } from 'lib/selectors/getLatestJobId';
import useStartJobInMode from 'pages/SelectJob/useStartJobInMode';
import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

import { Top, Details, Detail } from './styled';
import { ActivitiesControls } from './ActivitiesControls';

const DURATION_FORMAT = 'short';
const DURATION_OPTIONS = { trim: 'both' };

function formatQuantity(quantity, t) {
  return quantity === undefined ? (
    <Small muted normal italic>
      {t('Calculating...')}
    </Small>
  ) : (
    quantity?.toLocaleString()
  );
}

function decorate(
  job,
  workOrderOp,
  shouldShowWorkOrderIdField,
  scopeNetPartsMade,
  workOrderId,
  t
) {
  const shimmedValues = workOrderOp
    ? {
        quantityRequired: workOrderOp.finishQuantity,
        quantityProduced: scopeNetPartsMade?.toLocaleString(),
        expectedSetupTime: workOrderOp.setupTimeMs / 1000,
        expectedCycleTime: workOrderOp.cycleTimeMs / 1000,
        expectedPartTime: calculateWorkOrderOpPartDuration(workOrderOp) / 1000,
        jobDescription:
          workOrderOp.partOperation?.description || _startCase(t('none')),
        part: workOrderOp.partOperation?.partNumber,
        partOperation: workOrderOp.sequenceNumber,
        lot: workOrderOp.lot,
      }
    : {
        quantityRequired: job.quantity,
        quantityProduced: formatQuantity(job.quantityProduced, t),
        expectedSetupTime: job.setupTime,
        expectedCycleTime: (job.expectedPartTime * job.actualParts) / 1000,
        expectedPartTime: job.expectedPartTime / 1000,
        jobDescription: job.description || _startCase(t('none')),
        part: job.part,
        partOperation: job.operation,
        lot: job.lot,
      };

  return {
    top: [
      [
        'Expected Setup Time',
        shimmedValues.expectedSetupTime
          ? formatDuration(
              shimmedValues.expectedSetupTime,
              DURATION_FORMAT,
              DURATION_OPTIONS
            )
          : _startCase(t('none')),
      ],
      [
        'Expected Cycle Time',
        formatCycleTime(shimmedValues.expectedCycleTime, DURATION_OPTIONS),
      ],
      ['Production Order', workOrderId || t('-'), shouldShowWorkOrderIdField],
    ],
    content: [
      [
        'expected part time',
        shimmedValues.expectedPartTime
          ? formatCycleTime(shimmedValues.expectedPartTime, DURATION_OPTIONS)
          : t('-'),
      ],
      [
        'ideal part time',
        job.idealPartTime
          ? formatCycleTime(job.idealPartTime / 1000, DURATION_OPTIONS)
          : 'N/A',
      ],
      [
        'description',
        shimmedValues.jobDescription,
        !shimmedValues.jobDescription,
      ],
      [
        'quantity required',
        shimmedValues.quantityRequired
          ? shimmedValues.quantityRequired?.toLocaleString()
          : 'N/A',
      ],
      ['quantity produced', shimmedValues.quantityProduced],
      ['part', shimmedValues.part],
      ['part operation', shimmedValues.partOperation],
      ['lot', shimmedValues.lot],
    ],
  };
}

const JobDetails = ({
  job,
  startJob,
  startJobAsJSG,
  nextStep,
  previousStep,
  isStartingNewRun,
  handleOnClose,
  workOrderId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const workOrderFlowEnabled = useIsWorkOrderFlow();

  const shouldShowWorkOrderIdField = useSelector(getShouldShowWorkOrderIdField);

  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  // Need a backend to get all parts made for WOO
  const scopeNetPartsMade = useSelector(getScopeNetPartsMade);

  const details = decorate(
    job,
    workOrderFlowEnabled && currentWorkOrderOp,
    shouldShowWorkOrderIdField,
    scopeNetPartsMade,
    workOrderId,
    t
  );
  const hasThreeOrMoreActivities = useSelector(getHasThreeOrMoreActivities);
  const oldJobId = job.jobId;
  const jobId = useSelector(getLatestJobId);
  const isAPMEnabled = useSelector(getIsAPMEnabled);
  const machine = useSelector(getMachine);

  const [openAddWorkOrderModal, setOpenAddWorkOrderModal] = useState(false);
  const [openStartNewRunModal, setOpenStartNewRunModal] = useState(false);
  const [operationHasChanged, setOperationHasChanged] = useState(false);
  // the start run button will be disabled if
  // 1. no work order id, or
  // 2. for APM machine, user opened the current operation overview page,
  //    but a new operation automatically start before user do anything
  const disableStartRunCondition =
    (isAPMEnabled && operationHasChanged) || !workOrderId;

  const closeAddWorkOrderModal = () => {
    setOpenAddWorkOrderModal(false);
  };

  useEffect(() => {
    if (jobId !== oldJobId) {
      setOperationHasChanged(true);
    }
  }, [jobId, oldJobId]);

  const closeStartNewRunModal = () => {
    setOpenStartNewRunModal(false);
  };

  // Applies shimming between JSG and Activities
  const { startSetup, startProduction } = useStartJobInMode(
    startJob,
    startJobAsJSG,
    job,
    workOrderId
  );

  return (
    <>
      <MainLayout.Container direction="column" shadow>
        <Top>
          <Details top>
            {details.top.map(([title, value, isEnabled = true]) => {
              if (!isEnabled) {
                return null;
              }
              /* eslint-disable consistent-return */
              return <Detail key={title} title={t(title)} value={value} top />;
            })}
          </Details>
        </Top>
        <Details>
          {details.content.map(([title, value, muted]) => {
            return (
              (value || value === 0) && (
                <Detail
                  key={title}
                  title={_startCase(t(title))}
                  value={value}
                  muted={muted}
                />
              )
            );
          })}
        </Details>
      </MainLayout.Container>
      {!isStartingNewRun && (
        <ActivitiesControls
          nextStep={nextStep}
          previousStep={previousStep}
          hasThreeOrMoreActivities={hasThreeOrMoreActivities}
          startProduction={startProduction}
          startSetup={startSetup}
          job={job}
        />
      )}
      {isStartingNewRun && (
        <ControlBar>
          {shouldShowWorkOrderIdField ? (
            <>
              {openAddWorkOrderModal && (
                <AddWorkOrderModal
                  forEditWorkOrderId
                  handleOnClose={closeAddWorkOrderModal}
                />
              )}
              {openStartNewRunModal && (
                <AddWorkOrderModal
                  isStopStartNewRun
                  handleOnClose={closeStartNewRunModal}
                  disableStartRunCondition={disableStartRunCondition}
                />
              )}
              <ControlBarButton
                icon={pencilRegular}
                onClick={() => {
                  if (workOrderFlowEnabled) {
                    return history.push(
                      `${Routes.machineIdSelectJobPath(
                        machine.id
                      )}?updateWorkOrderId=true`
                    );
                  }
                  return setOpenAddWorkOrderModal(true);
                }}
              >
                {workOrderId ? t('Update Order') : t('Add Order')}
              </ControlBarButton>
              <ControlBarButton
                icon={lightPlusCircle}
                disabled={disableStartRunCondition}
                onClick={() => {
                  if (workOrderFlowEnabled) {
                    return history.push(
                      Routes.machineIdSelectJobPath(machine.id)
                    );
                  }
                  return setOpenStartNewRunModal(true);
                }}
              >
                {t('Start New Order')}
              </ControlBarButton>
            </>
          ) : (
            <ControlBarButton icon={times} onClick={handleOnClose}>
              {t('Close Window')}
            </ControlBarButton>
          )}
        </ControlBar>
      )}
    </>
  );
};

export { JobDetails };
