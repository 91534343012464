import { createSelector } from 'reselect';

import { getCompany } from 'lib/selectors/getCompany';

const getLongDurationCutoff = createSelector(
  [getCompany],
  ({ downtimeListFilterDuration }) => {
    return downtimeListFilterDuration / 1000;
  }
);

export { getLongDurationCutoff };
