import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { useInterval } from 'lib/hooks';
import { now } from 'lib/utils/date';

const TickerContext = createContext();

const TickerProvider = ({ children }) => {
  const [nowMs, setNowMs] = useState(now().valueOf());

  useInterval(() => {
    setNowMs(now().valueOf());
  }, 1000);

  return (
    <TickerContext.Provider value={nowMs}>{children}</TickerContext.Provider>
  );
};

TickerProvider.propTypes = {
  children: PropTypes.any,
};

export { TickerProvider, TickerContext };
