class TokenStore {
  constructor(key) {
    this.key = `tokens/${key}`;
    this.value = null;
  }

  fetchValue() {
    const item = localStorage.getItem(this.key);
    return JSON.parse(item || null);
  }

  get() {
    this.value = this.value || this.fetchValue();
    return this.value;
  }

  set(value) {
    localStorage.setItem(this.key, JSON.stringify(value));
    this.value = value;
  }

  clear() {
    localStorage.removeItem(this.key);
    this.value = null;
  }
}

export default TokenStore;
