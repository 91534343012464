import styled from 'styled-components';
import { CardGrid, Icon, palette } from '@m12s/component-library';

const Grid = styled(CardGrid)`
  padding: 2.5rem 3rem;
`;

const InfoNavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  width: 100%;
  background-color: ${palette.Blue600};
  box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.05),
    inset -1px -1px 0px rgba(0, 0, 0, 0.05);
`;

const InfoNavIcon = styled(Icon)`
  margin: 0.5rem;
`;

const PriorCategory = styled(InfoNavWrapper)`
  background-color: ${palette.Grey200};
  color: ${palette.Grey700};
  font-weight: bold;
  font-size: 1rem;
`;

const Dark = styled.span`
  color: ${palette.Grey800};
  margin-right: 0.5rem;
`;

export { Grid, InfoNavWrapper, InfoNavIcon, PriorCategory, Dark };
