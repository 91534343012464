import { createSelector } from 'reselect';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { getActivitiesByRef } from './getActivitiesByRef';
import { getActivityTypes } from './getActivityTypes';
import { getHasOpenActivity } from './getHasOpenActivity';

const getActivityTypesByRef = createSelector([getActivityTypes], (types) => {
  if (!types) {
    return null;
  }

  return types.reduce((accum, type) => {
    return {
      ...accum,
      [type.activityTypeRef]: type,
    };
  }, {});
});

const getActivityMode = (activity, typesByRef) => {
  if (!activity) {
    return null;
  }
  const activityType = typesByRef[activity.activityTypeRef];
  return activityType.activityMode;
};

const findStartAt = (activities, currentActivityMode, typesByRef) => {
  for (let i = 0; i < activities.length; i += 1) {
    const activity = activities[i];
    const previousActivityMode = getActivityMode(activities[i + 1], typesByRef);

    if (!previousActivityMode || previousActivityMode !== currentActivityMode) {
      return activity.start;
    }
  }
  return null;
};

const getLatestActivityModeStartAt = createSelector(
  [
    getActivitiesByRef,
    getLatestActivityType,
    getActivityTypesByRef,
    getHasOpenActivity,
  ],
  (activitiesByRef, latestActivityType, typesByRef, hasOpenActivity) => {
    if (!activitiesByRef || !typesByRef || !hasOpenActivity) {
      return null;
    }
    const activities = Object.values(activitiesByRef);

    activities.sort((a, b) => {
      return new Date(b.start) - new Date(a.start);
    });

    const currentActivityMode = latestActivityType.activityMode;

    return findStartAt(activities, currentActivityMode, typesByRef);
  }
);

export { getLatestActivityModeStartAt };
