import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { check, arrowUp, arrowDown } from 'lib/icons';
import { LoadingTextPlaceholder } from 'components/LoadingTextPlaceholder';
import { Loading } from 'components/Loading';


export const LoadingCounterValue = styled(LoadingTextPlaceholder)`
  height: 2rem;
  width: 90%;
  border-radius: 2px;
`;

export const LoadingPartBuckets =  () => {
  const { t } = useTranslation();
  return (
    <Loading size="32px" fontSize="1rem" text={t('Loading')} />
  )
}

export const createStatusIcon = (isAtGoal, isBehind) => {
  if (isAtGoal) {
    return check;
  }
  if (isBehind) {
    return arrowDown;
  }
  return arrowUp;
}
