import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    background: 'rgb(26, 32, 44, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    top: 'auto',
    right: 'auto',
    left: 'auto',
    bottom: 'auto',
    border: 0,
    borderRadius: '0.25rem',
    padding: 0,
    width: '34rem',
    maxWidth: '100%',
    overflow: 'hidden',
    boxShadow:
      '0px 1px 1px rgba(42, 54, 57, 0.04), 0px 1px 3px rgba(42, 54, 57, 0.08)',
  },
};

const Dialog = ({ children, getStyle, ...props }) => {
  return (
    <Modal {...props} style={getStyle ? getStyle(customStyles) : customStyles}>
      {children}
    </Modal>
  );
};

export { Dialog };
