import { createSelector } from 'reselect';
import { toISO, getStartOf, add } from 'lib/utils/date';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';

const getStartOfNextHour = createSelector(
  [getHeavyUpdate],
  // eslint-disable-next-line no-unused-vars
  (_heavyUpdate) => {
    const nowISO = toISO();
    const currentHourEnd = getStartOf(nowISO, 'hour');
    return add(currentHourEnd, 1, 'hour');
  }
);

export { getStartOfNextHour };
