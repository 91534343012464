import React, { useMemo } from 'react';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import { Root, Line, Circle, KeyItem } from 'components/ChartKey';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getJobRunASWrapper } from 'lib/selectors/getJobRunASWrapper';
import { formatCycleTime, getUnitDurationFromString } from 'lib/utils/date';
import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';

const KeyRoot = styled(Root)`
  margin-left: 1rem;
  @media (orientation: portrait) {
    flex-direction: column;
    margin-left: 0;
    > div {
      margin-bottom: 1rem;
    }
  }
`;

const ExpectedLine = styled(Line)`
  border-bottom: dotted 3px ${palette.Teal400};
`;

const ScopeAvgCircle = styled(Circle)`
  background-color: ${palette.Purple500};
`;

const RecentAvgCircle = styled(Circle)`
  background-color: ${palette.Purple300};
`;

const RecentCircle = styled(Circle)`
  background-color: ${palette.Grey300};
`;

const DurationLabel = styled.b`
  white-space: nowrap;
`;

const Key = () => {
  const { t } = useTranslation();
  const hasOpenJobRun = useSelector(getHasOpenJobRun);
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const jobRunASWrapper = useSelector(getJobRunASWrapper);
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );
  const { expectedUnitDuration, actualParts } = areActivitiesEnabled
    ? latestActivitySet
    : jobRunASWrapper;

  const expectedPartTimeSeconds = useMemo(() => {
    return (
      getUnitDurationFromString(expectedUnitDuration, 'seconds') * actualParts
    );
  }, [expectedUnitDuration, actualParts]);

  return (
    <KeyRoot>
      <KeyItem>
        <ScopeAvgCircle />
        {_startCase(
          t(`${hasOpenActivity || hasOpenJobRun ? 'operation ' : ''}average`)
        )}
      </KeyItem>
      <KeyItem>
        <RecentAvgCircle />
        {_startCase(t('recent average'))}
      </KeyItem>
      <KeyItem>
        <RecentCircle />
        {_startCase(t('recent cycles'))}
      </KeyItem>
      {!openActivitySetHasNoStandards && (hasOpenActivity || hasOpenJobRun) && (
        <KeyItem>
          <ExpectedLine />
          {_startCase(t('expected'))}&nbsp;
          <DurationLabel>
            ({formatCycleTime(expectedPartTimeSeconds)})
          </DurationLabel>
        </KeyItem>
      )}
    </KeyRoot>
  );
};

export { Key };
