import useWindowDimensions from './useWindowDimensions';

const useRemPx = () => {
  // using this to recalc rem value on every window resize
  // eslint-disable-next-line no-unused-vars
  const { width } = useWindowDimensions();
  const html = document.querySelector('html');
  const fontSize = window.getComputedStyle(html).getPropertyValue('font-size');
  const remPx = parseFloat(fontSize);

  return remPx;
};

export default useRemPx;
