import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import { ACTIVITY_MODES } from 'lib/constants';

const defaultTypes = [];

const getSetupActivityTypes = createSelector(
  [getActivityTypes],
  (activityTypes = defaultTypes) => {
    if (!_get(activityTypes, 'length')) {
      return defaultTypes;
    }
    return activityTypes.filter(({ activityMode }) => {
      return activityMode === ACTIVITY_MODES.SETUP;
    });
  }
);

export { getSetupActivityTypes };
