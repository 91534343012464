import { JOB_STATUSES } from 'lib/constants';
import { createSelector } from 'reselect';
import { getAreActivitiesEnabled } from './getAreActivitiesEnabled';
import { getCurrentSetupModeActivities } from './getCurrentSetupModeActivities';
import { getJobRun } from './getJobRun';
import { getJobStatus } from './getJobStatus';

// This returns the time you entered setup mode.
// If you have 3 consecutive setup activities (even with pauses), this will get the start of the first.
export const getSetupModeStart = createSelector(
  [
    getCurrentSetupModeActivities,
    getAreActivitiesEnabled,
    getJobRun,
    getJobStatus,
  ],
  (activities, areActivitiesEnabled, jobRun, jobStatus) => {
    if (!areActivitiesEnabled) {
      if (jobStatus === JOB_STATUSES.SETUP && jobRun && jobRun.startDate) {
        return jobRun.startDate;
      }
      return null;
    }
    if (!activities || !activities.length) {
      return null;
    }
    return activities[0].start;
  }
);
