import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import { useSelector } from 'react-redux';

export default () => {
  const history = useHistory();
  const machine = useSelector(getMachine);

  const goToHome = useCallback(() => {
    return history.push(Routes.machineIdPath(machine.id));
  }, [history, machine.id]);

  return goToHome;
};
