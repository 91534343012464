import { createSelector } from 'reselect';

import moment from 'moment';
import { getCurrentStatus } from 'lib/selectors/getCurrentStatus';

const getStatusStartAt = createSelector(
  [getCurrentStatus],
  (currentInterval) => {
    if (!currentInterval) return null;

    return moment(currentInterval.start);
  }
);

export { getStatusStartAt };
