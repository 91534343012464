/* eslint-disable no-shadow */
import { useSelector } from 'react-redux';
import {
  toISO,
  getUnitDurationFromString,
  formatDuration,
} from 'lib/utils/date';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getAdjustedProductionDurationDates } from 'lib/selectors/getAdjustedProductionDurationDates';
import { getLatestProductionActivityStartAt } from 'lib/selectors/getLatestProductionActivityStartAt';
import { getLatestProductionActivityEndAt } from 'lib/selectors/getLatestProductionActivityEndAt';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { getRejectedParts } from 'lib/selectors/getRejectedParts';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';

const useProductionStats = () => {
  const latestActivitySet = useSelector(getLatestActivitySet);
  const isPaused = useSelector(getIsPaused);
  const productionActivityStartAtISO = useSelector(
    getLatestProductionActivityStartAt
  );
  const productionActivityEndAtISO = useSelector(
    getLatestProductionActivityEndAt
  );
  const adjustedProductionDurationDates = useSelector(
    getAdjustedProductionDurationDates
  );

  const productionRejectedParts = useSelector(getRejectedParts);
  const productionPartsGoal = useSelector(getScopePartsGoal);
  const productionPartsMade = useSelector(getScopeNetPartsMade);

  const {
    adjustedProductionStartDate: adjustedProductionStart,
  } = adjustedProductionDurationDates;

  // Used for duration ticker of current production
  // 1. adjustedProductionStart will be jobRun.startDate if activities disabled
  const latestProductionStartDate = toISO(
    productionActivityStartAtISO || adjustedProductionStart
  );
  // 2. Adjusted for pauses, used for duration ticker of total aggregate production,
  const adjustedProductionStartDate = toISO(adjustedProductionStart);
  // 3. Sets an end to the duration tickers if paused
  const productionEndDateOverride =
    isPaused && productionActivityEndAtISO ? productionActivityEndAtISO : null;

  const { expectedProductionDuration } = latestActivitySet;
  const expectedProductionDurationSecs = getUnitDurationFromString(
    expectedProductionDuration,
    'seconds'
  );
  const expectedProductionDurationStr = formatDuration(
    expectedProductionDurationSecs,
    'short',
    {
      largest: 2,
    }
  );

  return {
    latestProductionStartDate,
    adjustedProductionStartDate,
    productionEndDateOverride,
    expectedProductionDurationStr,
    expectedProductionDuration,
    productionRejectedParts,
    productionPartsGoal,
    productionPartsMade,
  };
};

export { useProductionStats };
