import React from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';

import { useTranslation } from 'react-i18next';
import { Icon, palette } from '@m12s/component-library';

import { checkCircle, questionCircle } from 'lib/icons';
import { H3, P } from 'components/Text';
import { EmptyContainer } from './styled';

const Empty = ({ variant }) => {
  const { t } = useTranslation();
  const variants = {
    allCategorized: {
      icon: checkCircle,
      iconColor: palette.Green600,
      title: `${_startCase(t('nice work'))}!`,
      subtitle: _capitalize(t('all of your Downtime Events are categorized')),
    },
    noResults: {
      icon: questionCircle,
      iconColor: palette.Grey400,
      title: t('No Results Found'),
      subtitle: _capitalize(t('please try adjusting your filters')),
    },
  };

  const variantData = variants[variant];

  return (
    <EmptyContainer>
      <Icon
        style={{ marginBottom: '1rem' }}
        color={variantData.iconColor}
        icon={variantData.icon}
        size="3.5rem"
      />
      <H3 color={palette.Grey500}>{variantData.title}</H3>
      <P color={palette.Grey500} medium>
        {variantData.subtitle}
      </P>
    </EmptyContainer>
  );
};

Empty.propTypes = {
  variant: PropTypes.oneOf(['allCategorized', 'noResults']),
};

export { Empty };
