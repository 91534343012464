import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _find from 'lodash/find';

import { Control, FilterSelect, Filters } from 'components/Table';
import { SearchInput } from 'components/SearchInput';

const getOptions = (t) => {
  return {
    state: [
      { label: _capitalize(t('all')), value: 'all' },
      { label: _capitalize(t('active')), value: 'active' },
      { label: _capitalize(t('inactive')), value: 'inactive' },
    ],
    orderBy: [
      { label: _capitalize(t('date')), value: 'date' },
      { label: _capitalize(t('description')), value: 'description' },
      { label: _capitalize(t('duration')), value: 'duration' },
    ],
    severity: [
      { label: _capitalize(t('all')), value: 'all' },
      { label: _capitalize(t('warning')), value: 'warning' },
      { label: _capitalize(t('fault')), value: 'fault' },
    ],
  };
};

const TableFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return getOptions(t);
  }, [t]);
  const [description, setDescription] = useState(filters.description);

  return (
    <>
      <Control>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFilters({ ...filters, description });
          }}
        >
          <SearchInput
            inputProps={{
              value: description,
              onChange: ({ target: { value } }) => {
                return setDescription(value);
              },
              placeholder: _capitalize(t('search by keyword')),
            }}
            onSubmit={() => {
              return setFilters({ ...filters, description });
            }}
          />
        </form>
      </Control>
      <Filters>
        <FilterSelect
          id="show-filter"
          label={_capitalize(t('show'))}
          options={options.state}
          value={_find(options.state, { value: filters.state })}
          onChange={({ value }) => {
            return setFilters({ ...filters, state: value });
          }}
        />
        <FilterSelect
          id="sort-by-filter"
          label={_capitalize(t('sort by'))}
          options={options.orderBy}
          value={_find(options.orderBy, { value: filters.orderBy })}
          onChange={({ value }) => {
            return setFilters({ ...filters, orderBy: value });
          }}
        />
        <FilterSelect
          id="severity-filter"
          label={_capitalize(t('severity'))}
          options={options.severity}
          value={_find(options.severity, { value: filters.severity })}
          onChange={({ value }) => {
            return setFilters({ ...filters, severity: value });
          }}
        />
      </Filters>
    </>
  );
};

TableFilters.propTypes = {
  filters: PropTypes.shape({
    description: PropTypes.string,
    orderBy: PropTypes.string,
    severity: PropTypes.string,
    state: PropTypes.string,
  }),
  setFilters: PropTypes.func,
};

export { TableFilters };
