import { createSelector } from 'reselect';
import { getPartCountEvents } from 'lib/selectors/getPartCountEvents';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';

const defaultScopePartCountEvents = [];

const getScopePartCountEvents = createSelector(
  [getPartCountEvents, getScopeStart, getScopeReadTimePCM],
  (partCountEvents, scopeStart, scopeReadTimePCM = 1) => {
    if (!scopeStart) {
      return defaultScopePartCountEvents;
    }
    const filteredEvents = partCountEvents.reduce(
      (accum, { eventTime, value, ...eventData }) => {
        if (eventTime < scopeStart) {
          return accum;
        }
        if (scopeReadTimePCM !== 1) {
          return [
            ...accum,
            { eventTime, value: value * getScopeReadTimePCM, ...eventData },
          ];
        }
        return [...accum, { eventTime, value, ...eventData }];
      },
      []
    );
    return filteredEvents;
  }
);

export { getScopePartCountEvents };
