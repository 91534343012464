import { createSelector } from 'reselect';

import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getLatestActivitySetActivities } from 'lib/selectors/getLatestActivitySetActivities';

/*
  this selector really only applies so long as scoping prevents different PCM's from appearing
  on-screen simultaneously, except for the following edge case: when migrating customers to
  activities, we stop applying the PCM on 'write' / bake this factor into the stream - so we
  should not transform in that case, even if the jsg has a PCM value other than 1. we split
  any open activities at the time of migration, so this makes for a reasonable demarc point at
  which to apply the PCM on-read. scope will be reset on this explicit demarc point, so we only
  need to ignore the pcm when all activities have been created via migration, as the presence of a
  single non-migrated activity in the set will force the query to only bucket parts after the migration
  point
*/

const getScopeReadTimePCM = createSelector(
  [
    getAreActivitiesEnabled,
    getLatestActivitySet,
    getHasOpenActivitySet,
    getLatestActivitySetActivities,
  ],
  (
    areActivitiesEnabled,
    latestActivitySet,
    hasOpenActivitySet,
    latestActivitySetActivities
  ) => {
    if (
      areActivitiesEnabled &&
      hasOpenActivitySet &&
      latestActivitySetActivities &&
      latestActivitySetActivities.length
    ) {
      // should already be ordered by ASC start in store
      const setHasActivityCreatedPostMigration = latestActivitySetActivities.some(
        (activity) => {
          return activity.jobScheduleGroupId === null;
        }
      );
      if (setHasActivityCreatedPostMigration) {
        return latestActivitySet.partCountMultiplier || 1;
      }
    }
    return 1;
  }
);

export { getScopeReadTimePCM };
