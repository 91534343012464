import { createSelector } from 'reselect';
import { getProductionActivityTypeRefs } from 'lib/selectors/getProductionActivityTypeRefs';
import { getCheckIsTargetActivityTypeRef } from 'lib/utils/activities';

// returns memoized check func with target type refs "baked" in
const getCheckIsProductionActivityTypeRef = createSelector(
  [getProductionActivityTypeRefs],
  (prodActivityTypeRefs = []) => {
    return getCheckIsTargetActivityTypeRef(prodActivityTypeRefs);
  }
);

export { getCheckIsProductionActivityTypeRef };
