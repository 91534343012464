/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true  }] */
import io from 'socket.io-client';
import session from 'lib/api/links/session';

const cmd = {
  JOIN: 'join',
};

const Event = {
  CONNECT: 'connect',
  JOIN_SUCCESS: 'channel_join_success',
  JOIN_FAILURE: 'channel_join_failure',
  ERROR: 'channel_error',
};

class SocketChannel {
  constructor({ url, initCmd, initParams, joinParams }) {
    this.url = url;
    this.socket = io(this.url, {
      transports: ['websocket'],
      forceNew: true,
      autoConnect: false,
      reconnection: true,
      reconnectionDelay: 10 * 1000,
      reconnectionDelayMax: 60 * 1000,
    });
    this.initCmd = initCmd;
    this.initParams = initParams || null;
    this.connect(joinParams);
  }

  get connected() {
    return this.socket.connected;
  }

  connect(joinParams = {}) {
    /* eslint-disable consistent-return */
    return new Promise((resolve, reject) => {
      if (this.connected) return resolve();

      this.socket.on(Event.CONNECT, async () => {
        const token = await session.getApiKey();
        this.socket.emit(cmd.JOIN, { ...joinParams, version: 1, token });
      });

      this.socket.on(Event.JOIN_FAILURE, (err) => {
        reject(err);
      });

      this.socket.on(Event.ERROR, (err) => {
        reject(err);
      });

      this.socket.on(Event.JOIN_SUCCESS, () => {
        if (!this.initParams) {
          this.socket.emit(this.initCmd);
        } else {
          this.socket.emit(this.initCmd, this.initParams);
        }
        resolve();
      });

      this.socket.open();
    });
  }

  disconnect() {
    this.socket.close();
    this.socket.listeners(Event.JOIN_FAILURE, (l) => {
      return this.socket.off(Event.JOIN_FAILURE, l);
    });
    this.socket.listeners(Event.ERROR, (l) => {
      return this.socket.off(Event.JOIN_FAILURE, l);
    });
  }

  on(event, cb) {
    this.socket.on(event, cb);
  }

  onError(cb) {
    this.socket.on(Event.ERROR, cb);
  }

  off(event, cb) {
    this.socket.off(event, cb);
  }

  once(event, cb) {
    this.socket.on(event, cb);
  }

  emit(event, data) {
    if (data) this.socket.emit(event, data);
    else this.socket.emit(event);
  }
}

export { SocketChannel };
