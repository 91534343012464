import { createSelector } from 'reselect';
import { getHasOpenActivity } from './getHasOpenActivity';
import { getLatestActivityMode } from './getLatestActivityMode';

const getHasOpenSetupActivity = createSelector(
  [getHasOpenActivity, getLatestActivityMode],
  (hasOpenActivity, latestActivityMode) => {
    return hasOpenActivity && latestActivityMode === 'setup';
  }
);

export { getHasOpenSetupActivity };
