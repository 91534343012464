/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true  }] */
import EventEmitter from 'lib/utils/EventEmitter';
import { REST_URI } from 'lib/appConfig';
import { SocketChannel } from './SocketChannel';

const event = {
  WORKFLOW_TRIGGERED: 'workflow_triggered',
  CHANNEL_JOIN_SUCCESS: 'channel_join_success',
};

const ENDPOINT = `${REST_URI}/events/workflows`;

class WorkflowSocket {
  constructor(machineRef) {
    this.socket = new SocketChannel({
      url: ENDPOINT,
      joinParams: { machineRef },
    });
    this.emitter = new EventEmitter();
    this.socket.onError(this._handleError);
  }

  async subscribe(onData, onError) {
    try {
      this.socket.on(event.WORKFLOW_TRIGGERED, ({ payload }) => {
        onData(payload);
      });
      this.emitter.on('error', onError);
    } catch (e) {
      this._handleError(e);
    }
  }

  unsubscribe(onData, onError) {
    this.emitter.off('error', onError);
    this.socket.disconnect();
  }

  _handleError(e) {
    /* eslint-disable no-console */
    console.error(e);
    this.emitter.emit('error', e);
  }
}

export { WorkflowSocket };
