import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';

const Root = styled.div`
  position: relative;
  z-index: ${zLayer.menu};

  svg {
    display: block !important;
  }
`;

const Menu = styled.nav`
  background: ${palette.Grey900};
  border-radius: 0.25rem;
  bottom: calc(100% + 1.5rem);
  box-shadow: 0px 1px 4px rgba(74, 85, 104, 0.08),
    0px 1px 8px rgba(74, 85, 104, 0.12), -1px -1px 4px rgba(74, 85, 104, 0.05);
  color: ${palette.White};
  left: 1.5rem;
  padding: 1.6rem;
  position: absolute;
  width: max-content;
`;

const MenuList = styled.ul`
  display: grid;
  grid-row-gap: 0.5rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
`;

const MenuIcon = styled.div`
  color: ${palette.Grey600};
  font-weight: bold;
  margin-right: 0.5rem;
  width: 2rem;
`;

const MenuLink = styled(Link)`
  color: inherit;
  display: flex;
  flex: 1;
  text-decoration: none;
  align-items: center;
  padding: 0.25rem 0;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  display: flex;
  flex: 1;
  font-size: inherit;
  font-weight: inherit;
  padding: 0.25rem 0;
  text-decoration: none;
  align-items: center;
`;
export { Root, Menu, MenuList, MenuItem, MenuIcon, MenuLink, MenuButton };
