import { createSelector } from 'reselect';
import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';

const utilizationHourliesData = (store) => {
  return store.utilizationHourlies;
};

const getLastGenerated = createSelector(
  [utilizationHourliesData],
  ({ generatedAt }) => {
    return generatedAt;
  }
);

const getUtilizationHourlies = createDeepEqualitySelector(
  [utilizationHourliesData],
  ({ hourlies }) => {
    return hourlies;
  }
);

export { getUtilizationHourlies, getLastGenerated };
