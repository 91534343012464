import { createSelector } from 'reselect';
import { getOperators } from 'lib/selectors/getOperators';
import { getLatestOperatorId } from 'lib/selectors/getLatestOperatorId';

const getLatestOperator = createSelector(
  [getOperators, getLatestOperatorId],
  (operators, operatorId) => {
    if (operators?.length > 0 && operatorId) {
      return operators?.find((operator) => {
        return operator?.id === operatorId;
      });
    }
    return null;
  }
);

export { getLatestOperator };
