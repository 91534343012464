import { createAction as cA } from 'lib/utils/createAction';

export const actionSetLaunchDarkleyFlags = cA('SET_LAUNCH_DARKLY_FLAGS')(
  (flags) => {
    return {
      flags,
    };
  }
);

export const actionSetNetworkError = cA('SET_NETWORK_ERROR')((id, message) => {
  return {
    id,
    message,
  };
});

export const actionUnsetNetworkError = cA('UNSET_NETWORK_ERROR')((id) => {
  return {
    id,
  };
});

export const actionRetryNetworkError = cA('RETRY_NETWORK_ERROR')((id) => {
  return {
    id,
  };
});

export const actionUpdateConnectionStatus = cA('UPDATE_CONNECTION_STATUS')(
  (status) => {
    return { status };
  }
);

export const actionSetMachine = cA('SET_MACHINE')(
  ({ machine, loading, error }) => {
    return {
      machine,
      loading,
      error,
    };
  }
);

export const actionSetShifts = cA('SET_SHIFTS')(
  ({ shifts, loading, error }) => {
    return {
      data: shifts,
      loading,
      error,
    };
  }
);

export const actionSetUser = cA('SET_USER')(({ user, loading, error }) => {
  return {
    data: user,
    loading,
    error,
  };
});

export const actionSetCompany = cA('SET_COMPANY')(
  ({ company, loading, error }) => {
    return {
      data: company,
      loading,
      error,
    };
  }
);

export const actionSetAgentStatus = cA('SET_AGENT_STATUS')((status) => {
  return { status };
});

export const actionSetJobRun = cA('SET_JOB_RUN')((jobRun) => {
  return { jobRun };
});

export const actionSetRecentActivitySets = cA('SET_RECENT_ACTIVITY_SETS')(
  ({ activitySets }) => {
    return { activitySets };
  }
);

export const actionSetRecentOperatorRuns = cA('SET_RECENT_OPERATOR_RUNS')(
  ({ operatorRuns }) => {
    return { operatorRuns };
  }
);

export const actionOptimisticActivitySetOpen = cA(
  'OPTIMISTIC/ACTIVITY_SET_OPENED'
)(({ activitySet, activity, job }) => {
  return { activitySet, activity, job };
});

export const actionCanonicalActivitySetOpen = cA(
  'CANONICAL/ACTIVITY_SET_OPENED'
)(
  ({
    activitySet,
    activity,
    optimisticActivitySetRef,
    optimisticActivityRef,
  }) => {
    return {
      activitySet,
      activity,
      optimisticActivitySetRef,
      optimisticActivityRef,
    };
  }
);

export const actionCanonicalActivitySetDelete = cA(
  'CANONICAL/ACTIVITY_SET_DELETED'
)(({ activitySetRef }) => {
  return { activitySetRef };
});

export const actionOptimisticActivitySetClose = cA(
  'OPTIMISTIC/ACTIVITY_SET_CLOSED'
)(({ activitySet, activity }) => {
  return { activitySet, activity };
});

export const actionCanonicalActivitySetClose = cA(
  'CANONICAL/ACTIVITY_SET_CLOSED'
)(({ activitySet, activity }) => {
  return { activitySet, activity };
});

export const actionCanonicalActivitySetReopen = cA(
  'CANONICAL/ACTIVITY_SET_REOPENED'
)(({ activitySet, activity }) => {
  return { activitySet, activity };
});

export const actionOptimisticActivitySetUpdate = cA(
  'OPTIMISTIC/ACTIVITY_SET_UPDATED'
)(({ activitySet }) => {
  return { activitySet };
});

export const actionCanonicalActivitySetUpdate = cA(
  'CANONICAL/ACTIVITY_SET_UPDATED'
)(({ activitySet }) => {
  return { activitySet };
});

export const actionOptimisticActivityCreate = cA('OPTIMISTIC/ACTIVITY_CREATED')(
  ({ activity }) => {
    return { activity };
  }
);

export const actionCanonicalActivityCreate = cA('CANONICAL/ACTIVITY_CREATED')(
  ({ activity, optimisticActivityRef }) => {
    return {
      activity,
      optimisticActivityRef,
    };
  }
);

export const actionOptimisticActivityUpdate = cA('OPTIMISTIC/ACTIVITY_UPDATED')(
  ({ activity }) => {
    return { activity };
  }
);

export const actionCanonicalActivityUpdate = cA('CANONICAL/ACTIVITY_UPDATED')(
  ({ activity }) => {
    return { activity };
  }
);

export const actionCanonicalActivityDelete = cA('CANONICAL/ACTIVITY_DELETED')(
  ({ activityRef }) => {
    return { activityRef };
  }
);

export const actionOptimisticActivitySwitch = cA(
  'OPTIMISTIC/ACTIVITY_SWITCHED'
)(({ optimisticActivity, latestActivityRef }) => {
  return { optimisticActivity, latestActivityRef };
});

export const actionCanonicalActivitySwitch = cA('CANONICAL/ACTIVITY_SWITCHED')(
  ({ activity, optimisticActivityRef, latestActivityRef }) => {
    return { activity, optimisticActivityRef, latestActivityRef };
  }
);

export const actionCanonicalActivityUnswitch = cA(
  'CANONICAL/ACTIVITY_UNSWITCHED'
)(({ activityRef, latestActivityRef }) => {
  return { activityRef, latestActivityRef };
});

export const actionSetActivityTypes = cA('SET_ACTIVITY_TYPES')(
  ({ activityTypes }) => {
    return { activityTypes };
  }
);

export const actionSetInitialData = cA('SET_INITIAL_DATA')((data) => {
  return data;
});

export const actionSetJobTotal = cA('SET_JOB_TOTAL')((jobTotal) => {
  return { jobTotal };
});

export const actionSetShiftStatusIntervals = cA('SET_SHIFT_STATUS_INTERVALS')(
  (executionIntervals) => {
    return { executionIntervals };
  }
);

export const actionSetUtilizationHourlies = cA('SET_UTILIZATION_HOURLIES')(
  ({ hourlies, generatedAt }) => {
    return { hourlies, generatedAt };
  }
);

export const actionSetDowntimeIntervals = cA('SET_DOWNTIME_INTERVALS')(
  (executionIntervals, lastNonIdleInterval) => {
    return { executionIntervals, lastNonIdleInterval };
  }
);

export const actionSetScopeStatusIntervals = cA('SET_SCOPE_STATUS_INTERVALS')(
  (executionIntervals) => {
    return { executionIntervals };
  }
);

export const actionSetPartAdjustmentTypes = cA('SET_PART_ADJUSTMENT_TYPES')(
  (partAdjustmentTypes) => {
    return { partAdjustmentTypes };
  }
);

export const actionSetAnnotationTypes = cA('SET_ANNOTATION_TYPES')(
  (annotationTypes) => {
    return { annotationTypes };
  }
);

export const actionSetAnnotations = cA('SET_ANNOTATIONS')((annotations) => {
  return {
    annotations,
  };
});

export const actionSetLoading = cA('SET_LOADING')();

export const actionUnsetLoading = cA('UNSET_LOADING')();

export const actionSetOperators = cA('SET_OPERATORS')((operators) => {
  return {
    operators,
  };
});

export const actionSetMachineERPResources = cA('SET_MACHINE_ERP_RESOURCES')(
  (resources, erpResourceId) => {
    return {
      resources,
      erpResourceId,
    };
  }
);

export const actionSetAlarms = cA('SET_ALARMS')((alarms) => {
  return { alarms };
});

export const actionSetPartCountEvents = cA('SET_PART_COUNT_EVENTS')(
  (partCountEvents) => {
    return { partCountEvents };
  }
);

export const actionClosePopup = cA('CLOSE_POPUP')();

export const actionDismissPopup = cA('DISMISS_POPUP')((params) => {
  return params;
});

export const actionClearDowntimeFlyup = cA('CLEAR_DOWNTIME_FLYUP')();

export const actionTriggerDowntimeFlyup = cA('TRIGGER_DOWNTIME_FLYUP')();

export const actionDismissDowntimeFlyup = cA('DISMISS_DOWNTIME_FLYUP')();

export const actionSetPopupStack = cA('SET_POPUP_STACK')(({ params }) => {
  return params;
});

export const actionRecalculate = cA('RECALCULATE')();

export const actionSetPartAdjustments = cA('SET_PART_ADJUSTMENTS')(
  (partAdjustments) => {
    return {
      partAdjustments,
    };
  }
);

export const actionCreatePartAdjustmentSuccess = cA(
  'CREATE_PART_ADJUSTMENT_SUCCESS'
)((partAdjustment) => {
  return {
    partAdjustment,
  };
});

export const actionSetLatestHeartbeat = cA('SET_LATEST_HEARTBEAT')(
  (payload) => {
    return payload;
  }
);

export const actionSetLastRefresh = cA('SET_LAST_REFRESH')((payload) => {
  return payload;
});

export const actionReset = cA('RESET')((params) => {
  return params;
});

export const actionToggleTraining = cA('TOGGLE_TRAINING')((params) => {
  return params;
});

export const actionSubmitAnnotation = cA('SUBMIT_ANNOTATION')();

export const actionSubmitAnnotationFailure = cA('SUBMIT_ANNOTATION_FAILURE')();

export const actionSubmitAnnotationSuccess = cA('SUBMIT_ANNOTATION_SUCCESS')(
  (annotations) => {
    return { annotations };
  }
);

export const actionSetActivitiesStatus = cA('SET_ACTIVITIES_STATUS')(
  (activitiesStatus) => {
    return { activitiesStatus };
  }
);

export const actionSetProgramNameData = cA('SET_PROGRAM_NAME_DATA')(
  (programNameData) => {
    return { programNameData };
  }
);

export const actionSetPartCountBuckets = cA('SET_PART_BUCKETS')(
  ({ scheduledPartBuckets, unscheduledPartBuckets, startDate }) => {
    return { scheduledPartBuckets, unscheduledPartBuckets, startDate };
  }
);

export const actionSetSetupRawParts = cA('SET_PART_SETUP_RAW_PARTS')(
  ({ sum }) => {
    return { sum };
  }
);

export const actionSetShiftSetupRawParts = cA('SET_SHIFT_SETUP_RAW_PARTS')(
  ({ sum }) => {
    return { sum };
  }
);

export const actionSetFirstPart = cA('SET_FIRST_PART')((firstPart) => {
  return { firstPart };
});

export const actionSetLastPartTimestamp = cA('SET_LAST_PART_TIMESTAMP')(
  (lastPartTimestamp) => {
    return { lastPartTimestamp };
  }
);

export const actionSetWorkflows = cA('SET_WORKFLOWS')((workflows) => {
  return { workflows };
});

export const actionTriggerWorkflow = cA('SUBMIT_WORKFLOWS')((workflow) => {
  return { workflow };
});

export const actionSetMachineGroups = cA('SET_MACHINE_GROUPS')(
  (machineGroups) => {
    return { machineGroups };
  }
);

export const actionSetCustomTabURL = cA('SET_CUSTOM_TAB_URL')((url) => {
  return { url };
});

export const actionSetTabs = cA('SET_TABS')((tabs) => {
  return { tabs };
});

export const actionSwitchMachine = cA('SWITCH_MACHINE')((machine) => {
  return { machine };
});

export const actionSetLatestPingTime = cA('SET_LATEST_PING')((ping) => {
  return { ping };
});

export const actionSetLocationRef = cA('SET_LOCATION_REF')((locationRef) => {
  return { locationRef };
});

export const actionSetHealthData = cA('SET_HEALTH_DATA')((healthData) => {
  return { healthData };
});
export const actionDismissUpdatePopup = cA('DISMISS_UPDATE_POPUP')();

export const actionOpenLanguageModal = cA('OPEN_LANGUAGE_MODAL')();
export const actionCloseLanguageModal = cA('CLOSE_LANGUAGE_MODAL')();

export const actionSetLabsAuthorized = cA('SET_LABS_AUTHORIZED')(
  (labsAuthorized) => {
    return { labsAuthorized };
  }
);

export const actionOpenLaborTicketModal = cA('OPEN_LABOR_TICKET_MODAL')(
  (laborTicketAction, options = {}) => {
    return { laborTicketAction, options };
  }
);

export const actionSetPendingActivityTypeRef = cA(
  'SET_PENDING_ACTIVITY_TYPE_REF'
)((pendingActivityTypeRef) => {
  return { pendingActivityTypeRef };
});

export const actionSetLaborTicketAction = cA('SET_LABOR_TICKET_ACTION')(
  (laborTicketAction) => {
    return { laborTicketAction };
  }
);

export const actionSetWorkOrderOpActionLoading = cA(
  'SET_WORK_ORDER_OP_START_LOADING'
)((loading) => {
  return { loading };
});

export const actionCloseLaborTicketModal = cA('CLOSE_LABOR_TICKET_MODAL')();

export const actionSetLaborTicketActionLoading = cA(
  'SET_LABOR_TICKET_ACTION_LOADING'
)((loading) => {
  return { loading };
});

export const actionSetLaborTickets = cA('SET_LABOR_TICKETS')((laborTickets) => {
  return { laborTickets };
});

export const actionSetCurrentWorkOrderOp = cA('SET_CURRENT_WORK_ORDER_OP')(
  (workOrderOp) => {
    return {
      workOrderOp,
    };
  }
);

export const actionSetCurrentWorkOrderOpGoodParts = cA(
  'SET_CURRENT_WORK_ORDER_OP_GOOD_PARTS'
)((goodParts, startAt) => {
  return {
    goodParts,
    startAt,
  };
});

export const actionSetLaborTicketScopeAdjustment = cA('SET_LABOR_TICKET_COUNT')(
  (count) => {
    return {
      count,
    };
  }
);

export const actionSetDefaultManualEntry = cA('SET_DEFAULT_MANUAL_ENTRY')(
  (workOrder) => {
    return {
      workOrder,
    };
  }
);

export const actionSetScheduledTime = cA('SET_SCHEDULED_TIME')(
  ({ scheduledTime }) => {
    return {
      scheduledTime,
    };
  }
);

export const actionSetScheduledTimeEnabled = cA('SET_SCHEDULED_TIME_ENABLED')(
  ({ isEnabled }) => {
    return {
      isEnabled,
    };
  }
);
