import { createSelector } from 'reselect';
import { getOperators } from 'lib/selectors/getOperators';
import { getLaunchDarklyFlags } from 'lib/selectors/getLaunchDarklyFlags';

import { FLAG_KEYS } from 'lib/constants';

const defaultActiveOperators = [];

/**
 * If ldFlag is true, returns active MAPPED operators.
 * Otherwise, only returns active operators.
 */
const getActiveOperators = createSelector(
  [getOperators, getLaunchDarklyFlags],
  (operators, flags) => {
    const isLaborTicketEnabled = flags?.[FLAG_KEYS.LABOR_TICKET_ENABLED];
    if (operators?.length > 0) {
      return operators?.filter((operator) => {
        if (isLaborTicketEnabled) {
          return !operator.archivedAt && operator?.erpId;
        }
        return !operator.archivedAt;
      });
    }
    return defaultActiveOperators;
  }
);

const getHasUnmappedActiveOperators = createSelector(
  [getOperators, getLaunchDarklyFlags],
  (operators, flags) => {
    const isLaborTicketEnabled = flags?.[FLAG_KEYS.LABOR_TICKET_ENABLED];
    const noOperators = operators?.length === 0;
    // return early if no operators or labor ticket is disabled
    if (noOperators || !isLaborTicketEnabled) {
      return false;
    }

    const unMappedActiveOperator = operators.find((operator) => {
      return !operator.erpId && !operator.archivedAt;
    });
    return !!unMappedActiveOperator;
  }
);

export { getActiveOperators, getHasUnmappedActiveOperators };
