// React and Hooks
import React from 'react';
// Actions
// Selectors
// Utils
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { P } from 'components/Text';
import { palette, Icon } from '@m12s/component-library';
import { infoCircle } from 'lib/icons';

const Wrapper = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  align-items: center;
  border: 2px solid ${palette.Grey300};
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  background-color: ${palette.White};
  border-radius: 2px;
`;

const InlineAlert = ({ text, icon = infoCircle }) => {
  return (
    <Wrapper>
      <Icon style={{ marginRight: '1rem' }} icon={icon} />
      <P
        style={{
          color: palette.Black,
        }}
      >
        {text}
      </P>
    </Wrapper>
  );
};

InlineAlert.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export { InlineAlert };
