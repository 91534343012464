import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import { GET_PART_ADJUSTMENT_TYPES } from 'lib/api/queries';
import { getPartAdjustmentTypes } from 'lib/selectors/getPartAdjustmentTypes';
import { Tree } from 'lib/utils/tree';
import { actionSetPartAdjustmentTypes } from 'lib/actions';

const usePartAdjustmentType = () => {
  const dispatch = useDispatch();
  const partAdjustmentTypes = useSelector(getPartAdjustmentTypes);
  const { loading, error } = useQuery(GET_PART_ADJUSTMENT_TYPES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ PartAdjustmentType }) => {
      dispatch(actionSetPartAdjustmentTypes(PartAdjustmentType));
    },
  });

  const tree = useMemo(() => {
    if (!partAdjustmentTypes || partAdjustmentTypes.length === 0)
      return new Tree();
    return Tree.parse(partAdjustmentTypes);
  }, [partAdjustmentTypes]);

  const [node, setNode] = useState(tree);

  useEffect(() => {
    return setNode(tree);
  }, [tree]);

  return { error, loading, node, setNode, tree };
};

export { usePartAdjustmentType };
