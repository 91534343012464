import { createSelector } from 'reselect';
import { getStartOf, subtract, toISO } from 'lib/utils/date';
import { MAX_QUERY_BOUNDARY_HOURS } from 'lib/constants';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';

// this boundary will be recalc'ed on every heavy update - this is to ensure that we quickly switch
// scope when crossing an hour boundary, but will otherwise return the bottom of the hour, 24 hours ago

const getMaxScopeQueryStart = createSelector(
  [getHeavyUpdate],
  // eslint-disable-next-line no-unused-vars
  (_heavyUpdate) => {
    const nowISO = toISO();
    // careful if/when changing this boundary - this protection should exist server-side,
    // but adding here as double precaution for any queries/subs driven by scope start
    const maxQueryHoursAgoISO = subtract(
      nowISO,
      MAX_QUERY_BOUNDARY_HOURS,
      'hours'
    );
    const startOfMaxRangeBoundaryHour = getStartOf(maxQueryHoursAgoISO, 'hour');
    return startOfMaxRangeBoundaryHour;
  }
);

export { getMaxScopeQueryStart };
