const zLayer = {
  lowered: 0,
  menu: 1,
  popup: 2,
  overlay: 3,
  // modal is 4, defined in index.css
  loading: 5,
  acrossAppPopup: 6,
};

export { zLayer };
