import _reject from 'lodash/reject';
import { createSelector } from 'reselect';

import { getStatusIntervals } from 'lib/selectors/getStatusIntervals';

const getInactiveStatusIntervals = createSelector(
  [getStatusIntervals],
  (intervals) => {
    return _reject(intervals, { label: 'ACTIVE' });
  }
);

export { getInactiveStatusIntervals };
