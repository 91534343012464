import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import _get from 'lodash/get';

import { Modal, Button, Tag } from '@m12s/component-library';
import Routes from 'lib/utils/routes';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Small, H6, P } from 'components/Text';
import { DurationTicker } from 'components/DurationTicker';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Note = styled.div`
  max-height: 60vh;
  overflow: auto;
  margin-top: 1rem;
`;

const AnnotationModal = ({
  selectedAnnotation,
  setSelectedAnnotation,
  machineId,
  priorShift,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { start, annotationType, end, message } = selectedAnnotation;

  return (
    <Modal
      isOpen={!!selectedAnnotation}
      details={
        <>
          <Row>
            <H6 semibold>{_get(annotationType, 'name')}</H6>&nbsp;
            {!end && <Tag>Active</Tag>}
          </Row>
          <Row>
            <span>
              <Small>{_capitalize(t('started'))}</Small>&nbsp;
              <Small>{moment(start).format('LT')}</Small>&nbsp;
              <Small>{moment(start).format('L')}</Small>
            </span>
            <Small bold>
              <DurationTicker startDate={moment(start)} endDateOverride={end} />
            </Small>
          </Row>
          {message && (
            <Note>
              <P>{message}</P>
            </Note>
          )}
        </>
      }
      buttons={
        <>
          <Button
            variant="tertiary"
            onClick={() => {
              setSelectedAnnotation(false);
            }}
          >
            {_capitalize(t('cancel'))}
          </Button>
          {!end && (
            <Button
              variant="secondary"
              onClick={() => {
                history.push({
                  pathname: Routes.machineIdCategorizePath(machineId),
                  state: {
                    preselected: selectedAnnotation,
                    splitting: true,
                    returnPath: history.location.pathname,
                  },
                });
              }}
            >
              {_startCase(t('split downtime'))}
            </Button>
          )}
          {!priorShift && (
            <Button
              variant="primary"
              onClick={() => {
                history.push({
                  pathname: Routes.machineIdCategorizePath(machineId),
                  state: {
                    preselected: selectedAnnotation,
                    returnPath: history.location.pathname,
                  },
                });
              }}
            >
              {_capitalize(t('recategorize'))}
            </Button>
          )}
        </>
      }
    ></Modal>
  );
};

export { AnnotationModal };
