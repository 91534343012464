import { useMachine } from 'lib/hooks/useMachine';
import { useRealTimeData } from './useRealTimeData';
import { useInitialData } from './useInitialData';
import { useAnnotations } from './useAnnotations';
import { useSetupData } from './useSetupData';
import { useWorkflowWatcher } from './useWorkflowWatcher';
// import { useStreamReader } from './useStreamReader';

const useData = () => {
  useMachine();
  useAnnotations();
  useRealTimeData();
  useSetupData();
  useWorkflowWatcher();
  // useStreamReader(); //TODO (Paul): Make a new StreamReader (Nats Client Consumer)
  const { refetch } = useInitialData();

  return { refetch };
};

export { useData };
