import { createSelector } from 'reselect';

import { getActiveStatusIntervals } from 'lib/selectors/getActiveStatusIntervals';
import { getUtilPageScopeStart } from 'lib/selectors/getUtilPageScopeStart';
import { chopBefore } from 'lib/utils/intervals';

const defaultScopeStatusIntervals = [];

const getUtilPageScopeActiveStatusIntervals = createSelector(
  [getUtilPageScopeStart, getActiveStatusIntervals],
  (utilPageScopeStart, activeStatusIntervals) => {
    if (!utilPageScopeStart) {
      return defaultScopeStatusIntervals;
    }
    return chopBefore(activeStatusIntervals, utilPageScopeStart);
  }
);

export { getUtilPageScopeActiveStatusIntervals };
