import { createSelector } from 'reselect';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';

const getLatestActivityMode = createSelector(
  [getLatestActivityType],
  (latestActivityType) => {
    if (!latestActivityType) {
      return null;
    }
    return latestActivityType.activityMode;
  }
);

export { getLatestActivityMode };
