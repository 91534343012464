import { useContext } from 'react';
import _get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { TEMP_REFS } from 'lib/constants';

import { getMachine } from 'lib/selectors/getMachine';
import Routes from 'lib/utils/routes';
import { throwSuccessToast, throwErrorToast } from 'lib/utils/toast';
import { START_JOB, CREATE_ACTIVITY_SET } from 'lib/api/mutations';
import { toISO } from 'lib/utils/date';
import { getActivitySetStandardsFromJob } from 'lib/utils/job';

import {
  actionSetJobTotal,
  actionOptimisticActivitySetOpen,
  actionCanonicalActivitySetOpen,
  actionCanonicalActivitySetDelete,
  actionSetWorkOrderOpActionLoading,
} from 'lib/actions';
import { RefreshContext } from 'components/DataProvider';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

const {
  ACTIVITY_SET_REF: optimisticActivitySetRef,
  ACTIVITY_REF: optimisticActivityRef,
} = TEMP_REFS;

const useStartJob = (job, jobWorkOrderId) => {
  const jobId = _get(job, 'jobId', null);
  const workOrderOperationRef = _get(job, 'workOrderOperationRef', null);

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workOrderFlowEnabled = useIsWorkOrderFlow();
  const { refetch } = useContext(RefreshContext);

  const machine = useSelector(getMachine);

  /* Pre-Activities */
  const [requestStartJob] = useMutation(START_JOB, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatch(actionSetJobTotal(undefined));
      refetch();
      history.push(Routes.machineIdPath(machine.id));
    },
  });

  const startJobAsJSG = (options = {}) => {
    const { setup } = options;

    return requestStartJob({
      variables: {
        input: {
          id: machine.id,
          jobId,
          setup,
        },
      },
    });
  };

  /* Activities */

  const [requestCreateActivitySet] = useMutation(CREATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          createActivitySetResponse: {
            activitySet: {
              activities: [activity],
              ...activitySet
            },
          },
        },
      }
    ) => {
      dispatch(actionOptimisticActivitySetOpen({ activitySet, activity, job }));
      dispatch(actionSetJobTotal(undefined));
      history.push(Routes.machineIdPath(machine.id));
    },
    onCompleted: ({
      createActivitySetResponse: {
        activitySet: {
          activities: [activity], // should be sorted start ASC
          ...activitySet
        },
      },
    }) => {
      throwSuccessToast(
        t('Started:', { jobName: _get(job, ['displayName'], '') })
      );
      dispatch(
        actionCanonicalActivitySetOpen({
          activitySet,
          activity,
          optimisticActivitySetRef,
          optimisticActivityRef,
        })
      );
    },
    onError: () => {
      dispatch(
        actionCanonicalActivitySetDelete({
          activitySetRef: optimisticActivitySetRef,
        })
      );
      history.push(Routes.machineIdPath(machine.id));
      dispatch(actionSetWorkOrderOpActionLoading(false));
      throwErrorToast(
        t('Could not start:', { jobName: _get(job, ['displayName'], '') })
      );
    },
  });

  const startJob = (options = {}) => {
    const {
      activityTypeRef,
      workOrderId: localWorkOrderId,
      shouldCloseLatestSet = false,
    } = options;
    const workOrderId = jobWorkOrderId || localWorkOrderId || null;
    const activitySetStandards = getActivitySetStandardsFromJob(job);
    const nowISO = toISO();
    return requestCreateActivitySet({
      variables: {
        input: {
          machineRef: machine.machineRef,
          activities: [{ activityTypeRef, startAt: nowISO }],
          expectedUnitDuration: activitySetStandards.expectedUnitDuration,
          idealUnitDuration: activitySetStandards.idealUnitDuration,
          expectedSetupDuration: activitySetStandards.expectedSetupDuration,
          optimalUnitDuration: activitySetStandards.optimalUnitDuration,
          partCountMultiplier: job.partCountMultiplier,
          actualParts: job.actualParts,
          // create operation from opdash: workOrderId will be in job object
          // add a work order id to an existing operation: work order id will be in options
          workOrderId,
          jobId,
          ...(workOrderOperationRef ? { workOrderOperationRef } : {}),
        },
        query: { shouldCloseLatestSet },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createActivitySetResponse: {
          __typename: 'ActivitySet',
          activitySet: {
            expectedUnitDuration: activitySetStandards.expectedUnitDuration,
            idealUnitDuration: activitySetStandards.idealUnitDuration,
            expectedSetupDuration: activitySetStandards.expectedSetupDuration,
            optimalUnitDuration: activitySetStandards.optimalUnitDuration,
            partCountMultiplier: job.partCountMultiplier,
            actualParts: job.actualParts,
            jobId,
            activitySetRef: optimisticActivitySetRef,
            jobScheduleGroupId: null,
            closedAt: null,
            workOrderId,
            ...(workOrderOperationRef && workOrderFlowEnabled
              ? { workOrderOperationRef }
              : {}),
            activities: [
              {
                activityRef: optimisticActivityRef,
                activitySetRef: optimisticActivitySetRef,
                activityTypeRef,
                start: nowISO,
                end: null,
                updatedAt: nowISO,
                jobScheduleGroupId: null,
              },
            ],
          },
        },
      },
    });
  };

  return { startJob, startJobAsJSG };
};

export default useStartJob;
