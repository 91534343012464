import { usePortraitMode } from 'lib/hooks';
import React from 'react';
import { Header, Row, Column } from './styled';

export default function Layout({
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  variant,
}) {
  const portraitMode = usePortraitMode();

  return (
    <Header id="machine-header" variant={variant}>
      {!portraitMode ? (
        <>
          <Row>
            <Column>{TopLeft}</Column>
            <Column>{TopRight}</Column>
          </Row>
          <Row>
            <Column block>{BottomLeft}</Column>
            <Column>{BottomRight}</Column>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Column>{TopLeft}</Column>
          </Row>
          <Row>
            <Column block>{BottomLeft}</Column>
          </Row>
          {TopRight && (
            <Row hideOnMobile>
              <Column pullRight>{TopRight}</Column>
            </Row>
          )}
          <Row hideOnMobile>
            <Column pullRight>{BottomRight}</Column>
          </Row>
        </>
      )}
    </Header>
  );
}
