import { useDispatch } from 'react-redux';
import { GET_TAB_CONFIG } from 'lib/api/queries';
import { actionSetTabs } from 'lib/actions';
import { useQuery } from '@apollo/client';
import _get from 'lodash/get';
import { DEFAULT_TABS } from 'lib/constants';

const useTabsConfig = () => {
  const dispatch = useDispatch();

  useQuery(GET_TAB_CONFIG, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ data: { templates } }) => {
      const config = _get(templates, [0, 'config', 'tabs'], undefined);
      if (config) {
        dispatch(actionSetTabs(config));
      } else {
        dispatch(actionSetTabs(DEFAULT_TABS));
      }
    },
  });
};

export { useTabsConfig };
