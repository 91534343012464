import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';

const getLatestActivitySetExpectedSetupDuration = createSelector(
  [getLatestActivitySet],
  (latestActivitySet) => {
    return _get(latestActivitySet, 'expectedSetupDuration', null);
  }
);

export { getLatestActivitySetExpectedSetupDuration };
