import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _findLast from 'lodash/findLast';
import { getLatestActivitySetActivities } from 'lib/selectors/getLatestActivitySetActivities';

// start of set is the earliest activity start
// latest set is determined by taking the latest activity start on machine
const getLatestActivitySetStartAt = createSelector(
  [getLatestActivitySetActivities],
  (activities) => {
    if (Array.isArray(activities) && activities.length) {
      // should be sorted on store by start DESC, so we should work backwards (hence "findLast")
      const firstNonMigratedActivity = _findLast(activities, (activity) => {
        // we split any open activities on jsg => activities migration, and it is easier
        // to calculate opdash views when we don't mix migrated activities with non-migrated
        // activities (this is determined by the presence of jobScheduleGroupId); if there is a
        // non-migrated activity, we should consider it as the start of the set here
        const wasActivityCreatedPostMigration =
          _get(activity, 'jobScheduleGroupId') === null;
        return wasActivityCreatedPostMigration;
      });
      if (!firstNonMigratedActivity) {
        // use the first activity as a fallback, even if the set only consists of migrated activities
        // this is rare, but possible, on newly migrated customers in production, but very common in
        // development with "pending" companies
        const firstActivity = _last(activities);
        return _get(firstActivity, 'start', null);
      }
      return _get(firstNonMigratedActivity, 'start', null);
    }
    return null;
  }
);

export { getLatestActivitySetStartAt };
