import { createSelector } from 'reselect';
import _pickBy from 'lodash/pickBy';

const getNetworkError = (store) => {
  return store.networkError;
};

const getNetworkErrors = createSelector(
  [getNetworkError],
  (networkError = {}) => {
    return _pickBy(networkError, (error) => {
      return error.message;
    });
  }
);

export { getNetworkErrors };
