import { rawRequest } from 'lib/utils/request';
import { useAsyncEffect } from 'lib/utils/hooks';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import session from 'lib/api/links/session';
import { Loading } from 'components/Loading';
import { CLIENT_ID, CLIENT_SECRET, APP_URI, LOGIN_URL } from 'lib/appConfig';
import Authorized from './Authorized';

const Authorize = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);

  useAsyncEffect(async (abortSignal) => {
    const { code } = qs.parse(window.location.search);

    const params = {
      grant_type: 'authorization_code',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      code,
      redirect_uri: `${APP_URI}/authorize/mm/callback`,
    };
    try {
      const response = await rawRequest(`${LOGIN_URL}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: new URLSearchParams(params),
        signal: abortSignal,
      });
      dispatch({ type: 'SET_TOKEN_SCOPE', scope: response.scope });
      session.setApiKey(response.access_token);
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
      setLoadError(`Login failed. Please try again.`);
    }
    setLoading(false);
  }, []);

  return loading ? <Loading /> : <Authorized loadError={loadError} />;
};

export default Authorize;
