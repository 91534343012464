import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${palette.Grey100};
  position: relative;
  z-index: ${zLayer.overlay};
`;

export const Content = styled.div`
  display: flex;
  width: 45rem;
  max-width: 90%;
  height: max-content;

  @media (orientation: portrait) {
    flex-direction: column-reverse;
    width: 70%;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
`;

export const ColumnRight = styled(ColumnLeft)`
  align-items: flex-end;
  padding: 2rem;

  @media (orientation: portrait) {
    align-items: center;
  }
`;

export const Details = styled.div`
  max-height: 12.25rem;
  overflow: scroll;
  display: none;
  ${({ show }) => {
    return (
      show &&
      `
    display: block;
  `
    );
  }}
`;
