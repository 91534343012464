import { createSelector } from 'reselect';

import { getCompany } from 'lib/selectors/getCompany';

const getIsOperatorRunAutoSignoutEnabled = createSelector(
  [getCompany],
  ({ isOperatorRunAutoSignoutEnabled }) => {
    return !!isOperatorRunAutoSignoutEnabled;
  }
);

export { getIsOperatorRunAutoSignoutEnabled };
