import styled from 'styled-components';
import { zLayer } from 'lib/utils/styled';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: ${zLayer.overlay};
`;

const FormRoot = styled.div`
  background: linear-gradient(180deg, #f7fafc 0%, #f4f8fb 51.04%, #ecf3f8 100%);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  min-height: 0;
`;

const Grid = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 4.5rem 2.5rem;
`;

const Inputs = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export { Root, FormRoot, Grid, Inputs };
