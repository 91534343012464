import React, { useState } from 'react';
import { TableOfContents } from './TableOfContents';
import { Section } from './Section';
import { Frame } from './styled';

const TrainingDoc = () => {
  const [section, setSection] = useState(-1);

  return (
    <Frame>
      {section >= 0 ? (
        <Section section={section} setSection={setSection} />
      ) : (
        <TableOfContents setSection={setSection} />
      )}
    </Frame>
  );
};

export { TrainingDoc };
