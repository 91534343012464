import { fallsWithinActivities } from 'lib/utils/intervals';
import { createSelector } from 'reselect';
import _sumBy from 'lodash/sumBy';
import { getPartAdjustments } from './getPartAdjustments';
import { getScopeReadTimePCM } from './getScopeReadTimePCM';
import {
  getScopeScheduledActivityIntervals,
  getScopeUnscheduledActivityIntervals,
} from './getScopeScheduledActivityIntervals';

export const getTotalScheduledRawParts = (state) => {
  return state.totalScheduledRawParts;
};

export const getTotalUnscheduledRawParts = (state) => {
  return state.totalUnscheduledRawParts;
};

// applies PCM
export const getTotalScheduledParts = createSelector(
  [getTotalScheduledRawParts, getScopeReadTimePCM],
  (rawTotalScheduledRawParts, scopeReadTimePCM) => {
    return rawTotalScheduledRawParts * scopeReadTimePCM;
  }
);

export const getTotalUnscheduledParts = createSelector(
  [getTotalUnscheduledRawParts, getScopeReadTimePCM],
  (rawTotalUnscheduledRawParts, scopeReadTimePCM) => {
    return rawTotalUnscheduledRawParts * scopeReadTimePCM;
  }
);

// This returns part adjustments that fall within scheduled time in scope
export const getTotalScheduledPartAdjustments = createSelector(
  [getPartAdjustments, getScopeScheduledActivityIntervals],
  (partAdjustments, scopeScheduledActivityIntervals) => {
    const scheduledAdjustments = partAdjustments.filter((adjustment) => {
      return fallsWithinActivities(
        adjustment.adjustmenttime,
        scopeScheduledActivityIntervals
      );
    });
    return _sumBy(scheduledAdjustments, 'count');
  }
);

// This returns part adjustments that fall within unscheduled time in scope
export const getTotalUnscheduledPartAdjustments = createSelector(
  [getPartAdjustments, getScopeUnscheduledActivityIntervals],
  (partAdjustments, scopeUnscheduledActivityIntervals) => {
    const unscheduledAdjustments = partAdjustments.filter((adjustment) => {
      return fallsWithinActivities(
        adjustment.adjustmenttime,
        scopeUnscheduledActivityIntervals
      );
    });
    return _sumBy(unscheduledAdjustments, 'count');
  }
);

// This returns total parts made during scheduled time.
// Accounts for PCM and part adjustments.
export const getNetScheduledParts = createSelector(
  [getTotalScheduledParts, getTotalScheduledPartAdjustments],
  (totalScheduledParts, totalScheduledPartAdjustments) => {
    return totalScheduledParts + totalScheduledPartAdjustments;
  }
);

// This returns total parts made during unscheduled time.
// Accounts for PCM and part adjustments.
export const getNetUnscheduledParts = createSelector(
  [getTotalUnscheduledParts, getTotalUnscheduledPartAdjustments],
  (totalUnscheduledParts, totalUnscheduledPartAdjustments) => {
    return totalUnscheduledParts + totalUnscheduledPartAdjustments;
  }
);
