/* New files posted to Hubspot can take several days or more before they will be fetched successfully here */

/* Pre-Activities */
const SectionsLegacy = [
  {
    title: 'dashboard anatomy',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/1-Dashboard%20Anatomy.pdf',
  },
  {
    title: 'explore tabs',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/2-Explore%20Tabs.pdf',
  },
  {
    title: 'categorize downtime',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/3-Categorize%20Downtime.pdf',
  },
  {
    title: 'track quality',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/4-Track%20Quality.pdf',
  },
  {
    title: 'start in setup',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/5-Start%20In%20Setup.pdf',
  },
  {
    title: 'sign in/out',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/6-Sign%20in&out.pdf',
  },
  {
    title: 'switch users',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/7-Switch%20Users.pdf',
  },
  {
    title: 'switch machines',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20files/Op%20Dash%20PDF%20training/8-Switch%20Machines.pdf',
  },
  {
    title: 'change language',
    url:
      'https://www.machinemetrics.com/hubfs/MM%20Academy%20Files/Op%20Dash%20PDF%20training/9-Change%20Language.pdf',
  },
];

/* For Activities Customers */
const Sections = [
  {
    title: 'dashboard anatomy',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/1-Dashboard%20Anatomy-1.pdf',
  },
  {
    title: 'explore tabs',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/2-Explore%20Tabs.pdf',
  },
  {
    title: 'categorize downtime',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/3-Categorize%20Downtime.pdf',
  },
  {
    title: 'track quality',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/4-Track%20Quality.pdf',
  },
  {
    title: 'start an operation in setup',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/5-Start%20a%20Job%20in%20Setup.pdf',
  },
  {
    title: 'pausing operations',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/6-Pausing%20Jobs.pdf',
  },
  {
    title: 'sign in/out',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/7-Sing%20in&out.pdf',
  },
  {
    title: 'switch users',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/8-Switch%20Users.pdf',
  },
  {
    title: 'switch machines',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/9-Switch%20Machines.pdf',
  },
  {
    title: 'change language',
    url:
      'https://www.machinemetrics.com/hubfs/Kaylee%20Files/OpDash-%20Activities/10-Change%20Language.pdf',
  },
];

export { Sections, SectionsLegacy };
