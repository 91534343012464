import styled, { css } from 'styled-components';
import { palette } from '@m12s/component-library';
import { Text } from 'components/Text';

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5.5rem;
  padding: 0 1rem;
  background-color: ${palette.White};
  border-radius: 4px;
  border: 2px solid transparent;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  overflow: hidden;

  ${({ selected }) => {
    return (
      selected &&
      css`
        background-color: ${palette.Purple100};
        border: 2px solid ${palette.Purple500};
      `
    );
  }}

  ${({ disabled }) => {
    return (
      disabled &&
      css`
        opacity: 0.6;
      `
    );
  }}
`;

const ActiveLabel = styled(Text)`
  letter-spacing: 0.015em;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-left: 0.375rem;
  font-weight: ${({ theme }) => {
    return theme.fontWeights.bold;
  }};
  color: ${palette.Purple400};
`;

const Category = styled(Text)`
  letter-spacing: 0.015em;
  font-size: 1rem;
  line-height: 1.25;
  font-weight: ${({ theme }) => {
    return theme.fontWeights.bold;
  }};
  color: ${palette.Grey700};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AnnotationColor = styled(Text)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0.5rem;
  background-color: ${({ color }) => {
    return color || palette.Grey100;
  }};
`;

export { ActiveLabel, CardWrapper, Category, AnnotationColor };
