import { createSelector } from 'reselect';
import { isBefore } from 'lib/utils/date';
import { getShiftStart } from './getShiftStart';
import { getSetupModeStart } from './getSetupModeStart';

const getSetupModeSpansShift = createSelector(
  [getSetupModeStart, getShiftStart],
  (setupModeStart, shiftStart) => {
    if (!shiftStart) {
      return false;
    }
    return isBefore(setupModeStart, shiftStart);
  }
);

export { getSetupModeSpansShift };
