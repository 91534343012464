import moment from 'moment';
import 'moment-duration-format';
import _max from 'lodash/max';
import _min from 'lodash/min';
import { ts } from 'lib/hooks/useTimesync';

moment.now = () => {
  return ts.now();
};

function getDurationFormat(format, value) {
  switch (format) {
    case 'short':
    case undefined:
    case null:
      return `d[d] hh[h] mm[m] ss[s]${value && value < 1000 ? ' SSS[ms]' : ''}`;
    case 'long':
      return 'd [days], h [hours], m [minutes], s [seconds]';
    default:
      return format;
  }
}

function ago(...args) {
  return moment().subtract(...args);
}

function fromNow(...args) {
  return moment().add(...args);
}

function formatDate(any) {
  return moment(any).format('L');
}

function formatDuration(seconds, format, options) {
  const durationFormat = getDurationFormat(format, seconds * 1000);
  return moment.duration(seconds * 1000).format(durationFormat, options);
}

function formatCycleTime(seconds, options = {}) {
  const durationFormat = getDurationFormat('short', seconds * 1000);
  const hasMillis = Math.round(seconds * 100) / 100 !== Math.round(seconds);
  return moment.duration(seconds * 1000).format(durationFormat, {
    ...options,
    trim: 'both',
    precision: seconds > 1 && seconds < 59.5 && hasMillis ? 1 : 0,
  });
}

function formatTime(any) {
  return moment(any).format('LT');
}

function formatTimeWithSeconds(any) {
  return moment(any).format('LTS');
}

function formatTimeAndDate(any) {
  return `${moment(any).format('LT')} / ${moment(any).format('ll')}`;
}

function now() {
  return moment();
}

function toISO(any) {
  return moment(any).toISOString();
}

function add(date, nr, variant) {
  return moment(date)
    .add(nr, variant)
    .toISOString();
}

function subtract(date, nr, variant) {
  return moment(date)
    .subtract(nr, variant)
    .toISOString();
}

function isSame(first, second) {
  return moment(first).isSame(second);
}

function isBefore(first, second, options = {}) {
  if (options.inclusive) {
    return moment(first).isSameOrBefore(second);
  }

  return moment(first).isBefore(second);
}

function isSameOrBefore(first, second) {
  return moment(first).isSameOrBefore(second);
}

function isAfter(first, second) {
  return moment(first).isAfter(second);
}

function isSameOrAfter(first, second) {
  return moment(first).isSameOrAfter(second);
}

function diff(first, second, unit = 'milliseconds') {
  return moment.duration(moment(first).diff(second)).as(unit);
}

function dateUTC(date) {
  if (!date) return null;
  return moment.utc(date);
}

function millis(date) {
  return moment(date).valueOf();
}

// args must be in ISO format
function takeEarliest(...args) {
  const filteredArgs = args.filter((dt) => {
    return !!dt;
  });
  return _min(filteredArgs);
}

// args must be in ISO format
function takeLatest(...args) {
  const filteredArgs = args.filter((dt) => {
    return !!dt;
  });
  return _max(filteredArgs);
}

function startOfAppHistory() {
  return ago(1, 'days')
    .startOf('day')
    .format();
}

function getUnitDurationFromString(durationStr, units = 'milliseconds') {
  return moment.duration(durationStr).as(units);
}

function diffDate(end, start) {
  return Math.floor((end - start) / 1000);
}

function millisSince(startDate) {
  return moment.utc() - moment.utc(startDate);
}

function getStartOf(dt, unit = 'hour') {
  return toISO(moment.utc(dt).startOf(unit));
}

// e.g. '{"[2021-08-31 14:00:00, 2021-08-31 15:00:00)", "[2021-08-31 17:00:00, 2021-08-31 23:00:00)"}'
function formatIntervalsAsTSRange(intervals) {
  if (!intervals) {
    return `{}`;
  }
  return `{${intervals
    .filter((interval) => {
      return interval.end ? isBefore(interval.start, interval.end) : true;
    })
    .map(({ start, end }) => {
      return `"[${start},${end ? ` ${end}` : ''})"`;
    })
    .join(', ')}}`;
}

function isBetween(timestamp, start, end) {
  return moment(timestamp).isBetween(start, end || undefined);
}

export {
  add,
  ago,
  dateUTC,
  diff,
  formatDate,
  formatDuration,
  formatCycleTime,
  formatTime,
  formatTimeWithSeconds,
  formatTimeAndDate,
  formatIntervalsAsTSRange,
  fromNow,
  isAfter,
  isBefore,
  isBetween,
  isSame,
  isSameOrBefore,
  isSameOrAfter,
  millis,
  now,
  startOfAppHistory,
  toISO,
  takeEarliest,
  takeLatest,
  getUnitDurationFromString,
  diffDate,
  millisSince,
  subtract,
  getStartOf,
};
