import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { actionSetMachine } from 'lib/actions';
import { GET_MACHINE } from 'lib/api/queries';
import { useNetworkError } from 'lib/hooks';
import { ERROR_KEYS } from 'lib/constants';
import session from 'lib/api/links/session';

export const mapMachineFields = (machine) => {
  return {
    autostartBehavior: machine.autostartBehavior,
    downtimeFailureDelay: machine.downtimeFailureDelay,
    id: machine.machineId,
    machineRef: machine.machineRef,
    name: machine.name,
    utilizationFailurePercent: machine.utilizationFailurePercent,
    utilizationWarningPercent: machine.utilizationWarningPercent,
    operatorCreateOperation: machine.operatorCreateOperation,
    partOperationFingerprint: machine.partOperationFingerprint,
    agentId: machine.agentId,
    programMetric: machine.programMetric,
  };
};

export const useMachine = () => {
  const dispatch = useDispatch();
  const machineId = window.location.pathname.split('/')[2];

  const machineQuery = useQuery(GET_MACHINE, {
    fetchPolicy: 'no-cache',
    variables: { machineId },
    onCompleted: (data) => {
      const { loading, error } = machineQuery;

      if (!data.getMachine) {
        session.clear();
        window.location = '/switch-machines';
        return;
      }

      const machine = mapMachineFields(data.getMachine);
      dispatch(actionSetMachine({ machine, loading, error }));
    },
  });
  useNetworkError(machineQuery, ERROR_KEYS.MACHINE);
};
