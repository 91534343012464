import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getLatestActivityRef } from 'lib/selectors/getLatestActivityRef';
import { getActivitiesByRef } from 'lib/selectors/getActivitiesByRef';

const defaultLatestActivity = {};

const getLatestActivity = createSelector(
  [getLatestActivityRef, getActivitiesByRef],
  (latestActivityRef, activitiesByRef) => {
    return _get(activitiesByRef, latestActivityRef, defaultLatestActivity);
  }
);

export { getLatestActivity };
