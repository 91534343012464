import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

function parseParams(text) {
  return queryString.parse(text);
}

function stringifyParams(params) {
  return queryString.stringify(params);
}

function applyURLFilters(defaultFilters, history) {
  const params = parseParams(history.location.search);
  return { ...defaultFilters, ...params };
}

const useQueryFilters = (defaultFilters) => {
  const history = useHistory();
  const [filters, setFilters] = useState(
    applyURLFilters(defaultFilters, history)
  );

  useEffect(() => {
    const search = parseParams(history.location.search);
    const updatedSearch = stringifyParams({ ...search, ...filters });

    history.push({
      pathname: history.location.pathname,
      search: updatedSearch,
    });
  }, [filters, history]);

  return [filters, setFilters];
};

export { useQueryFilters };
