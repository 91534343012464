export const getPartCountBuckets = (store) => {
  return [
    ...store.partCountBuckets.data.scheduledPartBuckets,
    ...store.partCountBuckets.data.unscheduledPartBuckets,
  ];
};

export const getArePartCountBucketsLoading = (store) => {
  return store.partCountBuckets.loading ? 1 : 0;
};
