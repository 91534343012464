import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'lib/utils/routes';
import queryString from 'query-string';

import ErrorPage from 'pages/Error';
import { SelectActivityType } from 'components/SelectActivityType';

import useStartNewProductionRun from 'components/SwitchActivityTypeReview/useStartNewProductionRun';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';
import { actionSetWorkOrderOpActionLoading } from 'lib/actions';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';

import SelectWorkOrderOp from './SelectWorkOrderOp';
import JobList from './JobList';
import JobInfo from './JobInfo';

import useCreateActivitySetWithWorkOrder from './useCreateActivitySetWithWorkOrder';

const steps = {
  JOB_LIST: 0,
  JOB_INFO: 1,
  SELECT_ACTIVITY: 2,
};

const workManagementSteps = {
  SELECT_WORK_ORDER: 0,
  SELECT_ACTIVITY: 1,
};

const MultiStep = ({
  inSetup,
  controlAction,
  startJob,
  machine,
  workOrderId,
  partOperation,
  workOrderOp,
  job,
  ...props
}) => {
  const history = useHistory();
  const mainDispatch = useDispatch();
  const queryParams = queryString.parse(history.location.search);

  const workOrderFlowEnabled = useIsWorkOrderFlow();
  const startNewProductionRun = useStartNewProductionRun();

  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);

  const [step, setStep] = useState(steps.JOB_LIST);
  const nextStep = () => {
    return setStep(step + 1);
  };
  const previousStep = () => {
    return setStep(step - 1);
  };

  const handleOnClose = useCallback(() => {
    return history.push(routes.machineIdHomePath(machine.id));
  }, [history, machine]);

  const requestCreateActivitySetWithWorkOrder = useCreateActivitySetWithWorkOrder(
    {
      machine,
      workOrderOp,
    }
  );

  if (workOrderFlowEnabled && !queryParams.manual) {
    switch (step) {
      case workManagementSteps.SELECT_WORK_ORDER:
        return (
          <SelectWorkOrderOp
            {...props}
            nextStep={nextStep}
            previousStep={previousStep}
            handleOnClose={handleOnClose}
          />
        );
      case workManagementSteps.SELECT_ACTIVITY: {
        const switchActivities = history.location.pathname.includes(
          'switchActivities'
        );

        return (
          <SelectActivityType
            {...props}
            showAllTypes={!switchActivities}
            isStopAndStartRun={switchActivities}
            partOperation={partOperation}
            workOrderOp={workOrderOp}
            handleOnBack={previousStep}
            handleOnSelect={async (activityTypeRef) => {
              mainDispatch(actionSetWorkOrderOpActionLoading(true));
              if (hasOpenActivitySet && workOrderOp) {
                // start new work order off of existing production run
                return startNewProductionRun(
                  activityTypeRef,
                  workOrderOp.workOrderId,
                  workOrderOp.workOrderOperationRef
                );
              }
              // start production run (we handle looking up correct job)
              return requestCreateActivitySetWithWorkOrder({
                activityTypeRef,
                partOperation,
              });
            }}
            handleOnClose={handleOnClose}
          />
        );
      }
      default:
        return (
          /* Haven't updated translation for this */
          <ErrorPage
            error={new Error('Invalid step while selecting an operation')}
          />
        );
    }
  }

  switch (step) {
    case steps.JOB_LIST:
      return (
        <JobList
          {...props}
          nextStep={nextStep}
          previousStep={previousStep}
          handleOnClose={handleOnClose}
        />
      );
    case steps.JOB_INFO:
      return (
        <JobInfo
          {...props}
          nextStep={nextStep}
          previousStep={previousStep}
          handleOnClose={handleOnClose}
          startJob={startJob}
          workOrderId={workOrderId}
          job={job}
        />
      );
    case steps.SELECT_ACTIVITY:
      return (
        <SelectActivityType
          {...props}
          handleOnBack={previousStep}
          handleOnSelect={(activityTypeRef) => {
            startJob({ activityTypeRef, workOrderId });
          }}
          handleOnClose={handleOnClose}
          job={job}
        />
      );
    default:
      return (
        /* Haven't updated translation for this */
        <ErrorPage
          error={new Error('Invalid step while selecting an operation')}
        />
      );
  }
};

MultiStep.propTypes = {
  inSetup: PropTypes.bool,
  controlAction: PropTypes.func,
  startJob: PropTypes.func,
  machine: PropTypes.object,
  workOrderId: PropTypes.string,
  partOperation: PropTypes.object,
  workOrderOp: PropTypes.object,
};

export { MultiStep };
