import { chopAfter } from 'lib/utils/intervals';
import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';
import { getUnavailableStartAt } from './getUnavailableStartAt';

const statusIntervals = (store) => {
  return store.statusIntervals;
};

const getStatusIntervalsLoaded = (store) => {
  return store.statusIntervals.length;
};

const getStatusIntervals = createDeepEqualitySelector(
  [statusIntervals, getUnavailableStartAt],
  (intervals, unavailableStartAt) => {
    return chopAfter(intervals, unavailableStartAt, true);
  }
);

export { getStatusIntervals, getStatusIntervalsLoaded };
