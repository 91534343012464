import { createSelector } from 'reselect';
import { getHasActiveOperatorRun } from 'lib/selectors/getHasActiveOperatorRun';
import { getLatestOperator } from 'lib/selectors/getLatestOperator';

const getCurrentOperator = createSelector(
  [getHasActiveOperatorRun, getLatestOperator],
  (hasActiveOperatorRun, latestOperator) => {
    if (hasActiveOperatorRun) {
      return latestOperator;
    }
    return null;
  }
);

export { getCurrentOperator };
