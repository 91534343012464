import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const H3 = WithProps(styled.h3`
  ${includeBaseStyle}
  font-size: 1.75rem;
  line-height: 2.25rem;
`);

H3.defaultProps = {
  ...H3.defaultProps,
  defaultWeight: 'bold',
};

export { H3 };
