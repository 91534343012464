/* For instructions on how to add/update locale/translation files,
 * see the notes in src/lib/translations/locales/index.js
 */

import i18n from 'i18next';
import _keys from 'lodash/keys';
import TokenStore from 'lib/api/links/TokenStore';
import { initReactI18next } from 'react-i18next';
import { RELEASE_STAGE } from 'lib/appConfig';
import { resources } from './locales';

const companyLocaleToken = new TokenStore('companyLocale');
const langToken = new TokenStore('locale');
// order of locale preference: user selected > company default > English if neither are set or if company default is not currently available
const lng = langToken.get() || companyLocaleToken.get() || 'en';

i18n.use(initReactI18next).init({
  lng,
  resources,
  fallbackLng: ['en', ..._keys(resources)],
  keySeparator: '|',
  nsSeparator: false,
  // suppress log output in test
  debug: RELEASE_STAGE === 'development',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
