import { createSelector } from 'reselect';

import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { JOB_STATUSES } from 'lib/constants';
import { getJobRun } from 'lib/selectors/getJobRun';
import { now, isBefore } from 'lib/utils/date';

const getJobStatus = createSelector(
  [
    getAreActivitiesEnabled,
    getHasOpenActivity,
    getLatestActivityMode,
    getJobRun,
  ],
  (areActivitiesEnabled, hasOpenActivity, latestActivityMode, jobRun) => {
    if (areActivitiesEnabled) {
      if (!hasOpenActivity || !latestActivityMode) {
        return {};
      }
      return JOB_STATUSES[latestActivityMode.toUpperCase()];
    }
    if (!jobRun) return {};

    const { setupEnd, setupTime } = jobRun;

    if (
      setupTime !== null &&
      (!setupEnd ||
        // check to handle case where setup is scheduled to end after now
        isBefore(now(), setupEnd))
    ) {
      return JOB_STATUSES.SETUP;
    }

    if (setupEnd) {
      return JOB_STATUSES.PRODUCTION;
    }

    return null;
  }
);

export { getJobStatus };
