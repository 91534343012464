import { palette, theme as componentTheme } from '@m12s/component-library';

/* This theme is unable of supporting responsive text sizes, since the theme is registered once when the page loads, and does not support rem values as font sizes.  Therefore we pass in responsive font sizes in the Chart options prop, using the current calculated rem size. */

const chartTheme = () => {
  const axisCommon = {
    axisLabel: {
      show: true,
      color: componentTheme.colors.text.label,
      height: '1.25rem',
      fontWeight: 500,
      fontFamily: componentTheme.fonts.sans,
      fontSize: 12,
      align: 'center',
      margin: 18,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: palette.Grey200,
      },
    },
  };

  const commonLabel = {
    color: componentTheme.colors.text.label,
    fontSize: 12,
    fontWeight: componentTheme.fontWeights.bold,
    fontFamily: componentTheme.fonts.sans,
    show: true,
  };

  return {
    grid: {
      backgroundColor: palette.White,
      borderColor: palette.Grey200,
      borderWidth: 1,
      bottom: 0,
      containLabel: true,
      left: 16,
      right: 16,
      show: true,
      top: 16,
    },
    valueAxis: axisCommon,
    timeAxis: axisCommon,
    logAxis: axisCommon,
    categoryAxis: axisCommon,
    line: {
      itemStyle: {
        color: palette.Grey700,
      },
      lineStyle: {
        width: 2,
        color: palette.Pink600,
      },
      symbol: 'circle',
      symbolSize: 7,
      label: {
        ...commonLabel,
        show: true,
      },
    },
    bar: {
      barWidth: '50%',
      label: {
        ...commonLabel,
        position: 'top',
      },
    },
  };
};

chartTheme.themeName = 'defaultTheme';

export { chartTheme };
