import styled from 'styled-components';
import { zLayer } from 'lib/utils/styled';

import { Text } from 'components/Text';
import {
  Popup as PopupDefault,
  Overlay as OverlayDefault,
} from 'components/Popup/styled';

const Popup = styled(PopupDefault)`
  z-index: ${zLayer.acrossAppPopup};
`;

const LoadingWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;

  ${Text} {
    margin-left: 0.75rem;
  }
`;

const Overlay = styled(OverlayDefault)`
  z-index: ${zLayer.acrossAppPopup};
`;

export { Overlay, Popup, LoadingWrapper };
