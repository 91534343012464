import styled from 'styled-components';
import { palette } from '@m12s/component-library';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Chart = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
`;

const EmptyChart = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  background-color: ${palette.White};
  align-items: center;
  justify-content: center;
  border: solid 1px ${palette.Grey200};
`;

export { Root, Header, Chart, EmptyChart };
