/* eslint-disable no-self-assign */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Button, palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';

import { exclamationTriangle } from 'lib/icons';
import { H5, P } from 'components/Text';

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  transform: translateY(-1.25rem);
`;

const Title = styled(H5)`
  margin-bottom: 0.5rem;
`;

const ErrorIcon = styled(Icon)`
  color: ${palette.Red600};
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const refreshWindow = () => {
  window.location = window.location;
};

const ErrorInline = ({ title, queryKey, reload, retrying }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <ErrorIcon icon={exclamationTriangle} />
      <Title color={palette.Grey700}>{title}</Title>
      {(queryKey || retrying) && (
        <P muted>{t('We are continuing to request the data')}</P>
      )}
      <br />
      {reload && (
        <Button rounded onClick={refreshWindow}>
          {t('Reload')}
        </Button>
      )}
    </Root>
  );
};

ErrorInline.propTypes = {
  title: PropTypes.string.isRequired,
};

export { ErrorInline };
