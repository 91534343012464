import moment from 'moment';
import { isBefore, ago } from 'lib/utils/date';
import { createSelector } from 'reselect';

import { getIsAgentConnected } from './getIsAgentConnected';
import { getUnavailableStartAt } from './getUnavailableStartAt';
import { getConnection } from './getConnection';
import { getCurrentStatus } from './getCurrentStatus';
import { getHeavyUpdate } from './getUpdate';

const getDisconnectedErrorStatus = createSelector(
  [
    getConnection,
    getUnavailableStartAt,
    getIsAgentConnected,
    getCurrentStatus,
    getHeavyUpdate,
  ],
  ({ disconnectedAt }, unavailableStartAt, agentConnected, status) => {
    if (isBefore(moment(disconnectedAt), ago(3, 'minutes'))) {
      return 'isNetworkUnavailable'; // Missing websocket connection/no internet
    }
    if (!agentConnected && unavailableStartAt) {
      // if agent is dissconnected AND heartbeat is stale
      return 'isEdgeUnavailable'; // agent/:id/status is false, only for prod
    }
    if (
      status &&
      status.label === 'UNAVAILABLE' &&
      isBefore(moment(status.start), ago(3, 'minutes'))
    ) {
      return 'isMachineUnavailable'; // Latest machine status is UNAVAILABLE
    }
    return '';
  }
);

export { getDisconnectedErrorStatus };
