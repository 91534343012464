import React from 'react';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { Root, Line, KeyItem } from 'components/ChartKey';

const KeyRoot = styled(Root)`
  margin-left: 3rem;
  @media (orientation: portrait) {
    margin-left: 1rem;
  }
`;

const Dashed = styled(Line)`
  border-bottom: dashed 3px ${palette.Grey400};
`;

const PinkLine = styled(Line)`
  border-bottom: solid 2px ${palette.Pink600};
`;

const Key = () => {
  const { t } = useTranslation();
  return (
    <KeyRoot>
      <KeyItem>
        <Dashed />
        {_startCase(t('operation standard'))}
      </KeyItem>
      <KeyItem>
        <PinkLine />
        {_startCase(t('parts made'))}
      </KeyItem>
    </KeyRoot>
  );
};

export { Key };
