/* eslint-disable no-shadow */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  toISO,
  getUnitDurationFromString,
  formatDuration,
} from 'lib/utils/date';
import { getJobRunASWrapper } from 'lib/selectors/getJobRunASWrapper';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getLatestSetupActivityStartAt } from 'lib/selectors/getLatestSetupActivityStartAt';
import { getLatestSetupActivityEndAt } from 'lib/selectors/getLatestSetupActivityEndAt';
import { getAdjustedSetupDurationDates } from 'lib/selectors/getAdjustedSetupDurationDates';

const useSetupStats = () => {
  const jobRunASWrapper = useSelector(getJobRunASWrapper);
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const isPaused = useSelector(getIsPaused);
  const setupActivityStartAtISO = useSelector(getLatestSetupActivityStartAt);
  const setupActivityEndAtISO = useSelector(getLatestSetupActivityEndAt);
  const adjustedSetupDurationDates = useSelector(getAdjustedSetupDurationDates);

  const [
    // Used for duration ticker of current setup
    latestSetupStartDate,
    // Adjusted for pauses, used for duration ticker of total aggregate setup,
    adjustedSetupStartDate,
    // Sets an end to the duration tickers if paused
    setupEndDateOverride,
  ] = useMemo(() => {
    const {
      adjustedSetupStartDate: adjustedSetupStart,
    } = adjustedSetupDurationDates;
    return [
      // adjustedSetupStart will be jobRun.startDate if activities disabled
      toISO(setupActivityStartAtISO || adjustedSetupStart),
      toISO(adjustedSetupStart),
      isPaused && setupActivityEndAtISO ? setupActivityEndAtISO : null,
    ];
  }, [
    adjustedSetupDurationDates,
    setupActivityStartAtISO,
    setupActivityEndAtISO,
    isPaused,
  ]);

  const { expectedSetupDuration } = latestActivitySet;
  const expectedSetupDurationStr = useMemo(() => {
    const { expectedSetupDuration } = areActivitiesEnabled
      ? latestActivitySet
      : jobRunASWrapper;
    const expectedSetupDurationSecs = getUnitDurationFromString(
      expectedSetupDuration,
      'seconds'
    );
    return formatDuration(expectedSetupDurationSecs, 'short', { largest: 2 });
  }, [areActivitiesEnabled, latestActivitySet, jobRunASWrapper]);

  return {
    latestSetupStartDate,
    adjustedSetupStartDate,
    setupEndDateOverride,
    expectedSetupDurationStr,
    expectedSetupDuration,
  };
};

export { useSetupStats };
