import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ControlBarButton } from '@m12s/component-library';
import { stop, sync } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { CONTROL_ACTIONS } from 'lib/constants';
import { getHasActivityToSwitchTo } from 'lib/selectors/getHasActivityToSwitchTo';
import { getHasThreeOrMoreActivities } from 'lib/selectors/getActivityTypes';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { Production, Setup } from 'lib/customIcons';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getIsRunningUntaggedOperation } from 'lib/selectors/getIsRunningUntaggedOperation';
import { MobileIcon, ResponsiveText } from './styled';

const { STOP, SWITCH_TO_PRODUCTION } = CONTROL_ACTIONS;

const APMControlBar = ({ setControlAction, history, machine, isPaused }) => {
  const { t } = useTranslation();
  const hasActivityToSwitchTo = useSelector(getHasActivityToSwitchTo);
  const latestActivityMode = useSelector(getLatestActivityMode);
  const hasThreeOrMoreActivities = useSelector(getHasThreeOrMoreActivities);
  const isRunningUntaggedOp = useSelector(getIsRunningUntaggedOperation);
  const hasOpenActivity = useSelector(getHasOpenActivity);

  if (hasOpenActivity) {
    return (
      <>
        {isRunningUntaggedOp && (
          <ControlBarButton
            id="stop-job-untagged"
            icon={stop}
            iconSize="2rem"
            fitToContent
            onClick={() => {
              return setControlAction(STOP);
            }}
          ></ControlBarButton>
        )}

        {hasActivityToSwitchTo && hasThreeOrMoreActivities && (
          <ControlBarButton
            id="switch-activity-apm"
            onClick={() => {
              return history.push(
                Routes.machineIdSwitchActivitiesPath(machine.id)
              );
            }}
          >
            <MobileIcon icon={sync} />
            <ResponsiveText
              full={t('switchActivity|full')}
              medium={t('switchActivity|medium')}
              short={t('switchActivity|short')}
            />
          </ControlBarButton>
        )}
        {hasActivityToSwitchTo &&
          !hasThreeOrMoreActivities &&
          latestActivityMode === 'production' && (
            <ControlBarButton
              id="enter-setup-apm"
              onClick={() => {
                return history.push(
                  Routes.machineIdSwitchActivitiesPath(machine.id)
                );
              }}
            >
              <Setup />
              <ResponsiveText
                full={t('enterSetup|full')}
                medium={t('enterSetup|medium')}
                short={t('enterSetup|short')}
              />
            </ControlBarButton>
          )}
        {!hasThreeOrMoreActivities && latestActivityMode === 'setup' && (
          <ControlBarButton
            id="enter-production-apm"
            onClick={() => {
              if (isPaused) {
                return setControlAction(SWITCH_TO_PRODUCTION);
              }
              return history.push(
                Routes.machineIdSwitchActivitiesPath(machine.id)
              );
            }}
          >
            <Production />
            <ResponsiveText
              full={t('enterProduction|full')}
              medium={t('enterProduction|medium')}
              short={t('enterProduction|short')}
            />
          </ControlBarButton>
        )}
      </>
    );
  }
  return (
    <ControlBarButton
      id="enter-activity-apm"
      onClick={() => {
        return history.push(Routes.machineIdSwitchActivitiesPath(machine.id));
      }}
    >
      <MobileIcon icon={sync} />
      <ResponsiveText
        full={t('enterActivity|full')}
        medium={t('enterActivity|medium')}
        short={t('enterActivity|short')}
      />
    </ControlBarButton>
  );
};

export { APMControlBar };
