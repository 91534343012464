import { createSelector } from 'reselect';
import moment from 'moment';
import { isBefore, ago, isAfter } from 'lib/utils/date';
import { getHeavyUpdate } from './getUpdate';

const getLatestHeartbeat = (store) => {
  return store.latestHeartbeat;
};

const getUnavailableStartAt = createSelector(
  [getLatestHeartbeat, getHeavyUpdate],
  (latestHeartbeat) => {
    if (!latestHeartbeat) return false;

    return isBefore(moment(latestHeartbeat.eventTime), ago(5, 'minutes')) &&
      isAfter(moment(latestHeartbeat.messageTime), ago(5, 'minutes'))
      ? latestHeartbeat.eventTime
      : false;
  }
);

export { getUnavailableStartAt, getLatestHeartbeat };
