import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { STOP_OPERATOR } from 'lib/api/mutations';
import { getMachine } from 'lib/selectors/getMachine';
import { actionSetRecentOperatorRuns } from 'lib/actions';

const useOperatorLogout = () => {
  const dispatch = useDispatch();
  const machine = useSelector(getMachine);

  const [stopOperator] = useMutation(STOP_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ stopOperatorResponse }) => {
      if (stopOperatorResponse) {
        const {
          machine: localMachine,
          operator,
          ...operatorRun
        } = stopOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: localMachine?.id,
              operatorId: operator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
      }
    },
  });

  return async () => {
    await stopOperator({
      variables: {
        input: {
          machineId: machine.id,
        },
      },
    });
  };
};

export default useOperatorLogout;
