import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Tooltip, palette } from '@m12s/component-library';
import { questionCircle } from 'lib/icons';
import { loadingFade } from 'components/LoadingTextPlaceholder';

export const Root = styled.div`
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: bold;
  color: ${palette.Grey800};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  ${({ loading }) => {
    return loading && loadingFade;
  }}
`;

const ChartLabel = ({ children, tooltip, tooltipDirection, loading }) => {
  const ref = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  const onClickOutside = useCallback((event) => {
    if (!ref.current.contains(event.target)) setShowTooltip(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      return document.removeEventListener('mousedown', onClickOutside);
    };
  }, [onClickOutside]);

  return (
    <Root ref={ref} loading={loading}>
      <span>{children}</span>
      {tooltip && (
        <Tooltip
          icon={questionCircle}
          show={showTooltip}
          onClick={() => {
            return setShowTooltip(!showTooltip);
          }}
          customStyle={{ marginRight: '-0.75rem' }}
          location={tooltipDirection}
        >
          {tooltip}
        </Tooltip>
      )}
    </Root>
  );
};

export { ChartLabel };
