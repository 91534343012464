import { createSelector } from 'reselect';
import { toISO } from 'lib/utils/date';
import { getGlobalScope } from 'lib/selectors/getGlobalScope';

const getScopeStart = createSelector([getGlobalScope], ({ startDate }) => {
  if (!startDate) {
    return null;
  }
  return toISO(startDate);
});

export { getScopeStart };
