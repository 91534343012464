import { createSelector } from 'reselect';
import { toISO } from 'lib/utils/date';
import { getGlobalScope } from 'lib/selectors/getGlobalScope';

const getScopeEnd = createSelector([getGlobalScope], ({ endDate }) => {
  if (!endDate) {
    return null;
  }
  return toISO(endDate);
});

export { getScopeEnd };
