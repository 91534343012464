import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _range from 'lodash/range';
import { Tbody, Td } from '@m12s/component-library';

import { LoadingTextPlaceholder } from 'components/LoadingTextPlaceholder';
import { DefaultLoading } from 'components/Loading';

const Root = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  flex: 1;
`;

const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 2.5rem));
`;

const LoadingTd = styled(Td)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoadingText = styled(LoadingTextPlaceholder)`
  display: block;
  height: 0.875rem;
  margin-bottom: 0.375rem;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LoadingTbody = ({ rows, row }) => {
  return (
    <Root>
      <Tbody>
        {_range(rows).map((index) => {
          return React.cloneElement(row, { key: index });
        })}
      </Tbody>
      <Centered>
        <DefaultLoading />
      </Centered>
    </Root>
  );
};

LoadingTbody.propTypes = {
  rows: PropTypes.number,
  row: PropTypes.object.isRequired,
};

LoadingTbody.defaultProps = {
  rows: 1,
};

export { LoadingText, LoadingTd, LoadingTbody };
