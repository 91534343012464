import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';

// use most recent activity start to drive "latest" set
const getLatestActivitySetRef = createSelector(
  [getLatestActivity],
  (latestActivity) => {
    return _get(latestActivity, 'activitySetRef', null);
  }
);

export { getLatestActivitySetRef };
