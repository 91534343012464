import { createSelector } from 'reselect';

import { getCurrentStatus } from 'lib/selectors/getCurrentStatus';

const getIsDown = createSelector([getCurrentStatus], (interval) => {
  if (!interval) return false;
  return interval.label !== 'ACTIVE';
});

export { getIsDown };
