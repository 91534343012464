import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
import _sumBy from 'lodash/sumBy';
import _values from 'lodash/values';
import _get from 'lodash/get';
import { palette } from '@m12s/component-library';
import moment from 'moment';

import { AppNav } from 'components/AppNav';
import { DurationTicker } from 'components/DurationTicker';
import { diff, formatDuration } from 'lib/utils/date';
import { actionDismissDowntimeFlyup, actionDismissPopup } from 'lib/actions';
import { getDowntimeFlyup } from 'lib/selectors/getDowntimeFlyup';
import { getActiveUncategorizedInterval } from 'lib/selectors/getActiveUncategorizedInterval';
import { getCompany } from 'lib/selectors/getCompany';
import { HeaderText } from './styled';

function findActiveDowntime(selected) {
  return _find(selected, { end: null });
}

function durationTimestamp(selectedDuration, activeDowntime) {
  const initialDuration = selectedDuration - activeDowntime.duration;
  const durationSoFar = activeDowntime.timestamp - initialDuration * 1000;
  return moment(durationSoFar);
}

function sumSelected(selected) {
  return _sumBy(_values(selected), 'duration');
}

const CategorizeHeader = ({
  selected,
  handleOnBack,
  splitting,
  hideBack,
  hideClose,
  returnPath,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const activeDowntime = useMemo(() => {
    return findActiveDowntime(selected);
  }, [selected]);
  const totalSelected = useMemo(() => {
    return _values(selected).length;
  }, [selected]);
  const selectedDuration = useMemo(() => {
    return sumSelected(selected);
  }, [selected]);

  const dispatch = useDispatch();
  const { categorized } = _get(_values(selected), 0, {});

  const dismissFlyup = useCallback(() => {
    return dispatch(actionDismissDowntimeFlyup());
  }, [dispatch]);
  const dismissPopup = useCallback(() => {
    return dispatch(actionDismissPopup());
  }, [dispatch]);
  const downtimeFlyup = useSelector(getDowntimeFlyup);
  const activeUncategorizedInterval = useSelector(
    getActiveUncategorizedInterval
  );
  const company = useSelector(getCompany);

  const closeAndDismiss = () => {
    if (
      downtimeFlyup.triggered ||
      (activeUncategorizedInterval &&
        diff(new Date(), activeUncategorizedInterval.start, 'milliseconds') >=
          company.microstopDuration)
    ) {
      dismissFlyup();
      dismissPopup();
    }
    history.push(returnPath);
  };

  return (
    <AppNav
      onBack={!hideBack && (handleOnBack || closeAndDismiss)}
      onClose={!hideClose && closeAndDismiss}
    >
      {splitting ? (
        <>
          <HeaderText color={palette.Yellow400}>
            {_startCase(`1 ${t('event')}`)}
          </HeaderText>
          <HeaderText color={palette.White} normal>
            {_startCase(t('starting now'))}
          </HeaderText>
        </>
      ) : (
        <>
          <HeaderText color={palette.Yellow400}>
            {categorized
              ? _startCase(t('recategorizing event', { count: totalSelected }))
              : _startCase(t('event selected', { count: totalSelected }))}
          </HeaderText>
          <HeaderText color={palette.White} normal>
            {activeDowntime ? (
              <DurationTicker
                startDate={durationTimestamp(selectedDuration, activeDowntime)}
              />
            ) : (
              formatDuration(selectedDuration)
            )}{' '}
            {_capitalize(t('total'))}
          </HeaderText>
        </>
      )}
    </AppNav>
  );
};

CategorizeHeader.propTypes = {
  handleOnBack: PropTypes.func,
  selected: PropTypes.object,
  returnPath: PropTypes.string,
  splitting: PropTypes.bool,
  hideBack: PropTypes.bool,
  hideClose: PropTypes.bool,
};

CategorizeHeader.defaultProps = {
  selected: {},
};

export { CategorizeHeader };
