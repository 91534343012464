/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button as OriginalButton,
  Icon,
  palette,
} from '@m12s/component-library';

const machineHeader = {
  active: {
    background: palette.Green600,
    text: palette.White,
    mutedText: palette.Green300,
    shadow: true,
  },
  idle: {
    background: palette.Blue500,
    text: palette.White,
    mutedText: palette.Blue300,
    shadow: true,
  },
  unavailable: {
    background: palette.Grey600,
    text: palette.White,
    mutedText: palette.Grey400,
    shadow: true,
  },
  setup: {
    background: palette.Yellow400,
    text: palette.Grey900,
    mutedText: palette.Yellow600,
    shadow: false,
  },
  fault: {
    background: palette.Red600,
    text: palette.White,
    mutedText: palette.Red300,
    shadow: true,
  },
  pause: {
    background: palette.Purple700,
    text: palette.White,
    mutedText: palette.Purple400,
    shadow: true,
  },
};

const Text = styled.span`
  color: ${({ variant, categorize }) => {
    if (variant === 'unavailable') {
      return palette.Grey500;
    }
    if (categorize && variant !== 'setup') {
      return palette.Yellow400;
    }
    return machineHeader[variant].text;
  }};
  font-size: 1rem;
  font-weight: ${({ theme }) => {
    return theme.fontWeights.bold;
  }};
  letter-spacing: 0.005em;
  
  ${({ noLineHeight }) => {
    if (!noLineHeight) {
      return css`
        line-height: 1;
      `;
    }
  }}
  

  ${({ muted, variant }) => {
    return (
      muted &&
      variant !== 'unavailable' &&
      css`
        color: ${machineHeader[variant].mutedText};
      `
    );
  }}

  ${({ variant }) => {
    return (
      machineHeader[variant].shadow &&
      css`
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
      `
    );
  }}

  ${({ noWrap }) => {
    return (
      noWrap &&
      css`
        white-space: nowrap;
      `
    );
  }}
`;

const Header = styled.div`
  background-color: ${({ variant }) => {
    return machineHeader[variant].background;
  }};

  padding: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  @media (orientation: portrait) {
    padding: 0.125rem 0.25rem;
  }

  ${({ hideOnMobile }) => {
    return (
      hideOnMobile &&
      `
      @media (max-width: 576px) {
        display: none;
      }
    `
    );
  }}
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  @media (orientation: portrait) {
    margin: 0.25rem;
  }

  ${({ pullRight }) => {
    return (
      pullRight &&
      `
      @media (orientation: portrait) {
        margin-left: auto;
      }
    `
    );
  }}

  ${({ block }) => {
    return (
      block &&
      `
      display: block;
      overflow: hidden;
    `
    );
  }}
`;

const MachineStatusIcon = ({ variant, icon }) => {
  return (
    <Icon
      style={{ marginLeft: '0.5rem' }}
      icon={icon}
      size="1.25rem"
      color={variant === 'setup' ? palette.Black : palette.White}
      opacity="0.5"
    />
  );
};

MachineStatusIcon.propTypes = {
  variant: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

const JobStatusIcon = ({ variant, icon }) => {
  return (
    <Icon
      style={{ marginRight: '0.5rem' }}
      icon={icon}
      color={variant === 'setup' ? palette.Black : palette.White}
    />
  );
};

JobStatusIcon.propTypes = {
  variant: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

const Button = styled(OriginalButton)`
  height: 1.375rem;
  font-size: 0.625rem;
  line-height: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.004em;
  text-transform: uppercase;
  padding: 0 0.5rem;
  margin-left: 0.625rem;
  color: ${({ headerVariant }) => {
    return machineHeader[headerVariant].background;
  }};
  ${({ headerVariant }) => {
    return (
      headerVariant === 'setup' &&
      css`
        background-color: ${palette.Black};
        &:hover {
          background-color: ${palette.Black};
        }
        &:active {
          background-color: ${palette.Black};
        }
      `
    );
  }}
`;

const TouchTargetPosition = styled.span`
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 640px) {
    display: none;
  }
`;

const TouchTarget = styled.a`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 2.75rem;
`;

const TextSpacer = styled.span`
  display: inline-block;
  width: ${({ width = '0.75rem' }) => {
    return width;
  }};
`;

const BottomLeftInfoGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const TouchAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 1rem;
  overflow: hidden;
`;

const OperationName = styled(Text)`
  flex-direction: row;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const WorkOrderInfoGroupContainer = styled.div`
  height: 1.5rem;
  font-weight: 700;
  padding-right: 0.7rem;
  display: flex;
  ${({ workOrderId }) => {
    return workOrderId && `min-width: 0;`;
  }}
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.2rem;
  color: ${({ variant, isInSetup }) => {
    if (variant === 'unavailable') {
      return palette.Grey500;
    }
    if (isInSetup) {
      return palette.Black;
    }
    return machineHeader?.[variant]?.background || 'inherit';
  }};
  ${({ variant }) => {
    return variant !== 'unavailable' && `cursor: pointer;`;
  }}
`;

const WorkOrderIdContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  justify-content: center;
  height: 1.5rem;
  font-size: 1rem;
`;

const WithWorkOrderIdIcon = styled(WorkOrderIdContainer)`
  height: 1.5rem;
  min-width: 1.5rem;
  background-color: ${({ isInSetup, variant }) => {
    if (variant === 'unavailable') {
      return palette.Grey500;
    }
    if (isInSetup) {
      return palette.Grey900;
    }
    return palette.Yellow300;
  }};
  border-radius: 0.2rem;
  z-index: 1;
  ${({ hoverOverTouchPoint, isInSetup, variant }) => {
    if (hoverOverTouchPoint && variant !== 'unavailable') {
      return css`
        background-color: ${isInSetup ? palette.Black : palette.Yellow400};
      `;
    }
  }}
  color: ${({ isInSetup, variant }) => {
    if (variant === 'unavailable') {
      return palette.Grey600;
    }
    if (isInSetup) {
      return palette.Yellow400;
    }
  }};
`;

const NoWorkOrderIdContainer = styled.div`
  background-color: ${({ isInSetup, variant }) => {
    if (variant === 'unavailable') {
      return palette.Grey500;
    }
    if (isInSetup) {
      return palette.Grey900;
    }
    return palette.Yellow300;
  }};
  z-index: 1;
  ${({ hoverOverTouchPoint, isInSetup, variant }) => {
    if (hoverOverTouchPoint && variant !== 'unavailable') {
      return css`
        background-color: ${isInSetup ? palette.Black : palette.Yellow400};
      `;
    }
  }}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 9rem;
  border-radius: 0.2rem;
  font-size: 0.625rem;
  color: ${({ isInSetup, variant }) => {
    if (variant === 'unavailable') {
      return palette.Grey600;
    }
    if (isInSetup) {
      return palette.Yellow400;
    }
  }};
`;

const NotShowWorkOrderId = styled(NoWorkOrderIdContainer)`
  width: 1.5rem;
  ${({ hoverOverTouchPoint, isInSetup, variant }) => {
    if (variant === 'unavailable') {
      return css`
        background-color: ${palette.Grey500};
      `;
    }
    if (hoverOverTouchPoint) {
      return css`
        background-color: ${isInSetup ? palette.Black : palette.Yellow400};
      `;
    }
  }}
`;

const WorkOrderText = styled.div`
  margin-left: 0.45rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ isInSetup, useHeaderBG, variant, isPaused, workOrderId }) => {
    if (variant === 'unavailable') {
      return palette.Grey600;
    }
    if (isInSetup && workOrderId) {
      return palette.Grey900;
    }
    if (useHeaderBG) {
      return machineHeader?.[variant]?.background || 'inherit';
    }
    if (isPaused) {
      return machineHeader?.[variant]?.mutedText;
    }
    if (variant === 'unavailable') {
      return palette.Grey500;
    }
    return palette.Yellow300;
  }};
`;

const MachineNameContainer = styled(Text)`
  cursor: default;
`;

const TouchPointArea = styled.div`
  position: fixed;
  top: 2.4rem;
  left: 0;
  width: ${({ width }) => {
    return `${width}px`;
  }};
  height: 3.1rem;
`;

const PausedContainer = styled.div`
  margin-left: 0.75rem;
  display: flex;
  flex-direction: row;
`;

export {
  Button,
  Column,
  Header,
  Row,
  MachineStatusIcon,
  JobStatusIcon,
  Text,
  TouchTarget,
  TouchTargetPosition,
  TextSpacer,
  BottomLeftInfoGroup,
  WorkOrderInfoGroupContainer,
  WorkOrderIdContainer,
  NoWorkOrderIdContainer,
  WorkOrderText,
  WithWorkOrderIdIcon,
  NotShowWorkOrderId,
  MachineNameContainer,
  TouchPointArea,
  TouchAreaContainer,
  OperationName,
  PausedContainer,
};
