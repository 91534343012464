import React, { useMemo, useState } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';

import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getShiftDowntimeIntervals } from 'lib/selectors/getShiftDowntimeIntervals';
import { getShopDayDowntimeIntervals } from 'lib/selectors/getShopDayDowntimeIntervals';
import MainLayout from 'components/Layouts/MainLayout';
import { MachineHeader } from 'components/MachineHeader';
import { UtilityMenu } from 'components/UtilityMenu';
import { LanguageModal } from 'components/LanguageModal';
import { comment, ban } from 'lib/icons';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';

import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getDisconnectedErrorStatus } from 'lib/selectors/getDisconnectedErrorStatus';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import { getOpenTraining } from 'lib/selectors/getOpenTraining';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';

import { DowntimePopup } from 'components/DowntimePopup';
import { WorkflowPopup } from 'components/WorkflowPopup';
import { MessagePopup } from 'components/MessagePopup';
import { TrainingDoc } from 'components/TrainingDoc';
import { getLongDurationCutoff } from 'lib/api/getLongDurationCutoff';
import { getHomeTab } from 'lib/selectors/getTabs';
import { actionCloseLanguageModal } from 'lib/actions';
import { getLanguageModalOpen } from 'lib/selectors/getLanguageModalOpen';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import {
  ButtonBadge,
  ControlBarResponsive,
  MobileIcon,
  ResponsiveText,
  Scrollable,
} from './styled';
import MachineMain from './MachineMain';
import DisconnectedErrorPage from './DisconnectedErrorPage';
import { ControlActionModal } from './ControlActionModal';
import { ActivitiesJobControlBar } from './ActivitiesJobControlBar';
import { APMControlBar } from './APMControlBar';

const Page = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flags = useFlags();
  const laborTicketsEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];

  const openTraining = useSelector(getOpenTraining);
  const machine = useSelector(getMachine);
  const shiftDowntimeIntervals = useSelector(getShiftDowntimeIntervals);
  const shopDayDowntimeIntervals = useSelector(getShopDayDowntimeIntervals);
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const downtimeIntervals = isLatestShiftActive
    ? shiftDowntimeIntervals
    : shopDayDowntimeIntervals;
  const hasOpenJobRun = useSelector(getHasOpenJobRun);
  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);
  const disconnectedErrorStatus = useSelector(getDisconnectedErrorStatus);
  const homeTab = useSelector(getHomeTab);
  const isPaused = useSelector(getIsPaused);
  const longDurationCutoff = useSelector(getLongDurationCutoff);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const JobControlBar = isAPMEnabled ? APMControlBar : ActivitiesJobControlBar;

  const [controlAction, setControlAction] = useState(null);

  const languageModalOpen = useSelector(getLanguageModalOpen);

  const totalUncategorized = useMemo(() => {
    return downtimeIntervals.filter(({ categorized, duration }) => {
      return !categorized && duration > longDurationCutoff;
    }).length;
  }, [downtimeIntervals, longDurationCutoff]);

  if (openTraining) {
    return <TrainingDoc />;
  }

  return (
    <>
      <Route exact path={Routes.machineIdHomePath.pattern}>
        <Redirect to={Routes.machineIdHomeTabPath(machine.id, homeTab)} />
      </Route>
      <Route
        path={Routes.machineIdHomeTabPath.pattern}
        render={({
          match: {
            params: { tab },
          },
        }) => {
          return (
            <>
              <WorkflowPopup />
              <DowntimePopup />
              <MessagePopup />
              {controlAction && (
                <ControlActionModal
                  controlAction={controlAction}
                  setControlAction={setControlAction}
                />
              )}
              <LanguageModal
                isOpen={languageModalOpen}
                onClose={() => {
                  return dispatch(actionCloseLanguageModal());
                }}
              />
              <MainLayout.Container direction="column">
                <MachineHeader />
                <MainLayout.Container direction="column">
                  {disconnectedErrorStatus ? (
                    <DisconnectedErrorPage
                      errorStatus={disconnectedErrorStatus}
                    />
                  ) : (
                    <MachineMain tab={tab} />
                  )}
                </MainLayout.Container>
                <ControlBarResponsive id="job-control-bar">
                  <UtilityMenu />
                  <Scrollable>
                    <JobControlBar
                      setControlAction={setControlAction}
                      history={history}
                      machine={machine}
                      hasOpenJobRun={hasOpenJobRun}
                      hasOpenActivitySet={hasOpenActivitySet}
                      isPaused={isPaused}
                      hasOpenSetupActivity={hasOpenSetupActivity}
                    />
                    {!laborTicketsEnabled && (
                      <ControlBarButton
                        id="reject-parts"
                        onClick={() => {
                          return history.push(
                            Routes.machineIdRejectPartsPath(machine.id)
                          );
                        }}
                      >
                        <MobileIcon icon={ban} />
                        <ResponsiveText
                          full={t('rejectParts|full')}
                          medium={t('rejectParts|medium')}
                          short={t('rejectParts|short')}
                        />
                      </ControlBarButton>
                    )}
                    <ControlBarButton
                      id="categorize"
                      onClick={() => {
                        return history.push({
                          pathname: Routes.machineIdCategorizePath(machine.id),
                          state: {
                            returnPath: history.location.pathname,
                          },
                        });
                      }}
                    >
                      {totalUncategorized ? (
                        <ButtonBadge>{totalUncategorized}</ButtonBadge>
                      ) : (
                        <MobileIcon icon={comment} />
                      )}
                      <ResponsiveText
                        full={t('categorize|full')}
                        medium={t('categorize|medium')}
                        short={t('categorize|short')}
                      />
                    </ControlBarButton>
                  </Scrollable>
                </ControlBarResponsive>
              </MainLayout.Container>
            </>
          );
        }}
      />
    </>
  );
};

export default Page;
