import { createSelector } from 'reselect';
import { getLatestProductionActivity } from './getLatestProductionActivity';

const getLatestProductionActivityEndAt = createSelector(
  [getLatestProductionActivity],
  ({ end }) => {
    return end || null;
  }
);

export { getLatestProductionActivityEndAt };
