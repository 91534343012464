import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRejectedParts } from 'lib/selectors/getRejectedParts';
import { useSelector } from 'react-redux';
import { WheelSublabel } from '@m12s/component-library';


export const OpRunOverrideRejectLabel = () => {
  const { t } = useTranslation();
  const rejectCount = useSelector(getRejectedParts);
  const labelString = `{{rejectCount}} Reject${rejectCount === 1 ? '' : 's'}`;
  return (
    <WheelSublabel
      style={{
        marginTop: '0.5rem',
      }}
    >
      {t(labelString, {
        rejectCount,
      })}
    </WheelSublabel>
  );
};
