import { getScopePartAdjustmentData } from 'lib/selectors/getScopePartAdjustmentData';
import { getScopePartCountByHour } from 'lib/selectors/getScopePartCountByHour';
import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';

const getScopeNetPartCountHourlies = createDeepEqualitySelector(
  [getScopePartCountByHour, getScopePartAdjustmentData],
  (scopePartCountByHour, scopePartAdjustmentData) => {
    const { scopeNetPartAdjustmentsByHour } = scopePartAdjustmentData;
    return Object.keys(scopePartCountByHour).map((hourKey) => {
      const partCountHourAgg = scopePartCountByHour[hourKey];
      const netPartAdjustmentHourAgg = scopeNetPartAdjustmentsByHour[hourKey];
      return {
        time: hourKey,
        sum: Math.max(partCountHourAgg + netPartAdjustmentHourAgg, 0),
      };
    });
  }
);

export { getScopeNetPartCountHourlies };
