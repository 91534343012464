import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { Text } from 'components/Text';
import { loadingFade } from 'components/LoadingTextPlaceholder';

export const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(14rem, 40vh) 1fr;
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  position: relative;

  @media (orientation: portrait) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  @media (max-height: 599px) {
    grid-template-rows: 1fr 2fr;
  }

  @media (max-height: 540px) {
    grid-template-columns: 1fr minmax(11rem, 40vh) 1fr;
    grid-template-rows: 2fr 3fr;
  }
`;

export const WheelWrapper = styled.div`
  position: relative;
  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: span 2;
  align-self: center;

  @media (orientation: portrait) {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row: 1;
    width: max(min(16rem, 35vh, 50%), 13rem);
    justify-self: center;
    padding-top: 2rem;
  }
`;

export const LoginPortrait = styled.div`
  position: absolute;
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row: 3;
  width: 100%;
  margin: 0;
  align-self: flex-start;
  justify-self: center;
  transform: translateY(-2.25rem);

  @media (orientation: landscape) {
    display: none;
  }
`;

export const LoginLandscape = styled.div`
  position: relative;
  grid-column: 2;
  grid-row: 2;
  align-self: center;

  @media (orientation: portrait) {
    display: none;
  }
`;

export const TopLeftContent = styled.div`
  display: flex;
  border-bottom: 1px solid ${palette.Grey300};

  @media (orientation: portrait) and (max-width: 720px) {
    grid-column: 1 / span 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
`;

export const TopRightContent = styled.div`
  display: flex;
  border-bottom: 1px solid ${palette.Grey300};
  justify-content: flex-end;

  @media (orientation: portrait) and (max-width: 720px) {
    grid-column: 1 / span 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
`;

export const BottomLeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  max-height: 35vh;
  @media (orientation: portrait) {
    max-height: 25vh;
    min-height: 15rem;
  }
  @media (orientation: portrait) and (max-width: 720px) {
    grid-column: 1 / span 2;
    grid-row-start: 4;
    grid-row-end: 4;
  }
`;

export const BottomRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  max-height: 35vh;
  @media (orientation: portrait) {
    max-height: 25vh;
    min-height: 15rem;
  }
  @media (orientation: portrait) and (max-width: 720px) {
    grid-column: 1 / span 2;
    grid-row-start: 5;
    grid-row-end: 5;
  }
`;

export const CounterLabel = styled(Text)`
  color: ${palette.Grey800};
  font-size: 1.5rem;
  @media (orientation: portrait) {
    font-size: 1.25rem;
  }
  ${({ loading }) => {
    return loading && loadingFade;
  }}
`;

export const WheelCounterPadding = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;

  @media (max-width: 30rem) {
    padding: 1rem;
  }
  @media (max-height: 540px) {
    padding: 1rem;
    display: flex;
    justify-content: center;
    > div {
      display: flex;
      align-items: center;
      > div {
        margin: 0 1rem;
      }
    }
  }

  ${({ left }) => {
    return (
      left &&
      `
    align-items: flex-start;
    padding-left: 3rem;
      
    @media (orientation: portrait) {
      padding-right: 0.5rem;
    }
  `
    );
  }}
  ${({ right }) => {
    return (
      right &&
      `
    align-items: flex-end;
    padding-right: 3rem;

    @media (orientation: portrait) {
      padding-left: 0.5rem;
    }
  `
    );
  }}
  ${({ top }) => {
    return (
      top &&
      `
    padding-top: 3rem;

    @media (orientation: portrait) {
      padding-bottom: 2rem;
    }
    @media (max-height: 600px) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  `
    );
  }}
  ${({ bottom }) => {
    return (
      bottom &&
      `
    padding-top: 2rem;
    align-items: flex-start;

    @media (max-height: 600px) {
      padding-top: 1.5rem;
    }
    @media (orientation: portrait) {
      padding-bottom: 2rem;
    }
  `
    );
  }}
`;

export const WheelChartPadding = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  padding-bottom: 2rem;
  max-height: 35vh;
  @media (orientation: portrait) {
    max-height: 25vh;
    min-height: 15rem;
  }
`;
