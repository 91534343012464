import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pill, palette, Icon } from '@m12s/component-library';

import { signIn, user } from 'lib/icons';
import Routes from 'lib/utils/routes';

import { PillWrapper } from './styled';

const OperatorPill = ({ operator, machineId }) => {
  const { t } = useTranslation();

  return (
    <PillWrapper id="operator-pill" to={Routes.machineIdLoginPath(machineId)}>
      <Pill
        backgroundColor={operator ? palette.Purple100 : palette.Grey200}
        borderColor={operator ? palette.Purple200 : palette.Grey300}
        textColor={operator ? palette.Purple500 : palette.Grey700}
      >
        <Icon icon={operator ? user : signIn} size="0.625rem"></Icon>
        <span style={{ marginLeft: '0.375rem' }}>
          {operator ? operator.name : t('Sign In Now')}
        </span>
      </Pill>
    </PillWrapper>
  );
};

export { OperatorPill };
