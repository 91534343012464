import { createSelector } from 'reselect';
import { fallsWithinActivities } from 'lib/utils/intervals';
import { sumNetPartAdjustments } from 'lib/utils/parts';
import _sumBy from 'lodash/sumBy';
import { getCurrentSetupModeActivities } from './getCurrentSetupModeActivities';
import { getPartAdjustments } from './getPartAdjustments';
import { getAreActivitiesEnabled } from './getAreActivitiesEnabled';
import { getScopeNetPartsMade } from './getScopeNetPartsMade';
import { getScopePartAdjustmentData } from './getScopePartAdjustmentData';
import { getRejectedParts } from './getRejectedParts';
import { getScopeReadTimePCM } from './getScopeReadTimePCM';

const getSetupRawParts = (store) => {
  return store.setupRawParts;
};

const getSetupParts = createSelector(
  [getSetupRawParts, getScopeReadTimePCM],
  (setupRawParts, scopeReadTimePCM) => {
    return setupRawParts * scopeReadTimePCM;
  }
);

// This returns part adjustments that fall within your current setup session (all activities since entering setup mode)
const getSetupPartAdjustments = createSelector(
  [
    getPartAdjustments,
    getCurrentSetupModeActivities,
    getAreActivitiesEnabled,
    getScopePartAdjustmentData,
  ],
  (partAdjustments, currentSetupModeActivities) => {
    return partAdjustments.filter((adjustment) => {
      return fallsWithinActivities(
        adjustment.adjustmenttime,
        currentSetupModeActivities
      );
    });
  }
);

const getSetupPartsWithPartAdjustments = createSelector(
  [getSetupParts, getSetupPartAdjustments],
  (setupParts, setupPartAdjustments) => {
    return setupParts + sumNetPartAdjustments(setupPartAdjustments);
  }
);

const getNetSetupParts = createSelector(
  [
    getSetupPartsWithPartAdjustments,
    getScopeNetPartsMade,
    getAreActivitiesEnabled,
  ],
  (setupPartsWithPartAdjustments, scopeNetPartsMade, areActivitiesEnabled) => {
    if (!areActivitiesEnabled) {
      // jsg shim, will already be scoped as expected
      return scopeNetPartsMade;
    }
    return setupPartsWithPartAdjustments;
  }
);

const getSetupRejectCount = createSelector(
  [getAreActivitiesEnabled, getSetupPartAdjustments, getRejectedParts],
  (areActivitiesEnabled, setupPartAdjustments, rejectedParts) => {
    if (!areActivitiesEnabled) {
      // jsg shim, will already be scoped as expected
      return rejectedParts;
    }
    return _sumBy(setupPartAdjustments, 'scrapcount');
  }
);

export { getNetSetupParts, getSetupParts, getSetupRejectCount };
