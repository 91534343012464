import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { testIsEmbeddable } from '@machinemetrics/mm-react-embeddable';
import CustomTabBasic from './CustomTabBasic';
import CustomTabZone from './CustomTabZone';

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ hidden }) => {
    return (
      hidden &&
      `height: calc(100vh - 10rem); position: absolute; z-index: -1; visibility: hidden`
    );
  }}
`;

const CustomTab = ({ tab, base, hidden = true }) => {
  const [isEmbeddable, setIsEmbeddable] = useState();

  useEffect(() => {
    (async () => {
      const embeddable = await testIsEmbeddable(tab.url);
      setIsEmbeddable(embeddable);
    })();
  }, [tab.url]);

  if (isEmbeddable === undefined) {
    return <></>;
  }

  return (
    <Root hidden={hidden}>
      {isEmbeddable ? (
        <CustomTabZone src={tab.url} id={tab.id} base={base} />
      ) : (
        <CustomTabBasic url={tab.url} />
      )}
    </Root>
  );
};

export { CustomTab };
