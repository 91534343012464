import { createSelector } from 'reselect';
import { getIsCompanyLoading } from 'lib/selectors/getCompany';
import { getAreAnnotationsLoading } from 'lib/selectors/getAnnotations';
import { getIsJobRunLoading } from 'lib/selectors/getJobRun';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getAreActivityTypesLoading } from 'lib/selectors/getActivityTypes';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getIsActivitiesStatusLoading } from 'lib/selectors/getActivitiesStatus';

const getIsBaseAppDataLoading = createSelector(
  [
    (state) => {
      return state.loading;
    },
    (state) => {
      return state.shifts.loading;
    },
    getIsCompanyLoading,
    getAreAnnotationsLoading,
    getIsInitialActivitySetPollLoading,
    getAreActivityTypesLoading,
    getAreActivitiesEnabled,
    getIsJobRunLoading,
    getIsActivitiesStatusLoading,
  ],
  (
    isInitialDataLoading,
    areShiftsLoading,
    isCompanyLoading,
    areAnnotationsLoading,
    isInitialActivitySetPollLoading,
    areActivityTypesLoading,
    areActivitiesEnabled,
    isJobRunLoading,
    isActivitiesStatusLoading
  ) => {
    return !!(
      isInitialDataLoading ||
      areShiftsLoading ||
      isCompanyLoading ||
      areAnnotationsLoading ||
      isActivitiesStatusLoading ||
      (areActivitiesEnabled
        ? isInitialActivitySetPollLoading || areActivityTypesLoading
        : isJobRunLoading)
    );
  }
);

const getBaseAppDataLoadingStates = createSelector(
  [
    (state) => {
      return state.loading;
    },
    (state) => {
      return state.shifts.loading;
    },
    getIsCompanyLoading,
    getAreAnnotationsLoading,
    getIsInitialActivitySetPollLoading,
    getAreActivityTypesLoading,
    getAreActivitiesEnabled,
    getIsJobRunLoading,
    getIsActivitiesStatusLoading,
  ],
  (
    isInitialDataLoading,
    areShiftsLoading,
    isCompanyLoading,
    areAnnotationsLoading,
    isInitialActivitySetPollLoading,
    areActivityTypesLoading,
    areActivitiesEnabled,
    isJobRunLoading,
    isActivitiesStatusLoading
  ) => {
    return {
      initialData: isInitialDataLoading,
      shifts: areShiftsLoading,
      company: isCompanyLoading,
      annotations: areAnnotationsLoading,
      activitiesStatus: isActivitiesStatusLoading,
      ...(areActivitiesEnabled
        ? {
            latestActivitySet: isInitialActivitySetPollLoading,
            activityTypes: areActivityTypesLoading,
          }
        : { jobRun: isJobRunLoading }),
    };
  }
);

export { getIsBaseAppDataLoading, getBaseAppDataLoadingStates };
