import styled, { css, keyframes } from 'styled-components';
import { Icon, palette } from '@m12s/component-library';
import { P, Small } from 'components/Text';
import { zLayer } from 'lib/utils/styled';
import PausePulse from 'graphics/status/pulse-bg-pattern-pause.gif';

const getDurationMsStr = (durationInt) => {
  return `${durationInt}ms`;
};

const drawerDurationMs = 500;
const drawerDurationMsStr = getDurationMsStr(drawerDurationMs);

const partialDrawerDurationMs = 250;
const partialDrawerDurationMsStr = getDurationMsStr(partialDrawerDurationMs);

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  pointer-events: ${({ isOpen }) => {
    return isOpen ? 'all' : 'none';
  }};
  background: ${palette.Grey100};
  opacity: ${({ isOpen }) => {
    return isOpen ? '0.65' : '0';
  }};
  transition: opacity ${drawerDurationMsStr} ease;
  z-index: ${zLayer.menu};
`;

export const MainContainer = styled.div`
  position: fixed;
  bottom: 4.5rem;
  right: 0;
  overflow: hidden;
  pointer-events: all;
  z-index: ${zLayer.menu};
`;

export const TogglePromptContainer = styled.div`
  border-top-left-radius: ${({ isOpen }) => {
    return isOpen ? '0rem' : '0.5rem';
  }};
  background: ${({ isOpen }) => {
    return isOpen ? palette.Grey50 : palette.Purple500;
  }};
  min-width: ${({ isOpen }) => {
    return isOpen ? '100vw' : '1vw';
  }};
  display: flex;
  justify-content: space-between;
  box-shadow: ${({ isOpen }) => {
    return isOpen ? 'inset 0px 1px 0px #EDF2F7' : 'none';
  }};
  padding: ${({ isOpen }) => {
    return isOpen ? '1rem 1.5rem' : '1rem';
  }};
  font-size: 1rem;
  color: ${({ isOpen }) => {
    return isOpen ? palette.Grey700 : palette.White;
  }};
  font-weight: bold;
  line-height: 0.75rem;
  text-shadow: initial;
  cursor: pointer;
  transition: padding ${drawerDurationMsStr} ease,
    border-top-left-radius ${drawerDurationMsStr} ease,
    min-width ${drawerDurationMsStr} ease;
  ${({ isOpen, hasBeenOpened }) => {
    const transition = keyframes`
      0% {
        color: ${isOpen ? palette.White : palette.Grey700};
        background: ${isOpen ? palette.Purple500 : palette.Grey50};
      }
      100% {
        background: ${isOpen ? palette.Grey50 : palette.Purple500};
        color: ${isOpen ? palette.Grey700 : palette.White};
      }
    `;
    if (isOpen) {
      return css`
        animation: ${transition} ${drawerDurationMsStr} ease;
      `;
    }
    if (!isOpen && hasBeenOpened) {
      return css`
        background: url(${PausePulse}) repeat 100% 100% fixed;
        animation: ${transition} ${drawerDurationMsStr} ease;
      `;
    }
    if (!isOpen && !hasBeenOpened) {
      // prevent drawer color transition from firing on first render (ie when switching tabs)
      return css`
        background: url(${PausePulse}) repeat 100% 100% fixed;
      `;
    }
    return ``;
  }}
`;

export const ToggleIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

export const TogglePromptTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HideDetailsText = styled(Small)`
  color: ${palette.Purple500};
  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        opacity: 1;
        max-height: 0.75rem;
        max-width: 10rem;
        transition: opacity 150ms linear ${partialDrawerDurationMsStr},
          max-height 0ms linear ${partialDrawerDurationMsStr},
          max-width 0ms linear ${partialDrawerDurationMsStr};
      `;
    }
    return css`
      opacity: 0;
      max-height: 0;
      max-width: 0;
    `;
  }}
`;

export const PauseDetailsContainer = styled.div`
  box-shadow: inset 0px 1px 0px #edf2f7;
  overflow: hidden;
  background: ${palette.White};
  ${({ isOpen, hasOpenActivitySetInSetup }) => {
    if (isOpen) {
      return css`
        @media (orientation: landscape) {
          height: ${hasOpenActivitySetInSetup ? '8rem' : '10rem'};
        }
        @media (orientation: portrait) {
          height: ${hasOpenActivitySetInSetup ? '16rem' : '20rem'};
        }
        transition: height ${drawerDurationMsStr} ease;
      `;
    }
    return css`
      height: 0rem;
      transition: height ${drawerDurationMsStr} ease;
    `;
  }}
  @media (orientation: portrait) {
    flex-direction: column;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  > div {
    ${({ isOpen }) => {
      if (isOpen) {
        return css`
          opacity: 1;
          max-height: 40vh;
          max-width: 27rem;
          transition: opacity 150ms linear ${partialDrawerDurationMsStr},
            max-height 0ms linear ${partialDrawerDurationMsStr},
            max-width 0ms linear ${partialDrawerDurationMsStr};
        `;
      }
      return css`
        opacity: 0;
        max-height: 0;
        max-width: 0;
      `;
    }}
  }
`;

export const GeneralPauseText = styled(Small)`
  display: block;
  color: ${palette.Grey600};
`;

export const PauseMetricsContainer = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const InlineP = styled(P)`
  display: inline;
  color: ${palette.Grey600};
`;
