import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { palette, Icon } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import {
  millisSince,
  getUnitDurationFromString,
  formatDuration,
} from 'lib/utils/date';
import { DurationTicker } from 'components/DurationTicker';
import { TickerContext } from 'components/TickerProvider';

import { exclamationCircleLight } from 'lib/icons';
import {
  ReminderContainer,
  ReminderIconTextContainer,
  ReminderText,
  DataContainer,
  GroupDataContainer,
  DataRow,
  EachRowData,
  DataColumn,
  EachColumnData,
  DataTitle,
  DisplayData,
  SetupPerformanceData,
  GoodPartsInput,
  PlusMinusButton,
  SetupPerformanceTitle,
  PartsInputGroup,
} from './styled';

const SetupActivityReview = ({
  detectedSetupParts,
  setupRejectCount,
  adjustedSetupStartDate,
  setupEndDateOverride,
  expectedSetupDurationStr,
  expectedSetupDuration,
  dispatch,
  formState,
}) => {
  useContext(TickerContext);
  const totalSetupTime = millisSince(adjustedSetupStartDate);
  const setupPerformanceSeconds =
    (getUnitDurationFromString(expectedSetupDuration) - totalSetupTime) / 1000;

  const setupPerformance = formatDuration(Math.abs(setupPerformanceSeconds));

  const { t } = useTranslation();

  const detectedPartsMinusRejects = detectedSetupParts - setupRejectCount;

  const { idealUnitDuration } = useSelector(getLatestActivitySet);

  const adjustmentBuffer =
    idealUnitDuration &&
    getUnitDurationFromString(idealUnitDuration) &&
    Math.floor(totalSetupTime / getUnitDurationFromString(idealUnitDuration));

  return (
    <>
      <ReminderContainer>
        <ReminderIconTextContainer>
          <Icon
            size="2rem"
            icon={exclamationCircleLight}
            color={palette.Purple600}
            style={{ marginRight: '1rem' }}
          />
          <ReminderText>
            {t(
              'Adjust the part count below as necessary to remove any empty cycles or any parts that were counted incorrectly. Any rejected parts have already been removed from the part count.'
            )}
          </ReminderText>
        </ReminderIconTextContainer>
      </ReminderContainer>

      <DataContainer>
        <GroupDataContainer>
          <DataRow>
            <EachRowData>
              <DataTitle heavyFontWeight>{t('Elapsed')}</DataTitle>
              <DisplayData>
                <DurationTicker
                  startDate={adjustedSetupStartDate}
                  endDateOverride={setupEndDateOverride}
                />
              </DisplayData>
            </EachRowData>

            <>
              <EachRowData>
                <DataTitle heavyFontWeight>{t('Expected')}</DataTitle>
                <DisplayData>
                  {!expectedSetupDurationStr ? 'N/A' : expectedSetupDurationStr}
                </DisplayData>
              </EachRowData>
              <EachRowData>
                <SetupPerformanceTitle heavyFontWeight>
                  {/* expected - actual > 0, good thing: faster than expected */}
                  {setupPerformanceSeconds > 0
                    ? t('Below Expected')
                    : t('Above Expected')}
                </SetupPerformanceTitle>
                <SetupPerformanceData
                  setupPerformancePositive={setupPerformanceSeconds > 0}
                >
                  {setupPerformance}
                </SetupPerformanceData>
              </EachRowData>
            </>

            <EachRowData>
              <DataTitle heavyFontWeight>{t('Rejected Parts')}</DataTitle>
              <DisplayData>{setupRejectCount}</DisplayData>
            </EachRowData>
          </DataRow>

          <DataColumn>
            <EachColumnData>
              <DataTitle heavyFontWeight>{t('Good Parts')}</DataTitle>
              <DisplayData>
                <PartsInputGroup>
                  <GoodPartsInput
                    type="number"
                    value={formState?.parts}
                    min={0}
                    onChange={(e) => {
                      const positiveValue = Math.abs(
                        parseInt(e.target.value, 10)
                      );
                      const max = Math.max(
                        detectedPartsMinusRejects,
                        adjustmentBuffer,
                        2
                      );
                      const limitedValue = Math.min(positiveValue, max);
                      const value = e.target.value ? limitedValue : '';
                      return dispatch({
                        type: 'SET_PARTS',
                        parts: value,
                      });
                    }}
                    onBlur={() => {
                      if (!formState?.parts) {
                        dispatch({
                          type: 'SET_PARTS',
                          parts: 0,
                        });
                      }
                    }}
                    onFocus={() => {
                      if (!formState?.parts) {
                        dispatch({
                          type: 'SET_PARTS',
                          parts: '',
                        });
                      }
                    }}
                    onKeyUp={(event) => {
                      if (event.keyCode === 13) {
                        event.preventDefault();
                        event.target.blur();
                      }
                    }}
                  />
                  <PlusMinusButton
                    id="increment-setup-parts"
                    rounded
                    onClick={() => {
                      if (formState?.parts === 0) {
                        return null;
                      }
                      return dispatch({
                        type: 'SET_PARTS',
                        parts: formState?.parts - 1,
                      });
                    }}
                  >
                    {t('-')}
                  </PlusMinusButton>
                  <PlusMinusButton
                    id="decrement-setup-parts"
                    rounded
                    onClick={() => {
                      const max = Math.max(
                        detectedPartsMinusRejects,
                        adjustmentBuffer,
                        2
                      );
                      if (formState?.parts === max) {
                        return null;
                      }
                      return dispatch({
                        type: 'SET_PARTS',
                        parts: formState?.parts + 1,
                      });
                    }}
                  >
                    {t('+')}
                  </PlusMinusButton>
                </PartsInputGroup>
              </DisplayData>
            </EachColumnData>
          </DataColumn>
        </GroupDataContainer>
      </DataContainer>
    </>
  );
};

SetupActivityReview.propTypes = {
  detectedSetupParts: PropTypes.number,
  setupRejectCount: PropTypes.number,
  adjustedSetupStartDate: PropTypes.string,
  setupEndDateOverride: PropTypes.string,
  expectedSetupDurationStr: PropTypes.string,
  expectedSetupDuration: PropTypes.string,
};

export { SetupActivityReview };
