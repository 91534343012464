import { createSelector } from 'reselect';
import { FLAG_KEYS } from 'lib/constants';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { getIsOperatorRunAutoSignoutEnabled } from 'lib/selectors/getIsOperatorRunAutoSignoutEnabled';
import { getLaunchDarklyFlags } from 'lib/selectors/getLaunchDarklyFlags';

const getShouldUseOpRunOverrideScope = createSelector(
  [
    getIsOperatorTrackingEnabled,
    getIsOperatorRunAutoSignoutEnabled,
    getLaunchDarklyFlags,
  ],
  (isOperatorTrackingEnabled, isOperatorRunAutoSignoutEnabled, flags) => {
    const workOrderFlowEnabled = flags
      ? flags[FLAG_KEYS.WORK_ORDER_FLOW_ENABLED]
      : false;

    const shouldUseOpRunOverrideScope = !!(
      isOperatorTrackingEnabled &&
      (!isOperatorRunAutoSignoutEnabled || workOrderFlowEnabled)
    );
    return shouldUseOpRunOverrideScope;
  }
);

export { getShouldUseOpRunOverrideScope };
