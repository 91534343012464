import { createSelector } from 'reselect';
import { getSortedActivities } from 'lib/selectors/getSortedActivities';
import { getLatestActivitySetRef } from 'lib/selectors/getLatestActivitySetRef';

const defaultLatestActivities = [];

export const getLatestActivitySetActivities = createSelector(
  [getLatestActivitySetRef, getSortedActivities],
  (latestActivitySetRef, sortedActivities) => {
    if (
      !latestActivitySetRef ||
      (sortedActivities && !sortedActivities.length)
    ) {
      return defaultLatestActivities;
    }
    return sortedActivities.filter((a) => {
      return a.activitySetRef === latestActivitySetRef;
    });
  }
);
