import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { DurationTicker } from 'components/DurationTicker';
import {
  DataContainer,
  GroupDataContainer,
  DataRow,
  EachRowData,
  DataTitle,
  DisplayData,
  CheckboxContainer,
  CheckboxText,
} from './styled';

const ProductionActivityReview = ({
  jobName,
  productionRejectedParts,
  productionPartsGoal,
  productionPartsMade,
  adjustedProductionStartDate,
  productionEndDateOverride,
  setCreateNewProductionRun,
  createNewProductionRun,
}) => {
  const { t } = useTranslation();
  const partPerformancePositive =
    productionPartsMade - productionPartsGoal >= 0;

  return (
    <>
      <DataContainer>
        <GroupDataContainer>
          <DataRow>
            <EachRowData>
              <DataTitle>{t('Operation')}</DataTitle>
              <DisplayData>{jobName}</DisplayData>
            </EachRowData>
            <EachRowData>
              <DataTitle>{t('Elapsed')}</DataTitle>
              <DisplayData>
                <DurationTicker
                  startDate={adjustedProductionStartDate}
                  endDateOverride={productionEndDateOverride}
                />
              </DisplayData>
            </EachRowData>
            <EachRowData>
              <DataTitle>{t('Rejected Parts')}</DataTitle>
              <DisplayData>{productionRejectedParts}</DisplayData>
            </EachRowData>
          </DataRow>

          <DataRow>
            <EachRowData>
              <DataTitle>{t('Parts Expected')}</DataTitle>
              <DisplayData>{productionPartsGoal || '-'}</DisplayData>
            </EachRowData>
            <EachRowData>
              <DataTitle>{t('Good Parts')}</DataTitle>
              <DisplayData>{productionPartsMade}</DisplayData>
            </EachRowData>
            <EachRowData>
              <DataTitle>
                {partPerformancePositive ? t('Parts Ahead') : t('Parts Behind')}
              </DataTitle>
              <DisplayData
                partPerformancePositive={
                  productionPartsGoal && partPerformancePositive
                }
                partPerformanceNegative={
                  productionPartsGoal && !partPerformancePositive
                }
              >
                {productionPartsGoal !== undefined
                  ? Math.abs(productionPartsGoal - productionPartsMade)
                  : '-'}
              </DisplayData>
            </EachRowData>
          </DataRow>
        </GroupDataContainer>
      </DataContainer>
      <CheckboxContainer>
        <Checkbox
          id="toggle-new-run-box"
          inputProps={{ checked: createNewProductionRun }}
          onChange={() => {
            return setCreateNewProductionRun(!createNewProductionRun);
          }}
        />
        <CheckboxText>
          {t('Create a new production run when starting this activity.')}
        </CheckboxText>
      </CheckboxContainer>
    </>
  );
};

ProductionActivityReview.propTypes = {
  jobName: PropTypes.string,
  productionRejectedParts: PropTypes.number,
  productionPartsGoal: PropTypes.number,
  productionPartsMade: PropTypes.number,
  adjustedProductionStartDate: PropTypes.string,
  productionEndDateOverride: PropTypes.string,
};

export { ProductionActivityReview };
