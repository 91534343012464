import React from 'react';
import { useSelector } from 'react-redux';
import { Wheel } from '@m12s/component-library';

import { getShouldUseOpRunOverrideScope } from 'lib/selectors/getShouldUseOpRunOverrideScope';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';

import { WheelContentStandard } from './WheelContent'
import { WheelContentOpRunOverride } from 'components/TabsCommon';


export const WheelComponent = () => {
  const { totalExpectedParts, expectedPartsNow } = useSelector(getScopePartsGoal);
  const shouldUseOpRunOverrideScope = useSelector(
    getShouldUseOpRunOverrideScope
  );
  const {
    failurePercent,
    warningPercent,
  } = useSelector(getLatestJob);
  const {
    partsMadePercent,
  } = useSelector(getScopePartsGoal);

  const hasOpenActivity = useSelector(getHasOpenActivity);
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const scopeNetPartsMade = useSelector(getScopeNetPartsMade);

  const appearance = React.useMemo(() => {
    switch (true) {
      case !hasOpenActivity:
        return 'empty';
      case openActivitySetHasNoStandards:
        return 'noStandards';
      case (!expectedPartsNow && !partsMadePercent) ||
        arePartCountBucketsLoading:
        return 'empty';
      case partsMadePercent < failurePercent:
        return 'failure';
      case partsMadePercent < warningPercent:
        return 'warning';
      case partsMadePercent >= warningPercent:
        return 'positive';
      default:
        return 'empty';
    }
  }, [
    hasOpenActivity,
    expectedPartsNow,
    partsMadePercent,
    failurePercent,
    warningPercent,
    arePartCountBucketsLoading,
    openActivitySetHasNoStandards,
  ]);

  return (
    <Wheel
      goal={
        (totalExpectedParts && expectedPartsNow / totalExpectedParts) *
          100
      }
      shape="rounded"
      value={
        totalExpectedParts &&
          (scopeNetPartsMade / totalExpectedParts) * 100
      }
      appearance={appearance}
      fillMode="full"
      showProgress={!shouldUseOpRunOverrideScope}
      withMarkers={!shouldUseOpRunOverrideScope}
      Contents={
        shouldUseOpRunOverrideScope ? (
          <WheelContentOpRunOverride
            appearance={appearance}
            fillMode="full"
          />
        ) : (
            <WheelContentStandard />
          )
      }
    />
  )
}
