import { createSelector } from 'reselect';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';

const getHasOpenActivitySet = createSelector(
  [getLatestActivitySet],
  (latestActivitySet) => {
    return !!(latestActivitySet && latestActivitySet.closedAt === null);
  }
);

export { getHasOpenActivitySet };
