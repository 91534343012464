import { createSelector } from 'reselect';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import {
  getProgramNameData,
  getIsProgramNameDataLoading,
} from 'lib/selectors/getProgramNameData';
import { getJobRun } from 'lib/selectors/getJobRun';
import _get from 'lodash/get';

const getProgramName = createSelector(
  [
    getAreActivitiesEnabled,
    getIsProgramNameDataLoading,
    getProgramNameData,
    getJobRun,
  ],
  (areActivitiesEnabled, isProgramNameDataLoading, programNameData, jobRun) => {
    if (areActivitiesEnabled) {
      if (isProgramNameDataLoading) {
        return null;
      }
      return _get(programNameData, ['value'], null);
    }
    if (!jobRun) {
      return null;
    }
    return jobRun.programName;
  }
);

export { getProgramName };
