import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import session from 'lib/api/links/session';

function isAuthenticated() {
  return !!session.getApiKey();
}

const UnauthenticatedRoute = ({ component, ...props }) => {
  return !isAuthenticated() ? (
    <Route {...props} component={component} />
  ) : (
    <Redirect to="/" />
  );
};

export { UnauthenticatedRoute };
