import { useState } from 'react';
import _get from 'lodash/get';
import { rawRequest } from 'lib/utils/request';
import { useTranslation } from 'react-i18next';
import { throwStatusToast } from 'lib/utils/toast';
import { useInterval } from 'lib/hooks';

const getStatusType = (status) => {
  switch (status) {
    case 'operational':
      return 'primary';
    case 'major_outage':
      return 'error';
    case 'degraded_performance':
    case 'partial_outage':
    default:
      return 'warning';
  }
};

const useStatusPage = () => {
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState(null);

  useInterval(
    () => {
      pollStatusPage();
    },
    5 * 60 * 1000,
    true
  );

  const pollStatusPage = async () => {
    try {
      const response = await rawRequest(
        `https://status.machinemetrics.com/api/v2/components.json`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
        false
      );
      const components = _get(response, 'components', []);
      const opDash = components.find((component) => {
        return component.id === '9c81s2426n04';
      });
      const incomingStatus = opDash.status;

      if (incomingStatus) {
        // If currentstatus exists, then any change should be reported on
        // Or if no currentstatus (app just loaded) then only non-operational statuses should be reported on
        if (
          (currentStatus && incomingStatus !== currentStatus) ||
          (!currentStatus && incomingStatus !== 'operational')
        ) {
          throwStatusToast(
            t(`status_page_${incomingStatus}`),
            getStatusType(incomingStatus)
          );
        }
        setCurrentStatus(incomingStatus);
      }
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
    }
  };
};

export { useStatusPage };
