import styled from 'styled-components';
import { Button } from '@m12s/component-library';

import { H5 } from 'components/Text';

const Title = styled(H5)`
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
`;

const IconWrapper = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

const ModalButton = styled(Button)`
  height: 3rem;
`;

export { ModalButton as Button, Title, IconWrapper };
