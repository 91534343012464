import React, { useCallback, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

import {
  CREATE_PART_ADJUSTMENT,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
} from 'lib/api/mutations';
import Routes from 'lib/utils/routes';
import { SwitchActivityTypeReview } from 'components/SwitchActivityTypeReview';
import { throwSuccessToast, throwErrorToast } from 'lib/utils/toast';
import {
  actionOptimisticActivityCreate,
  actionCanonicalActivityCreate,
  actionCanonicalActivityDelete,
  actionOptimisticActivitySwitch,
  actionCanonicalActivitySwitch,
  actionCanonicalActivityUnswitch,
  actionCreatePartAdjustmentSuccess,
  actionOptimisticActivityUpdate,
  actionCanonicalActivityUpdate,
} from 'lib/actions';
import { DefaultLoading } from 'components/Loading';
import ErrorPage from 'pages/Error';
import MainLayout from 'components/Layouts/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import useStartNewProductionRun from 'components/SwitchActivityTypeReview/useStartNewProductionRun';

import { getMachine } from 'lib/selectors/getMachine';
import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import { TEMP_REFS } from 'lib/constants';
import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getIsRunningUntaggedOperation } from 'lib/selectors/getIsRunningUntaggedOperation';
import { getActiveSetupActivityTypeRefs } from 'lib/selectors/getActiveSetupActivityTypeRefs';
import { getActiveProductionActivityTypeRefs } from 'lib/selectors/getActiveProductionActivityTypeRefs';
import { useCreateActivityRequestParams } from 'lib/hooks/useCreateActivityRequestParams';
import { getNetSetupParts } from 'lib/selectors/getSetupParts';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getCheckIsSetupActivityTypeRef } from 'lib/selectors/getCheckIsSetupActivityTypeRef';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { MultiStep } from './MultiStep';

const { ACTIVITY_REF: optimisticActivityRef } = TEMP_REFS;

const initialState = {
  parts: 0,
  note: '',
  activityTypeRef: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PARTS':
      return { ...state, parts: action.parts };
    case 'SET_NOTE':
      return { ...state, note: action.note };
    case 'SET_ACTIVITY_TYPE':
      return { ...state, activityTypeRef: action.activityTypeRef };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

const SwitchActivities = ({ location }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const startNewProductionRun = useStartNewProductionRun();

  const machine = useSelector(getMachine);
  const latestActivity = useSelector(getLatestActivity);
  const checkIsSetupActivityTypeRef = useSelector(
    getCheckIsSetupActivityTypeRef
  );
  const isRunningUntaggedOp = useSelector(getIsRunningUntaggedOperation);
  const jobNameRaw = useSelector(getLatestJobName);
  const jobName = isRunningUntaggedOp ? t(jobNameRaw) : jobNameRaw;
  const hasOpenActivity = useSelector(getHasOpenActivity);

  const inSetup = checkIsSetupActivityTypeRef(latestActivity.activityTypeRef);

  const activityTypes = useSelector(getActivityTypes);
  const job = useSelector(getLatestJob);
  const isPaused = useSelector(getIsPaused);
  const wantToPause = location?.state?.controlAction === 'pause';
  const netSetupParts = useSelector(getNetSetupParts);
  const createActivityRequestParams = useCreateActivityRequestParams();
  const setupActivityTypeRef = useSelector(getActiveSetupActivityTypeRefs);
  const productionActivityTypeRef = useSelector(
    getActiveProductionActivityTypeRefs
  );

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    parts: netSetupParts,
  });

  const storeDispatch = useDispatch();

  const latestActivityRef = latestActivity.activityRef;

  const lookupActivityTypeName = useCallback(
    (activityTypeRef) => {
      const activityType = activityTypes.find((type) => {
        return type.activityTypeRef === activityTypeRef;
      });
      return _get(activityType, 'name', 'Unknown Activity');
    },
    [activityTypes]
  );

  const [requestSwitchActivity] = useMutation(CREATE_ACTIVITY, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          createActivityResponse: { activity },
        },
      }
    ) => {
      storeDispatch(
        isPaused
          ? actionOptimisticActivityCreate({ activity })
          : actionOptimisticActivitySwitch({
              optimisticActivity: activity,
              latestActivityRef,
            })
      );
      history.push(Routes.machineIdPath(machine.id));
    },
    onCompleted: ({ createActivityResponse: { activity } }) => {
      storeDispatch(
        isPaused
          ? actionCanonicalActivityCreate({
              activity,
              optimisticActivityRef,
            })
          : actionCanonicalActivitySwitch({
              activity,
              optimisticActivityRef,
              latestActivityRef,
            })
      );

      const activityTypeName = lookupActivityTypeName(activity.activityTypeRef);
      throwSuccessToast(t('Switched Activity:', { activityTypeName }));
    },
    onError: () => {
      storeDispatch(
        isPaused
          ? actionCanonicalActivityDelete({
              activityRef: optimisticActivityRef,
            })
          : actionCanonicalActivityUnswitch({
              activityRef: optimisticActivityRef,
              latestActivityRef,
            })
      );
      history.push(Routes.machineIdPath(machine.id));
      throwErrorToast(t('Could not switch activity'));
    },
  });

  const [requestUpdateActivity] = useMutation(UPDATE_ACTIVITY, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          updateActivityResponse: { activity },
        },
      }
    ) => {
      storeDispatch(actionOptimisticActivityUpdate({ activity }));
      history.push(Routes.machineIdPath(machine.id));
    },
    onCompleted: ({ updateActivityResponse: { activity } }) => {
      throwSuccessToast(t('Successfully Paused'));
      storeDispatch(actionCanonicalActivityUpdate({ activity }));
    },
    onError: () => {
      storeDispatch(
        actionCanonicalActivityUpdate({
          activity: {
            activityRef: latestActivity?.activityRef,
            end: null,
          },
        })
      );
      history.push(Routes.machineIdPath(machine.id));
      throwErrorToast(t('Failed to Pause'));
    },
  });

  const [
    createPartAdjustment,
    { loading: adjustmentLoading, error: adjustmentError },
  ] = useMutation(CREATE_PART_ADJUSTMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ createPartAdjustmentResponse }) => {
      storeDispatch(
        actionCreatePartAdjustmentSuccess(createPartAdjustmentResponse)
      );
      if (!wantToPause) {
        return requestSwitchActivity(
          createActivityRequestParams(state.activityTypeRef)
        );
      }
      return null;
    },
  });

  const adjustParts = (delta = 0, note) => {
    return createPartAdjustment({
      variables: {
        input: {
          count: delta,
          message: note,
          machine_id: machine.id,
        },
      },
    });
  };

  const submit = (activityTypeRefOverride, workOrderId) => {
    if (activityTypeRefOverride) {
      state.activityTypeRef = activityTypeRefOverride;
    }
    const { parts, note, activityTypeRef } = state;
    const delta = parts - netSetupParts || 0;

    if (delta !== 0) {
      return adjustParts(delta, note);
    }
    if (!hasOpenActivity && !isPaused) {
      return startNewProductionRun(
        activityTypeRefOverride || activityTypeRef,
        workOrderId
      );
    }
    return requestSwitchActivity(
      createActivityRequestParams(activityTypeRefOverride || activityTypeRef)
    );
  };

  const handleOnSelectActivityType = (activityTypeRef) => {
    dispatch({ type: 'SET_ACTIVITY_TYPE', activityTypeRef });
  };

  const loading = adjustmentLoading;
  const error = adjustmentError;

  if (loading) return <DefaultLoading />;
  if (error) return <ErrorPage error={error} />;

  // for less than/only has 2 activity type, no need to select activity type, go directly to review page
  if ((activityTypes.length <= 2 || wantToPause) && hasOpenActivity) {
    return (
      <SwitchActivityTypeReview
        dispatch={dispatch}
        formState={state}
        jobName={jobName}
        machine={machine}
        submit={submit}
        handleOnSelectActivityType={handleOnSelectActivityType}
        inSetup={inSetup}
        job={job}
        // currently in setup / isPaused(only setup has paused) => enter production, vice versa
        activityTypeRef={
          inSetup ? productionActivityTypeRef[0] : setupActivityTypeRef[0]
        }
        wantToPause={wantToPause}
        requestUpdateActivity={wantToPause ? requestUpdateActivity : undefined}
        adjustParts={adjustParts}
        startNewProductionRun={startNewProductionRun}
      />
    );
  }

  // have 3 or more activity type, user needs to select activity type first
  return (
    <MainLayout.Container direction="column">
      <MultiStep
        dispatch={dispatch}
        formState={state}
        jobName={jobName}
        machine={machine}
        submit={submit}
        handleOnSelectActivityType={handleOnSelectActivityType}
        inSetup={inSetup}
        job={job}
        startNewProductionRun={startNewProductionRun}
      />
    </MainLayout.Container>
  );
};

export default SwitchActivities;
