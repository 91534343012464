import _mapValues from 'lodash/mapValues';

/* Steps to update/add new locale/translation files:
 * ADDING A NEW TRANSLATION FILE
 * 1. Add the .json file in this folder.
 * 2. Add the locale key and name to LANGUAGE_NAMES object.
 *    Note: The .json is automatically imported using the key in LANGUAGE_NAMES
 * 3. Update localeList (internals/scripts/translation-audit.js:7) to include matching 2 char locale code.
 *
 * CHECKING FOR MISSING TRANSLATIONS
 * 1. In the development terminal for op dash run yarn translations:audit to list how many are missing per file
 * 2. For more detail on which specific lines are missing, run yarn translations:audit --show-missing-keys
 */

const LANGUAGE_NAMES = {
  en: 'English', // English
  sv: 'Svenska', // Swedish
  pt: 'Português', // Portuguese
  fr: 'Français', // French
  es: 'Español', // Spanish
  da: 'Dansk', // Danish
  de: 'Deutsch', // German
  zh_CN: '中文', // Chinese
  uk: 'Українська', // Ukrainian
  pl: 'Polski', // Polish
  ru: 'Русский', // Russian
};

const resources = _mapValues(LANGUAGE_NAMES, (value, key) => {
  return {
    /* eslint-disable global-require */
    translation: require(`./${key}.json`),
  };
});

export { resources, LANGUAGE_NAMES };
