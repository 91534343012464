import React from 'react';
import {
  CycleCountTab,
  PartCountTab,
  UtilizationTab,
  DowntimeTab,
  AlarmsTab,
  PartsGoalTab,
  CyclesTab,
  OEETab,
  CustomTab,
} from './Tabs';

export default function Tab({ tab }) {
  if (tab.url) {
    return <CustomTab tab={tab} />;
  }
  const tabIdentifier = tab.id;
  switch (tabIdentifier) {
    case 'cycleCount':
      return <CycleCountTab />;
    case 'partCount':
      return <PartCountTab />;
    case 'utilization':
      return <UtilizationTab />;
    case 'downtime':
      return <DowntimeTab />;
    case 'alarms':
      return <AlarmsTab />;
    case 'partsGoal':
      return <PartsGoalTab />;
    case 'cycleTime':
      return <CyclesTab />;
    case 'oee':
      return <OEETab />;
    default:
      /* eslint-disable no-console */
      console.error('Invalid Tab');
      return null;
  }
}
