import { createSelector } from 'reselect';
import { takeLatest } from 'lib/utils/date';
import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';
import { getMaxScopeQueryStart } from 'lib/selectors/getMaxScopeQueryStart';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getShiftEnd } from 'lib/selectors/getShiftEnd';
import { getHasActiveOperatorRun } from 'lib/selectors/getHasActiveOperatorRun';
import { getShouldUseOpRunOverrideScope } from 'lib/selectors/getShouldUseOpRunOverrideScope';
import { getIsInitialOperatorRunsPollLoading } from 'lib/selectors/getIsInitialOperatorRunsPollLoading';
import { getStartOfNextHour } from 'lib/selectors/getStartOfNextHour';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getIsInitialShiftPollLoading } from 'lib/selectors/getIsInitialShiftPollLoading';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';
import { getStartOfNextShopDay } from 'lib/selectors/getStartOfNextShopDay';

const defaultScope = {
  startDate: null,
  endDate: null,
};

const getUtilPageScope = createSelector(
  [
    getShouldUseOpRunOverrideScope,
    getIsInitialShiftPollLoading,
    getStartOfCurrentShopDay,
    getStartOfNextShopDay,
    getIsInitialOperatorRunsPollLoading,
    getLatestOperatorRun,
    getHasActiveOperatorRun,
    getMaxScopeQueryStart,
    getStartOfNextHour,
    checkIsLatestShiftActive,
    getShiftStart,
    getShiftEnd,
  ],
  (
    shouldUseOpRunOverrideScope,
    isInitialShiftPollLoading,
    startOfCurrentShopDay,
    startOfNextShopDay,
    isInitialOperatorRunsPollLoading,
    latestOperatorRun,
    hasActiveOperatorRun,
    maxScopeQueryStart,
    startOfNextHour,
    isLatestShiftActive,
    shiftStart,
    shiftEnd
  ) => {
    const haveScopeCalcDepsLoaded = !!(
      !isInitialShiftPollLoading &&
      startOfCurrentShopDay &&
      startOfNextShopDay &&
      maxScopeQueryStart &&
      (!shouldUseOpRunOverrideScope || !isInitialOperatorRunsPollLoading)
    );
    if (haveScopeCalcDepsLoaded) {
      if (shouldUseOpRunOverrideScope && hasActiveOperatorRun) {
        const operatorRunScope = {
          startDate: takeLatest(
            latestOperatorRun?.startAt || maxScopeQueryStart,
            maxScopeQueryStart
          ),
          endDate: startOfNextHour,
        };
        return operatorRunScope;
      }
      if (isLatestShiftActive) {
        const shiftScope = {
          startDate: takeLatest(shiftStart, maxScopeQueryStart),
          endDate: shiftEnd,
        };
        return shiftScope;
      }
      // if no active shift run, use shop day for util scope
      return {
        startDate: startOfCurrentShopDay,
        endDate: startOfNextShopDay,
      };
    }

    // subscriber is responsible for handling loading/invalid scope state
    // be sure to check for falsey vals and show loading, bail out, etc if
    // scope start and/or scope end are dependencies
    return defaultScope;
  }
);

export { getUtilPageScope };
