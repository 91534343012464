import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { now, toISO } from 'lib/utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, palette, LoadingCircle } from '@m12s/component-library';
import { farTicketAlt, spinner } from 'lib/icons';

import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { useMutation } from '@apollo/client';
import { CREATE_PART_ADJUSTMENT } from 'lib/api/mutations';
import { actionCreatePartAdjustmentSuccess } from 'lib/actions';
import { getMachine } from 'lib/selectors/getMachine';
import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getPartAdjustmentTypes } from 'lib/selectors/getPartAdjustmentTypes';

import useSubmitLaborTicket from './useSubmitLaborTicket';

import Card from './Card';
import { LaborTicketItem, ModalButton } from './styled';

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  gap: 0.5rem;

  > * {
    min-width: 38px;
  }
`;

const LaborTicketCard = ({ form, loading }) => {
  const dispatch = useDispatch();

  const latestActivityType = useSelector(getLatestActivityType);
  const partAdjustmentTypes = useSelector(getPartAdjustmentTypes);
  const latestJobName = useSelector(getLatestJobName);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const currentLaborTicket = useSelector(getCurrentLaborTicket);
  const machine = useSelector(getMachine);

  const [initialState, setInitialState] = useState({
    workOrderOp: currentWorkOrderOp,
    activityType: latestActivityType,
    jobName: latestJobName,
    laborTicket: currentLaborTicket,
  });

  useEffect(() => {
    setInitialState({
      workOrderOp: currentWorkOrderOp,
      activityType: latestActivityType,
      jobName: latestJobName,
      laborTicket: currentLaborTicket,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { workOrderOp, activityType, jobName, laborTicket } = initialState;

  const scopeStart = useSelector(getScopeStart);
  const operator = useSelector(getCurrentOperator);

  const workOrderId = _get(workOrderOp, 'workOrderId');
  const workOrder = _get(workOrderOp, 'workOrder');

  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);

  const partAdjustment = form.totalParts.value - laborTicketPartsMade;
  const rejectedParts = form.rejectedParts?.value || 0;
  const rejectReason = form.rejectReason?.value || 0;
  const goodParts = form.totalParts.value - rejectedParts;

  const nowISO = toISO(now().valueOf());

  const requestLaborTicketAction = useSubmitLaborTicket({ form });

  const [
    createPartAdjustment,
    { loading: loadingPartAdjustment },
  ] = useMutation(CREATE_PART_ADJUSTMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ createPartAdjustmentResponse }) => {
      dispatch(actionCreatePartAdjustmentSuccess(createPartAdjustmentResponse));
    },
  });

  const orderTotalParts =
    workOrderOp?.totalGoodParts + partAdjustment - rejectedParts;

  return (
    <Card
      header={
        <>
          <Icon icon={farTicketAlt} style={{ marginRight: '0.5rem' }} />
          Labor Ticket
        </>
      }
      body={
        <BodyContainer>
          <ColumnContainer>
            <LaborTicketItem
              label="Operator"
              value={_get(operator, 'name') || 'Not found'}
            />
            <LaborTicketItem label="Production order" value={workOrderId} />
            <Row>
              <LaborTicketItem label="Lot" value={workOrder?.lot} />
              <LaborTicketItem label="Split" value={workOrder?.split} />
              <LaborTicketItem label="Sub" value={workOrder?.sub} />
            </Row>
            <LaborTicketItem label="Part Operation" value={jobName} />
            <LaborTicketItem
              label="Order Progress"
              value={`${orderTotalParts?.toLocaleString() || '-'} / ${
                workOrderOp?.finishQuantity
              }`}
            />
          </ColumnContainer>

          <ColumnContainer>
            <LaborTicketItem label="Total Good Parts" value={goodParts} />
            <LaborTicketItem
              label="Total Rejects"
              value={form.rejectedParts.value}
            />
            <LaborTicketItem
              label="Started"
              value={
                laborTicket?.clockIn
                  ? `${moment(laborTicket.clockIn).format('L')} ${moment(
                      laborTicket.clockIn
                    ).format('LT')}`
                  : `${moment(scopeStart).format('L')} ${moment(
                      scopeStart
                    ).format('LT')}`
              }
            />
            <LaborTicketItem
              label="Ended"
              value={`Today ${moment(nowISO).format('LT')}`}
            />
            <LaborTicketItem label="Activity" value={activityType.name} />
          </ColumnContainer>
        </BodyContainer>
      }
      footer={
        <ModalButton
          customStyles={{
            background: palette.Indigo600,
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
          hoverStyles={{
            background: palette.Indigo700,
          }}
          disabled={
            loading || (!rejectReason && rejectedParts) || loadingPartAdjustment
          }
          // eslint-disable-next-line consistent-return
          onClick={async () => {
            if (!loading) {
              if (partAdjustment) {
                await createPartAdjustment({
                  variables: {
                    input: {
                      count: partAdjustment,
                      machine_id: machine.id,
                    },
                  },
                });
              }
              if (rejectedParts) {
                const partAdjustmentTypeId = rejectReason.rejectReasonRef;
                const partAdjustmentType = partAdjustmentTypes.find((type) => {
                  return type.id === rejectReason.rejectReasonRef;
                });

                const rejectBehavior =
                  partAdjustmentType.rejectBehavior === 'operator'
                    ? 'scrap'
                    : partAdjustmentType.rejectBehavior;

                await createPartAdjustment({
                  variables: {
                    input: {
                      scrapcount: rejectedParts,
                      machine_id: machine.id,
                      count: 0,
                      reject_behavior: rejectBehavior,
                      category_id: partAdjustmentTypeId,
                      message: null,
                    },
                  },
                });
              }

              return requestLaborTicketAction();
            }
          }}
        >
          {loading && <LoadingCircle icon={spinner} size="1.25rem" />} Submit to
          ERP
        </ModalButton>
      }
    />
  );
};

LaborTicketCard.propTypes = {
  form: PropTypes.object,
  loading: PropTypes.bool,
};

export default LaborTicketCard;
