import { createSelector } from 'reselect';

import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';

const getScopeNetCyclesFinished = createSelector(
  [getScopeNetPartsMade, getScopeReadTimePCM],
  (scopeNetPartsMade, scopeReadTimePCM) => {
    return Math.floor(scopeNetPartsMade / scopeReadTimePCM);
  }
);

export { getScopeNetCyclesFinished };
