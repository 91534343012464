import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const Display = WithProps(styled.span`
  ${includeBaseStyle}
  font-size: 3rem;
  line-height: 3.5rem;
`);

Display.defaultProps = {
  ...Display.defaultProps,
  defaultWeight: 'bold',
};

export { Display };
