import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { formatValue } from 'lib/utils/text';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { LoadingStatTabLabel, LoadingStatTabSecondaryLabel } from '../styled';

export const PartsGoalTab = () => {
  const { t } = useTranslation();
  const { partsMadePercent } = useSelector(getScopePartsGoal);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  return (
    <StatTab
      id="parts-goal-tab"
      value="partsGoal"
      label={
        <LoadingStatTabLabel loading={arePartCountBucketsLoading}>
          {_startCase(t('parts goal'))}
        </LoadingStatTabLabel>
      }
      secondaryLabel={
        arePartCountBucketsLoading ? (
          <LoadingStatTabSecondaryLabel />
        ) : (
          formatValue(partsMadePercent, (value) => {
            return `${value}%`;
          })
        )
      }
    />
  );
};
