import React from 'react';

import {
  Root,
  TopLeftContent,
  WheelWrapper,
  LoginLandscape,
  LoginPortrait,
  TopRightContent,
  BottomLeftContent,
  BottomRightContent,
} from './styled';

const WheelLayout = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  wheel,
  login,
  id,
}) => {
  return (
    <Root id={id}>
      <TopLeftContent id="top-left">{topLeft}</TopLeftContent>
      <TopRightContent id="top-right">{topRight}</TopRightContent>
      <WheelWrapper>
        {wheel}
        <LoginLandscape>{login}</LoginLandscape>
      </WheelWrapper>
      <LoginPortrait>{login}</LoginPortrait>
      <BottomLeftContent id="bottom-left">{bottomLeft}</BottomLeftContent>
      <BottomRightContent id="bottom-right">{bottomRight}</BottomRightContent>
    </Root>
  );
};

export { WheelLayout };
