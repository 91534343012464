/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true  }] */

import { REST_URI } from 'lib/appConfig';
import EventEmitter from 'lib/utils/EventEmitter';

import { SocketChannel } from './SocketChannel';

const cmd = {
  OBSERVE: 'observe',
};

const event = {
  CURRENT_ANNOTATIONS: 'current_annotations',
  LOAD_RANGE_ANNOTATIONS: 'load_range_annotations',
};

const ENDPOINT = `${REST_URI}/api/annotation`;

class AnnotationsSocket {
  constructor(machineId, start, end) {
    this.emitter = new EventEmitter();
    this.socket = new SocketChannel({
      url: ENDPOINT,
      joinParams: { machineId },
      initCmd: cmd.OBSERVE,
      initParams: {
        start,
        end,
        includeSetup: true,
      },
    });

    this.socket.onError(this._handleError);
    this.socket.on(event.CURRENT_ANNOTATIONS, this._onData);
    this.socket.on(event.LOAD_RANGE_ANNOTATIONS, this._onData);
  }

  subscribe(onData) {
    this.emitter.on('changed', onData);
    this.emitter.emit('changed', this.data);
  }

  unsubscribe(onData) {
    this.emitter.off('changed', onData);
    this.socket.disconnect();
  }

  _onData = (data) => {
    this.data = data;
    this.emitter.emit('changed', this.data);
  };

  _handleError(e) {
    /* eslint-disable no-console */
    console.error(e);
    this.emitter.emit('error', e);
  }
}

export { AnnotationsSocket };
