import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { Small } from 'components/Text';

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    border: '1px solid #E2E8F0',
    borderRadius: '0.25rem',
    minHeight: '30rem',
    maxHeight: '100vh',
    padding: 0,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45rem',
    maxWidth: '100vw',
    boxShadow:
      '-0.0625rem -0.0625rem 0.25rem rgba(74, 85, 104, 0.05), 0 0.0625rem 0.5rem rgba(74, 85, 104, 0.12), 0 0.0625rem 0.25rem rgba(74, 85, 104, 0.08)',
  },
};

const Item = styled.div`
  color: ${palette.Grey600};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
  padding-top: 0.5rem;
  border: solid 1px ${palette.Grey300};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;

  svg {
    color: ${palette.Grey400};
  }

  ${({ selected }) => {
    return (
      selected &&
      `
      color: ${palette.Purple500};
      border-color: ${palette.Purple500};
      background-color: ${palette.Purple100};
      svg {
        color: ${palette.Purple500};
      }
    `
    );
  }};
`;

const List = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
`;

const StyledSmall = styled(Small)`
  color: ${palette.Purple500};
  font-weight: 600;
  line-height: 2rem;
`;

const Content = styled.div`
  margin: 1rem 2rem;
`;

export { customStyles, Item, List, StyledSmall as Small, Content };
