import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { TIMESYNC } from 'lib/api/mutations';
import { create } from 'timesync/dist/timesync.min';

export const ts = create({
  peers: ['/timesync'],
  interval: 10 * 60 * 1000,
  repeat: 2,
});

const useTimesync = () => {
  const [postToTimesync] = useMutation(TIMESYNC, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ timesyncData }) => {
      ts.receive('/timesync', timesyncData);
    },
  });

  useEffect(() => {
    ts.send = (to, data) => {
      return postToTimesync({
        variables: {
          input: data,
        },
      });
    };
  }, [postToTimesync]);
};

export { useTimesync };
