import React, { useMemo } from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import _maxBy from 'lodash/maxBy';
import _startCase from 'lodash/startCase';

import { palette, useTheme } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formatCycleTime, formatTimeWithSeconds } from 'lib/utils/date';
import { getPartCountEvents } from 'lib/selectors/getPartCountEvents';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getJobRunASWrapper } from 'lib/selectors/getJobRunASWrapper';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { getScopePartCountEvents } from 'lib/selectors/getScopePartCountEvents';
import { getScopeFirstPartTimestamp } from 'lib/selectors/getScopeFirstPartTimestamp';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';

import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';
import useRemPx from 'lib/hooks/useRemPx';
import { useCycleTime } from './useCycleTime';
import { Chart } from './Chart';

const chartLabels = [
  ['L1', 'Last'],
  ['L2', 'Second to Last'],
  ['L3', 'Third to Last'],
  ['LAvg', 'Last 3 Average'],
  ['Avg', 'Average'],
];

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const durationTicks = [
  1,
  2,
  5,
  10,
  20,
  30,
  MINUTE,
  2 * MINUTE,
  5 * MINUTE,
  10 * MINUTE,
  20 * MINUTE,
  30 * MINUTE,
  HOUR,
  2 * HOUR,
  3 * HOUR,
  5 * HOUR,
  12 * HOUR,
  DAY,
];

const getDurationChartInterval = (max, micro) => {
  const ticksPerChart = micro ? 5 : 10;
  return durationTicks.find((tick) => {
    return max < tick * ticksPerChart;
  });
};

const CycleTimeChart = ({ micro }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const remPx = useRemPx();
  const partCountEvents = useSelector(getPartCountEvents);
  const scopePartCountEvents = useSelector(getScopePartCountEvents);
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const jobRunASWrapper = useSelector(getJobRunASWrapper);
  const scopeFirstPartTimestamp = useSelector(getScopeFirstPartTimestamp);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );

  const { expectedUnitDuration, actualParts } = areActivitiesEnabled
    ? latestActivitySet
    : jobRunASWrapper;
  const {
    average,
    lastFewAvg,
    lastFewStarts,
    lastFew,
    expected,
  } = useCycleTime({
    scopeFirstPartTimestamp,
    partCountEvents,
    scopePartCountEvents,
    expectedUnitDuration,
    actualParts,
  });
  const hasOpenJobRun = useSelector(getHasOpenJobRun);
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const shouldShowExpected =
    (hasOpenActivity || hasOpenJobRun) && !openActivitySetHasNoStandards;
  const labels = useMemo(() => {
    return chartLabels.map((item) => {
      return item[micro ? 0 : 1];
    });
  }, [micro]);

  const scopeLabel = !micro && shouldShowExpected ? 'Operation ' : '';

  const timestamps = [];
  const values = [];

  lastFew.forEach((cycleTime, index) => {
    const cycleStart = formatTimeWithSeconds(lastFewStarts[index]);
    const label = micro ? labels[index] : cycleStart;
    timestamps.unshift(label);
    values.unshift({ value: cycleTime });
  });

  if (lastFewAvg) {
    timestamps.unshift(_startCase(t(labels[3])));
    values.unshift({
      value: lastFewAvg,
      itemStyle: {
        color: palette.Purple300,
      },
    });
  }

  if (average) {
    timestamps.unshift(
      _startCase(t(`${scopeLabel}${labels[4]}`.toLowerCase()))
    );
    values.unshift({
      value: average,
      itemStyle: {
        color: palette.Purple400,
      },
    });
  }

  // Empty bars, required for 'expected' line to show when no data on graph
  if (!lastFew.length) {
    timestamps.push(...['', '']);
    values.push(...[{ value: '' }, { value: '' }]);
  }

  const shallowValuesClone = [...values];

  if (shouldShowExpected) {
    // only use expected as factor in graph width when applicable
    shallowValuesClone.push({ value: expected });
  }

  const maxValue = _get(_maxBy(shallowValuesClone, 'value'), 'value', null);

  const tickInterval = useMemo(() => {
    return getDurationChartInterval(maxValue, micro);
  }, [maxValue, micro]);

  const loadingOption = {
    xAxis: {
      data: ['-', '-', '-', '-', '-', '-', '-'],
      axisLabel: {
        margin: remPx / 2,
      },
    },
    yAxis: {
      data: ['Avg', 'L Avg', 'L3', 'L2', 'L1'],
      axisLabel: {
        margin: remPx * 1.5,
      },
    },
    grid: {
      backgroundColor: theme.colors.loader.textPlaceholder,
    },
  };

  const option = {
    xAxis: {
      max: maxValue * 1.2,
      type: 'value',
      interval: tickInterval,
      axisLabel: {
        formatter: (value) => {
          return moment
            .duration(value, 'seconds')
            .format('h[h] m[m] s[s]', { trim: 'both' });
        },
        showMinLabel: false,
        showMaxLabel: false,
        margin: remPx / 2,
        fontSize: micro ? remPx / 1.75 : remPx / 1.25,
      },
      minorTick: {
        show: true,
        lineStyle: {
          color: palette.Grey300,
        },
      },
    },
    yAxis: {
      data: timestamps,
      axisLabel: {
        margin: remPx / 2,
        align: 'right',
        fontSize: micro ? remPx / 1.75 : remPx / 1.25,
        fontWeight: micro ? 500 : 600,
      },
    },
    series: [
      {
        type: 'line',
        animation: false,
        lineStyle: {
          color: palette.Teal400,
          type: 'dotted',
        },
        label: false,
        itemStyle: false,
        data: expected &&
          shouldShowExpected && [
            {
              value: [expected, -1],
            },
            {
              value: [expected, 7],
            },
          ],
        showSymbol: false,
        z: 1,
      },
      {
        type: 'bar',
        animation: false,
        data: values,
        label: {
          position: 'right',
          formatter: ({ value }) => {
            return formatCycleTime(value);
          },
          fontWeight: 600,
          fontSize: micro ? remPx / 2 : remPx / 1.25,
          color: theme.colors.text.default,
          backgroundColor: '#fff',
          show: !micro,
        },
        barWidth: micro ? remPx / 2 : remPx,
        z: 2,
      },
    ],
    itemStyle: {
      color: palette.Grey300,
    },
  };

  return (
    <Chart
      option={arePartCountBucketsLoading ? loadingOption : option}
      loading={arePartCountBucketsLoading}
    />
  );
};

export { CycleTimeChart };
