import React, { useMemo } from 'react';
import moment from 'moment';
import _uniqWith from 'lodash/uniqWith';
import { palette } from '@m12s/component-library';
import { formatTime, toISO } from 'lib/utils/date';

import { useSelector } from 'react-redux';
import { getScopePartAdjustmentData } from 'lib/selectors/getScopePartAdjustmentData';

import { getScopeNetPartCountHourlies } from 'lib/selectors/getScopeNetPartCountHourlies';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeEnd } from 'lib/selectors/getScopeEnd';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getAdjustedPartCountBuckets } from 'lib/selectors/getAdjustedPartCountBuckets';
import useRemPx from 'lib/hooks/useRemPx';
import { Chart } from './Chart';

const PartsGoalChart = () => {
  const remPx = useRemPx();
  const { totalExpectedParts } = useSelector(getScopePartsGoal);
  const data = useSelector(getScopeNetPartCountHourlies);
  const adjustedPartCountBuckets = useSelector(getAdjustedPartCountBuckets);
  const scopeStart = useSelector(getScopeStart);
  const scopeEnd = useSelector(getScopeEnd);
  const { scopeNetPartAdjustments } = useSelector(getScopePartAdjustmentData);

  const chartData = useMemo(() => {
    let totalParts = 0;

    const chartTimes = Object.keys(adjustedPartCountBuckets).sort();
    const chartBuckets = chartTimes.map((interval) => {
      const timestamp = chartTimes[0] < scopeStart ? scopeStart : interval;
      const chartBucket = {
        timestamp,
        value: adjustedPartCountBuckets[interval],
      };
      return chartBucket;
    });

    const scopeNetPartAdjustmentsAsEvents = scopeNetPartAdjustments.map(
      ({ adjustmenttime, scrapcount, count }) => {
        return {
          timestamp: adjustmenttime,
          value: count - scrapcount,
        };
      }
    );

    const graphPoints =
      chartBuckets.length > 0
        ? _uniqWith(
            chartBuckets
              .concat(scopeNetPartAdjustmentsAsEvents)
              .sort((a, b) => {
                return a.timestamp.localeCompare(b.timestamp);
              })
              /* eslint-disable no-return-assign */
              /* not returning an assignment, returning an object that contains assignment */
              .map(({ timestamp, value }) => {
                return {
                  value: [timestamp, (totalParts += value)],
                };
              }),
            (point, point2) => {
              return point.value[0] === point2.value[0];
            }
          )
        : [];

    return [
      {
        value: [scopeStart, 0],
      },
      ...graphPoints,
      {
        value: [toISO(), totalParts],
      },
    ];
  }, [adjustedPartCountBuckets, scopeNetPartAdjustments, scopeStart]);

  if (!scopeStart || !scopeEnd) {
    // prevent renders for invalid scope
    return null;
  }

  const option = {
    xAxis: {
      type: 'time',
      axisLabel: {
        show: true,
        data: data.map(({ time }) => {
          return moment(time).valueOf();
        }),
        formatter: (label) => {
          return formatTime(label);
        },
        fontSize: remPx / 1.25,
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        interval: 0,
        lineStyle: {
          color: palette.Grey200,
        },
      },
      showSymbol: false,
    },
    yAxis: {
      axisLabel: {
        margin: remPx * 1.5,
        formatter: (value) => {
          return value || '';
        },
        fontSize: remPx / 1.25,
      },
    },
    series: [
      {
        type: 'line',
        animation: false,
        lineStyle: {
          color: '#ddd',
          type: 'dashed',
        },
        itemStyle: false,
        data: [
          {
            value: [scopeStart, totalExpectedParts],
          },
          {
            value: [scopeEnd, totalExpectedParts],
          },
        ],
        label: false,
        showSymbol: false,
      },
      {
        type: 'line',
        step: null,
        data: chartData,
        label: false,
        showSymbol: false,
      },
    ],
    grid: {
      right: '30px',
    },
  };

  return <Chart option={option} />;
};

export { PartsGoalChart };
