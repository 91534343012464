import fetch from 'isomorphic-fetch';
import { ApolloLink, split, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GRAPHQL_URI } from 'lib/appConfig';
import { CloseCode } from 'graphql-ws';
import { WebSocketLink } from './websocketlink';
import { getAuthHeaders } from './helpers';

import session from './session';

const fetchAuthHeaders = async () => {
  const token = await session.getJwt();
  return { ...getAuthHeaders(token), 'X-Hasura-Role': 'operator' };
};

const authLink = setContext(async (_, { headers }) => {
  const authHeaders = await fetchAuthHeaders();
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const httpLink = new HttpLink({
  uri: GRAPHQL_URI,
  fetch,
});

let shouldRefreshToken = false;
// the socket close timeout due to token expiry
let tokenExpiryTimeout = null;

let restartRequested = false;
let restart = () => {
  restartRequested = true;
};
/* eslint-disable import/no-mutable-exports */
export let socketConnection = null;

export const wsLink = new WebSocketLink({
  url: GRAPHQL_URI.replace('https', 'wss'),
  retryAttempts: Infinity,
  keepAlive: 10000,
  retryWait: async function waitForRetry() {
    await new Promise((resolve) => {
      return setTimeout(resolve, 1000 + Math.random() * 4000);
    });
  },
  connectionParams: async () => {
    if (shouldRefreshToken) {
      session.clearJwt();
      shouldRefreshToken = false;
    }
    const authHeaders = await fetchAuthHeaders();
    return { headers: authHeaders };
  },
  on: {
    /* eslint-disable no-console */
    error: (err) => {
      console.error(err);
    },
    opened: (socket) => {
      socketConnection = socket;

      restart = () => {
        if (socket.readyState === WebSocket.OPEN) {
          // if the socket is still open for the restart, do the restart
          socket.close(4205, 'Client Restart');
        } else {
          // otherwise the socket might've closed, indicate that you want
          // a restart on the next opened event
          restartRequested = true;
        }
      };

      if (restartRequested) {
        restartRequested = false;
        restart();
      }
    },
    connected: (socket) => {
      clearTimeout(tokenExpiryTimeout);

      tokenExpiryTimeout = setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          return socket.close(CloseCode.Forbidden, 'Forbidden');
        }
        return null;
      }, 270000);
    },
    closed: (event) => {
      if (event.code === CloseCode.Forbidden) {
        shouldRefreshToken = true;
      }
    },
  },
});

export default split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  ApolloLink.from([authLink, httpLink])
);
