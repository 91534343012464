import { createSelector } from 'reselect';
import { HEAVY_UPDATE_INTERVAL } from 'lib/constants';

const getUpdate = (store) => {
  return store.update;
};

const getHeavyUpdate = createSelector([getUpdate], (update) => {
  return Math.floor(update / HEAVY_UPDATE_INTERVAL);
});

export { getUpdate, getHeavyUpdate };
