import { useEffect, useContext } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TickerContext } from 'components/TickerProvider';
import { diff } from 'lib/utils/date';
import { getCompany } from 'lib/selectors/getCompany';
import { getMachine } from 'lib/selectors/getMachine';
import { getPopup } from 'lib/selectors/getPopup';
import {
  actionClearDowntimeFlyup,
  actionClosePopup,
  actionTriggerDowntimeFlyup,
  actionSetPopupStack,
} from 'lib/actions';
import { getActiveUncategorizedInterval } from 'lib/selectors/getActiveUncategorizedInterval';
import { getJobStatus } from 'lib/selectors/getJobStatus';
import Routes from 'lib/utils/routes';
import { getUser } from 'lib/selectors/getUser';
import { getAreAnnotationsLoading } from 'lib/selectors/getAnnotations';
import { getDowntimeFlyup } from 'lib/selectors/getDowntimeFlyup';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getIsLoadingJobRunASWrapper } from 'lib/selectors/getIsLoadingJobRunASWrapper';
import { JOB_STATUSES } from 'lib/constants';

const useDowntimeWatcher = () => {
  const dispatch = useDispatch();
  const popup = useSelector(getPopup);
  const company = useSelector(getCompany);
  const history = useHistory();
  const nowMs = useContext(TickerContext);
  const activeUncategorizedInterval = useSelector(
    getActiveUncategorizedInterval
  );
  const machine = useSelector(getMachine);
  const jobStatus = useSelector(getJobStatus);
  const user = useSelector(getUser);
  const areAnnotationsLoading = useSelector(getAreAnnotationsLoading);
  const downtimeFlyup = useSelector(getDowntimeFlyup);
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const jobLoading = useSelector(getIsLoadingJobRunASWrapper);

  useEffect(() => {
    (() => {
      if (!company || !machine || areAnnotationsLoading || jobLoading) return;

      if (
        !company.showDowntimeCategorizationFlyup &&
        !company.showInitialDowntimePrompt
      )
        return;

      if (!activeUncategorizedInterval && popup) {
        dispatch(actionClosePopup());
        dispatch(actionClearDowntimeFlyup());
        return;
      }

      if (jobStatus === JOB_STATUSES.SETUP) {
        if (popup) {
          dispatch(actionClosePopup());
          dispatch(actionClearDowntimeFlyup());
        }
        return;
      }

      if (
        !activeUncategorizedInterval ||
        diff(new Date(), activeUncategorizedInterval.start, 'milliseconds') <
          company.downtimeUnderlayDelay
      )
        return;

      if (
        activeUncategorizedInterval &&
        company.showDowntimeCategorizationFlyup &&
        !downtimeFlyup.dismissed &&
        (!(popup && popup.dismissed) || company.mandatoryCategorization) &&
        diff(new Date(), activeUncategorizedInterval.start, 'milliseconds') >=
          company.downtimeFlyupDelay
      ) {
        dispatch(actionTriggerDowntimeFlyup());
        history.push({
          pathname: Routes.machineIdCategorizePath(machine.id),
          state: {
            preselected: activeUncategorizedInterval,
            forced: company.mandatoryCategorization && user && !user.isRoot,
            returnPath: history.location.pathname,
          },
        });
      }

      if (popup && popup.dismissed) return;

      if (
        popup &&
        popup.machineId === machine.id &&
        popup.mandatory === company.mandatoryCategorization &&
        moment(popup.startAt).isSame(activeUncategorizedInterval.start)
      )
        return;

      if (company.showInitialDowntimePrompt) {
        dispatch(
          actionSetPopupStack({
            params: {
              machineId: machine.id,
              startAt: activeUncategorizedInterval.start,
              mandatory: company.mandatoryCategorization,
              popupType: 'downtime',
              order: 2,
            },
          })
        );
      }
    })();
  }, [
    company,
    machine,
    activeUncategorizedInterval,
    popup,
    dispatch,
    jobLoading,
    jobStatus,
    nowMs,
    history,
    user,
    areAnnotationsLoading,
    downtimeFlyup.dismissed,
    areActivitiesEnabled,
  ]);
};

export { useDowntimeWatcher };
