import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';
import { getActivitiesByRef } from 'lib/selectors/getActivitiesByRef';
import { getAllActivityRefs } from 'lib/selectors/getAllActivityRefs';

const defaultSortedActivities = [];

// returns activities joined with default store sort order (latest / start DESC)
export const getSortedActivities = createDeepEqualitySelector(
  [getAllActivityRefs, getActivitiesByRef],
  (allActivityRefs, activitiesByRef) => {
    if (allActivityRefs && !allActivityRefs.length) {
      return defaultSortedActivities;
    }
    return allActivityRefs.map((ref) => {
      return activitiesByRef[ref];
    });
  }
);
