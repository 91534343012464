import { createSelector } from 'reselect';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';

const getIsLatestActivitySetOpen = createSelector(
  [getLatestActivitySet],
  (latestActivitySet) => {
    // ensure that we only consider latest set to be "open" when set exists
    return !!(
      latestActivitySet?.activitySetRef && !latestActivitySet?.closedAt
    );
  }
);

export { getIsLatestActivitySetOpen };
