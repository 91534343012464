import styled from 'styled-components';
import { palette } from '@m12s/component-library';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopRow = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  align-items: center;
  display: flex;
  background-color: ${palette.White};
  justify-content: space-between;
  > button {
    align-self: flex-start;
  }

  @media (orientation: portrait) {
    flex-direction: column-reverse;
    align-items: flex-start;
    > button {
      align-self: flex-end;
    }
  }
`;

const BottomRow = styled(TopRow)`
  border-top: solid 1px ${palette.Grey200};
  background-color: ${palette.Grey100};
  justify-content: flex-start;

  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const Times = styled.div`
  color: ${palette.Grey400};
  font-size: 3rem;
  margin: 0 4rem;

  @media (orientation: portrait) {
    margin: 0 0 1rem;
  }
`;

const FadedText = styled.div`
  color: ${palette.Grey700};
`;

export { Root, TopRow, BottomRow, Times, FadedText };
