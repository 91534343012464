import { createSelector } from 'reselect';

import { getCompany } from 'lib/selectors/getCompany';

const getIsOperatorTrackingEnabled = createSelector(
  [getCompany],
  ({ operatorTracking }) => {
    return !!operatorTracking;
  }
);

export { getIsOperatorTrackingEnabled };
