import { createSelector } from 'reselect';
import { takeLatest, isSameOrBefore, toISO } from 'lib/utils/date';

import { getShouldUseOpRunOverrideScope } from 'lib/selectors/getShouldUseOpRunOverrideScope';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getIsInitialOperatorRunsPollLoading } from 'lib/selectors/getIsInitialOperatorRunsPollLoading';
import { getIsInitialShiftPollLoading } from 'lib/selectors/getIsInitialShiftPollLoading';
import { getLatestOperatorRunStartAt } from 'lib/selectors/getLatestOperatorRunStartAt';
import { getLatestOperatorRunEndAt } from 'lib/selectors/getLatestOperatorRunEndAt';
import { getMaxScopeQueryStart } from 'lib/selectors/getMaxScopeQueryStart';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getHasActiveOperatorRun } from 'lib/selectors/getHasActiveOperatorRun';
import { getLatestActivitySetStartAt } from 'lib/selectors/getLatestActivitySetStartAt';
import { getIsLatestActivitySetClosed } from 'lib/selectors/getIsLatestActivitySetClosed';
import { getLatestActivityEndAt } from 'lib/selectors/getLatestActivityEndAt';
import { getStartOfNextHour } from 'lib/selectors/getStartOfNextHour';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';

const defaultScope = {
  startDate: null,
  endDate: null,
};

const getOpRunOverrideScope = createSelector(
  [
    getShouldUseOpRunOverrideScope,
    getIsInitialActivitySetPollLoading,
    getIsInitialOperatorRunsPollLoading,
    getIsInitialShiftPollLoading,
    getLatestOperatorRunStartAt,
    getLatestOperatorRunEndAt,
    getLatestActivitySetStartAt,
    getIsLatestActivitySetClosed,
    getLatestActivityEndAt,
    getHasOpenActivitySet,
    getHasActiveOperatorRun,
    getMaxScopeQueryStart,
    checkIsLatestShiftActive,
    getStartOfCurrentShopDay,
    getStartOfNextHour,
    getShiftStart,
  ],
  (
    shouldUseOpRunOverrideScope,
    isInitialActivitySetPollLoading,
    isInitialOperatorRunsPollLoading,
    isInitialShiftPollLoading,
    latestOperatorRunStartAt,
    latestOperatorRunEndAt,
    latestActivitySetStartAt,
    isLatestActivitySetClosed,
    latestActivityEndAt,
    hasOpenActivitySet,
    hasActiveOperatorRun,
    maxScopeQueryStart,
    isLatestShiftActive,
    startOfCurrentShopDay,
    startOfNextHour,
    shiftStart
  ) => {
    // shortcircuit to prevent prematurely returning scope - since a lot of
    // reqs are tied to scope start/end, we do not want to return dates and trigger
    // more network reqs than necessary if scope calc has not yet settled
    const haveScopeCalcDepsLoaded = !!(
      !isInitialShiftPollLoading &&
      startOfCurrentShopDay &&
      startOfNextHour &&
      maxScopeQueryStart &&
      !isInitialOperatorRunsPollLoading &&
      !isInitialActivitySetPollLoading
    );
    if (shouldUseOpRunOverrideScope && haveScopeCalcDepsLoaded) {
      const shouldResetScopeStartOnShiftBounds = !hasActiveOperatorRun;
      const doesLatestOpRunHaveSetEnd = !!latestOperatorRunEndAt;
      const nowISO = toISO();
      const hasLatestOpRunEnded = !!(
        doesLatestOpRunHaveSetEnd &&
        isSameOrBefore(latestOperatorRunEndAt, nowISO)
      );
      const shouldUseShopDayScope = !!(
        !hasOpenActivitySet &&
        !isLatestShiftActive &&
        !hasActiveOperatorRun
      );
      if (shouldUseShopDayScope) {
        return {
          startDate: takeLatest(
            // only consider latest opRun end when set (ie non-null) and already elapsed (can occur in future)
            hasLatestOpRunEnded ? latestOperatorRunEndAt : maxScopeQueryStart,
            // only consider latest activity end when latest set has been closed (ie no job running)
            isLatestActivitySetClosed
              ? latestActivityEndAt
              : maxScopeQueryStart,
            startOfCurrentShopDay,
            maxScopeQueryStart
          ),
          endDate: startOfNextHour,
        };
      }
      // it is ok to derive hasLatestOpRunEnded from dynamic value like nowISO because
      // hasActiveOperatorRun will force a recalc of scope when end date of run has elapsed
      return {
        startDate: takeLatest(
          shouldResetScopeStartOnShiftBounds
            ? shouldUseShopDayScope
              ? startOfCurrentShopDay
              : shiftStart
            : maxScopeQueryStart,
          latestOperatorRunStartAt || maxScopeQueryStart,
          // only consider latest opRun end when set (ie non-null) and already elapsed (can occur in future)
          hasLatestOpRunEnded ? latestOperatorRunEndAt : maxScopeQueryStart,
          latestActivitySetStartAt || maxScopeQueryStart,
          // only consider latest activity end when latest set has been closed (ie no job running)
          isLatestActivitySetClosed ? latestActivityEndAt : maxScopeQueryStart,
          maxScopeQueryStart
        ),
        endDate: startOfNextHour,
      };
    }

    // subscriber is responsible for handling loading/invalid scope state
    // be sure to check for falsey vals and show loading, bail out, etc if
    // scope start and/or scope end are dependencies
    return defaultScope;
  }
);

export { getOpRunOverrideScope };
