import { createSelector } from 'reselect';

import { downtimeIntervals as calculateDowntimeIntervals } from 'lib/decorators/downtimeIntervals';
import { getInactiveStatusIntervals } from 'lib/selectors/getInactiveStatusIntervals';
import { getJobStatus } from 'lib/selectors/getJobStatus';
import { getJobRun } from 'lib/selectors/getJobRun';
import { getAnnotations } from 'lib/selectors/getAnnotations';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';

const getDowntimeIntervals = createSelector(
  [
    getAnnotations,
    getInactiveStatusIntervals,
    getJobRun,
    getJobStatus,
    getAreActivitiesEnabled,
  ],
  (annotations, intervals, jobRun, jobStatus, areActivitiesEnabled) => {
    return calculateDowntimeIntervals(
      annotations,
      intervals,
      jobRun,
      jobStatus,
      areActivitiesEnabled
    );
  }
);

export { getDowntimeIntervals };
