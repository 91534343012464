import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Routes from 'lib/utils/routes';
import MainLayout from 'components/Layouts/MainLayout';
import { getMachine } from 'lib/selectors/getMachine';
import { SelectActivityType } from 'components/SelectActivityType';
import useGoToHome from 'lib/hooks/useGoToHome';
import useStartJob from '../SelectJob/useStartJob';

const StartActivityNewOperation = ({ location }) => {
  const {
    job,
    jobName,
    workOrderId,
    isStopAndStartRun,
    newWorkOrderId,
  } = location.state;
  job.jobId = job.id;
  job.displayName = jobName;
  const { startJob } = useStartJob(job);
  const history = useHistory();
  const machine = useSelector(getMachine);
  const goToHome = useGoToHome();
  const handleOnClose = useCallback(() => {
    return history.push(Routes.machineIdHomePath(machine.id));
  }, [history, machine]);

  return (
    <MainLayout.Container direction="column">
      <SelectActivityType
        jobName={jobName}
        job={job}
        handleOnBack={goToHome}
        handleOnClose={handleOnClose}
        handleOnSelect={async (activityTypeRef) => {
          await startJob({ activityTypeRef, workOrderId });
          history.push(Routes.machineIdPath(machine.id));
        }}
        isStopAndStartRun={isStopAndStartRun}
        newWorkOrderId={newWorkOrderId}
      />
    </MainLayout.Container>
  );
};

export default StartActivityNewOperation;
