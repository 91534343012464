/* eslint-disable arrow-body-style */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Routes from 'lib/utils/routes';
import { Icon } from '@m12s/component-library';
import { pause, pencil } from 'lib/icons';
import { getWorkOrderId } from 'lib/selectors/getWorkOrderId';
import { getShouldShowWorkOrderIdField } from 'lib/selectors/getShouldShowWorkOrderIdField';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { getMachine } from 'lib/selectors/getMachine';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

import {
  WorkOrderInfoGroupContainer,
  WorkOrderIdContainer,
  NoWorkOrderIdContainer,
  WorkOrderText,
  WithWorkOrderIdIcon,
  NotShowWorkOrderId,
  PausedContainer,
  JobStatusIcon,
  Text,
} from './styled';

const WorkOrderInfoGroup = ({ variant, onClickToOverview, isPaused }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const withWorkOrderWidthRef = useRef();
  const noWorkOrderWidthRef = useRef();
  const workOrderFlowEnabled = useIsWorkOrderFlow();

  const [hoverOverTouchPoint, setHoverOverTouchPoint] = useState(false);

  const machine = useSelector(getMachine);
  const workOrderId = useSelector(getWorkOrderId);
  const shouldShowWorkOrderIdField = useSelector(getShouldShowWorkOrderIdField);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  return (
    <WorkOrderInfoGroupContainer
      variant={variant}
      isInSetup={hasOpenSetupActivity}
      workOrderId={workOrderId}
    >
      {workOrderId ? (
        <WorkOrderIdContainer>
          <WithWorkOrderIdIcon
            ref={withWorkOrderWidthRef}
            onClick={onClickToOverview}
            hoverOverTouchPoint={hoverOverTouchPoint}
            onMouseEnter={() => setHoverOverTouchPoint(true)}
            onMouseLeave={() => setHoverOverTouchPoint(false)}
            isInSetup={hasOpenSetupActivity}
            variant={variant}
          >
            <Icon icon={pencil} size="0.8rem" />
          </WithWorkOrderIdIcon>
          {isPaused && (
            <PausedContainer>
              <JobStatusIcon variant={variant} icon={pause} />
              <Text variant={variant}>{t('Paused')}</Text>
            </PausedContainer>
          )}
          {shouldShowWorkOrderIdField && (
            <WorkOrderText
              isInSetup={hasOpenSetupActivity}
              useHeaderBG={false}
              variant={variant}
              isPaused={isPaused}
              workOrderId={workOrderId}
            >
              {workOrderId}
            </WorkOrderText>
          )}
        </WorkOrderIdContainer>
      ) : (
        <>
          {shouldShowWorkOrderIdField ? (
            <>
              <NoWorkOrderIdContainer
                ref={noWorkOrderWidthRef}
                onClick={(e) => {
                  e.stopPropagation();
                  if (workOrderFlowEnabled) {
                    return history.push(
                      `${Routes.machineIdSelectJobPath(
                        machine.id
                      )}?updateWorkOrderId=true`
                    );
                  }
                  return onClickToOverview();
                }}
                hoverOverTouchPoint={hoverOverTouchPoint}
                onMouseEnter={() => setHoverOverTouchPoint(true)}
                onMouseLeave={() => setHoverOverTouchPoint(false)}
                isInSetup={hasOpenSetupActivity}
                variant={variant}
              >
                <Icon icon={pencil} />
                <WorkOrderText
                  isInSetup={hasOpenSetupActivity}
                  useHeaderBG
                  variant={variant}
                  workOrderId={workOrderId}
                >
                  Add Production Order
                </WorkOrderText>
              </NoWorkOrderIdContainer>
              {isPaused && (
                <PausedContainer>
                  <JobStatusIcon variant={variant} icon={pause} />
                  <Text variant={variant}>{t('Paused')}</Text>
                </PausedContainer>
              )}
            </>
          ) : (
            <NotShowWorkOrderId
              useHeaderBG
              onClick={onClickToOverview}
              hoverOverTouchPoint={hoverOverTouchPoint}
              onMouseEnter={() => setHoverOverTouchPoint(true)}
              onMouseLeave={() => setHoverOverTouchPoint(false)}
              isInSetup={hasOpenSetupActivity}
              variant={variant}
            >
              <Icon icon={pencil} />
            </NotShowWorkOrderId>
          )}
        </>
      )}
    </WorkOrderInfoGroupContainer>
  );
};

export { WorkOrderInfoGroup };
