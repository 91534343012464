import React from 'react';
import { palette, Icon } from '@m12s/component-library';
import { EmptyContainer } from 'components/Table';

import { DescriptionText, DescriptionTitle } from './styled';

function EmptyWithGraphic({ icon, text, subtext, Graphic }) {
  return (
    <EmptyContainer>
      {icon && (
        <Icon
          style={{ marginBottom: '1rem' }}
          color={palette.Grey400}
          icon={icon}
          size="3.5rem"
        />
      )}
      {Graphic}
      <DescriptionTitle color={palette.Grey700}>{text}</DescriptionTitle>
      <DescriptionText>{subtext}</DescriptionText>
    </EmptyContainer>
  );
}

export { EmptyWithGraphic };
