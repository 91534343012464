import React, { Fragment } from 'react';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { palette } from '@m12s/component-library';

import { H5 } from 'components/Text';
import { NavWrapper, Separator } from './styled';

const CategoryHeader = ({ path }) => {
  const { t } = useTranslation();

  return (
    <NavWrapper>
      {path.length ? (
        <H5 bold color={palette.Blue500}>
          {path.map((name, index) => {
            return (
              <Fragment key={name}>
                {index !== 0 && <Separator />}
                {name}
              </Fragment>
            );
          })}
        </H5>
      ) : (
        <H5 bold color={palette.Grey500}>
          {_startCase(t('no category selected'))}
        </H5>
      )}
    </NavWrapper>
  );
};

export { CategoryHeader };
