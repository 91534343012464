import { createSelector } from 'reselect';

import { MACHINE_STATUSES } from 'lib/constants';
import { getDowntimeIntervals } from 'lib/selectors/getDowntimeIntervals';
import { getStatusLabel } from 'lib/selectors/getStatusLabel';

const { ACTIVE } = MACHINE_STATUSES;

const getActiveCategorizedInterval = createSelector(
  [getStatusLabel, getDowntimeIntervals],
  (status, downtimes) => {
    if (status === ACTIVE) return null;

    const interval = downtimes.filter((downtime) => {
      return !downtime.end && !!downtime.categorized;
    })[0];

    return interval || null;
  }
);

export { getActiveCategorizedInterval };
