import React from 'react';
import { Route } from 'react-router-dom';
import qs from 'query-string';
import session from 'lib/api/links/session';
import TokenStore from 'lib/api/links/TokenStore';
import { ExternalLoginRedirect } from 'components/ExternalLoginRedirect';

function isAuthenticated() {
  return !!session.getApiKey();
}

const PrivateRoute = ({ component, ...props }) => {
  const authenticated = isAuthenticated();
  if (!authenticated) {
    const redirectTo = new TokenStore('redirectTo');
    redirectTo.set(window.location.pathname);
  }
  const { machineSet } = qs.parse(window.location.search);
  const switchToken = new TokenStore('switchDisabled');
  if (machineSet) {
    switchToken.set(true);
    new TokenStore('presetMachine').clear();
  } else if (!switchToken) {
    switchToken.set(false);
  }

  return authenticated ? (
    <Route {...props} component={component} />
  ) : (
    <ExternalLoginRedirect />
  );
};

export { PrivateRoute };
