import React from 'react';
import { palette } from '@m12s/component-library';

const NoGoal = ({ width = '70', fill = palette.Grey400, ...props }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.95606 1.21365L4.40457 0.00203633C4.46665 -0.00117962 4.52978 -0.000618965 4.59375 0.0039503C5.27786 0.0336941 5.84872 0.54471 5.95606 1.21365Z"
        fill={fill}
      />
      <path
        d="M18.7914 11.2373C18.923 11.0115 19 10.7506 19 10.4727V2.9727C19 1.9102 17.875 1.19145 16.9062 1.5977C15.7812 2.0977 14.5938 2.4727 13.5 2.4727C12.797 2.4727 12.1203 2.2619 11.3883 2.03387C10.5259 1.76524 9.58685 1.4727 8.4375 1.4727C7.75725 1.4727 7.1493 1.57393 6.61365 1.72719L8.21498 2.97773C8.28879 2.97441 8.363 2.9727 8.4375 2.9727C9.375 2.9727 10.1719 3.2227 10.9688 3.4727C11.7656 3.7227 12.5625 3.9727 13.5 3.9727C14.8438 3.9727 16.5 3.44145 17.5 2.9727V10.2287L18.7914 11.2373Z"
        fill={fill}
      />
      <path
        d="M5.25 6.36539V11.2227C6.21875 10.7852 7.875 10.4727 9.21875 10.4727C9.77529 10.4727 10.2823 10.5608 10.7691 10.6847L13.6559 12.9439C12.7824 12.8638 12.0311 12.6298 11.3305 12.4115C10.5985 12.1835 9.92179 11.9727 9.21875 11.9727C7.46875 11.9727 6.40625 12.3477 5.25 12.879V15.4727C5.25 15.754 5 15.9727 4.75 15.9727H4.25C3.96875 15.9727 3.75 15.754 3.75 15.4727V5.19148L5.25 6.36539Z"
        fill={fill}
      />
      <path
        d="M2.125 0.124992L20.8125 14.7187C21 14.9062 21.0625 15.2187 20.875 15.4375L20.5625 15.8125C20.4062 16.0312 20.0625 16.0625 19.875 15.9062L1.1875 1.28124C0.96875 1.12499 0.9375 0.812492 1.09375 0.593742L1.40625 0.187492C1.59375 -8.44896e-06 1.90625 -0.0625084 2.125 0.124992Z"
        fill={fill}
      />
    </svg>
  );
};

export default NoGoal;
