import styled from 'styled-components';
import { Small } from 'components/Text';
import { palette } from '@m12s/component-library';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSmall = styled(Small)`
  color: ${palette.Grey600};
`;
