import { useEffect } from 'react';
import i18n from 'i18next';
import TokenStore from 'lib/api/links/TokenStore';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCompany } from 'lib/selectors/getCompany';

// We set the language according to the language switcher in the app, which is stored in a token
export const updateLanguage = () => {
  const setLocaleToken = new TokenStore('locale');
  const locale = setLocaleToken.get();
  i18n.changeLanguage(locale);
};

// We format time according to the locale of the company in the database
export const updateLocale = async (locale) => {
  try {
    if (locale !== 'en') {
      await import(`moment/locale/${locale}`);
    }
    moment.locale(locale);
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.error(e);
  }
};

const useLocale = () => {
  const company = useSelector(getCompany);

  useEffect(() => {
    updateLanguage();
  }, []);

  useEffect(() => {
    if (company.locale) {
      updateLocale(company.locale);
    }
  }, [company.locale]);
};

export { useLocale };
