import { createSelector } from 'reselect';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import { getActivityTypesWithArchived } from 'lib/selectors/getActivityTypes';

const getLatestActivityType = createSelector(
  [getLatestActivity, getActivityTypesWithArchived],
  (latestActivity, activityTypes) => {
    if (!latestActivity || !activityTypes || !activityTypes.length) {
      return null;
    }
    const activityType = activityTypes.find((at) => {
      return at.activityTypeRef === latestActivity.activityTypeRef;
    });
    if (!activityType) {
      return null;
    }
    return activityType;
  }
);

export { getLatestActivityType };
