class Tree {
  static parse(annotationTypes) {
    const tree = new this();

    annotationTypes.forEach((annotationType) => {
      const names = annotationType.name.split('|');

      names.reduce((node, name) => {
        const trimmedName = name.trim();
        const child = node.find(trimmedName);
        return child || node.add(trimmedName, annotationType);
      }, tree);
    });

    return tree;
  }

  constructor(name = null, parent = null, data = null) {
    this.children = [];
    this.data = data;
    this.name = name;
    this.parent = parent;
    this.root = parent === null;
  }

  add(name, data) {
    const node = new this.constructor(name, this, data);
    this.children.push(node);
    return node;
  }

  find(name) {
    return this.children.find((child) => {
      return child.name === name;
    });
  }

  path() {
    if (this.root) return [];

    if (this.parent && this.parent.root) {
      return [this.name];
    }

    return [...this.parent.path(), this.name];
  }
}

export { Tree };
