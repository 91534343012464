import styled from 'styled-components';
import { TextArea as OriginalTextArea } from '@m12s/component-library';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0;
  width: 35rem;
`;

const RadioControl = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const TextArea = styled(OriginalTextArea)`
  margin: 1.625rem;
`;

export { Container, RadioControl, TextArea };
