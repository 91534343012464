import { createSelector } from 'reselect';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { diff, toISO } from 'lib/utils/date';

const getTotalScopeTimeMs = createSelector(
  [getScopeStart, getHeavyUpdate],
  (
    scopeStart,
    _heavyUpdate // eslint-disable-line no-unused-vars
  ) => {
    if (!scopeStart) {
      return 0;
    }
    return diff(toISO(), scopeStart, 'milliseconds');
  }
);

export { getTotalScopeTimeMs };
