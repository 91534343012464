import { createSelector } from 'reselect';
import { getConnection } from 'lib/selectors/getConnection';
import { isAfter, ago } from 'lib/utils/date';
import { getHeavyUpdate } from './getUpdate';

const getOffline = createSelector(
  [getConnection, getHeavyUpdate],
  ({ disconnectedAt }) => {
    return disconnectedAt && isAfter(ago(30, 'seconds'), disconnectedAt);
  }
);

export { getOffline };
