import { createSelector } from 'reselect';
import { toISO } from 'lib/utils/date';
import { getUtilPageScope } from 'lib/selectors/getUtilPageScope';

const getUtilPageScopeStart = createSelector(
  [getUtilPageScope],
  ({ startDate }) => {
    if (!startDate) {
      return null;
    }
    return toISO(startDate);
  }
);

export { getUtilPageScopeStart };
