import React from 'react';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { getUtilPageScopeUtilizationDecimal } from 'lib/selectors/getUtilPageScopeUtilizationDecimal';
import { formatPercentFromDecimal } from 'lib/utils/text';

export const UtilizationTab = () => {
  const { t } = useTranslation();
  const utilPageScopeUtilizationDecimal = useSelector(
    getUtilPageScopeUtilizationDecimal
  );

  return (
    <StatTab
      id="utilization-tab"
      value="utilization"
      label={_capitalize(t('utilization'))}
      secondaryLabel={formatPercentFromDecimal(utilPageScopeUtilizationDecimal)}
    />
  );
};
