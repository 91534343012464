import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { formatDuration, millis, diffDate } from 'lib/utils/date';
import { TickerContext } from 'components/TickerProvider';

const DurationTicker = ({
  startDate,
  endDateOverride,
  format,
  reverse,
  formatOptions,
}) => {
  const nowMs = useContext(TickerContext);
  const startMs = useMemo(() => {
    return millis(startDate);
  }, [startDate]);
  const endMs = endDateOverride ? millis(endDateOverride) : nowMs;
  const duration = Math.max(
    reverse ? diffDate(startMs, endMs) : diffDate(endMs, startMs),
    0
  );

  return <span>{formatDuration(duration, format, formatOptions)}</span>;
};

DurationTicker.propTypes = {
  format: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  // optional prop for end other than 'now' (ie static ticker)
  endDateOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { DurationTicker };
