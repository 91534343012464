import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ControlBarButton, ControlBar } from '@m12s/component-library';
import { arrowLeft, arrowRight } from 'lib/icons';
import _startCase from 'lodash/startCase';
import { Production, Setup } from 'lib/customIcons';
import { ResponsiveText } from 'pages/Machine/styled';
import { getActivityTypes } from 'lib/selectors/getActivityTypes';

const ActivitiesControls = ({
  nextStep,
  previousStep,
  hasThreeOrMoreActivities,
  startProduction,
  startSetup,
  job,
}) => {
  const { t } = useTranslation();
  const allowsSetup = job.setupTime !== null;
  const activityTypes = useSelector(getActivityTypes);
  const numberOfProductionTypes = activityTypes.filter((act) => {
    return act.activityMode === 'production';
  }).length;

  return (
    <ControlBar>
      <ControlBarButton icon={arrowLeft} onClick={previousStep}>
        {_startCase(t('all operations'))}
      </ControlBarButton>
      {hasThreeOrMoreActivities &&
      (numberOfProductionTypes > 1 || allowsSetup) ? (
        <ControlBarButton icon={arrowRight} onClick={nextStep}>
          {t('Select Activity')}
        </ControlBarButton>
      ) : (
        <>
          {allowsSetup && (
            <ControlBarButton onClick={startSetup}>
              <Setup />
              <ResponsiveText
                full={t('enterSetup|full')}
                medium={t('enterSetup|medium')}
                short={t('enterSetup|short')}
              />
            </ControlBarButton>
          )}
          <ControlBarButton onClick={startProduction}>
            <Production />
            <ResponsiveText
              full={t('enterProduction|full')}
              medium={t('enterProduction|medium')}
              short={
                allowsSetup
                  ? t('enterProduction|short')
                  : t('enterProduction|medium')
              }
            />
          </ControlBarButton>
        </>
      )}
    </ControlBar>
  );
};

export { ActivitiesControls };
