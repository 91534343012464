import { createSelector } from 'reselect';

const getCustomTab = (store) => {
  return store.customTab;
};

const getCustomTabURL = createSelector([getCustomTab], (customTab) => {
  return customTab.url;
});

const getCustomTabs = (store) => {
  return store.customTabs;
};

export { getCustomTabURL, getCustomTabs };
