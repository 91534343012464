import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { H3 } from 'components/Text';

const DescriptionText = styled.p`
  text-align: center;
  max-width: 500px;
  color: ${palette.Grey600};
  font-weight: medium;
  font-size: 1.3rem;
  line-height: 1.7rem;
  letter-spacing: 0.005em;
  margin: 0.5rem 0;
`;

const DescriptionTitle = styled(H3)`
  text-align: center;
`;

export { DescriptionText, DescriptionTitle };
