import { createSelector } from 'reselect';
import moment from 'moment';
import 'moment-duration-format';
import _get from 'lodash/get';

import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getUnitDurationFromString } from 'lib/utils/date';
import { getCurrentWorkOrderOpPartDuration } from './getCurrentWorkOrderOpPartDuration';

const getLatestExpectedUnitDuration = createSelector(
  [getLatestActivitySet, getCurrentWorkOrderOpPartDuration],
  (latestActivitySet, currentWorkOrderOpPartDuration) => {
    const productionRunExpected = _get(
      latestActivitySet,
      'expectedUnitDuration'
    );

    if (
      currentWorkOrderOpPartDuration &&
      !getUnitDurationFromString(productionRunExpected)
    ) {
      return moment
        .duration(currentWorkOrderOpPartDuration)
        .format('HH:mm:ss.SSS', { trim: false, trunc: true });
    }

    return productionRunExpected;
  }
);

export { getLatestExpectedUnitDuration };
