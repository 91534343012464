import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const H2 = WithProps(styled.span`
  ${includeBaseStyle}
  font-size: 2rem;
  line-height: 2.75rem;
`);

H2.defaultProps = {
  ...H2.defaultProps,
  defaultWeight: 'bold',
};

export { H2 };
