import moment from 'moment';
import 'moment-duration-format';

function getActivitySetStandardsFromJob({
  expectedPartTime,
  idealPartTime,
  setupTime,
  optimalUnitDurationMs,
}) {
  return {
    expectedUnitDuration: moment
      .duration(expectedPartTime || 0)
      .format('HH:mm:ss.SSS', { trim: false, trunc: true }),
    idealUnitDuration: moment
      .duration(idealPartTime || 0)
      .format('HH:mm:ss.SSS', { trim: false, trunc: true }),
    expectedSetupDuration: moment
      .duration(setupTime || 0, 'seconds')
      .format('HH:mm:ss.SSS', { trim: false, trunc: true }),
    optimalUnitDuration: optimalUnitDurationMs
      ? moment
          .duration(optimalUnitDurationMs || 0, 'milliseconds')
          .format('HH:mm:ss.SSS', { trim: false, trunc: true })
      : null,
  };
}

function mapJobMachinesOntoJobs(jobs, machineId) {
  return jobs.map((job) => {
    const jobMachine =
      job.jobMachines &&
      job.jobMachines.find((jm) => {
        return jm.machineId === machineId;
      });

    if (!jobMachine) {
      return job;
    }

    const {
      actualParts,
      expectedPartTime,
      idealPartTime,
      partCountMultiplier,
      setupTime,
    } = jobMachine;

    return {
      ...job,
      actualParts,
      expectedPartTime,
      idealPartTime,
      partCountMultiplier,
      setupTime,
    };
  });
}

export { getActivitySetStandardsFromJob, mapJobMachinesOntoJobs };
