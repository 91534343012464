import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getCompany } from 'lib/selectors/getCompany';
import { getUser } from 'lib/selectors/getUser';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';

const getBugsnagUserInfo = createSelector(
  [getCompany, getUser, getCurrentOperator],
  (company, user, operator) => {
    return {
      company: _get(company, 'name'),
      companyId: _get(company, 'id'),
      companyTimezone: _get(company, 'timezone'),
      user: _get(user, 'displayName'),
      userId: _get(user, 'id'),
      userRole: _get(user, 'role'),
      operator: _get(operator, 'name'),
      operatorId: _get(operator, 'id'),
    };
  }
);

export { getBugsnagUserInfo };
