import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { getAlarms } from 'lib/selectors/getAlarms';
import { TextMedia } from '../styled';

export const AlarmsTab = () => {
  const { t } = useTranslation();
  const alarms = useSelector(getAlarms);

  return (
    <StatTab
      id="alarms-tab"
      value="alarms"
      label={
        <>
          <TextMedia max={60}>{_startCase(t('alarms'))}</TextMedia>
          <TextMedia min={60}>{_startCase(t('active alarms'))}</TextMedia>
        </>
      }
      secondaryLabel={alarms.length}
    />
  );
};
