import React from 'react';
import styled from 'styled-components';
import { Button as OriginalButton, palette } from '@m12s/component-library';

import { H3, H5, H6, Small } from 'components/Text';

const Top = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${palette.White};
  box-shadow: inset 0px -1px 0px #edf2f7;
`;

const Details = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1.5rem;
  padding: 2rem 2.5rem;

  @media (orientation: portrait) {
    grid-template-columns: 1fr 1fr;
  }

  ${({ top }) => {
    return (
      top &&
      `
    @media (orientation: portrait) {
      grid-template-columns: 1fr;
    }
  `
    );
  }}
`;

const DetailContainer = styled.div`
  & > * {
    display: block;
    margin-bottom: 0.25rem;
  }
`;

const Detail = ({ title, value, muted, top }) => {
  return (
    <DetailContainer>
      {top ? (
        <>
          <H6 color={palette.Purple600}>{title}</H6>
          <H3>{value}</H3>
        </>
      ) : (
        <>
          <Small bold muted>
            {title}
          </Small>
          <H5 muted={muted}>{value}</H5>
        </>
      )}
    </DetailContainer>
  );
};

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.5rem;
  padding: 0 2rem;
  background-color: ${palette.White};

  @media (orientation: portrait) {
    flex-direction: column;
    justify-content: space-around;
    height: 9rem;
    padding: 1rem;
  }
`;

const Button = styled(OriginalButton)`
  width: auto;

  &:disabled {
    opacity: 0.3;
  }
`;

export { Button, ControlsContainer, Detail, Details, Top };
