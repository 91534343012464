import _remove from 'lodash/remove';

class EventEmitter {
  constructor() {
    this.listeners = {};
  }

  on(event, listener) {
    this.listeners[event] = this.listeners[event] || [];
    this.listeners[event].push(listener);
    return listener;
  }

  off(event, listener) {
    _remove(this.listeners[event], (l) => {
      return l === listener;
    });
  }

  emit(event, data) {
    if (this.listeners[event])
      this.listeners[event].forEach((listener) => {
        return listener(data);
      });
  }
}

export default EventEmitter;
