import React from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { palette } from '@m12s/component-library';
import { H6, Small } from 'components/Text';
import { DurationTicker } from 'components/DurationTicker';
import { formatDate, formatTime, formatDuration } from 'lib/utils/date';
import { ActiveLabel, CardWrapper, Category, AnnotationColor } from './styled';

const DowntimeCard = ({ downtime, ...props }) => {
  const { t } = useTranslation();
  const { categorized, duration, end, start, annotationType } = downtime;

  return (
    <CardWrapper {...props}>
      <H6>
        {end ? (
          formatDuration(duration)
        ) : (
          <DurationTicker startDate={moment(start)} />
        )}
      </H6>
      <Small>
        {_capitalize(t('started'))}: {formatTime(start)} {formatDate(start)}
        {!end && <ActiveLabel>{_startCase(t('active now'))}</ActiveLabel>}
      </Small>
      {!categorized && (
        <Small color={palette.Red500}>{_capitalize(t('uncategorized'))}</Small>
      )}
      {annotationType && (
        <>
          <AnnotationColor color={annotationType.color} />
          <Category>{annotationType.name}</Category>
        </>
      )}
    </CardWrapper>
  );
};

DowntimeCard.propTypes = {
  downtime: PropTypes.shape({
    duration: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
    categorized: PropTypes.bool,
  }),
};

export { DowntimeCard };
