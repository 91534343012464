import { createSelector } from 'reselect';

import { getActiveStatusIntervals } from 'lib/selectors/getActiveStatusIntervals';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { chopBefore } from 'lib/utils/intervals';

const defaultScopeStatusIntervals = [];

const getScopeActiveStatusIntervals = createSelector(
  [getScopeStart, getActiveStatusIntervals],
  (scopeStart, activeStatusIntervals) => {
    if (!scopeStart) {
      return defaultScopeStatusIntervals;
    }
    return chopBefore(activeStatusIntervals, scopeStart);
  }
);

export { getScopeActiveStatusIntervals };
