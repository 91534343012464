import { createSelector } from 'reselect';

import { isBefore, isAfter, toISO } from 'lib/utils/date';
import { getLatestOperatorRunStartAt } from 'lib/selectors/getLatestOperatorRunStartAt';
import { getLatestOperatorRunEndAt } from 'lib/selectors/getLatestOperatorRunEndAt';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';

// because it is possible for operator runs to end in the future, we need to regularly
// check to ensure that any defined end times on the latest run are still in the future - that
// is why heavy update is a dependency here, to "bust" the selector cache every 10 seconds and
// recompute whether or not the latest operator run is active

const getHasActiveOperatorRun = createSelector(
  [getLatestOperatorRunStartAt, getLatestOperatorRunEndAt, getHeavyUpdate],
  (
    runStartAt,
    runEndAt, // eslint-disable-next-line no-unused-vars
    _heavyUpdate
  ) => {
    if (runStartAt) {
      const nowISO = toISO();
      const doesRunStartBeforeNow = isBefore(runStartAt, nowISO);
      if (doesRunStartBeforeNow && runEndAt !== undefined) {
        const doesRunHaveOpenEnd = runEndAt === null;
        const doesRunEndAfterNow = isAfter(runEndAt, nowISO);
        return doesRunHaveOpenEnd || doesRunEndAfterNow;
      }
    }
    return false;
  }
);

export { getHasActiveOperatorRun };
