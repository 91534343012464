import { createSelector } from 'reselect';
import { getTotalScopeScheduledTimeMs } from 'lib/selectors/getTotalScopeScheduledTimeMs';
import { getScopeScheduledTimeInCycleMs } from 'lib/selectors/getScopeScheduledTimeInCycleMs';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getIsActivitiesStatusLoading } from 'lib/selectors/getActivitiesStatus';
import { getScopeUtilizationDecimal } from 'lib/selectors/getScopeUtilizationDecimal';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { isNumber } from 'lib/utils/validation';

/*
  calculating availability: https://machinemetrics.quip.com/bDM9AOj7yo6q
  availability: scheduledTimeInCycle / scheduledTime

  for jsgs / deprecated opdash data model, we do not worry about the scheduled time
  component; this only applies to AJD customers, as they are the only customers still
  pending activities migration.

  because the formula without scheduledTime is the same as the one used to derive utilization:
    scopeTimeInCycle / scopeTotalTime
  we can use that as our shim for jsg customers
*/

const getAvailabilityDecimal = createSelector(
  [
    getScopeScheduledTimeInCycleMs,
    getTotalScopeScheduledTimeMs,
    getAreActivitiesEnabled,
    getIsActivitiesStatusLoading,
    getScopeUtilizationDecimal,
    getHasOpenActivity,
    getHasOpenJobRun,
  ],
  (
    scopeScheduledTimeInCycleMs,
    totalScopeScheduledTimeMs,
    areActivitiesEnabled,
    isActivitiesStatusLoading,
    scopeUtilizationDecimal,
    hasOpenActivity,
    hasOpenJobRun
  ) => {
    if (!hasOpenActivity && !hasOpenJobRun) {
      // metric is only relevant when active / job is running and unpaused
      return null;
    }
    if (!isActivitiesStatusLoading) {
      if (!areActivitiesEnabled) {
        // jsg shim; remove when all customers are on activities
        return scopeUtilizationDecimal;
      }
      if (
        areActivitiesEnabled &&
        isNumber(scopeScheduledTimeInCycleMs) &&
        isNumber(totalScopeScheduledTimeMs) &&
        totalScopeScheduledTimeMs !== 0
      ) {
        const availabilityDecimal =
          scopeScheduledTimeInCycleMs / totalScopeScheduledTimeMs;
        return availabilityDecimal > 1 ? 1 : availabilityDecimal;
      }
    }
    return null;
  }
);

export { getAvailabilityDecimal };
