import { createSelector } from 'reselect';
import { getSortedActivities } from 'lib/selectors/getSortedActivities';
import { getCheckIsSetupActivityTypeRef } from 'lib/selectors/getCheckIsSetupActivityTypeRef';
import _get from 'lodash/get';

const defaultLatestSetupActivity = {};

export const getLatestSetupActivity = createSelector(
  [getSortedActivities, getCheckIsSetupActivityTypeRef],
  (sortedActivities, checkIsSetupActivityTypeRef) => {
    if (!_get(sortedActivities, 'length')) {
      return defaultLatestSetupActivity;
    }
    const latestSetupActivity = sortedActivities.find(({ activityTypeRef }) => {
      return checkIsSetupActivityTypeRef(activityTypeRef);
    });
    return latestSetupActivity || defaultLatestSetupActivity;
  }
);
