import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { formatPercentFromDecimal } from 'lib/utils/text';
import { getOEEDecimal } from 'lib/selectors/getOEEDecimal';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { LoadingStatTabLabel, LoadingStatTabSecondaryLabel } from '../styled';

export const OEETab = () => {
  const { t } = useTranslation();
  const oeeDecimal = useSelector(getOEEDecimal);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  return (
    <StatTab
      id="oee-tab"
      value="oee"
      label={
        <LoadingStatTabLabel loading={arePartCountBucketsLoading}>
          {t('oee').toUpperCase()}
        </LoadingStatTabLabel>
      }
      secondaryLabel={
        arePartCountBucketsLoading ? (
          <LoadingStatTabSecondaryLabel />
        ) : (
          formatPercentFromDecimal(oeeDecimal)
        )
      }
    />
  );
};
