import { createSelector } from 'reselect';
import _dropWhile from 'lodash/dropWhile';

import { isSameOrBefore } from 'lib/utils/date';
import { getDowntimeIntervals } from 'lib/selectors/getDowntimeIntervals';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';

const defaultDowntimeIntervals = [];

const getShopDayDowntimeIntervals = createSelector(
  [getDowntimeIntervals, getStartOfCurrentShopDay],
  (downtimes, startOfCurrentShopDay) => {
    return !startOfCurrentShopDay
      ? defaultDowntimeIntervals
      : _dropWhile(downtimes, (downtime) => {
          return (
            downtime.end && isSameOrBefore(downtime.end, startOfCurrentShopDay)
          );
        });
  }
);

export { getShopDayDowntimeIntervals };
