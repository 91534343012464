import { createSelector } from 'reselect';

import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';

const getLatestOperatorRunStartAt = createSelector(
  [getLatestOperatorRun],
  (latestOperatorRun) => {
    return latestOperatorRun?.startAt;
  }
);

export { getLatestOperatorRunStartAt };
