import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, palette } from '@m12s/component-library';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';
import { exclamationTriangle } from 'lib/icons';
import { Buttons, Dialog, Header, HeaderIcon, Title } from 'components/Dialog';
import { getHomeTabRoute } from 'lib/selectors/getTabs';

const ErrorDialog = ({ message }) => {
  const { t } = useTranslation();
  const defaultMessage = t('Sorry, there has been an error');
  const machine = useSelector(getMachine);
  const homeTabRoute = useSelector(getHomeTabRoute);
  const errorRedirect = machine ? homeTabRoute : '/switch-machines';

  return (
    <Dialog isOpen>
      <Header background="linear-gradient(180deg, #E53E3E 0%, #E33030 100%)">
        <Title color={palette.White}>{message || defaultMessage}</Title>
        <HeaderIcon
          color={palette.White}
          icon={exclamationTriangle}
        ></HeaderIcon>
      </Header>
      <Buttons>
        <Button
          onClick={() => {
            window.location = errorRedirect;
          }}
        >
          {_startCase(t('reload application'))}
        </Button>
      </Buttons>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  message: PropTypes.string,
};

export default ErrorDialog;
