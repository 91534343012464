import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';
import { getMachine } from 'lib/selectors/getMachine';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';

const useIsLaborTicketFlow = () => {
  const machine = useSelector(getMachine);
  const erpResourceId = _get(machine, 'erpResourceId');
  const erpAssociatedResources = _get(machine, 'erpResources');
  const operator = useSelector(getCurrentOperator);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const flags = useFlags();
  const laborTicketEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];

  return !!(
    laborTicketEnabled &&
    !isAPMEnabled &&
    (erpResourceId || erpAssociatedResources?.length) &&
    operator?.erpId
  );
};

export { useIsLaborTicketFlow };
