import { useCallback, useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _pickBy from 'lodash/pickBy';

import moment from 'moment';
import { useSelector } from 'react-redux';

import { generateAlarmSubscriptionQuery } from 'lib/api/queries';
import { getMachine } from 'lib/selectors/getMachine';
import { startOfAppHistory } from 'lib/utils/date';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';

const getSortByDate = ({ orderBy }) => {
  if (!orderBy || orderBy === 'date') return 'desc_nulls_first';

  return null;
};

const getSortByDescription = ({ orderBy }) => {
  if (orderBy === 'description') return 'asc';

  return 'desc';
};

const getLabel = ({ severity }) => {
  if (!severity || severity === 'all') return ['FAULT', 'WARNING'];
  if (severity === 'fault') return ['FAULT'];
  return ['WARNING'];
};

const getEndDateIsNull = ({ state }) => {
  if (state === 'all') return null;
  if (state === 'active') return true;
  return false;
};

const sortByDuration = (alarms) => {
  return _reverse(_sortBy(alarms, 'duration'));
};

const assignDurationToAlarm = (alarm) => {
  return {
    ...alarm,
    duration: moment
      .duration(moment(alarm.end || undefined).diff(alarm.start))
      .as('seconds'),
  };
};

const processAlarms = (data, filters) => {
  let alarms = data;
  alarms = alarms.map(assignDurationToAlarm);

  if (filters.orderBy === 'duration') {
    alarms = sortByDuration(alarms);
  }

  return alarms;
};

const useAlarms = (filters) => {
  const machine = useSelector(getMachine);
  const [limit, setLimit] = useState(20);
  const loadMore = useCallback(() => {
    return setLimit(limit + 20);
  }, [limit, setLimit]);
  const [alarms, setAlarms] = useState([]);
  const windowStart = startOfAppHistory();
  const heavyUpdate = useSelector(getHeavyUpdate);

  useEffect(() => {
    setAlarms(processAlarms(alarms, filters));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [heavyUpdate]);

  const removeNullEntries = (obj) => {
    return _pickBy(obj, (val) => {
      return val != null;
    });
  };

  let variables = {
    description: filters.description ? `%${filters.description}%` : null,
    endDateIsNull: getEndDateIsNull(filters),
    label: getLabel(filters),
    machineRef: machine.machineRef,
    sortByDate: getSortByDate(filters),
    sortByDescription: getSortByDescription(filters),
    limit,
    windowStart,
  };

  variables = removeNullEntries(variables);

  const query = generateAlarmSubscriptionQuery(variables);

  const { loading, error } = useSubscription(query, {
    fetchPolicy: 'no-cache',
    variables,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      setAlarms(processAlarms(_get(data, 'alarmIntervals', []), filters));
    },
  });

  return {
    loading,
    error: !alarms.length && error,
    alarms,
    loadMore: alarms.length >= limit ? loadMore : null,
  };
};

export { useAlarms };
