import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useHeavyUpdateInterval } from 'lib/hooks/useHeavyUpdateInterval';
import { useData } from './useData';

const RefreshContext = createContext({
  refetch: () => {},
});

const DataProvider = ({ children }) => {
  useHeavyUpdateInterval();
  const { refetch } = useData();

  return (
    <RefreshContext.Provider value={{ refetch }}>
      {children}
    </RefreshContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.any,
};

export { DataProvider, RefreshContext };
