import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';
import { getTokenScope } from 'lib/selectors/getTokenScope';
import { WorkflowSocket } from 'lib/api/websockets/WorkflowSocket';
import { actionSetPopupStack } from 'lib/actions';

const useWorkflowWatcher = () => {
  const machine = useSelector(getMachine);
  const tokenScope = useSelector(getTokenScope);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!machine?.machineRef) return () => {};

    const socket = new WorkflowSocket(machine?.machineRef);
    const onData = (data) => {
      if (data) {
        dispatch(
          actionSetPopupStack({
            params: {
              ...data,
              popupType: 'workflow',
              order: 3,
            },
          })
        );
      }
      return data;
    };

    socket.subscribe(onData);
    return () => {
      return socket.unsubscribe(onData);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [machine, tokenScope]);
};

export { useWorkflowWatcher };
