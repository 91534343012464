import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import { palette } from '@m12s/component-library';
import { AppNav } from 'components/AppNav';
import { JobTable } from 'components/JobTable';
import MainLayout from 'components/Layouts/MainLayout';
import { H5 } from 'components/Text';
import { SearchInput } from 'components/SearchInput';

import { mapJobMachinesOntoJobs } from 'lib/utils/job';
import { GET_JOBS } from 'lib/api/queries';

import { useSelector } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';
import { useDebounce } from 'lib/hooks';

const JobList = ({ dispatch, nextStep, filter, handleOnClose }) => {
  const machine = useSelector(getMachine);
  const machineId = _get(machine, 'id');
  const { t } = useTranslation();

  const [jobs, setJobs] = useState([]);

  const debouncedFilter = useDebounce(filter, 850); // debounce to prevent firing reqs for each backspace, etc
  const [count, setCount] = useState(0);
  const [callCount, setCallCount] = useState(0);

  // manually handle load state to prevent flashing ui before state has been updated
  const [shouldShowSkeletonLoaders, setShouldShowSkeletonLoaders] = useState(
    true
  );
  const [isLoadingMoreJobs, setIsLoadingMoreJobs] = useState(false);

  // prevent apollo from refetching on args change by passing vars to lazy queries
  const [
    requestJobs,
    { variables: { limit = 50 } = {}, error, loading: areAnyJobReqsInFlight },
  ] = useLazyQuery(GET_JOBS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      const originalJobs = _get(res, ['jobs', 'rows'], []);
      setJobs(mapJobMachinesOntoJobs(originalJobs, machineId));
      setCount(_get(res, ['jobs', 'count'], 0));
      setCallCount(callCount + 1);
      setShouldShowSkeletonLoaders(false);
      setIsLoadingMoreJobs(false);
    },
    onError: () => {
      setShouldShowSkeletonLoaders(false);
      setIsLoadingMoreJobs(false);
    },
  });

  useEffect(() => {
    if (machineId) {
      requestJobs({
        variables: {
          limit: 50, // reset limit for new filter queries
          machineId,
          filter: debouncedFilter,
        },
      });
    }
  }, [requestJobs, machineId, debouncedFilter]);

  return (
    <MainLayout.Container direction="column" overlay id="select-job-page">
      <AppNav onBack={handleOnClose} onClose={handleOnClose}>
        <H5 color={palette.Yellow400} bold>
          {t('Select an Operation')}
        </H5>
      </AppNav>
      <div style={{ padding: '1.125rem' }}>
        <SearchInput
          // autofocus input after initial load only
          autofocus={callCount === 1}
          inputProps={{
            placeholder: _capitalize(t('filter by keyword')),
            onChange: (event) => {
              dispatch({ type: 'SET_FILTER', filter: event.target.value });
              // setFilter(event.target.value || '');
              // prevent showing loading when debounced filter / last req filter matches new value
              setShouldShowSkeletonLoaders(
                event.target.value !== debouncedFilter
              );
            },
            value: filter,
          }}
        />
      </div>
      <JobTable
        filter={filter}
        dispatch={dispatch}
        nextStep={nextStep}
        jobs={jobs}
        loading={
          (areAnyJobReqsInFlight && !isLoadingMoreJobs) ||
          shouldShowSkeletonLoaders
        }
        error={error}
        isLoadingMore={isLoadingMoreJobs}
        onLoadMore={
          limit < count
            ? (event) => {
                // prevents soft keyboard drawer from reopening when input still focused
                event.currentTarget.focus();
                setIsLoadingMoreJobs(true);
                requestJobs({
                  variables: {
                    limit: limit + 50,
                    machineId,
                    filter: debouncedFilter,
                  },
                });
              }
            : null
        }
      />
    </MainLayout.Container>
  );
};

export default JobList;
