import React from 'react';

const ErrorMachine = ({ width = 241, height = 187 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 241 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M21.2572 174.282C21.2572 174.282 216.427 187 216.53 187L220.771 181.758C221.234 181.322 221.825 181.09 222.467 181.09H234.78C235.294 181.09 242.902 171.943 240.563 171.943L230.693 171.789L237.376 162.488C238.198 161.358 237.479 159.765 236.091 159.636L188.256 154.831H87.3677C87.1363 154.857 86.7765 154.908 86.5451 154.934L28.1715 168.655C25.4983 169.22 18.5068 173.999 21.2572 174.282Z"
          fill="#CBD5E0"
        />
        <path
          d="M156.64 1.31042L160.727 1.92708C161.678 2.15832 162.68 3.05761 162.68 4.57355V35.3548C162.68 36.4596 161.858 37.3332 160.804 37.3332H137.002C135.974 37.3332 135.126 36.4596 135.126 35.3548V5.78116"
          fill="#FEEBC8"
        />
        <path
          d="M156.64 1.31042L160.727 1.92708C161.678 2.15832 162.68 3.05761 162.68 4.57355V35.3548C162.68 36.4596 161.858 37.3332 160.804 37.3332H137.002C135.974 37.3332 135.126 36.4596 135.126 35.3548V5.78116"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M156.486 35.8172H132.684C131.656 35.8172 130.807 34.9437 130.807 33.8388V4.26518C130.807 3.21173 131.604 2.33814 132.607 2.28675L156.408 1.28469C157.462 1.2333 158.362 2.13259 158.362 3.26312V33.8131C158.362 34.918 157.539 35.8172 156.486 35.8172Z"
          fill="#F7FAFC"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M71.987 174.399C71.987 175.735 73.4521 176.532 74.5574 175.838L101.187 159.446L71.987 151.48V174.399Z"
          fill="#FEEBC8"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M34.7361 128.497L15.0048 141.381C14.1683 141.928 13.3246 143.274 14.2757 143.58L35.1216 150.44L34.7361 128.497Z"
          fill="#FEEBC8"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M180.93 181.065L190.954 171.198C193.088 169.091 194.296 166.239 194.296 163.259V142.498C194.296 139.466 191.906 136.948 188.975 136.82L182.961 136.589V34.1728C182.961 31.1666 180.596 28.7514 177.691 28.7771L161.061 27.2869L55.4177 28.3917L180.93 181.065Z"
          fill="#FEEBC8"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M176.766 147.406L170.057 147.123V33.1708C170.057 29.8819 167.435 27.2355 164.197 27.2612L27.6317 28.5202C24.7529 28.5459 22.4138 31.0639 22.4138 34.1728V141.111L17.3244 140.905C14.4713 140.777 12.158 143.192 12.158 146.25V167.884C12.158 170.967 14.4713 173.562 17.3244 173.742L71.7654 176.774C74.7727 176.954 77.2146 174.513 77.2146 171.352V160.381L111.658 162.026V173.151C111.658 176.363 114.177 179.086 117.287 179.292L176.766 182.581C180.03 182.761 182.703 180.217 182.703 176.928V153.624C182.678 150.335 180.03 147.534 176.766 147.406Z"
          fill="#F7FAFC"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M100.477 98.4332H85.8511C82.4325 98.4332 79.6822 95.7097 79.6822 92.3695V53.2891C79.6822 51.5933 81.0702 50.2572 82.7666 50.2572H103.587C105.309 50.2572 106.671 51.619 106.671 53.2891V92.3438C106.671 95.7097 103.921 98.4332 100.477 98.4332Z"
          fill="#F7FAFC"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M183.989 146.506L189.772 141.65"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.684 32.9652L179.028 33.1965"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M123.096 127.904L119.035 127.108C103.381 123.999 85.08 123.562 69.1179 125.951L63.0517 126.851"
          stroke="#ED8936"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M41.6404 67.8575L60.0701 86.2801"
          stroke="#ED8936"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.0701 67.8575L41.6404 86.2801"
          stroke="#ED8936"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M126.875 69.8103L145.304 88.2328"
          stroke="#ED8936"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M145.304 69.8103L126.875 88.2328"
          stroke="#ED8936"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M0 153L24.0332 153"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M5 156H12.9804"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M149.292 76.4028L191.947 76.4393"
          stroke="#F7FAFC"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M148.226 20.8371L171.686 20.3029"
          stroke="#F7FAFC"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M148.903 74.3324H191.417"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M208.437 105.994L149.292 105.789"
          stroke="#F7FAFC"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M154.146 107.966H177.64"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M148.903 22.6877L171.882 22.328"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M171.882 20.2725L181.084 20.144"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M41.7494 45.9664L14.6061 45.9921"
          stroke="#F7FAFC"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M50.8424 44.0649L1.51654 44.0906"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M183.989 68.7054H200.311"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M182.678 100.386H189.258"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M64.8253 47.0712L50.8423 47.0969"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M144.585 110.895L154.326 110.92"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M13 90H30.8688"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M5 94H23.1283"
          stroke="#ED8936"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M37.5929 92H12"
          stroke="#F7FAFC"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M0 154.615H47.9892"
          stroke="#F7FAFC"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="241" height="187" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorMachine;
