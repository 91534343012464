import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';

const getLatestActivityStartAt = createSelector(
  [getLatestActivity],
  (latestActivity) => {
    return _get(latestActivity, 'start');
  }
);

export { getLatestActivityStartAt };
