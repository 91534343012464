import { createSelector } from 'reselect';
import Routes from 'lib/utils/routes';
import { LABS_URL } from 'lib/appConfig';
import { getMachine } from './getMachine';

const getTabs = (store) => {
  return store.tabs;
};

const getTabConfig = createSelector([getTabs], (tabs) => {
  return tabs.data;
});

const getTabConfigLoading = createSelector([getTabs], (tabs) => {
  return tabs.loading;
});

const getVisibleTabs = createSelector([getTabConfig], (tabs) => {
  return tabs.filter((tab) => {
    return tab.enabled;
  });
});

const getTabRoutes = createSelector(
  [getVisibleTabs, getMachine],
  (tabs, machine) => {
    return tabs.map((tab) => {
      return Routes.machineIdHomeTabPath(machine.id, tab.id);
    });
  }
);

const getHomeTabRoute = createSelector(
  [getVisibleTabs, getMachine],
  (tabs, machine) => {
    if (!tabs.length || !machine.id) {
      return null;
    }

    return Routes.machineIdHomeTabPath(machine.id, tabs[0].id);
  }
);

const getHomeTab = createSelector([getVisibleTabs], (tabs) => {
  if (!tabs.length) {
    return null;
  }

  return tabs[0].id;
});

const getVisibleTabIDs = createSelector([getVisibleTabs], (tabs) => {
  return tabs.map((tab) => {
    return tab.id || tab.name;
  });
});

const getHasPartsGoalTab = createSelector([getVisibleTabIDs], (tabs) => {
  return tabs.includes('partsGoal');
});

const getHasDowntimeTab = createSelector([getVisibleTabIDs], (tabs) => {
  return tabs.includes('downtime');
});

const historicalPartsTabs = ['partCount', 'utilization', 'partsGoal', 'oee'];

const getHasAHistoricalPartsTab = createSelector([getVisibleTabIDs], (tabs) => {
  return tabs.some((tab) => {
    return historicalPartsTabs.includes(tab);
  });
});

const getHasLabTab = createSelector([getVisibleTabs], (tabs) => {
  return tabs.some((tab) => {
    return tab.url?.includes(LABS_URL?.replace('https://', ''));
  });
});

export {
  getVisibleTabs,
  getTabRoutes,
  getHomeTabRoute,
  getHomeTab,
  getTabConfigLoading,
  getTabConfig,
  getHasPartsGoalTab,
  getHasDowntimeTab,
  getHasAHistoricalPartsTab,
  getHasLabTab,
};
