// React and Hooks
import { createSelector } from 'reselect';
// Actions
// Selectors
import { getHasActiveOperatorRun } from 'lib/selectors/getHasActiveOperatorRun';
import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';
import { getLatestOperatorRunEndAt } from 'lib/selectors/getLatestOperatorRunEndAt';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getShiftStart } from 'lib/selectors/getShiftStart';
// Utils
import { isSameOrAfter } from 'lib/utils/date';
// Components

const getAllowStartOfShift = createSelector(
  [
    getShiftStart,
    getHasActiveOperatorRun,
    checkIsLatestShiftActive,
    getLatestOperatorRun,
    getLatestOperatorRunEndAt,
  ],
  (
    shiftStart,
    hasActiveOperatorRun,
    isLatestShiftActive,
    latestOperatorRun,
    latestOperatorRunEndAt
  ) => {
    const noPreviousRun = !latestOperatorRun;
    const isPreviousRunCompleted = latestOperatorRunEndAt !== null;
    const isPreviousRunCompletedBeforeShiftStart =
      isPreviousRunCompleted &&
      isSameOrAfter(shiftStart, latestOperatorRunEndAt);
    return !!(
      shiftStart &&
      isLatestShiftActive &&
      !hasActiveOperatorRun &&
      (noPreviousRun || isPreviousRunCompletedBeforeShiftStart)
    );
  }
);

export { getAllowStartOfShift };
