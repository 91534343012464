import React from 'react';
import moment from 'moment';
import _find from 'lodash/find';
import { palette, Modal, Icon } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getLatestOperatorRunEndAt } from 'lib/selectors/getLatestOperatorRunEndAt';
import { getLatestOperator } from 'lib/selectors/getLatestOperator';
import { getShiftName } from 'lib/selectors/getShiftName';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getAllowStartOfShift } from 'lib/selectors/getAllowStartOfShift';
import { getAllowPreviousSignOut } from 'lib/selectors/getAllowPreviousSignOut';

import { timesCircle } from 'lib/icons';

import { P } from 'components/Text';
import { Button, Title, IconWrapper } from './styled';

const SignInModal = ({ operators, operator, onClose, onSubmit, isOpen }) => {
  const { t } = useTranslation();

  const shiftName = useSelector(getShiftName);
  const shiftStart = useSelector(getShiftStart);
  const latestOperatorRunEndAt = useSelector(getLatestOperatorRunEndAt);
  const latestOperator = useSelector(getLatestOperator);
  const allowPreviousSignOut = useSelector(getAllowPreviousSignOut);
  const allowStartOfShift = useSelector(getAllowStartOfShift);

  return (
    <Modal
      isOpen={isOpen || !!operator}
      onClose={onClose}
      header={
        <Title color={palette.White}>
          {t('What time would you like to login at?')}
        </Title>
      }
      headerIcon={
        <IconWrapper onClick={onClose}>
          <Icon size="2rem" color={palette.Purple200} icon={timesCircle} />
        </IconWrapper>
      }
      headerStyles={{
        background: `linear-gradient(180deg, ${palette.Purple500} 0%, #9272DB 100%)`,
      }}
      details={
        <>
          {allowPreviousSignOut && (
            <P>
              <strong>{t('Last Sign Out')}:</strong> &quot;
              {_find(operators, { id: latestOperator.id }).name}&quot;{' '}
              {t('signed out at')} {moment(latestOperatorRunEndAt).format('LT')}
            </P>
          )}
          {allowStartOfShift && (
            <P>
              <strong>{t('Current Shift')}:</strong> &quot;{shiftName}&quot;{' '}
              {t('started at')} {moment(shiftStart).format('LT')}
            </P>
          )}
        </>
      }
      buttons={
        <>
          {allowPreviousSignOut && (
            <Button
              variant="tertiary"
              onClick={() => {
                return onSubmit(operator, { pinToPreviousEvent: true });
              }}
            >
              {t('Last Sign Out')}
            </Button>
          )}
          {allowStartOfShift && (
            <Button
              variant="tertiary"
              onClick={() => {
                return onSubmit(operator, { pinToPreviousEvent: true });
              }}
            >
              {t('Start Of Shift')} - {moment(shiftStart).format('LT')}
            </Button>
          )}
          <Button
            onClick={() => {
              return onSubmit(operator, { pinToPreviousEvent: false });
            }}
          >
            {t('Now')}
          </Button>
        </>
      }
    ></Modal>
  );
};

SignInModal.propTypes = {
  operators: PropTypes.array,
  operator: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
};

export { SignInModal };
