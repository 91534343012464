import { useMemo } from 'react';
import useWindowDimensions from './useWindowDimensions';

const usePortraitMode = () => {
  const { width, height } = useWindowDimensions();
  const portraitMode = useMemo(() => {
    return height > width;
  }, [width, height]);
  return portraitMode;
};

export { usePortraitMode };
