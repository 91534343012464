import React from 'react';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { LoadingCircle, Icon, palette } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { wifiSlash, spinner } from 'lib/icons';
import { Text, Small } from 'components/Text';
import { DurationTicker } from 'components/DurationTicker';
import { getConnection } from 'lib/selectors/getConnection';
import { getOffline } from 'lib/selectors/getOffline';
import {
  Body,
  Header,
  HeaderText,
  Title,
  Description,
} from 'components/Popup/styled';
import { Overlay, Popup, LoadingWrapper } from './styled';

const Reconnect = () => {
  const { t } = useTranslation();
  const { status, disconnectedAt } = useSelector(getConnection);
  const offline = useSelector(getOffline);

  return (
    offline && (
      <>
        <Overlay />
        <Popup>
          <Header>
            <HeaderText>
              <Icon icon={wifiSlash} />
              &nbsp;&nbsp;
              {_startCase(t('not connected'))}:&nbsp;
              <DurationTicker startDate={disconnectedAt} />
            </HeaderText>
          </Header>
          <Body>
            <Title>{t('Attempting to reconnect...')}</Title>
            <Description>
              <Small>
                {t(
                  'This tablet appears to have lost its connection to the internet. Data Streaming will be paused until we can re-establish a connection.'
                )}
              </Small>
            </Description>
            <LoadingWrapper>
              {status === 'connecting' && (
                <>
                  <LoadingCircle size="1.25rem" icon={spinner} />
                  <Text color={palette.Grey700}>
                    {t('Attempting to reconnect...')}
                  </Text>
                </>
              )}
            </LoadingWrapper>
          </Body>
        </Popup>
      </>
    )
  );
};

export { Reconnect };
