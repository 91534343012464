import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from '@m12s/component-library';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import routes from 'lib/utils/routes';
import moment from 'moment';

import { getMachine } from 'lib/selectors/getMachine';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';

import { Small } from 'components/Text';

import {
  OpButton,
  TopCard,
  BottomCardContainer,
  InfoGroup,
  Divider,
} from './styled';
import { withNotApplicableFallback } from './helpers';

const formatDuration = (duration) => {
  return (
    moment.duration(duration).format('h[h] m[m] s[s]', {
      largest: 2,
    }) || '0m'
  );
};

const Top = ({ job }) => {
  const history = useHistory();
  const machine = useSelector(getMachine);
  const latestActivitySet = useSelector(getLatestActivitySet);

  const expectedCycleTime = job.expectedPartTime * job.actualParts;

  return (
    <>
      <TopCard
        label="Production Order Operation"
        value={latestActivitySet?.workOrderId || 'None'}
        subValue={
          <OpButton
            onClick={() => {
              return history.push(
                `${routes.machineIdSelectJobPath(
                  machine.id
                )}?updateWorkOrderId=true`
              );
            }}
          >
            <Icon
              icon={faEdit}
              size="0.75rem"
              style={{ marginRight: '0.375rem' }}
            />
            Change Operation
          </OpButton>
        }
      />
      <TopCard
        label="Expected cycle time"
        value={withNotApplicableFallback(
          expectedCycleTime && formatDuration(expectedCycleTime)
        )}
      />
      <TopCard
        label="Expected setup time"
        value={
          (job.setupTime && formatDuration(job.setupTime * 1000)) || 'None'
        }
      />
    </>
  );
};

Top.propTypes = {
  job: PropTypes.object,
};

const formatQuantity = (quantity) => {
  return quantity === undefined ? (
    <Small muted normal italic>
      Calculating...
    </Small>
  ) : (
    quantity?.toLocaleString()
  );
};

const Bottom = ({ job }) => {
  return (
    <BottomCardContainer>
      <InfoGroup
        top={{
          label: 'Expected part time',
          value: job.expectedPartTime
            ? formatDuration(job.expectedPartTime)
            : '-',
        }}
        bottom={{
          label: 'Quantity required',
          value: withNotApplicableFallback(
            job.quantityRequired?.toLocaleString()
          ),
        }}
      />
      <Divider />
      <InfoGroup
        top={{
          label: 'Ideal part time',
          value: withNotApplicableFallback(
            job.idealPartTime && formatDuration(job.idealPartTime)
          ),
        }}
        bottom={{
          label: 'Quantity produced',
          value: formatQuantity(job.quantityProduced),
        }}
      />
      <Divider />
      <InfoGroup
        top={{
          label: 'Description',
          value: job.description || 'None',
        }}
        bottom={{ label: 'Part', value: withNotApplicableFallback(job.part) }}
      />
    </BottomCardContainer>
  );
};

Bottom.propTypes = {
  job: PropTypes.object,
};

export default ({ job }) => {
  return (
    <>
      <Top job={job} />
      <Bottom job={job} />
    </>
  );
};
