import styled from 'styled-components';
import { Icon } from '@m12s/component-library';

import { H5 } from 'components/Text';

const Header = styled.div`
  align-items: center;
  background: ${({ color }) => {
    return color;
  }};
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
`;

const Title = styled(H5)`
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
`;

const HeaderIcon = styled(Icon)`
  font-size: 2.5rem;
  line-height: 2.5rem;
`;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding: 2rem;
`;

export { Buttons, HeaderIcon, Title, Header };
