import { toISO } from 'lib/utils/date';

/*
  this will need to change if/when scoping changes to support multiple PCM's "on-screen" / simultaneously;
  right now, it is safe to assume that any aggs to be adjusted will use the same PCM because the PCM exists at
  the activitySet level, and the scope currently only shows one set at a time. additionally, we use the agg from
  adjusted hourly totals because that is the current read-time PCM status quo in Shared/Dashboard, and this will
  ensure consistency across platforms (see Shared/dataApi/parts.js)
*/

const formattedBucket = (partValue, toCarryOverDecimal, keys, index) => {
  const intervalKey = keys[index];
  const nextIntervalKey = keys[index + 1];

  return {
    [toISO(intervalKey)]: Math.floor(partValue),
    ...(toCarryOverDecimal
      ? { [toISO(nextIntervalKey)]: toCarryOverDecimal }
      : {}),
    // if there's a decimal to carry over, add to next interval
  };
};

export const adjustPartCountBuckets = (
  partCountIntervals,
  scopeReadTimePCM
) => {
  const partCountKeys = Object.keys(partCountIntervals || {});
  const adjustedPartCountBuckets = partCountKeys.reduce(
    (adjustedPartCountBucketsAgg, intervalKey, index) => {
      const unadjustedPartsAggForBucket = parseFloat(
        partCountIntervals[intervalKey].toPrecision(3)
      );

      const carriedOverDecimal =
        adjustedPartCountBucketsAgg[partCountKeys[index]] || 0;
      // if key already exists in accum, a decimal was carried over
      let toCarryOverDecimal;

      const shouldApplyPCMAdjustment = scopeReadTimePCM !== 1;
      const nextIntervalKey = partCountKeys[index + 1];

      if (shouldApplyPCMAdjustment) {
        const adjustedPartsAggForBucket = parseFloat(
          (
            unadjustedPartsAggForBucket * scopeReadTimePCM +
            carriedOverDecimal
          ).toPrecision(3)
        );

        toCarryOverDecimal = nextIntervalKey && adjustedPartsAggForBucket % 1;

        return {
          ...adjustedPartCountBucketsAgg,
          ...formattedBucket(
            adjustedPartsAggForBucket,
            toCarryOverDecimal,
            partCountKeys,
            index
          ),
        };
      }

      // just use unadjusted count for hour when pcm === 1 (no need to apply pcm in this case)
      const partsAggForBucketWithCarryOver = parseFloat(
        (unadjustedPartsAggForBucket + carriedOverDecimal).toPrecision(3)
      );
      toCarryOverDecimal =
        nextIntervalKey && partsAggForBucketWithCarryOver % 1;

      return {
        ...adjustedPartCountBucketsAgg,
        ...formattedBucket(
          partsAggForBucketWithCarryOver,
          toCarryOverDecimal,
          partCountKeys,
          index
        ),
      };
    },
    {}
  );
  return adjustedPartCountBuckets;
};

export const sumNetPartAdjustments = (partAdjustments = []) => {
  return partAdjustments.reduce(
    (totalNetPartAdjustments, { count, scrapcount }) => {
      const netPartAdjustment = count - scrapcount;
      return totalNetPartAdjustments + netPartAdjustment;
    },
    0
  );
};
