import styled from 'styled-components';
import { palette, Icon } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';

import { questionCircle } from 'lib/icons';
import { Text } from 'components/Text';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  background: ${palette.Grey100};
  position: relative;
  z-index: ${zLayer.overlay};
`;

const TitleText = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${palette.White};
  margin-left: 0.5rem;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2.5rem;
  overflow-y: scroll;
`;

const Label = styled(Text)`
  padding-top: 4rem;
  padding-bottom: 2.5rem;
`;

const HeaderIcon = styled(Icon)`
  color: white;
  font-weight: 900;
  opacity: 0.65;
`;

const InfoHeader = styled.div`
  background: ${palette.Blue600};
  color: ${palette.White};
  box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.05),
    inset -1px -1px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  display: flex;
  flex-basis: 3rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  justify-content: center;
  line-height: 1.25rem;
`;

const InfoIcon = styled(Icon).attrs(() => {
  return { icon: questionCircle };
})`
  margin: 0.5rem;
`;

const InnerBody = styled.div`
  width: 35rem;
`;

const Group = styled.div`
  margin-bottom: 2rem;
  display: flex;

  > * {
    margin-right: 1.5rem;
  }
`;

export {
  Root,
  TitleText,
  Body,
  Label,
  HeaderIcon,
  InfoHeader,
  InfoIcon,
  InnerBody,
  Group,
};
