import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toISO } from 'lib/utils/date';
import { TEMP_REFS } from 'lib/constants';
import Routes from 'lib/utils/routes';

import { CREATE_ACTIVITY_SET } from 'lib/api/mutations';
import { throwSuccessToast, throwErrorToast } from 'lib/utils/toast';
import {
  actionCanonicalActivitySetOpen,
  actionCanonicalActivitySetDelete,
  actionOptimisticActivitySetOpen,
  actionSetJobTotal,
  actionSetWorkOrderOpActionLoading,
} from 'lib/actions';
import { getMachine } from 'lib/selectors/getMachine';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getActivitySetStandardsFromJob } from 'lib/utils/job';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

const {
  ACTIVITY_REF: optimisticActivityRef,
  ACTIVITY_SET_REF: optimisticActivitySetRef,
} = TEMP_REFS;

const useStartNewProductionRun = () => {
  const history = useHistory();
  const storeDispatch = useDispatch();
  const { t } = useTranslation();
  const workOrderFlowEnabled = useIsWorkOrderFlow();

  const machine = useSelector(getMachine);
  const job = useSelector(getLatestJob);

  const [requestCreateActivitySet] = useMutation(CREATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          createActivitySetResponse: {
            activitySet: {
              activities: [activity],
              ...activitySet
            },
          },
        },
      }
    ) => {
      storeDispatch(
        actionOptimisticActivitySetOpen({ activitySet, activity, job })
      );
      storeDispatch(actionSetJobTotal(undefined));
      history.push(Routes.machineIdPath(machine.id));
    },
    onCompleted: ({
      createActivitySetResponse: {
        activitySet: {
          activities: [activity], // should be sorted start ASC
          ...activitySet
        },
      },
    }) => {
      if (activitySet.workOrderId && workOrderFlowEnabled) {
        throwSuccessToast(`Started order ${activitySet.workOrderId}`);
      } else {
        throwSuccessToast(t('Started new production run'));
      }
      storeDispatch(
        actionCanonicalActivitySetOpen({
          activitySet,
          activity,
          optimisticActivitySetRef,
          optimisticActivityRef,
        })
      );
      if (workOrderFlowEnabled) {
        storeDispatch(actionSetWorkOrderOpActionLoading(false));
      }
    },
    onError: () => {
      storeDispatch(
        actionCanonicalActivitySetDelete({
          activitySetRef: optimisticActivitySetRef,
        })
      );
      history.push(Routes.machineIdPath(machine.id));
      throwErrorToast(t('Could not start production run'));
    },
  });

  const startNewProductionRun = (
    activityTypeRef,
    workOrderId,
    workOrderOperationRef
  ) => {
    const nowISO = toISO();
    const partCountMultiplier = job.partCountMultiplier || 1;
    const actualParts = job.actualParts || 1;
    const activitySetStandards = getActivitySetStandardsFromJob(job);

    return requestCreateActivitySet({
      variables: {
        input: {
          machineRef: machine.machineRef,
          activities: [
            {
              activityTypeRef,
              startAt: nowISO,
            },
          ],
          partCountMultiplier,
          actualParts,
          expectedUnitDuration: activitySetStandards.expectedUnitDuration,
          idealUnitDuration: activitySetStandards.idealUnitDuration,
          expectedSetupDuration: activitySetStandards.expectedSetupDuration,
          optimalUnitDuration: activitySetStandards.optimalUnitDuration,
          jobId: job.jobId,
          workOrderId,
          ...(workOrderOperationRef ? { workOrderOperationRef } : {}),
        },
        query: { shouldCloseLatestSet: true },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createActivitySetResponse: {
          __typename: 'ActivitySet',
          activitySet: {
            partCountMultiplier,
            actualParts,
            expectedUnitDuration: activitySetStandards.expectedUnitDuration,
            idealUnitDuration: activitySetStandards.idealUnitDuration,
            expectedSetupDuration: activitySetStandards.expectedSetupDuration,
            optimalUnitDuration: activitySetStandards.optimalUnitDuration,
            jobId: job.jobId,
            activitySetRef: optimisticActivitySetRef,
            jobScheduleGroupId: null,
            closedAt: null,
            workOrderId,
            ...(workOrderOperationRef ? { workOrderOperationRef } : {}),
            activities: [
              {
                activityRef: optimisticActivityRef,
                activitySetRef: optimisticActivitySetRef,
                activityTypeRef,
                start: nowISO,
                end: null,
                updatedAt: nowISO,
                jobScheduleGroupId: null,
              },
            ],
          },
        },
      },
    });
  };

  return startNewProductionRun;
};

export default useStartNewProductionRun;
