import { millis, now } from 'lib/utils/date';
import {
  chopAfter,
  chopBefore,
  mergeContiguousDowntimeIntervals,
} from 'lib/utils/intervals';

const types = {
  ANNOTATION: 0,
  DOWNTIME: 1,
};

function duration(start, end) {
  const endDate = end || millis(now());
  return Math.round((endDate - start) / 1000);
}

function parseDate(date) {
  if (date) return millis(date);
  return null;
}

const setupAnnotation = {
  annotationType: {
    color: '#ffcc00',
    name: 'Setup',
  },
  end: null,
  planned: true,
  type: types.ANNOTATION,
  setup: true,
};

function downtimeIntervals(
  initialAnnotations = [],
  downtimes = [],
  jobRun,
  jobStatus,
  areActivitiesEnabled
) {
  const annotations = [...initialAnnotations];

  if (!areActivitiesEnabled) {
    // activities do not create annotations for setup, so only insert 'artificial'
    // setup anno here when they are not enabled
    const setupStart = jobStatus === 'setup' && jobRun && jobRun.startDate;
    const activeSetupAnnotation = { ...setupAnnotation, start: setupStart };
    if (setupStart) {
      annotations.push(activeSetupAnnotation);
    }
  }

  // first merge contiguous idle and unavailable intervals
  const downtimesMerged = mergeContiguousDowntimeIntervals(downtimes);

  const [combinedList, remainingDowntimeList] = annotations.reduce(
    ([combined, remainingDowntimes], annotation) => {
      const downtimesBefore = chopAfter(remainingDowntimes, annotation.start);
      const remaining = chopBefore(
        remainingDowntimes,
        annotation.isEndSet ? annotation.end : null
      );

      return [[...combined, ...downtimesBefore, annotation], remaining];
    },
    [[], [...downtimesMerged]]
  );

  return combinedList.concat(remainingDowntimeList).map((interval) => {
    const isAnnotation = !!interval.annotationType;
    const end = isAnnotation && !interval.isEndSet ? null : interval.end;
    const startMs = parseDate(interval.start);
    const endMs = parseDate(interval.end);
    return {
      ...interval,
      timestamp: startMs,
      duration: duration(startMs, endMs),
      categorized: !!interval.annotationType,
      end,
    };
  });
}

export { downtimeIntervals };
