import { createSelector } from 'reselect';
import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';

const getLatestOperatorId = createSelector(
  [getLatestOperatorRun],
  (latestOperatorRun) => {
    return latestOperatorRun?.operatorId;
  }
);

export { getLatestOperatorId };
