import React from 'react';

const NoResults = () => {
  return (
    <svg
      width="212"
      height="144"
      viewBox="0 0 212 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.6613 144C137.174 144 170.016 111.764 170.016 72C170.016 32.2355 137.174 0 96.6613 0C56.1486 0 23.3066 32.2355 23.3066 72C23.3066 111.764 56.1486 144 96.6613 144Z"
        fill="#EBF4FF"
      />
      <g filter="url(#filter0_d_5575_281)">
        <path
          d="M180.506 75.6055H48.4675C45.7666 75.6055 43.5771 77.7545 43.5771 80.4055V104.405C43.5771 107.056 45.7666 109.205 48.4675 109.205H180.506C183.207 109.205 185.396 107.056 185.396 104.405V80.4055C185.396 77.7545 183.207 75.6055 180.506 75.6055Z"
          fill="white"
        />
      </g>
      <path
        d="M111.063 83.2852H85.6334C84.0129 83.2852 82.6992 84.5746 82.6992 86.1652C82.6992 87.7557 84.0129 89.0452 85.6334 89.0452H111.063C112.684 89.0452 113.997 87.7557 113.997 86.1652C113.997 84.5746 112.684 83.2852 111.063 83.2852Z"
        fill="#C3DAFE"
      />
      <path
        d="M128.668 95.7656H85.6334C84.0129 95.7656 82.6992 97.055 82.6992 98.6456C82.6992 100.236 84.0129 101.526 85.6334 101.526H128.668C130.289 101.526 131.602 100.236 131.602 98.6456C131.602 97.055 130.289 95.7656 128.668 95.7656Z"
        fill="#EBF4FF"
      />
      <path
        d="M74.8755 92.4063C74.8755 84.7185 68.5261 78.4863 60.6936 78.4863C52.8612 78.4863 46.5117 84.7185 46.5117 92.4063C46.5117 100.094 52.8612 106.326 60.6936 106.326C68.5261 106.326 74.8755 100.094 74.8755 92.4063Z"
        fill="#5A67D8"
      />
      <path
        d="M59.8138 96.9125C61.0279 96.9123 62.2071 96.5136 63.1637 95.7797L66.1712 98.7317L67.1385 97.7822L64.13 94.8293C64.8859 93.8701 65.2885 92.6878 65.2722 91.4753C65.2559 90.2627 64.8216 89.0913 64.0402 88.152C63.2588 87.2127 62.1763 86.5609 60.9692 86.3027C59.762 86.0445 58.5014 86.1953 57.3927 86.7304C56.284 87.2655 55.3927 88.1534 54.8639 89.2494C54.3352 90.3454 54.2002 91.5849 54.481 92.7658C54.7618 93.9467 55.4418 94.9995 56.4102 95.7525C57.3786 96.5055 58.5783 96.9144 59.8138 96.9125ZM59.8138 87.5102C60.6263 87.5102 61.4205 87.7467 62.096 88.1898C62.7715 88.6328 63.2981 89.2625 63.609 89.9993C63.9199 90.736 64.0012 91.5467 63.8427 92.3289C63.6842 93.111 63.293 93.8294 62.7185 94.3933C62.144 94.9572 61.4121 95.3412 60.6152 95.4968C59.8184 95.6523 58.9924 95.5725 58.2418 95.2673C57.4912 94.9622 56.8496 94.4454 56.3982 93.7823C55.9469 93.1192 55.7059 92.3397 55.7059 91.5422C55.7059 90.4729 56.1387 89.4473 56.9091 88.6912C57.6795 87.935 58.7243 87.5102 59.8138 87.5102Z"
        fill="white"
      />
      <g filter="url(#filter1_d_5575_281)">
        <path
          d="M200.776 29.1816H68.738C66.0371 29.1816 63.8477 31.3307 63.8477 33.9816V57.9816C63.8477 60.6326 66.0371 62.7816 68.738 62.7816H200.776C203.477 62.7816 205.667 60.6326 205.667 57.9816V33.9816C205.667 31.3307 203.477 29.1816 200.776 29.1816Z"
          fill="white"
        />
      </g>
      <path
        d="M131.334 36.8613H105.904C104.283 36.8613 102.97 38.1507 102.97 39.7413C102.97 41.3319 104.283 42.6213 105.904 42.6213H131.334C132.954 42.6213 134.268 41.3319 134.268 39.7413C134.268 38.1507 132.954 36.8613 131.334 36.8613Z"
        fill="#C3DAFE"
      />
      <path
        d="M148.939 49.3418H105.904C104.283 49.3418 102.97 50.6312 102.97 52.2218C102.97 53.8124 104.283 55.1018 105.904 55.1018H148.939C150.559 55.1018 151.873 53.8124 151.873 52.2218C151.873 50.6312 150.559 49.3418 148.939 49.3418Z"
        fill="#EBF4FF"
      />
      <path
        d="M95.146 45.9825C95.146 38.2947 88.7966 32.0625 80.9641 32.0625C73.1317 32.0625 66.7822 38.2947 66.7822 45.9825C66.7822 53.6703 73.1317 59.9025 80.9641 59.9025C88.7966 59.9025 95.146 53.6703 95.146 45.9825Z"
        fill="#5A67D8"
      />
      <path
        d="M80.0843 50.4887C81.2984 50.4885 82.4776 50.0897 83.4342 49.3559L86.4417 52.3079L87.409 51.3584L84.4005 48.4055C85.1564 47.4462 85.559 46.264 85.5427 45.0515C85.5264 43.8389 85.0921 42.6675 84.3107 41.7282C83.5293 40.7889 82.4469 40.137 81.2397 39.8789C80.0325 39.6207 78.7719 39.7715 77.6632 40.3066C76.5545 40.8417 75.6632 41.7296 75.1344 42.8256C74.6057 43.9216 74.4707 45.161 74.7515 46.342C75.0323 47.5229 75.7123 48.5756 76.6807 49.3287C77.6491 50.0817 78.8488 50.4906 80.0843 50.4887ZM80.0843 41.0864C80.8968 41.0864 81.691 41.3229 82.3665 41.7659C83.0421 42.209 83.5686 42.8387 83.8795 43.5754C84.1904 44.3122 84.2717 45.1229 84.1132 45.905C83.9547 46.6872 83.5635 47.4056 82.989 47.9695C82.4145 48.5334 81.6826 48.9174 80.8857 49.0729C80.0889 49.2285 79.2629 49.1487 78.5123 48.8435C77.7617 48.5383 77.1201 48.0215 76.6688 47.3585C76.2174 46.6954 75.9765 45.9159 75.9765 45.1184C75.9765 44.0491 76.4092 43.0235 77.1796 42.2674C77.95 41.5112 78.9948 41.0864 80.0843 41.0864Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_5575_281"
          x="37.5771"
          y="72.6055"
          width="153.819"
          height="45.5996"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5575_281"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5575_281"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5575_281"
          x="57.8477"
          y="26.1816"
          width="153.819"
          height="45.5996"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5575_281"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5575_281"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default NoResults;
