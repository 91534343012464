import { createSelector } from 'reselect';

import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestExpectedUnitDuration } from 'lib/selectors/getLatestExpectedUnitDuration';
import { getLatestIdealUnitDuration } from 'lib/selectors/getLatestIdealUnitDuration';
import { getLatestActualParts } from 'lib/selectors/getLatestActualParts';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getTotalScopeScheduledTimeMs } from 'lib/selectors/getTotalScopeScheduledTimeMs';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeEnd } from 'lib/selectors/getScopeEnd';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';
import { getScheduledCurrentCycleActiveTimeMs } from 'lib/selectors/getScopeCurrentCycleActiveTime';
import { diff, getUnitDurationFromString, toISO } from 'lib/utils/date';
import { getScopeFirstPartTimestamp } from 'lib/selectors/getScopeFirstPartTimestamp';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { getScopeNetPartsMade } from './getScopeNetPartsMade';
import { getScopeCurrentCycleTimeMs } from './getScopeCurrentCycleTimeMs';

const defaultScopePartsGoal = {
  partsMadePercent: null,
  expectedParts: null,
  totalExpectedParts: null,
  priorPartProgress: 0,
  partsDiff: 0,
  cyclesDiff: 0,
};

const getScopePartsGoal = createSelector(
  [
    getAreActivitiesEnabled,
    getLatestExpectedUnitDuration,
    getLatestIdealUnitDuration,
    getLatestActualParts,
    getHasOpenActivity,
    getScopeStart,
    getScopeEnd,
    getTotalScopeScheduledTimeMs,
    getScopeNetPartsMade,
    getScheduledCurrentCycleActiveTimeMs,
    getScopeCurrentCycleTimeMs,
    getScopeFirstPartTimestamp,
    getHeavyUpdate, // force recalc every fifteen seconds
    getScopeReadTimePCM,
  ],
  (
    areActivitiesEnabled,
    expectedUnitDuration,
    idealUnitDuration,
    actualParts,
    hasOpenActivity,
    scopeStart,
    scopeEnd,
    totalScopeScheduledTimeMs,
    scopeNetPartsMade,
    scheduledCurrentCycleActiveTimeMs,
    currentCycleTimeMs,
    scopeFirstPartTimestamp,
    _heavyUpdate, // eslint-disable-line no-unused-vars
    scopeReadTimePCM
  ) => {
    const expectedUnitDurationMs = getUnitDurationFromString(
      expectedUnitDuration
    );
    const idealUnitDurationMs = getUnitDurationFromString(idealUnitDuration);

    if (
      !scopeStart ||
      !scopeEnd ||
      // do not calc goal for 'invalid' (0, nullish) durations
      !expectedUnitDuration ||
      expectedUnitDurationMs <= 0 ||
      !actualParts ||
      (areActivitiesEnabled &&
        // paused activity sets should have no goal in r1
        (!hasOpenActivity ||
          // no parts goal when no prod time in scope
          !totalScopeScheduledTimeMs))
    ) {
      return defaultScopePartsGoal;
    }

    /* Adjust for parts that may have started in previous scope */

    const nowISO = toISO();
    const remainingScopeDurationMs = diff(scopeEnd, nowISO, 'milliseconds');
    const scopeDurationMs = diff(scopeEnd, scopeStart, 'milliseconds');

    const totalEstimatedScopeScheduledTime = areActivitiesEnabled
      ? // heuristic, assumes 100% production time for remainder of scope
        totalScopeScheduledTimeMs + remainingScopeDurationMs
      : scopeDurationMs;

    let totalExpectedParts =
      totalEstimatedScopeScheduledTime / expectedUnitDurationMs;

    let priorPartProgress = 0;

    if (scopeFirstPartTimestamp) {
      const timeToFirstPartMs = diff(
        scopeFirstPartTimestamp,
        scopeStart,
        'milliseconds'
      );

      if (timeToFirstPartMs < expectedUnitDurationMs) {
        priorPartProgress =
          (expectedUnitDurationMs - timeToFirstPartMs) / expectedUnitDurationMs;
        totalExpectedParts += priorPartProgress;
      }
    }

    /*
      calculate expected parts at 'now', ratcheted appropriately per operator expectations -
      'actualParts' represents the number of parts actually produced per cycle. we 'cap' the expected part
      count at multiples of the 'actualParts' factor to ensure that the parts tick in batch sizes that
      match the operator's real-time expectations for that machine. ex: the expected counter for an actualParts
      value of 5 should only tick in intervals of 5 (instead of 1 at a time)
    */

    const msSinceScopeStart = diff(nowISO, scopeStart, 'milliseconds');

    const totalScopeProdAggShimMs = areActivitiesEnabled
      ? totalScopeScheduledTimeMs
      : msSinceScopeStart;

    const unadjustedExpectedPartsNow =
      totalScopeProdAggShimMs / expectedUnitDurationMs + priorPartProgress;
    const expectedPartsNow = Math.round(
      Math.floor(unadjustedExpectedPartsNow / actualParts) * actualParts
    );

    /* Calculate percent of current cycle that's been ACTIVE, to contribute it to parts goal.
      Jacob (2017): The cycle is credited the difference between expected cycle time and ideal cycle time before time out of cycle starts penalizing it.
      Ben (2021): We cap the active cycle contribution at expected cycle time so that an overly long current cycle that is active does not skew parts goal upwards.
    */

    const activeCycleAdjustment = Math.max(
      expectedUnitDurationMs - (idealUnitDurationMs || 0),
      0
    );

    const expectedCycleTimeMs = expectedUnitDurationMs * actualParts;
    const activeCycleCap = Math.min(expectedCycleTimeMs, currentCycleTimeMs);
    /* Calculate Parts Goal */

    const partsMadeDecimal =
      (scopeNetPartsMade * expectedUnitDurationMs +
        Math.min(
          scheduledCurrentCycleActiveTimeMs + activeCycleAdjustment,
          activeCycleCap
        )) /
      (totalScopeProdAggShimMs + priorPartProgress * expectedUnitDurationMs);

    const partsDiff = scopeNetPartsMade - expectedPartsNow;
    const isBehind = partsDiff < 0;
    const isAtGoal = partsDiff === 0;

    const cyclesDiff = partsDiff / scopeReadTimePCM;

    const expectedCyclesNow = expectedPartsNow / scopeReadTimePCM;

    return {
      partsMadePercent: Math.round(partsMadeDecimal * 100),
      expectedPartsNow,
      expectedCyclesNow,
      totalExpectedParts: Math.floor(totalExpectedParts),
      priorPartProgress,
      isAtGoal,
      isBehind,
      partsDiff,
      cyclesDiff,
    };
  }
);

export { getScopePartsGoal };
