import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from 'lib/selectors/getCompany';
import { getUser } from 'lib/selectors/getUser';
import { RELEASE_STAGE } from 'lib/appConfig';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';

const useAnalytics = () => {
  const company = useSelector(getCompany);
  const user = useSelector(getUser);
  const pendo = window.pendo || null;
  const userId = user?.id?.startsWith('operator')
    ? user?.id
        ?.split(':')
        .slice(0, 2)
        .join(':')
    : user?.id;

  useEffect(() => {
    /*
      pendo _excludes_ data based on host/domain: it is handy to still report on dev/staging,
      as pendo can parse out testing data (unlike most of our analytics integrations, which is why
      shouldReportAnalytics may be false - meant as pollution protection setting for _ALL_ integrations)
    */
    const isSafeToReportToPendo =
      RELEASE_STAGE === 'development' ||
      RELEASE_STAGE === 'staging' ||
      user.shouldReportAnalytics;
    if (
      user &&
      userId &&
      company &&
      company.id &&
      isSafeToReportToPendo &&
      pendo
    ) {
      const hasBeenInitialized = !!(
        _isFunction(pendo.isReady) && pendo.isReady()
      );
      const currentOptions = {
        visitor: {
          id: userId, // reserved pendo key
          email: _get(user, 'email'), // reserved pendo key
          full_name: _get(user, 'displayName'), // reserved pendo key
          role: _get(user, 'role'), // reserved pendo key
          createdAt: _get(user, 'createdAt'), // custom MM key
        },
        account: {
          id: _get(company, 'id'), // reserved pendo key
          name: _get(company, 'name'), // reserved pendo key
          creationDate: _get(company, 'createdAt'), // reserved pendo key
          trialExpirationDate: _get(company, 'trialExpirationDate'), // custom MM key
          onboardedAt: _get(company, 'onboardedAt'), // custom MM key
          timezone: _get(company, 'timezone'), // custom MM key
        },
      };
      // Disable at Paragon Holyoke for performance testing
      if (_get(company, 'id') === '4f483a94-5239-4ff6-8c6c-14f3ee4c9015') {
        return;
      }

      if (!hasBeenInitialized) {
        if (pendo.initialize) {
          pendo.initialize(currentOptions);
        }
      } else {
        pendo.updateOptions(currentOptions);
      }
    }
  }, [user, company, pendo, userId]);
};

export { useAnalytics };
