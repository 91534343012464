import styled from 'styled-components';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Chart = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
`;

export { Root, Header, Chart };
