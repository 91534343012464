import { createSelector } from 'reselect';
import { toISO } from 'lib/utils/date';
import { getUtilPageScope } from 'lib/selectors/getUtilPageScope';

const getUtilPageScopeEnd = createSelector(
  [getUtilPageScope],
  ({ endDate }) => {
    if (!endDate) {
      return null;
    }
    return toISO(endDate);
  }
);

export { getUtilPageScopeEnd };
