import { createSelector } from 'reselect';

import { getPartitionedStatusIntervals } from 'lib/selectors/getPartitionedStatusIntervals';

const getActiveStatusIntervals = createSelector(
  [getPartitionedStatusIntervals],
  ([activeStatusIntervals]) => {
    return activeStatusIntervals;
  }
);

export { getActiveStatusIntervals };
