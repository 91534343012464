import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';

const getLatestActualParts = createSelector(
  [getLatestActivitySet],
  (latestActivitySet) => {
    return _get(latestActivitySet, 'actualParts');
  }
);

export { getLatestActualParts };
