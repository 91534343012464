import _isFunction from 'lodash/isFunction';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

import { diff } from 'lib/utils/date';

const getCheckIsTargetActivityTypeRef = (targetActivityTypeRefs = []) => {
  return (activityTypeRef) => {
    if (!_isArray(targetActivityTypeRefs) || _isEmpty(targetActivityTypeRefs)) {
      return false;
    }
    return _includes(targetActivityTypeRefs, activityTypeRef);
  };
};

const getAggClosedActivitiesMs = ({ activities = [], filterCb }) => {
  return activities.reduce((aggMs, activity) => {
    const { start, end } = activity;
    if (_isFunction(filterCb)) {
      const shouldIncludeActivityInAgg = filterCb(activity);
      if (!shouldIncludeActivityInAgg) {
        return aggMs;
      }
    }
    if (!end) {
      // only include closed activities
      return aggMs;
    }
    return aggMs + diff(end, start, 'milliseconds');
  }, 0);
};

export { getAggClosedActivitiesMs, getCheckIsTargetActivityTypeRef };
