import React, { useState, useEffect, useCallback, useRef } from 'react';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { ControlBarButton, palette, Icon } from '@m12s/component-library';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  bars,
  infoCircle,
  signIn,
  random,
  globe,
  bolt,
  farTicketAlt,
} from 'lib/icons';
import Routes from 'lib/utils/routes';
import TokenStore from 'lib/api/links/TokenStore';

import { getMachine } from 'lib/selectors/getMachine';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { STOP_OPERATOR } from 'lib/api/mutations';
import {
  actionOpenLanguageModal,
  actionToggleTraining,
  actionSetRecentOperatorRuns,
  actionOpenLaborTicketModal,
} from 'lib/actions';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { getUser } from 'lib/selectors/getUser';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

import {
  Root,
  Menu,
  MenuList,
  MenuItem,
  MenuIcon,
  MenuLink,
  MenuButton,
} from './styled';

const UtilityMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const laborTicketEnabled = useIsLaborTicketFlow();
  const [open, setOpen] = useState(false);

  const operator = useSelector(getCurrentOperator);
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);

  const machine = useSelector(getMachine);
  const isOperatorTrackingEnabled = useSelector(getIsOperatorTrackingEnabled);
  const user = useSelector(getUser);
  const [stopOperator] = useMutation(STOP_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ stopOperatorResponse }) => {
      if (stopOperatorResponse) {
        const {
          machine: localMachine,
          operator: localOperator,
          ...operatorRun
        } = stopOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: localMachine?.id,
              operatorId: localOperator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
      }
    },
  });
  const presetMachineToken = new TokenStore('presetMachine');
  const switchDisabled = new TokenStore('switchDisabled').get();
  const onClickOutside = useCallback((event) => {
    if (!ref.current.contains(event.target)) setOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => {
      return document.removeEventListener('mousedown', onClickOutside);
    };
  }, [onClickOutside]);

  const onLogout = useCallback(() => {
    stopOperator({
      variables: {
        input: {
          machineId: machine.id,
        },
      },
    });
    setOpen(false);
  }, [machine, stopOperator, setOpen]);

  return (
    <Root id="utility-menu" ref={ref}>
      <ControlBarButton
        id="utility-menu"
        icon={bars}
        iconSize="2.5rem"
        fitToContent
        onClick={() => {
          return setOpen(!open);
        }}
        backgroundColor={open ? palette.Grey300 : palette.White}
      />
      {open && (
        <Menu>
          <MenuList>
            {laborTicketEnabled && canCloseLaborTicket && (
              <MenuItem>
                <MenuButton
                  onClick={() => {
                    return dispatch(actionOpenLaborTicketModal());
                  }}
                  id="labor-ticket"
                >
                  <MenuIcon>
                    <Icon color={palette.Grey600} icon={farTicketAlt} />
                  </MenuIcon>
                  {_startCase('labor ticket')}
                </MenuButton>
              </MenuItem>
            )}
            {isOperatorTrackingEnabled && operator && (
              <MenuItem>
                <MenuButton
                  onClick={() => {
                    if (laborTicketEnabled && canCloseLaborTicket) {
                      history.push(Routes.machineIdHomePath(machine.id));
                      return dispatch(
                        actionOpenLaborTicketModal(
                          LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT
                        )
                      );
                    }
                    return onLogout();
                  }}
                  id="sign-out"
                >
                  <MenuIcon>
                    <Icon color={palette.Grey600} icon={signIn} />
                  </MenuIcon>
                  {_startCase(t('sign out'))}
                </MenuButton>
              </MenuItem>
            )}
            {isOperatorTrackingEnabled && !operator && (
              <MenuItem>
                <MenuLink
                  to={Routes.machineIdLoginPath(machine.id)}
                  id="select-user"
                >
                  <MenuIcon>
                    <Icon color={palette.Grey600} icon={signIn} />
                  </MenuIcon>
                  {_startCase(t('select user'))}
                </MenuLink>
              </MenuItem>
            )}
            {(!switchDisabled || user.isRoot) && (
              <MenuItem>
                <MenuLink
                  onClick={() => {
                    presetMachineToken.clear();
                  }}
                  to={Routes.switchMachinesPath()}
                  id="switch-machine"
                >
                  <MenuIcon>
                    <Icon color={palette.Grey600} icon={random} />
                  </MenuIcon>
                  {_startCase(t('switch machine'))}
                </MenuLink>
              </MenuItem>
            )}
            <MenuItem>
              <MenuButton
                onClick={() => {
                  return dispatch(
                    actionToggleTraining({ toggleTraining: true })
                  );
                }}
                id="training-pdf"
              >
                <MenuIcon>
                  <Icon color={palette.Grey600} icon={infoCircle} />
                </MenuIcon>
                {_startCase(t('Quick Start Guide'))}
              </MenuButton>
            </MenuItem>
            <MenuItem>
              <MenuButton
                onClick={() => {
                  return dispatch(actionOpenLanguageModal());
                }}
                id="select-language"
              >
                <MenuIcon>
                  <Icon color={palette.Grey600} icon={globe} />
                </MenuIcon>
                {_startCase(t('select language'))}
              </MenuButton>
            </MenuItem>
            <MenuItem>
              <MenuLink
                to={Routes.machineIdSelectWorkflowPath(machine.id)}
                id="workflow-trigger"
              >
                <MenuIcon>
                  <Icon color={palette.Grey600} icon={bolt} />
                </MenuIcon>
                {_startCase(t('workflows'))}
              </MenuLink>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Root>
  );
};

export { UtilityMenu };
