import React from 'react';
import PropTypes from 'prop-types';
import { palette } from '@m12s/component-library';
import _get from 'lodash/get';

import MainLayout from 'components/Layouts/MainLayout';
import { Table } from './Table';
import { Nav } from './Nav';

const SelectActivityType = ({
  handleOnBack,
  handleOnSelect = () => {},
  handleOnClose,
  jobName,
  job,
  newWorkOrderId,
  isStopAndStartRun,
  workOrderOp,
  showAllTypes,
  ...props
}) => {
  const disallowSetup = _get(job, 'setupTime', undefined) === null;

  return (
    <MainLayout.Column id="select-activity-type">
      <Nav
        handleOnClose={handleOnClose}
        handleOnBack={handleOnBack}
        jobName={jobName}
        {...props}
      />
      <MainLayout.Container direction="column" bgColor={palette.Grey100}>
        <Table
          search=""
          sort=""
          handleOnSelect={handleOnSelect}
          disallowSetup={disallowSetup}
          isStopAndStartRun={isStopAndStartRun}
          newWorkOrderId={newWorkOrderId}
          showAllTypes={showAllTypes}
        />
      </MainLayout.Container>
    </MainLayout.Column>
  );
};

SelectActivityType.propTypes = {
  jobName: PropTypes.string.isRequired,
  handleOnBack: PropTypes.func,
  handleOnSelect: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  job: PropTypes.object,
  newWorkOrderId: PropTypes.string,
  isStopAndStartRun: PropTypes.bool,
  workOrderOp: PropTypes.object,
};

export { SelectActivityType };
