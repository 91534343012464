import React from 'react';
import { Icon } from '@m12s/component-library';

import { minus, plus } from 'lib/icons';

import { Wrapper, Button, Input } from './styled';

const NumberInput = ({ value, onChange, min = 0, max = Infinity }) => {
  return (
    <Wrapper>
      <Button
        disabled={value <= min}
        onClick={() => {
          if (value > min) {
            onChange((value || 0) - 1);
          }
        }}
      >
        <Icon icon={minus} />
      </Button>
      <Input
        onChange={(e) => {
          return onChange(
            Math.max(Math.min(parseInt(e.target.value, 10), max), min)
          );
        }}
        type="number"
        value={value}
        min={min}
        max={max}
      />
      <Button
        disabled={value >= max}
        onClick={() => {
          if (value < max) {
            onChange((value || 0) + 1);
          }
        }}
      >
        <Icon icon={plus} />
      </Button>
    </Wrapper>
  );
};

export { NumberInput };
