import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import { REST_URI } from 'lib/appConfig';
import { getAuthHeaders } from './helpers';
import session from './session';

const authLink = setContext(async (_, { headers }) => {
  const authHeaders = getAuthHeaders(await session.getApiKey());
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const restLink = new RestLink({ uri: REST_URI });

export default authLink.concat(restLink);
