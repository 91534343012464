import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import {
  palette,
  CounterBlock,
  Icon,
  OverlayButton,
  OverlayContainer,
} from '@m12s/component-library';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { formatPercentFromDecimal } from 'lib/utils/text';
import { times, questionCircle } from 'lib/icons';
import { getAvailabilityDecimal } from 'lib/selectors/getAvailabilityDecimal';
import { getPerformanceDecimal } from 'lib/selectors/getPerformanceDecimal';
import { getQualityDecimal } from 'lib/selectors/getQualityDecimal';
import { getOEEDecimal } from 'lib/selectors/getOEEDecimal';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';

import { DefaultLoading } from 'components/Loading';
import { Root, TopRow, BottomRow, Times, FadedText } from './styled';

const Multiplier = () => {
  return (
    <Times>
      <Icon icon={times} size="1.75rem" color={palette.Grey400} />
    </Times>
  );
};

const OEE = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const availabilityDecimal = useSelector(getAvailabilityDecimal);
  const performanceDecimal = useSelector(getPerformanceDecimal);
  const qualityDecimal = useSelector(getQualityDecimal);
  const oeeDecimal = useSelector(getOEEDecimal);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  if (arePartCountBucketsLoading) return <DefaultLoading />;

  return (
    <OverlayContainer>
      <PauseDetailsOverlayContainer>
        <Root>
          <TopRow>
            <CounterBlock
              label={`${_startCase(t('overall equipment effectiveness'))}`}
              value={formatPercentFromDecimal(oeeDecimal)}
            />
            <OverlayButton
              open={open}
              onToggle={(state) => {
                return setOpen(state);
              }}
              openLabel={_startCase(t('learn more about this metric'))}
              closeLabel={_startCase(t('close overlay'))}
              openIcon={questionCircle}
              closeIcon={times}
            >
              <p>
                {t(
                  'OEE (Overall Equipment Effectiveness) is a metric that measures the efficiency of the Machine and takes into consideration the Availability, Performance, and Quality.'
                )}
              </p>
              <p>
                {t(
                  'Availability indicates the amount of in-cycle time compared to total time. Performance indicates the amount of in-cycle time it takes to make each part compared to the ideal time it takes to make that part. Quality indicates the number of good parts accepted compared to the total number of parts produced.'
                )}
              </p>
              <p>
                {t(
                  'An increase in OEE indicates that more good parts were made faster which results in increased revenue.'
                )}
              </p>
            </OverlayButton>
          </TopRow>
          <BottomRow>
            <CounterBlock
              label={`${_startCase(t('availability'))}`}
              value={
                <FadedText>
                  {formatPercentFromDecimal(availabilityDecimal)}
                </FadedText>
              }
            />
            <Multiplier />
            <CounterBlock
              label={`${_startCase(t('performance'))}`}
              value={
                <FadedText>
                  {formatPercentFromDecimal(performanceDecimal)}
                </FadedText>
              }
            />
            <Multiplier />
            <CounterBlock
              label={`${_startCase(t('quality'))}`}
              value={
                <FadedText>
                  {formatPercentFromDecimal(qualityDecimal)}
                </FadedText>
              }
            />
          </BottomRow>
        </Root>
        <PauseDetailsToggle />
      </PauseDetailsOverlayContainer>
    </OverlayContainer>
  );
};

export { OEE };
