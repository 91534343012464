import _get from 'lodash/get';
import { createSelector } from 'reselect';

const defaultUser = {};

export const getUser = (state) => {
  return _get(state, ['user', 'data'], defaultUser);
};

export const getUserLoading = (state) => {
  return state.user.loading;
};

export const getIsRoot = createSelector([getUser], (user) => {
  return user && user.isRoot;
});
