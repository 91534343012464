import { useCallback, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';

import { getMachine } from 'lib/selectors/getMachine';
import { CREATE_ANNOTATION, UPDATE_ANNOTATION } from 'lib/api/mutations';
import { toISO, now } from 'lib/utils/date';
import {
  actionSubmitAnnotation,
  actionSubmitAnnotationFailure,
  actionSubmitAnnotationSuccess,
} from 'lib/actions';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';
import { useTranslation } from 'react-i18next';
import {
  getAnnotations,
  getAreAnnotationsLoading,
} from 'lib/selectors/getAnnotations';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
import { bugsnag } from 'lib/external/bugsnag';

const initialState = {
  downtimes: {},
  annotationType: null,
  planned: false,
  plannedEnabled: true,
  note: '',
  splitting: '',
};

const plannedBehaviorToRadioValue = (value) => {
  if (value === 'operator' || value === 'unplanned') return false;
  return true;
};

const isPlannedEnabled = (annotationType) => {
  return annotationType.plannedBehavior === 'operator';
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ANNOTATION_TYPE':
      return {
        ...state,
        annotationType: action.annotationType,
        plannedEnabled: isPlannedEnabled(action.annotationType),
        planned: plannedBehaviorToRadioValue(
          action.annotationType.plannedBehavior
        ),
      };
    case 'UNSET_ANNOTATION_TYPE':
      return {
        ...state,
        annotationType: null,
        plannedEnabled: true,
        planned: false,
      };
    case 'SET_NOTE':
      return { ...state, note: action.note };
    case 'SET_PLANNED':
      return { ...state, planned: action.planned };
    case 'TOGGLE_DOWNTIME':
      if (state.downtimes[action.downtime.start]) {
        return {
          ...state,
          downtimes: _omit(state.downtimes, action.downtime.start),
          note: '',
          splitting: false,
        };
      }
      return {
        ...state,
        downtimes: {
          ...state.downtimes,
          [action.downtime.start]: action.downtime,
        },
        note: action.downtime.message,
        splitting: false,
      };

    case 'SELECT_DOWNTIME_TO_SPLIT':
      return {
        ...state,
        downtimes: {
          [action.downtime.start]: action.downtime,
        },
        note: '',
        splitting: true,
      };
    case 'CLEAR_DOWNTIMES':
      return {
        ...state,
        downtimes: {},
        note: '',
        splitting: false,
      };
    default:
      throw new Error();
  }
}

const formatDowntimesForRequest = (state, machine) => {
  const { annotationType, downtimes, note, planned, splitting } = state;

  const categorized = _get(Object.values(downtimes), [0, 'categorized'], false);

  const payload = Object.values(downtimes).map((downtime) => {
    return {
      id: downtime.id || null,
      category_id: annotationType.id,
      end: downtime.end || null,
      machineId: machine.id,
      message: note,
      planned,
      start: splitting ? toISO(now()) : downtime.start,
    };
  });

  return [payload, categorized, splitting];
};

const useCategorization = ({ returnPath }) => {
  const globalDispatch = useDispatch();
  const history = useHistory();
  const machine = useSelector(getMachine);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const annotations = useSelector(getAnnotations);
  const annotationsLoading = useSelector(getAreAnnotationsLoading);
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);

  const displayErrors = {
    'Annotations start must be before they end.': t(
      'A downtime may already be categorized during this time.'
    ),
  };

  const onCompleted = useCallback(
    ({ updateAnnotationResponse, createAnnotationResponse }) => {
      const response = updateAnnotationResponse || createAnnotationResponse;
      if (response) {
        const newAnnotations = Array.isArray(response) ? response : [response];
        throwSuccessToast(t('Downtime categorized successfully'));
        globalDispatch(actionSubmitAnnotationSuccess(newAnnotations));
      } else {
        // null response, caused by 404 if annotation type or annotation does not exist
        throwErrorToast(
          `${t('Could not categorize downtime')}. ${t(
            'Downtime or downtime category not found'
          )}.`
        );

        bugsnag.notify(
          new Error('Error categorizing downtime'),
          (event) => {
            event.addMetadata('info', {
              error: 'No response on completed downtime request',
              annotations: state.downtimes,
            });
            event.addMetadata('user', bugsnagUserInfo);
          },
          () => {}
        );

        globalDispatch(actionSubmitAnnotationFailure());
      }
    },
    [t, globalDispatch, bugsnagUserInfo, state]
  );

  const onError = useCallback(
    ({ networkError }) => {
      if (networkError) {
        const [payload, categorized, splitting] = formatDowntimesForRequest(
          state,
          machine
        );
        const errorMsg = _get(
          networkError,
          ['result', 'errors', 0, 'message'],
          ''
        );
        const displayMsg = displayErrors[errorMsg] || errorMsg;
        throwErrorToast(
          `${t('Could not categorize downtime')}${displayMsg ? ': ' : '.'}${t(
            displayMsg
          )}`
        );

        bugsnag.notify(
          new Error('Error categorizing downtime'),
          (event) => {
            event.addMetadata('info', {
              annotationHeaders: payload,
              previouslyCategorized: categorized,
              splitting,
              errorMsg,
            });
            event.addMetadata('user', bugsnagUserInfo);
          },
          () => {}
        );
      }
      globalDispatch(actionSubmitAnnotationFailure());
    },
    [t, displayErrors, state, machine, globalDispatch, bugsnagUserInfo]
  );

  const [createAnnotation, { error }] = useMutation(CREATE_ANNOTATION, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError,
  });

  const [updateAnnotation, { error: updateError }] = useMutation(
    UPDATE_ANNOTATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    }
  );

  const submit = useCallback(() => {
    const [downtimesArray, categorized, splitting] = formatDowntimesForRequest(
      state,
      machine
    );

    const payload =
      downtimesArray.length < 2 ? downtimesArray[0] : downtimesArray;

    globalDispatch(actionSubmitAnnotation());

    if (!categorized || splitting) {
      createAnnotation({ variables: { input: payload } });
    } else if (categorized) {
      const upToDateAnno =
        annotations.find((anno) => {
          return anno.id === payload.id;
        }) || {};
      const end = upToDateAnno.isEndSet ? upToDateAnno.end : null;
      updateAnnotation({ variables: { input: { ...payload, end } } });
    }

    history.push(returnPath);
  }, [
    createAnnotation,
    updateAnnotation,
    machine,
    state,
    annotations,
    globalDispatch,
    history,
    returnPath,
  ]);

  return {
    error: error || updateError,
    loading: annotationsLoading,
    data: { ...state, dispatch, submit },
  };
};

export { useCategorization };
