import { createSelector } from 'reselect';
import { getFirstPartTimestamp } from 'lib/selectors/getFirstPartTimestamp';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { isSameOrBefore, isBetween } from 'lib/utils/date';
import { getLatestActivitySetActivities } from './getLatestActivitySetActivities';

// see https://github.com/machinemetrics/OperatorView/pull/459 for more context on this selector

const getScopeFirstPartTimestamp = createSelector(
  [getFirstPartTimestamp, getScopeStart, getLatestActivitySetActivities],
  (firstPartTimestamp, scopeStart, activities) => {
    const firstPartWithinActivity =
      firstPartTimestamp &&
      activities.some(({ start, end }) => {
        return isBetween(firstPartTimestamp, start, end);
      });
    if (
      scopeStart &&
      // ensure returned firstPartTimestamp from store is up to date on scope change
      isSameOrBefore(scopeStart, firstPartTimestamp) &&
      firstPartWithinActivity
    ) {
      return firstPartTimestamp;
    }
    return null;
  }
);

export { getScopeFirstPartTimestamp };
