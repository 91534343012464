import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, palette } from '@m12s/component-library';
import { H5 } from 'components/Text';

const StyledIcon = styled(Icon)`
  opacity: 0.65;
  filter: drop-shadow(0.5px 0.5px 0px rgba(0, 0, 0, 0.15));
`;

const StyledHeaderButton = styled.button`
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    left: ${({ alignRight }) => {
      return alignRight ? '-1rem' : '0';
    }};
    right: ${({ alignRight }) => {
      return alignRight ? '0' : '-1rem';
    }};
  }
`;

const HeaderButton = ({ icon, iconColor, onClick, alignRight }) => {
  return (
    <StyledHeaderButton onClick={onClick} alignRight={alignRight}>
      <StyledIcon size="1.5rem" opacity="0.65" color={iconColor} icon={icon} />
    </StyledHeaderButton>
  );
};

HeaderButton.propTypes = {
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
};

HeaderButton.defaultProps = {
  iconColor: palette.White,
};

const HeaderText = styled(H5)`
  @media (orientation: landscape) {
    line-height: 1;
    &:not(:first-child) {
      padding-left: 0.75rem;
      border-left: solid 2px
        ${({ overLight }) => {
          return overLight ? `${palette.Black}11` : `${palette.White}33`;
        }};
    }
    &:not(:last-child) {
      padding-right: 0.75rem;
    }
  }
`;

HeaderText.defaultProps = {
  color: palette.Grey900,
};

const HeaderLayout = styled.div`
  display: flex;
  @media (orientation: portrait) {
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 6rem;
  }
`;

export { HeaderText, HeaderLayout, HeaderButton };
