import session from 'lib/api/links/session';
import uuidv4 from 'uuid/v4';
import _map from 'lodash/map';
import { CLIENT_ID, APP_URI, LOGIN_URL } from 'lib/appConfig';

const createParamString = (params) => {
  return _map(params, (v, k) => {
    return `${k}=${v}`;
  }).join('&');
};

export const rawRequest = async (url, options, required = true) => {
  const response = await fetch(url, options);

  const text = await response.text();
  const obj = JSON.parse(text);

  if (response.status >= 200 && response.status < 300) {
    return obj;
  }

  if (response.status === 401 && required) {
    session.clear();
    window.location = '/login';
  }

  const error = new Error(response.statusText);
  error.json = obj;
  throw error;
};

export const createLoginUrl = () => {
  const authState = uuidv4();

  const loginParams = createParamString({
    response_type: 'code',
    state: authState,
    scope: 'operator.view',
    ttl: 1209600,
    client_id: CLIENT_ID,
    redirect_uri: `${APP_URI}/authorize/mm/callback`,
  });
  return `${LOGIN_URL}/oauth/authorize?${loginParams}`;
};
