import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { bugsnag } from 'lib/external/bugsnag';

import { UPDATE_ORDER_OPERATION } from 'lib/api/mutations';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
import { throwErrorToast } from 'lib/utils/toast';

const useCloseOrderOperaiton = () => {
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);

  const [requestUpdateOrderOperation] = useMutation(UPDATE_ORDER_OPERATION, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      bugsnag.notify(err, (event) => {
        event.addMetadata('user', bugsnagUserInfo);
      });
      throwErrorToast('Failed to close operation');
    },
  });

  return async (input, workOrderOperationRef) => {
    await requestUpdateOrderOperation({
      variables: { input, workOrderOperationRef },
    });
  };
};

export default useCloseOrderOperaiton;
