import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';
import { getMachine } from 'lib/selectors/getMachine';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

const useIsWorkOrderFlow = () => {
  const machine = useSelector(getMachine);
  const erpResourceId = _get(machine, 'erpResourceId');
  const erpAssociatedResources = _get(machine, 'erpResources');

  const flags = useFlags();
  const workOrderFlowEnabled = flags[FLAG_KEYS.WORK_ORDER_FLOW_ENABLED];

  return (
    workOrderFlowEnabled && !!(erpResourceId || erpAssociatedResources?.length)
  );
};

export { useIsWorkOrderFlow };
