import React, { useMemo, useState, useCallback } from 'react';
import _flow from 'lodash/flow';
import _filter from 'lodash/fp/filter';
import _orderBy from 'lodash/fp/orderBy';
import { useSelector } from 'react-redux';

import { getDowntimeIntervals } from 'lib/selectors/getDowntimeIntervals';
import { FilterColumn, TableColumn } from 'components/Table';
import MainLayout from 'components/Layouts/MainLayout';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { useQueryFilters, useErrorStatus } from 'lib/hooks';
import { ERROR_KEYS } from 'lib/constants';
import { getLongDurationCutoff } from 'lib/api/getLongDurationCutoff';
import { TableFilters } from './TableFilters';
import { Table } from './Table';

const defaultFilters = {
  duration: 'long',
  status: 'both',
  order: 'timestamp',
  planned: 'both',
  search: '',
};

function bySearch(search) {
  return (interval) => {
    if (!search) return true;
    const searchTerm = search.toLowerCase();

    const { categorized, annotationType } = interval;
    if (!categorized || !annotationType) return false;
    return annotationType.name.toLowerCase().includes(searchTerm);
  };
}

function byPlanned(planned) {
  return (interval) => {
    const intervalPlanned = !!interval.planned;

    if (planned === 'planned') {
      return intervalPlanned === true;
    }
    if (planned === 'unplanned') {
      return intervalPlanned === false;
    }

    return true;
  };
}

function byCategorized(status) {
  return (interval) => {
    if (status === 'categorized') {
      return interval.categorized === true;
    }
    if (status === 'uncategorized') {
      return interval.categorized === false;
    }

    return true;
  };
}

const Downtime = () => {
  const [filters, setFilters] = useQueryFilters(defaultFilters);
  const [limit, setLimit] = useState(20);
  const intervals = useSelector(getDowntimeIntervals);
  const longDurationCutoff = useSelector(getLongDurationCutoff);
  const downtimeError = useErrorStatus(ERROR_KEYS.DOWNTIME);
  const annotationError = useErrorStatus(ERROR_KEYS.ANNOTATION);

  const byDuration = useCallback(
    (status) => {
      return (interval) => {
        if (status === 'long') {
          return (
            interval.duration >= longDurationCutoff || interval.end === null
          );
        }
        return true;
      };
    },
    [longDurationCutoff]
  );

  const [downtimes, hasMore] = useMemo(() => {
    const filteredDowntimes = _flow(
      _filter(byDuration(filters.duration)),
      _filter(bySearch(filters.search)),
      _filter(byPlanned(filters.planned)),
      _filter(byCategorized(filters.status)),
      _orderBy([filters.order], ['desc'])
    )(intervals);

    const limitedDowntimes = filteredDowntimes.slice(0, limit);
    const showHasMoreButton =
      limitedDowntimes.length < filteredDowntimes.length;

    return [limitedDowntimes, showHasMoreButton];
  }, [intervals, filters, limit, byDuration]);

  return (
    <PauseDetailsOverlayContainer>
      <MainLayout.Container
        direction="row"
        portraitDirection="column"
        id="downtime-page"
      >
        <FilterColumn>
          <TableFilters
            filters={filters}
            intervals={downtimes}
            setFilters={setFilters}
          />
        </FilterColumn>
        <TableColumn>
          <Table
            emptyStateVariant={
              filters.status === 'uncategorized'
                ? 'allCategorized'
                : 'noResults'
            }
            intervals={downtimes}
            filters={filters}
            onLoadMore={
              hasMore
                ? () => {
                    return setLimit(limit + 20);
                  }
                : null
            }
            error={
              (downtimeError.message && downtimeError) ||
              (annotationError.message && annotationError)
            }
          />
        </TableColumn>
      </MainLayout.Container>
      <PauseDetailsToggle />
    </PauseDetailsOverlayContainer>
  );
};

export { Downtime };
