import { ACTIVITY_MODES } from 'lib/constants';
import { createSelector } from 'reselect';
import { getActivityTypes } from './getActivityTypes';
import { getLatestJob } from './getLatestJob';
import { getOpenActivity } from './getOpenActivity';

const getHasActivityToSwitchTo = createSelector(
  [getActivityTypes, getLatestJob, getOpenActivity],
  (activityTypes, latestJob, openActivity) => {
    if (!latestJob) {
      return true;
    }
    // Check to see if any valid activity types to switch into
    return !!activityTypes.filter((at) => {
      return (
        // filter out setups if job disallows setup
        (latestJob.setupTime !== null ||
          at.activityMode !== ACTIVITY_MODES.SETUP) &&
        // filter out current activity type
        (!openActivity || at.activityTypeRef !== openActivity.activityTypeRef)
      );
    }).length;
  }
);

export { getHasActivityToSwitchTo };
