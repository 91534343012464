/* eslint-disable camelcase */

import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import {
  palette,
  Timetable,
  Thead,
  Th,
  Tbody,
  Td,
  Tag as ActiveTag,
} from '@m12s/component-library';

import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import { formatDuration } from 'lib/utils/date';
import { DurationTicker } from 'components/DurationTicker';
import { H6, P, Small } from 'components/Text';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { ErrorInline } from 'components/ErrorInline';
import {
  Category,
  Cell,
  Tag,
  Empty,
  LoadingTd,
  LoadingText,
  LoadingTbody,
  CategoryTitle,
  DividerRow,
} from '../Table';
import { AnnotationModal } from './AnnotationModal';

const getAnnotationName = (annotationType, setup, t) => {
  if (setup) {
    return _startCase(t(annotationType.name.toLowerCase()));
  }
  if (annotationType) {
    return annotationType.name;
  }
  return '-';
};

const TableBody = ({ intervals, onLoadMore, filters }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: machineId } = useSelector(getMachine);
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const shiftStart = useSelector(getShiftStart);
  const [selectedAnnotation, setSelectedAnnotation] = useState(false);
  const [priorShiftSelected, setPriorShiftSelected] = useState(false);

  return (
    <>
      <AnnotationModal
        selectedAnnotation={selectedAnnotation}
        setSelectedAnnotation={setSelectedAnnotation}
        machineId={machineId}
        priorShift={priorShiftSelected}
      />
      <Tbody onLoadMore={onLoadMore} loadMoreText={_startCase(t('load more'))}>
        {intervals.map((interval, index) => {
          const {
            categorized,
            duration,
            end,
            annotationType,
            planned,
            start,
            setup,
          } = interval;
          const name = getAnnotationName(annotationType, setup, t);
          const startDate = moment(start);
          const endDate = moment(end);
          const uncategorized = !annotationType;
          const fromPriorShift =
            isLatestShiftActive && endDate.isSameOrBefore(shiftStart);
          const sortByTimestamp = filters.order === 'timestamp';
          const showPriorShiftBoundary =
            isLatestShiftActive &&
            fromPriorShift &&
            (index === 0 ||
              moment(intervals[index - 1].end).isAfter(shiftStart) ||
              !intervals[index - 1].end);
          const showCurrentShiftBoundary =
            isLatestShiftActive && index === 0 && !showPriorShiftBoundary;

          const openCategorizeFlow = () => {
            if (interval.setup || (fromPriorShift && !categorized)) return;

            if (categorized) {
              setSelectedAnnotation(interval);
              setPriorShiftSelected(fromPriorShift);
            } else {
              history.push({
                pathname: Routes.machineIdCategorizePath(machineId),
                state: {
                  preselected: interval,
                  returnPath: history.location.pathname,
                },
              });
            }
          };

          return (
            <Fragment key={start}>
              {showCurrentShiftBoundary && sortByTimestamp && (
                <DividerRow>{t('Current Shift')}</DividerRow>
              )}
              {showPriorShiftBoundary && sortByTimestamp && (
                <DividerRow>{t('Previous Shifts')}</DividerRow>
              )}
              <Td
                onClick={openCategorizeFlow}
                interactive
                disabled={fromPriorShift && !categorized}
              >
                <Cell>
                  <Small bold>{startDate.format('L')}</Small>
                  <Small>{startDate.format('LT')}</Small>
                </Cell>
              </Td>
              <Td
                onClick={openCategorizeFlow}
                interactive
                disabled={fromPriorShift && !categorized}
              >
                <Cell>
                  <CategoryTitle>
                    <H6 semibold muted={!categorized}>
                      {categorized ? name : _capitalize(t('none'))}
                    </H6>
                    {!end && <ActiveTag>{t('Active')}</ActiveTag>}
                  </CategoryTitle>
                  {!categorized && (
                    <Category color={palette.Red400}>
                      {_capitalize(t('uncategorized'))}
                    </Category>
                  )}
                </Cell>
              </Td>
              <Td
                align="right"
                onClick={openCategorizeFlow}
                interactive
                disabled={fromPriorShift && !categorized}
              >
                <Cell>
                  <P>
                    {end ? (
                      formatDuration(duration)
                    ) : (
                      <DurationTicker startDate={moment(start)} />
                    )}
                  </P>
                </Cell>
              </Td>
              <Td
                align="center"
                onClick={openCategorizeFlow}
                interactive
                disabled={fromPriorShift && !categorized}
              >
                {!uncategorized &&
                  (planned ? (
                    <Tag color={palette.Green500}>
                      {t('planned')
                        .slice(0, 1)
                        .toUpperCase()}
                    </Tag>
                  ) : (
                    <Tag color={palette.Red500}>
                      {t('unplanned')
                        .slice(0, 1)
                        .toUpperCase()}
                    </Tag>
                  ))}
              </Td>
            </Fragment>
          );
        })}
      </Tbody>
    </>
  );
};

const Table = ({
  emptyStateVariant,
  intervals,
  loading,
  error,
  onLoadMore,
  filters,
}) => {
  const { t } = useTranslation();

  return (
    <Timetable templateColumns="minmax(6.5rem,0.5fr) 5fr minmax(10.5rem, 0.5fr) minmax(2rem,0.5fr)">
      <Thead>
        <Th>{_capitalize(t('started'))}</Th>
        <Th>{_capitalize(t('category'))}</Th>
        <Th align="right">{_capitalize(t('duration'))}</Th>
        <Th align="center">{_capitalize(t('type'))}</Th>
      </Thead>
      {loading ? (
        <LoadingTbody
          rows={20}
          row={
            <>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd align="right">
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd align="right">
                <LoadingText />
                <LoadingText />
              </LoadingTd>
            </>
          }
        />
      ) : error ? (
        <ErrorInline
          title={t('There was an error loading the downtime list')}
          {...error}
          reload
        />
      ) : intervals.length === 0 ? (
        <Empty variant={emptyStateVariant} onLoadMore={onLoadMore} />
      ) : (
        <>
          <TableBody
            intervals={intervals}
            onLoadMore={onLoadMore}
            filters={filters}
          />
        </>
      )}
    </Timetable>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  emptyStateVariant: PropTypes.string,
  intervals: PropTypes.arrayOf(
    PropTypes.shape({
      annotationType: PropTypes.shape({ name: PropTypes.string }),
      end: PropTypes.string,
      id: PropTypes.number,
      planned: PropTypes.bool,
      start: PropTypes.string,
    })
  ),
  filters: PropTypes.object,
};

Table.defaultProps = {
  emptyStateVariant: 'noResults',
  filters: {},
};

export { Table };
