import _find from 'lodash/find';
import { createSelector } from 'reselect';

import { getStatusIntervals } from 'lib/selectors/getStatusIntervals';

const getCurrentStatus = createSelector(
  [getStatusIntervals],
  (statusIntervals) => {
    return (
      _find(statusIntervals, (interval) => {
        return !interval.end;
      }) || null
    );
  }
);

export { getCurrentStatus };
