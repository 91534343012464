import { createSelector } from 'reselect';
import { getSortedActivities } from 'lib/selectors/getSortedActivities';
import _get from 'lodash/get';
import { getCheckIsProductionActivityTypeRef } from './getCheckIsProductionActivityTypeRef';

const defaultLatestProductionActivity = {};

export const getLatestProductionActivity = createSelector(
  [getSortedActivities, getCheckIsProductionActivityTypeRef],
  (sortedActivities, checkIsProductionActivityTypeRef) => {
    if (!_get(sortedActivities, 'length')) {
      return defaultLatestProductionActivity;
    }
    const latestProductionActivity = sortedActivities.find(
      ({ activityTypeRef }) => {
        return checkIsProductionActivityTypeRef(activityTypeRef);
      }
    );
    return latestProductionActivity || defaultLatestProductionActivity;
  }
);
