import { createSelector } from 'reselect';
import { getTotalRawParts } from 'lib/selectors/getTotalRawParts';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';
import { getLastPartBucketQueryStartDate } from 'lib/selectors/getLastPartBucketQueryStartDate';
import { getIsPartBucketQueryLoading } from 'lib/selectors/getIsPartBucketQueryLoading';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { isSameOrBefore } from 'lib/utils/date';
import { getLaborTicketScopeAdjustment } from './getLaborTicketScopeAdjustment';

// see https://github.com/machinemetrics/OperatorView/pull/459 for more context on this selector

const getScopeTotalParts = createSelector(
  [
    getScopeStart,
    getTotalRawParts,
    getLastPartBucketQueryStartDate,
    getIsPartBucketQueryLoading,
    getScopeReadTimePCM,
  ],
  (
    scopeStart,
    totalRawParts,
    lastPartBucketQueryStartDate,
    isPartBucketQueryLoading,
    scopeReadTimePCM = 1
  ) => {
    if (
      scopeStart &&
      !isPartBucketQueryLoading &&
      isSameOrBefore(
        // ensure returned parts data from store is up to date on scope change
        scopeStart,
        lastPartBucketQueryStartDate
      )
    ) {
      if (scopeReadTimePCM !== 1) {
        return Math.floor((totalRawParts * scopeReadTimePCM).toFixed(1));
      }
      return totalRawParts;
    }
    return 0;
  }
);

const getLaborScopeTotalParts = createSelector(
  [getScopeTotalParts, getLaborTicketScopeAdjustment],
  (scopeTotalParts, scopeAdjustment) => {
    if (scopeTotalParts) {
      return scopeTotalParts + (scopeAdjustment || 0);
    }
    return 0;
  }
);

export { getScopeTotalParts, getLaborScopeTotalParts };
