import { createSelector } from 'reselect';
import { subtract, now } from 'lib/utils/date';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import moment from 'moment';

const getStartOfSixDaysPrior = createSelector(
  [getHeavyUpdate],
  // eslint-disable-next-line no-unused-vars
  (_heavyUpdate) => {
    const nowTZ = now();
    const sixDaysBeforeNow = subtract(nowTZ, 6, 'days');
    const startOfSixDaysPrior = moment(sixDaysBeforeNow)
      .startOf('day')
      .format();
    return startOfSixDaysPrior;
  }
);

export { getStartOfSixDaysPrior };
