import { useEffect } from 'react';

export const useAsyncEffect = async (delegate, stateTriggers, destroy) => {
  useEffect(() => {
    const controller = window.AbortController
      ? new window.AbortController()
      : {};

    async function asyncEffect() {
      return delegate(controller.signal);
    }

    asyncEffect();

    return () => {
      if (controller.abort) {
        controller.abort();
      }

      if (destroy) {
        destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...stateTriggers]);
};
