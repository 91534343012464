import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionDismissPopup } from 'lib/actions';
import { getNextPopup } from 'lib/selectors/getNextPopup';
import { MessagePopupTriggered } from './MessagePopupTriggered';

const MessagePopup = () => {
  const dispatch = useDispatch();
  const nextPopup = useSelector(getNextPopup);
  const dismiss = useCallback(() => {
    return dispatch(actionDismissPopup({ params: 'message' }));
  }, [dispatch]);

  if (!nextPopup || nextPopup?.popupType !== 'message') {
    return null;
  }

  return <MessagePopupTriggered dismiss={dismiss} {...nextPopup} />;
};

export { MessagePopup };
