import styled from 'styled-components';
import { CardGrid } from '@m12s/component-library';

import { H3 } from 'components/Text';

const Grid = styled(CardGrid)`
  padding: 2.5rem 3rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const CardText = styled.div`
  width: 100%;
  height: 100%;
`;

const ExtendedTitle = styled.div`
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => {
    return theme.colors.text.default;
  }};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ExtendedDetail = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => {
    return theme.colors.text.label;
  }};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: normal;
  overflow-wrap: break-word;
  overflow: hidden;
`;

const EmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyMessageTitle = styled(H3)`
  margin: 1.5rem 0;
`;

const CardContainer = styled.div`
  @media (orientation: portrait) {
    min-width: 0;
  }
`;

export {
  Grid,
  ExtendedTitle,
  ExtendedDetail,
  CardText,
  EmptyMessageContainer,
  EmptyMessageTitle,
  CardContainer,
};
