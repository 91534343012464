import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const P = WithProps(styled.p`
  ${includeBaseStyle}
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.005em;
`);

export { P };
