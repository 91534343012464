import { createSelector } from 'reselect';
import { getActivitySetStandardsFromJob } from 'lib/utils/job';
import { getJobRun, getIsJobRunLoading } from 'lib/selectors/getJobRun';

const defaultJobRunASWrapper = {};

// returns job run with activity set mapping; the intent here
// is to center opdash around the activities paradigm and make
// job runs forward compatible where it is possible to do so
const getJobRunASWrapper = createSelector(
  [getJobRun, getIsJobRunLoading],
  (jobRun, isJobRunLoading) => {
    if (isJobRunLoading) {
      return defaultJobRunASWrapper;
    }
    const {
      expectedSetupDuration,
      idealUnitDuration,
      expectedUnitDuration,
    } = getActivitySetStandardsFromJob(jobRun);
    return {
      closedAt: jobRun.endDate,
      expectedSetupDuration,
      idealUnitDuration,
      expectedUnitDuration,
      jobId: jobRun.jobId,
      partCountMultiplier: jobRun.partCountMultiplier,
      actualParts: jobRun.actualParts,
      jobScheduleGroupId: jobRun.jobRunId,
    };
  }
);

export { getJobRunASWrapper };
