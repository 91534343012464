import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import { ACTIVITY_MODES } from 'lib/constants';

const defaultRefs = [];

const getActiveProductionActivityTypeRefs = createSelector(
  [getActivityTypes],
  (activityTypes = []) => {
    if (!_get(activityTypes, 'length')) {
      return defaultRefs;
    }
    return activityTypes.reduce(
      (productionActivityTypeRefs, { activityMode, activityTypeRef }) => {
        if (activityMode === ACTIVITY_MODES.PRODUCTION) {
          return [...productionActivityTypeRefs, activityTypeRef];
        }
        return productionActivityTypeRefs;
      },
      []
    );
  }
);

export { getActiveProductionActivityTypeRefs };
