import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Routes from 'lib/utils/routes';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';
import moment from 'moment';
import { TEMP_REFS } from 'lib/constants';

import { CREATE_ACTIVITY_SET_WITH_WORK_ORDER } from 'lib/api/mutations';
import {
  actionCanonicalActivitySetOpen,
  actionCanonicalActivitySetDelete,
  actionOptimisticActivitySetOpen,
  actionSetJobTotal,
  actionSetWorkOrderOpActionLoading,
  actionSetPendingActivityTypeRef,
} from 'lib/actions';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import useCreateLaborTicket from 'components/LaborTicket/hooks/useCreateLaborTicket';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

const {
  ACTIVITY_REF: optimisticActivityRef,
  ACTIVITY_SET_REF: optimisticActivitySetRef,
} = TEMP_REFS;

const useCreateActivitySetWithWorkOrder = ({ machine, workOrderOp }) => {
  const mainDispatch = useDispatch();
  const history = useHistory();
  const laborTicketEnabled = useIsLaborTicketFlow();
  const createLaborTicket = useCreateLaborTicket();

  const workOrderId = workOrderOp?.workOrderId;
  const workOrderOperationRef = workOrderOp?.workOrderOperationRef;

  const operator = useSelector(getCurrentOperator);
  const job = useSelector(getLatestJob);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const activityTypes = useSelector(getActivityTypes);

  const [requestCreateActivitySetWithWorkOrder] = useMutation(
    CREATE_ACTIVITY_SET_WITH_WORK_ORDER,
    {
      fetchPolicy: 'no-cache',
      update: (
        _cache,
        {
          data: {
            createActivitySetWithWorkOrderResponse: {
              activitySet: {
                activities: [activity],
                ...activitySet
              },
            },
          },
        }
      ) => {
        mainDispatch(
          actionOptimisticActivitySetOpen({ activitySet, activity, job })
        );
        mainDispatch(actionSetJobTotal(undefined));
        history.push(Routes.machineIdPath(machine.id));
      },
      onCompleted: async ({
        createActivitySetWithWorkOrderResponse: {
          activitySet: {
            activities: [activity], // should be sorted start ASC
            ...activitySet
          },
        },
      }) => {
        await mainDispatch(
          actionCanonicalActivitySetOpen({
            activitySet,
            activity,
            optimisticActivitySetRef,
            optimisticActivityRef,
          })
        );

        throwSuccessToast(`Started order ${activitySet.workOrderId}`);

        if (laborTicketEnabled && workOrderOp) {
          try {
            const activityMode = activityTypes.find((type) => {
              return type.activityTypeRef === activity.activityTypeRef;
            })?.activityMode;

            createLaborTicket({
              clockIn: activity.start,
              clockOut: null,
              lot: workOrderOp.lot,
              sequenceNumber: workOrderOp.sequenceNumber,
              split: workOrderOp.split,
              sub: workOrderOp.sub,
              state: 'OPEN',
              resourceId: machine.erpResourceId,
              personId: operator.erpId,
              workOrderId,
              type: activityMode,
              goodParts: 0,
              badParts: 0,
            });
          } catch (e) {
            throwErrorToast('Failed to open Labor Ticket');
          }
        }
        mainDispatch(actionSetWorkOrderOpActionLoading(false));
        mainDispatch(actionSetPendingActivityTypeRef(null));
        history.push(Routes.machineIdPath(machine.id));
      },
      onError: () => {
        mainDispatch(
          actionCanonicalActivitySetDelete({
            activitySetRef: optimisticActivitySetRef,
          })
        );
        mainDispatch(actionSetWorkOrderOpActionLoading(false));
        mainDispatch(actionSetPendingActivityTypeRef(null));
        throwErrorToast('Could not start production order');
      },
    }
  );

  return async ({ activityTypeRef, partOperation }) => {
    if (partOperation) {
      const nowISO = moment().toISOString();
      await requestCreateActivitySetWithWorkOrder({
        variables: {
          input: {
            machineRef: machine?.machineRef,
            activities: [
              {
                activityTypeRef,
                startAt: nowISO,
              },
            ],
            partNumber: partOperation.partNumber,
            sequenceNumber: partOperation.sequenceNumber,
            partOperationRef: partOperation.partOperationRef,
            cycleTime: partOperation.cycleTimeMs,
            workOrderOperationRef,
            workOrderId,
            expectedUnitDuration: partOperation.cycleTime,
            idealUnitDuration: partOperation.cycleTime,
            expectedSetupDuration: partOperation.setupTimeMs,
            // TODO : Fractional quantities
            partCountMultiplier: partOperation.quantityPerPart || 1,
            actualParts: partOperation.quantityPerPart || 1,
          },
          query: { shouldCloseLatestSet: true },
        },

        optimisticResponse: {
          __typename: 'Mutation',
          createActivitySetWithWorkOrderResponse: {
            __typename: 'ActivitySet',
            activitySet: {
              ...latestActivitySet,
              workOrderId,
            },
          },
        },
      });
    }
  };
};

export default useCreateActivitySetWithWorkOrder;
