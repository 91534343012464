import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionClosePopup, actionDismissPopup } from 'lib/actions';
import { useDowntimeWatcher } from 'components/DataProvider/useDowntimeWatcher';
import { getNextPopup } from 'lib/selectors/getNextPopup';
import { DowntimePopupTriggered } from './DowntimePopupTriggered';

const DowntimePopup = () => {
  const dispatch = useDispatch();
  const nextPopup = useSelector(getNextPopup);
  const close = useCallback(() => {
    return dispatch(actionClosePopup());
  }, [dispatch]);
  const dismiss = useCallback(() => {
    return dispatch(actionDismissPopup({ params: 'downtime' }));
  }, [dispatch]);

  useDowntimeWatcher();

  if (!nextPopup || nextPopup?.popupType !== 'downtime') {
    return null;
  }

  return (
    <DowntimePopupTriggered close={close} dismiss={dismiss} {...nextPopup} />
  );
};

export { DowntimePopup };
