import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const H1 = WithProps(styled.span`
  ${includeBaseStyle}
  font-size: 2.5rem;
  line-height: 3rem;
`);

H1.defaultProps = {
  ...H1.defaultProps,
  defaultWeight: 'bold',
};

export { H1 };
