import styled from 'styled-components';
import { WithProps, includeBaseStyle } from './base';

const H6 = WithProps(styled.h6`
  ${includeBaseStyle}
  font-size: 1rem;
  letter-spacing: 0.0015em;
  line-height: 1.5rem;
`);

H6.defaultProps = {
  ...H6.defaultProps,
  defaultWeight: 'bold',
};

export { H6 };
