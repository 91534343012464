import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Icon, theme } from '@m12s/component-library';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import { chevronRight } from 'lib/icons';
import { Text } from 'components/Text';
import { MACHINE_STATUSES } from 'lib/constants';
import { InfoContainer, StyledSmall } from './styled';

const MachineCard = ({ machine, onClick, alarm, isInSetup, isPaused }) => {
  const { t } = useTranslation();
  const { machineRef, name } = machine.machine;
  const status = alarm
    ? 'fault'
    : isPaused
    ? 'pause'
    : isInSetup
    ? 'setup'
    : _get(MACHINE_STATUSES, machine.value, '');

  return (
    <Card
      key={machineRef}
      type="extended"
      icon={<Icon icon={chevronRight} />}
      onClick={onClick}
      color={theme.states[status]}
    >
      <InfoContainer>
        <Text bold>{name}</Text>
        <StyledSmall>{_startCase(t(status))}</StyledSmall>
      </InfoContainer>
    </Card>
  );
};

export { MachineCard };
