import React from 'react';
import { useSelector } from 'react-redux';

import { MachineSetup } from 'components/MachineSetup';
import { JOB_STATUSES } from 'lib/constants';
import { getJobStatus } from 'lib/selectors/getJobStatus';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';

import { CycleCount } from './CycleCount';

const CycleCountStatus = () => {
  const jobStatus = useSelector(getJobStatus);
  const hasOpenActivitySetInSetup = useSelector(getHasOpenActivitySetInSetup);

  if (hasOpenActivitySetInSetup || jobStatus === JOB_STATUSES.SETUP) {
    return <MachineSetup />;
  }

  return <CycleCount />;
};

export { CycleCountStatus };
