import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { palette } from '@m12s/component-library';

import { AppNav } from 'components/AppNav';
import { HeaderText, HeaderLayout } from 'components/AppNav/styled';
import useGoToHome from 'lib/hooks/useGoToHome';

const SwitchActivityReviewNav = ({ inSetup }) => {
  const { t } = useTranslation();
  const goToHome = useGoToHome();

  return (
    <AppNav
      color={palette.Purple700}
      iconColor={palette.Purple200}
      onBack={goToHome}
      onClose={goToHome}
    >
      <HeaderLayout>
        <HeaderText color={palette.Purple100}>
          {inSetup ? t('General Setup') : t('General Production')}
        </HeaderText>
        <HeaderText color={palette.Purple200} overLight semibold>
          {t('Review Activity Details')}
        </HeaderText>
      </HeaderLayout>
    </AppNav>
  );
};

SwitchActivityReviewNav.propTypes = {
  inSetup: PropTypes.bool,
};

export { SwitchActivityReviewNav };
