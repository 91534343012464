import { createSelector } from 'reselect';

import { getScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getScopePartAdjustmentData } from 'lib/selectors/getScopePartAdjustmentData';

const getScopeNetPartsMade = createSelector(
  [getScopeTotalParts, getScopePartAdjustmentData],
  (scopeTotalParts, { scopeTotalNetPartAdjustments }) => {
    return scopeTotalParts + scopeTotalNetPartAdjustments;
  }
);

export { getScopeNetPartsMade };
