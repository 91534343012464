import React from 'react';
import { ErrorBoundary } from 'lib/external/bugsnag';
import ErrorPage from 'pages/Error';

const ErrorHandler = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={(error) => {
        return <ErrorPage error={error} nonreporting />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorHandler;
