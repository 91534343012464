import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ControlBarButton, ControlBar, palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toISO } from 'lib/utils/date';
import { useSetupStats, useProductionStats } from 'lib/hooks';
import { useHistory } from 'react-router-dom';
import Routes from 'lib/utils/routes';

import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import { getShouldShowWorkOrderIdField } from 'lib/selectors/getShouldShowWorkOrderIdField';
import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import {
  getNetSetupParts,
  getSetupParts,
  getSetupRejectCount,
} from 'lib/selectors/getSetupParts';
import { getIsRunningUntaggedOperation } from 'lib/selectors/getIsRunningUntaggedOperation';
import { getMachine } from 'lib/selectors/getMachine';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

import MainLayout from 'components/Layouts/MainLayout';
import { SwitchActivityReviewNav } from 'components/SwitchActivityReviewNav';
import { AddWorkOrderModal } from 'components/JobTable/AddWorkOrderModal';
import { check, undo } from 'lib/icons';
import { SetupActivityReview } from './SetupActivityReview';
import { ProductionActivityReview } from './ProductionActivityReview';
import { Body } from './styled';

const SwitchActivityTypeReview = ({
  dispatch,
  submit,
  inSetup,
  activityTypeRef,
  requestUpdateActivity,
  wantToPause,
  adjustParts,
  startNewProductionRun,
  formState,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const workOrderFlowEnabled = useIsWorkOrderFlow();

  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestActivity = useSelector(getLatestActivity);
  const scopeNetPartsMade = useSelector(getNetSetupParts);
  const detectedSetupParts = useSelector(getSetupParts);
  const setupRejectCount = useSelector(getSetupRejectCount);
  const isRunningUntaggedOp = useSelector(getIsRunningUntaggedOperation);
  const jobNameRaw = useSelector(getLatestJobName);
  const jobName = isRunningUntaggedOp ? t(jobNameRaw) : jobNameRaw;
  const workOrderIdEnabled = useSelector(getShouldShowWorkOrderIdField);
  const machine = useSelector(getMachine);
  const inSetupReview = inSetup;

  const {
    adjustedSetupStartDate,
    setupEndDateOverride,
    expectedSetupDurationStr,
    expectedSetupDuration,
  } = useSetupStats();

  const {
    adjustedProductionStartDate,
    productionEndDateOverride,
    productionRejectedParts,
    productionPartsGoal,
    productionPartsMade,
  } = useProductionStats();

  const productionPartsGoalData = productionPartsGoal?.expectedPartsNow;
  const [createNewProductionRun, setCreateNewProductionRun] = useState(
    !inSetupReview
  );
  const [openWorkOrderModal, setOpenWorkOrderModal] = useState(false);
  return (
    <MainLayout.Column id="switch-activity-review">
      <SwitchActivityReviewNav inSetup={inSetupReview} />

      <MainLayout.Container direction="column" bgColor={palette.Grey100}>
        <Body>
          {inSetupReview ? (
            <SetupActivityReview
              detectedSetupParts={detectedSetupParts}
              setupRejectCount={setupRejectCount}
              adjustedSetupStartDate={adjustedSetupStartDate}
              setupEndDateOverride={setupEndDateOverride}
              expectedSetupDurationStr={expectedSetupDurationStr}
              expectedSetupDuration={expectedSetupDuration}
              dispatch={dispatch}
              formState={formState}
            />
          ) : (
            <ProductionActivityReview
              jobName={jobName}
              productionRejectedParts={productionRejectedParts}
              productionPartsGoal={productionPartsGoalData}
              productionPartsMade={productionPartsMade}
              adjustedProductionStartDate={adjustedProductionStartDate}
              productionEndDateOverride={productionEndDateOverride}
              setCreateNewProductionRun={setCreateNewProductionRun}
              createNewProductionRun={createNewProductionRun}
            />
          )}
          {openWorkOrderModal && (
            <AddWorkOrderModal
              handleOnClose={() => {
                return setOpenWorkOrderModal(false);
              }}
              isStopStartNewRun
              onSubmit={startNewProductionRun}
              activityTypeRef={activityTypeRef}
            />
          )}
        </Body>
      </MainLayout.Container>

      <ControlBar>
        {inSetupReview && (
          <ControlBarButton
            id="reset-setup-parts"
            icon={undo}
            onClick={() => {
              dispatch({ type: 'SET_PARTS', parts: scopeNetPartsMade });
            }}
          >
            {t('Reset')}
          </ControlBarButton>
        )}
        <ControlBarButton
          id="submit-activity-review"
          icon={check}
          onClick={async () => {
            if (wantToPause) {
              const now = toISO();
              adjustParts(formState?.parts - scopeNetPartsMade);
              return requestUpdateActivity({
                variables: {
                  input: {
                    activitySetRef: latestActivitySet.activitySetRef,
                    activityRef: latestActivity.activityRef,
                    endAt: now,
                  },
                },
                optimisticResponse: {
                  __typename: 'Mutation',
                  updateActivityResponse: {
                    __typename: 'Activity',
                    activity: {
                      ...latestActivity,
                      end: now,
                      updatedAt: now,
                      jobScheduleGroupId: null,
                    },
                  },
                },
              });
            }
            if (createNewProductionRun) {
              if (workOrderIdEnabled) {
                if (workOrderFlowEnabled) {
                  return history.push(
                    `${Routes.machineIdSelectJobPath(
                      machine.id
                    )}?activityTypeRef=${activityTypeRef}`
                  );
                }
                return setOpenWorkOrderModal(true);
              }
              // create new activity set without work order id
              return startNewProductionRun(activityTypeRef);
            }
            return submit(activityTypeRef);
          }}
        >
          {t('Submit')}
        </ControlBarButton>
      </ControlBar>
    </MainLayout.Column>
  );
};

SwitchActivityTypeReview.propTypes = {
  dispatch: PropTypes.func,
  inSetup: PropTypes.bool,
  formState: PropTypes.object,
  submit: PropTypes.func,
};

export { SwitchActivityTypeReview };
