import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { bugsnag } from 'lib/external/bugsnag';
import { useTranslation } from 'react-i18next';

import { CREATE_LABOR_TICKET } from 'lib/api/mutations';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
import {
  actionCloseLaborTicketModal,
  actionSetLaborTicketActionLoading,
} from 'lib/actions';
import { throwErrorToast } from 'lib/utils/toast';

const useCreateLaborTicket = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);

  const [requestCreateLaborTicket] = useMutation(CREATE_LABOR_TICKET, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      bugsnag.notify(err, (event) => {
        event.addMetadata('user', bugsnagUserInfo);
      });
      dispatch(actionSetLaborTicketActionLoading(false));
      throwErrorToast(`${t('Failed to open labor ticket')}: ${err}`);
    },
  });

  const createLaborTicket = async (input) => {
    try {
      if (input) {
        await requestCreateLaborTicket({
          variables: {
            input,
          },
        });
      }
      dispatch(actionSetLaborTicketActionLoading(false));
      dispatch(actionCloseLaborTicketModal());
    } catch (e) {
      dispatch(actionSetLaborTicketActionLoading(false));
      throwErrorToast(`Failed to open labor ticket: ${e}`);
    }
  };

  return (input) => {
    createLaborTicket(input);
  };
};

export default useCreateLaborTicket;
