import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';
import _get from 'lodash/get';
import { getLatestJobId } from 'lib/selectors/getLatestJobId';
import { getJobsById } from 'lib/selectors/getJobsById';

const defaultLatestJob = {};

const getLatestJob = createDeepEqualitySelector(
  [getLatestJobId, getJobsById],
  (latestJobId, jobsById) => {
    // Show Untagged operation if
    // - There is a currently running operation, with no job id
    // - There is no activity running on an APM machine
    if (latestJobId === null) {
      return {
        displayName: 'No Operation',
        jobName: 'No Operation',
        name: 'No Operation',
        actualParts: 1,
        partCountMultiplier: 1,
        jobId: null,
      };
    }
    if (!latestJobId) {
      return defaultLatestJob;
    }
    return _get(jobsById, latestJobId, defaultLatestJob);
  }
);

export { getLatestJob };
