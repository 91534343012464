import { createSelector } from 'reselect';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getCheckIsSetupActivityTypeRef } from 'lib/selectors/getCheckIsSetupActivityTypeRef';
import { getLatestActivitySetExpectedSetupDuration } from 'lib/selectors/getLatestActivitySetExpectedSetupDuration';
import { getIsLatestActivitySetup } from 'lib/selectors/getIsLatestActivitySetup';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import {
  diff,
  toISO,
  subtract,
  getUnitDurationFromString,
} from 'lib/utils/date';
import { chopBefore } from 'lib/utils/intervals';
import { getLatestActivitySetActivities } from './getLatestActivitySetActivities';

const defaultPartitionedIntervals = {
  scheduledIntervals: [],
  unscheduledIntervals: [],
};

const getActivityIntervalCacheRefresh = createSelector(
  [
    getIsLatestActivitySetup,
    getHasOpenActivity,
    getHeavyUpdate, // force recalc every fifteen seconds
  ],
  (
    isLatestActivitySetup,
    hasOpenActivity,
    _heavyUpdate // eslint-disable-line no-unused-vars
  ) => {
    // we only need to check for unplanned setup when following conditions are true
    if (isLatestActivitySetup && hasOpenActivity) {
      return toISO(); // force activity interval refresh
    }
    return null; // return simple val to avoid unnecessary recalc
  }
);

// generates intervals for comparison / intersection with active execution machine
// intervals for the purpose of deriving scheduledTime and scheduledTimeInCycle

// truncates activities at following:
// expected setup is exceeded... this is necessary because scheduledTime is any "job" time where
// a machine SHOULD be making parts... this translates to activities as the following:
//  - any production activity time
//  - any "unplanned" setup activity time
// we define unplanned setup activity time as any portion of time during a setup activity in which
// the aggregate of setup activity time across the parent set has exceeded the expected setup time
// for that same set... in short, truncate the setup activity at that point and consider all setup going
// forward to be unplanned

const getPartitionedScheduledAndUnscheduledActivityIntervals = createSelector(
  [
    getScopeStart,
    getLatestActivitySetActivities,
    getCheckIsSetupActivityTypeRef,
    getLatestActivitySetExpectedSetupDuration,
    getActivityIntervalCacheRefresh,
  ],
  (
    scopeStart,
    latestActivitySetActivities = [], // sorted by earliest first (start ASC)
    checkIsSetupActivityTypeRef,
    expectedSetupDuration,
    // minimize recalcs as much as possible
    activityIntervalCacheRefresh // eslint-disable-line no-unused-vars
  ) => {
    if (
      !scopeStart ||
      (latestActivitySetActivities && !latestActivitySetActivities.length)
    ) {
      return defaultPartitionedIntervals;
    }
    const expectedSetupDurationMs = expectedSetupDuration
      ? getUnitDurationFromString(expectedSetupDuration, 'milliseconds')
      : 0;
    let timeInSetupMs = 0;
    const partitionedIntervals = latestActivitySetActivities
      .slice()
      .reverse()
      .reduce(({ scheduledIntervals, unscheduledIntervals }, activity) => {
        const {
          start: activityStart,
          end: activityEnd,
          activityTypeRef,
        } = activity;
        if (checkIsSetupActivityTypeRef(activityTypeRef)) {
          const effectiveActivityEnd = activityEnd || toISO(); // truncate to now if open
          const hasPriorSetupExceededExpected =
            timeInSetupMs > expectedSetupDurationMs;
          const activityDuration = diff(
            effectiveActivityEnd,
            activityStart,
            'milliseconds'
          );
          const currentSetupActivityInterval = {
            start: activityStart,
            end: effectiveActivityEnd,
          };
          if (hasPriorSetupExceededExpected) {
            // if we have already exceeded expected setup, then we know all setup activities going
            // forward (including current) are considered unplanned (scheduled)
            timeInSetupMs += activityDuration;
            return {
              scheduledIntervals: [
                ...scheduledIntervals,
                currentSetupActivityInterval,
              ],
              unscheduledIntervals,
            };
          }
          timeInSetupMs += activityDuration;
          const isInPlannedSetup = timeInSetupMs <= expectedSetupDurationMs;
          if (isInPlannedSetup) {
            // if entire current activity is within expected setup, then we consider it
            // planned (unscheduled)
            return {
              scheduledIntervals,
              unscheduledIntervals: [
                ...unscheduledIntervals,
                currentSetupActivityInterval,
              ],
            };
          }
          // if prior setup has not exceeded expected, but we are no longer in planned setup, then
          // we must be in the first activity to exceed expected setup, so we must split this activity
          // at that point and consider it part planned (unscheduled) and part unplanned (scheduled)
          const setupRunoverMs = timeInSetupMs - expectedSetupDurationMs;
          const newStartISO = subtract(effectiveActivityEnd, setupRunoverMs);
          return {
            unscheduledIntervals: [
              ...unscheduledIntervals,
              { ...currentSetupActivityInterval, end: newStartISO },
            ],
            scheduledIntervals: [
              ...scheduledIntervals,
              { ...currentSetupActivityInterval, start: newStartISO },
            ],
          };
        }
        // all production activities are scheduled
        const scheduledProdActivityInterval = {
          start: activityStart,
          end: activityEnd,
        };
        return {
          scheduledIntervals: [
            ...scheduledIntervals,
            scheduledProdActivityInterval,
          ],
          unscheduledIntervals,
        };
      }, defaultPartitionedIntervals);
    if (
      !partitionedIntervals.scheduledIntervals.length &&
      !partitionedIntervals.unscheduledIntervals.length
    ) {
      // small optimiziation - return same default ref in case no intervals apply, as returning a
      // new empty reduce arr would invalidate subscriber cache, even though it's another empty array
      return defaultPartitionedIntervals;
    }
    return partitionedIntervals;
  }
);

const getScopeScheduledActivityIntervals = createSelector(
  [getPartitionedScheduledAndUnscheduledActivityIntervals, getScopeStart],
  ({ scheduledIntervals }, scopeStart) => {
    return chopBefore(scheduledIntervals, scopeStart, true);
  }
);

const getScopeUnscheduledActivityIntervals = createSelector(
  [getPartitionedScheduledAndUnscheduledActivityIntervals, getScopeStart],
  ({ unscheduledIntervals }, scopeStart) => {
    return chopBefore(unscheduledIntervals, scopeStart, true);
  }
);

export {
  getScopeScheduledActivityIntervals,
  getScopeUnscheduledActivityIntervals,
};
