import { createSelector } from 'reselect';
import { getMachine } from './getMachine';

export const getMachineGroups = (state) => {
  return state.machineGroups;
};

export const getGroupIdsForMachine = createSelector(
  [getMachine, getMachineGroups],
  (machine, machineGroups) => {
    return machineGroups
      .filter((group) => {
        return group.machines.some((m) => {
          return m.id === machine.id;
        });
      })
      .map((group) => {
        return group.id;
      });
  }
);
