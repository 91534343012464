import { createSelector } from 'reselect';

import { getRecentHealthData } from './getRecentHealthData';
import { getHeavyUpdate } from './getUpdate';

const getHFLockedout = createSelector(
  [getRecentHealthData, getHeavyUpdate],
  (healthData) => {
    if (!healthData) {
      return false;
    }

    const { data } = healthData;

    if (data.value === 'FAULT') {
      return true;
    }

    return false;
  }
);

export { getHFLockedout };
