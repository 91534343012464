import { createSelector } from 'reselect';
import { getLatestJob } from './getLatestJob';

const getIsRunningUntaggedOperation = createSelector(
  [getLatestJob],
  (latestJob) => {
    return latestJob?.jobId === null;
  }
);

export { getIsRunningUntaggedOperation };
