import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Context } from './Context';

const ContainerRoot = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const PauseDetailsOverlayContainer = ({ children }) => {
  const ref = useRef();

  return (
    <Context.Provider value={ref}>
      <ContainerRoot ref={ref}>{children}</ContainerRoot>
    </Context.Provider>
  );
};

PauseDetailsOverlayContainer.propTypes = {
  children: PropTypes.any,
};

export { PauseDetailsOverlayContainer };
