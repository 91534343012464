import React from 'react';
import { useHistory } from 'react-router-dom';
import { ControlBarButton, ControlBar, palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';

import { AppNav } from 'components/AppNav';
import { NumberInput } from 'components/NumberInput';
import { undo, check } from 'lib/icons';
import { H5 } from 'components/Text';
import Routes from 'lib/utils/routes';

import { Root, TitleText, Body, Label } from './styled';

const Step1 = ({ parts, dispatch, machineId }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Root id="reject-parts-step1">
      <AppNav
        onBack={() => {
          return history.push(Routes.machineIdPath(machineId));
        }}
        onClose={() => {
          return history.push(Routes.machineIdPath(machineId));
        }}
      >
        <H5 color={palette.Yellow400} bold>
          {t('rejectParts|full')}
        </H5>
        <TitleText>{_startCase(t('confirm number of rejects'))}</TitleText>
      </AppNav>
      <Body>
        <Label>
          {_startCase(t('how many parts would you like to reject?'))}
        </Label>
        <NumberInput
          value={parts}
          min={1}
          onChange={(newParts) => {
            return dispatch({ type: 'SET_PARTS', value: newParts });
          }}
        />
      </Body>
      <ControlBar>
        <ControlBarButton
          id="reset"
          onClick={() => {
            return dispatch({ type: 'RESET' });
          }}
          icon={undo}
        >
          {_startCase(t('reset'))}
        </ControlBarButton>
        <ControlBarButton
          id="confirm-reject-count"
          onClick={() => {
            return dispatch({ type: 'NEXT_STEP' });
          }}
          icon={check}
        >
          {_startCase(t('confirm reject count'))}
        </ControlBarButton>
      </ControlBar>
    </Root>
  );
};

export { Step1 };
