import { createSelector } from 'reselect';
import { getScopeScheduledActivityIntervals } from 'lib/selectors/getScopeScheduledActivityIntervals';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { diff, toISO } from 'lib/utils/date';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _dropRight from 'lodash/dropRight';
import {
  getScheduledTimeEnabled,
  getScheduledTimeMs,
} from './getScheduledTime';

const getCachedActivityIntervals = createSelector(
  [
    getScopeScheduledActivityIntervals,
    getHeavyUpdate, // force recalc every fifteen seconds
  ],
  (
    activityIntervals = [],
    _heavyUpdate // eslint-disable-line no-unused-vars
  ) => {
    const lastInterval = _last(activityIntervals);
    // no need to recalc agg below unless end of last interval is open
    if (!lastInterval || _get(lastInterval, 'end')) {
      return activityIntervals;
    }
    return [
      ...(_dropRight(activityIntervals, 1) || []),
      {
        ...lastInterval,
        end: toISO(),
      },
    ];
  }
);

const getTotalScopeScheduledTimeMs = createSelector(
  // only recalc agg when necessary (last interval open)
  [getCachedActivityIntervals, getScheduledTimeEnabled, getScheduledTimeMs],
  (cachedActivityIntervals, scheduledTimeEnabled, scheduledTimeMs) => {
    // this should be removed when scheduled time is default for all customers
    if (scheduledTimeEnabled) {
      return scheduledTimeMs;
    }
    const totalScopeScheduledTimeMs = cachedActivityIntervals.reduce(
      (aggTimeMs, interval) => {
        const intervalDuration = diff(
          interval.end,
          interval.start,
          'milliseconds'
        );
        return aggTimeMs + intervalDuration;
      },
      0
    );
    return totalScopeScheduledTimeMs;
  }
);

export { getTotalScopeScheduledTimeMs };
