// React and Hooks
import { createSelector } from 'reselect';
// Actions
// Selectors
import { getHasActiveOperatorRun } from 'lib/selectors/getHasActiveOperatorRun';
import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';
import { getLatestOperatorRunEndAt } from 'lib/selectors/getLatestOperatorRunEndAt';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getShiftStart } from 'lib/selectors/getShiftStart';
// Utils
import moment from 'moment';
import { isBefore } from 'lib/utils/date';
// Components

const getAllowPreviousSignOut = createSelector(
  [
    getHasActiveOperatorRun,
    getLatestOperatorRun,
    getLatestOperatorRunEndAt,
    checkIsLatestShiftActive,
    getShiftStart,
  ],
  (
    hasActiveOperatorRun,
    latestOperatorRun,
    latestOperatorRunEndAt,
    isLatestShiftActive,
    shiftStart
  ) => {
    const referenceTime = isLatestShiftActive
      ? shiftStart
      : moment.utc().toISOString();
    const isPreviousRunIncomplete =
      latestOperatorRun && latestOperatorRunEndAt === null;
    const isPreviousRunCompletedBeforeReferenceTime = isBefore(
      referenceTime,
      latestOperatorRunEndAt
    );
    return !!(
      !hasActiveOperatorRun &&
      latestOperatorRun &&
      (isPreviousRunIncomplete || isPreviousRunCompletedBeforeReferenceTime)
    );
  }
);

export { getAllowPreviousSignOut };
