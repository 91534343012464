import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { palette, Button } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import { bugsnag } from 'lib/external/bugsnag';
import { refreshHard } from 'lib/utils/refresh';
import Loading from './Loading';

const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${palette.White};
  position: absolute;
  top: 0;
  z-index: ${zLayer.loading};
`;

const FallbackMessage = styled.div`
  color: ${palette.Grey600};
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const FullScreenLoading = ({ loadingStates }) => {
  const [fresh, setFresh] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      return setFresh(false);
    }, 15000);
    return () => {
      return clearTimeout(timeout);
    };
  }, []);

  return (
    <FullScreen>
      <Loading
        text={
          fresh ? (
            `${_capitalize(t('loading'))}...`
          ) : (
            <>
              <FallbackMessage>
                {t('It looks like loading is taking a while')}
              </FallbackMessage>
              <Button
                onClick={() => {
                  bugsnag.notify(
                    new Error('Timed out loading the app'),
                    (event) => {
                      event.addMetadata('loading states', loadingStates);
                    },
                    refreshHard
                  );
                  setTimeout(refreshHard, 1000);
                }}
              >
                {_capitalize(t('retry'))}
              </Button>
            </>
          )
        }
      />
    </FullScreen>
  );
};

export default FullScreenLoading;
