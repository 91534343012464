import React from 'react';
import styled from 'styled-components';
import { Logo } from 'components/Logo';
import PropTypes from 'prop-types';
import { createLoginUrl } from 'lib/utils/request';

const Centered = styled.div`
  text-align: center;
  padding-top: 200px;
`;

const LoginButton = styled.a`
  border-radius: 5px;
  display: inline-block;
  padding: 15px 20px;
  text-decoration: none;
  color: #000;
  border: solid 1px #eee;

  &:hover {
    background-color: #eee;
  }
`;

const LogoContainer = styled.div`
  display: block;
  margin-bottom: 50px;
`;

const Login = ({ location }) => {
  const loginUrl = createLoginUrl();
  const loadError = location && location.state && location.state.loadError;

  return (
    <Centered>
      {loadError && <div>{loadError}</div>}
      <LogoContainer>
        <Logo width="400px" />
      </LogoContainer>
      <LoginButton href={loginUrl}>Login With MachineMetrics</LoginButton>
    </Centered>
  );
};

Login.propTypes = {
  location: PropTypes.object,
};

export default Login;
