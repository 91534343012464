import _camelCase from 'lodash/camelCase';

const EDGE_SLASHES = /^\/*|\/*$/;
const PARAMS = /(:\w+)/g;

function trim(path) {
  return path.replace(EDGE_SLASHES, '');
}

function joinPath(...paths) {
  const cleanPaths = paths.map(trim).filter((path) => {
    return path;
  });
  const isRoot = cleanPaths.length === 0;

  if (isRoot) {
    return '/';
  }

  return cleanPaths
    .reduce((acc, path, index) => {
      if (index === 0) acc.push('');
      acc.push(path);
      return acc;
    }, [])
    .join('/');
}

function joinName(name1, name2) {
  return _camelCase([name1, name2].join('-'));
}

function replaceParams(path, params) {
  const replacements = [...params];
  return path.replace(PARAMS, () => {
    return replacements.shift();
  });
}

/**
 *  @example
 *
 *  const router = new Router();
 *
 *  // Define routes
 *
 *  router
 *    .route('users', users => {
 *      users.route('/');
 *      users.route('show', { path: ':id' })
 *      users.route('posts')
 *      users.route('comments', { path: 'user_comments' })
 *    })
 *    .route('posts');
 *
 *
 *  // Compile the tree to generate route helpers
 *
 *  const routes = router.compile();
 *
 *
 *  // Each route offers the corresponding:
 *  // - pattern: path.pattern
 *  // - helper: path(...), substitutes route params with given values
 *
 *  routes.usersPath.pattern      // => /users/
 *  routes.usersPath()            // => /users/
 *
 *  routes.usersShowPath.pattern  // => /users/:id/
 *  routes.usersShowPath(123)     // => /users/123/
 *
 *  routes.usersPostsPath.pattern // => /users/:id/posts/
 *  routes.usersPostsPath(123)    // => /users/123/posts/
 *
 *  routes.postsPath.pattern      // => /posts/
 *  routes.postsPath(123)         // => /posts/
 *
 */

class Router {
  constructor(name = '', pattern = '') {
    this.name = name;
    this.pattern = pattern;
    this.children = [];
  }

  addNode(name, optionsOrCb, cb) {
    const options = optionsOrCb || {};
    const callback = typeof optionsOrCb === 'function' ? optionsOrCb : cb;

    const node = new Router(name, options.path || name);

    this.children.push(node);

    if (callback) callback(node.addNode.bind(node));

    return this;
  }

  route(...args) {
    return this.addNode(...args);
  }

  compile(name = this.name, pattern = this.pattern, initialPaths = {}) {
    return this.children.reduce((paths, node) => {
      const newName = joinName(name, node.name);
      const newPath = joinPath(pattern, node.pattern);

      if (node.children.length > 0) {
        return node.compile(newName, newPath, paths);
      }

      const route = (...params) => {
        return replaceParams(newPath, params);
      };
      route.pattern = newPath;

      return { ...paths, [`${newName}Path`]: route };
    }, initialPaths);
  }
}

export default Router;
