import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _sumBy from 'lodash/sumBy';

import { useQueryFilters } from 'lib/hooks';
import { formatDuration } from 'lib/utils/date';
import MainLayout from 'components/Layouts/MainLayout';
import {
  Results,
  ResultsHeader,
  FilterColumn,
  TableColumn,
} from 'components/Table';
import { Small } from 'components/Text';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { useSelector } from 'react-redux';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { Table } from './Table';
import { TableFilters } from './TableFilters';
import { useAlarms } from './hooks';

const defaultFilters = {
  orderBy: 'date',
  severity: 'all',
  description: '',
  state: 'all',
};

const Alarms = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useQueryFilters(defaultFilters);
  const { alarms, loading, loadMore, error } = useAlarms(filters);
  const heavyUpdate = useSelector(getHeavyUpdate);

  const totalDuration = useMemo(() => {
    return formatDuration(_sumBy(alarms, 'duration'));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [alarms, heavyUpdate]);

  return (
    <PauseDetailsOverlayContainer>
      <MainLayout.Container
        direction="row"
        portraitDirection="column"
        id="alarms-page"
      >
        <FilterColumn>
          <TableFilters filters={filters} setFilters={setFilters} />
          {alarms && alarms.length > 0 && (
            <Results>
              <ResultsHeader>{_capitalize(t('results'))}</ResultsHeader>
              <div>
                <Small medium>{_capitalize(t('events'))}: </Small>
                <Small bold>{alarms && alarms.length}</Small>
              </div>
              <div>
                <Small medium>{_capitalize(t('total duration'))}: </Small>
                <Small bold>{totalDuration}</Small>
              </div>
            </Results>
          )}
        </FilterColumn>
        <TableColumn>
          <Table
            onLoadMore={loadMore}
            error={error}
            loading={loading}
            alarms={alarms}
          />
        </TableColumn>
      </MainLayout.Container>
      <PauseDetailsToggle />
    </PauseDetailsOverlayContainer>
  );
};

export { Alarms };
