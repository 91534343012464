import { createSelector } from 'reselect';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { getCompany, getIsCompanyLoading } from 'lib/selectors/getCompany';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';

const getStartOfCurrentShopDay = createSelector(
  [
    // important to recalc this regularly so it is refreshed on shop day change
    getHeavyUpdate,
    getIsCompanyLoading,
    getCompany,
  ],
  (
    // eslint-disable-next-line no-unused-vars
    _heavyUpdate,
    isCompanyLoading,
    company
  ) => {
    const tzid = _get(company, 'timezone');
    const offsetMs = _get(company, 'currentTimeContext.offsetMs');
    const isValidOffsetMs = Number.isSafeInteger(offsetMs);
    const haveShopDayDepsLoaded = !!(
      !isCompanyLoading &&
      isValidOffsetMs &&
      tzid
    );
    if (!haveShopDayDepsLoaded) {
      return null;
    }
    const now = moment.utc();
    const startOfShopDay = moment
      .tz(tzid)
      .startOf('day')
      .milliseconds(offsetMs);
    if (now.isBefore(startOfShopDay)) {
      return startOfShopDay.subtract(1, 'day').toISOString();
    }
    return startOfShopDay.toISOString();
  }
);

export { getStartOfCurrentShopDay };
