import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { palette } from '@m12s/component-library';
import routes from 'lib/utils/routes';
import styled from 'styled-components';

import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';
import useStopRun from 'components/LaborTicket/hooks/useStopRun';
import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { actionOpenLaborTicketModal } from 'lib/actions';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getLatestJob } from 'lib/selectors/getLatestJob';

import { AppNav } from 'components/AppNav';
import MainLayout from 'components/Layouts/MainLayout';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { H5 } from 'components/Text';
import { ModalButton } from 'components/LaborTicket/styled';

import NoOrderOpContent from './NoOrderOpContent';
import OrderOpContent from './OrderOpContent';
import { ContentContainer, Footer } from './styled';

const Title = styled(H5)`
  width: 100%;
  text-align: left;
  padding-left: 1.5rem;
  font-weight: 500;
  margin-left: -8rem;
`;

const JobInfoERP = ({
  handleOnClose = (history, machine) => {
    return history.push({ pathname: routes.machineIdHomePath(machine.id) });
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const laborTicketEnabled = useIsLaborTicketFlow();
  const requestStopRun = useStopRun();

  const job = useSelector(getLatestJob);
  const machine = useSelector(getMachine);
  const latestActivityType = useSelector(getLatestActivityType);
  const isPaused = useSelector(getIsPaused);
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  return (
    <MainLayout.Container id="job-details-page" direction="column" overlay>
      <AppNav
        iconColor={palette.Grey700}
        color={palette.White}
        onClose={() => {
          handleOnClose(history, machine);
        }}
        style={{
          borderBottom: `1px solid ${palette.Grey400}`,
        }}
      >
        <Title>
          {!currentWorkOrderOp
            ? `${job.name} | Operation overview`
            : 'Current production order operation'}
        </Title>
      </AppNav>
      <ContentContainer>
        {currentWorkOrderOp?.workOrderId ? (
          <OrderOpContent />
        ) : (
          <NoOrderOpContent job={job} />
        )}
      </ContentContainer>
      <Footer>
        <ModalButton
          customStyles={{
            backgroundColor: palette.Indigo600,
          }}
          hoverStyles={{
            backgroundColor: palette.Indigo700,
          }}
          onClick={async () => {
            handleOnClose(history, machine);
            if (isPaused) {
              return requestStopRun();
            }

            if (canCloseLaborTicket && laborTicketEnabled) {
              return dispatch(
                actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.STOP_RUN, {
                  redirectPath: routes.machineIdSelectJobPath(machine.id),
                })
              );
            }

            if (isAPMEnabled) {
              return history.push(
                `${routes.machineIdSelectJobPath(machine.id)}?activityTypeRef=${
                  latestActivityType.activityTypeRef
                }`
              );
            }
            await requestStopRun();
            return history.push(routes.machineIdSelectJobPath(machine.id));
          }}
        >
          Stop operation
        </ModalButton>
      </Footer>
    </MainLayout.Container>
  );
};

JobInfoERP.propTypes = {
  handleOnClose: PropTypes.func,
};

export default JobInfoERP;
