import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Icon } from '@m12s/component-library';
import { cube } from 'lib/icons';
import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { getIsLatestActivitySetOpen } from 'lib/selectors/getIsLatestActivitySetOpen';
import { getIsRunningUntaggedOperation } from 'lib/selectors/getIsRunningUntaggedOperation';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import { WorkOrderInfoGroup } from './WorkOrderInfoGroup';
import { Text, TouchAreaContainer, OperationName, TextSpacer } from './styled';

const TouchArea = ({
  variant,
  isPaused,
  onClickToOverview,
  setTouchPointRight,
}) => {
  const windowSize = useWindowDimensions();
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(windowSize.width);
  useEffect(() => {
    setWindowWidth(windowSize.width);
  }, [windowSize]);
  const isRunningUntaggedOp = useSelector(getIsRunningUntaggedOperation);
  const jobNameRaw = useSelector(getLatestJobName);
  const jobName = isRunningUntaggedOp ? t(jobNameRaw) : jobNameRaw;

  const isLatestActivitySetOpen = useSelector(getIsLatestActivitySetOpen);
  const latestActivityType = useSelector(getLatestActivityType);

  const latestActivityName = useMemo(() => {
    return _get(latestActivityType, 'name', '');
  }, [latestActivityType]);

  const bottomLeftRef = useRef();
  // need this to calculate the tablet touch point width
  useEffect(() => {
    const touchPointWidth = bottomLeftRef?.current?.getBoundingClientRect();
    if (touchPointWidth) {
      setTouchPointRight(touchPointWidth.width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  return (
    <TouchAreaContainer
      id="touch-point"
      ref={bottomLeftRef}
      onTouchEnd={onClickToOverview}
    >
      {isLatestActivitySetOpen && (
        <WorkOrderInfoGroup
          variant={variant}
          onClickToOverview={onClickToOverview}
          isPaused={isPaused}
        />
      )}

      <OperationName variant={variant} muted={isPaused} noLineHeight>
        <Icon
          icon={cube}
          style={{
            marginRight: '0.5rem',
          }}
        />
        {jobName}
      </OperationName>
      <TextSpacer />
      <Text variant={variant} shadow muted noWrap>
        {latestActivityName}
      </Text>
      <TextSpacer />
    </TouchAreaContainer>
  );
};

export { TouchArea };
