import { createSelector } from 'reselect';
import { getLatestSetupActivity } from 'lib/selectors/getLatestSetupActivity';

const getLatestSetupActivityEndAt = createSelector(
  [getLatestSetupActivity],
  ({ end }) => {
    return end || null;
  }
);

export { getLatestSetupActivityEndAt };
