import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { now, toISO } from 'lib/utils/date';
import { throwSuccessToast, throwErrorToast } from 'lib/utils/toast';

import { UPDATE_ACTIVITY_SET } from 'lib/api/mutations';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import {
  actionSetLaborTicketActionLoading,
  actionCanonicalActivitySetClose,
  actionCanonicalActivitySetReopen,
  actionOptimisticActivitySetClose,
} from 'lib/actions';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

const useStopRun = () => {
  const dispatch = useDispatch();
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestActivity = useSelector(getLatestActivity);
  const workOrderFlowEnabled = useIsWorkOrderFlow();
  const laborTicketEnabled = useIsLaborTicketFlow();

  const [requestUpdateActivitySet] = useMutation(UPDATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          updateActivitySetResponse: {
            activitySet: {
              activities: [activity],
              ...closedActivitySet
            },
          },
        },
      }
    ) => {
      dispatch(
        actionOptimisticActivitySetClose({
          activitySet: {
            activitySetRef: closedActivitySet.activitySetRef,
            closedAt: closedActivitySet.closedAt,
          },
          activity: {
            activityRef: activity.activityRef,
            end: activity.end,
          },
        })
      );
    },
    onCompleted: async ({
      updateActivitySetResponse: {
        activitySet: {
          activities: [activity], // should be sorted start ASC
          ...closedActivitySet
        },
      },
    }) => {
      if (!laborTicketEnabled) {
        if (closedActivitySet.workOrderId && workOrderFlowEnabled) {
          throwSuccessToast(`Stopped order ${closedActivitySet.workOrderId}`);
        } else {
          throwSuccessToast('Stopped order');
        }
      }

      await dispatch(
        actionCanonicalActivitySetClose({
          activitySet: closedActivitySet,
          activity,
        })
      );
    },
    onError: () => {
      dispatch(
        actionCanonicalActivitySetReopen({
          activitySet: {
            activitySetRef: latestActivitySet.activitySetRef,
            closedAt: null,
          },
          activity: {
            activityRef: latestActivity.activityRef,
            end: null,
          },
        })
      );
      throwErrorToast('Could not stop order');
    },
  });

  return async () => {
    const nowISO = toISO(now().valueOf());
    dispatch(actionSetLaborTicketActionLoading(true));

    await requestUpdateActivitySet({
      variables: {
        input: {
          machineRef: latestActivitySet.machineRef,
          activitySetRef: latestActivitySet.activitySetRef,
          closedAt: nowISO,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateActivitySetResponse: {
          __typename: 'ActivitySet',
          activitySet: {
            ...latestActivitySet,
            closedAt: nowISO,
            activities: [
              {
                ...latestActivity,
                // only close out activity if open
                end: latestActivity.end || nowISO,
              },
            ],
          },
        },
      },
    });
  };
};

export default useStopRun;
