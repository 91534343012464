import { createSelector } from 'reselect';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';

const getIsPaused = createSelector(
  [
    getAreActivitiesEnabled,
    getHasOpenActivitySet,
    getHasOpenActivity,
    getIsAPMEnabled,
  ],
  (areActivitiesEnabled, hasOpenActivitySet, hasOpenActivity, isAPMEnabled) => {
    return !!(
      areActivitiesEnabled &&
      hasOpenActivitySet &&
      !hasOpenActivity &&
      !isAPMEnabled
    );
  }
);

export { getIsPaused };
