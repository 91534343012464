import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { bugsnag } from 'lib/external/bugsnag';
import { useTranslation } from 'react-i18next';

import {
  UPDATE_LABOR_TICKET,
  UPDATE_VIA_INSERT_LABOR_TICKET,
} from 'lib/api/mutations';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
import {
  actionCloseLaborTicketModal,
  actionSetLaborTicketActionLoading,
} from 'lib/actions';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';

const useEditLaborTicket = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);

  const [requestUpdateLaborTicket] = useMutation(UPDATE_LABOR_TICKET, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      bugsnag.notify(err, (event) => {
        event.addMetadata('user', bugsnagUserInfo);
      });
      throwErrorToast(t('Failed to update labor ticket'));
    },
    onCompleted: ({ updateErpLaborTicket }) => {
      if (updateErpLaborTicket?.clockOut) {
        throwSuccessToast('Labor ticket submitted');
      }
    },
  });

  const [requestUpdateViaInsertLaborTicket] = useMutation(
    UPDATE_VIA_INSERT_LABOR_TICKET,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        bugsnag.notify(err, (event) => {
          event.addMetadata('user', bugsnagUserInfo);
        });
        throwErrorToast(t('Failed to update labor ticket'));
      },
      onCompleted: ({ insertErpLaborTicket }) => {
        if (insertErpLaborTicket?.clockOut) {
          throwSuccessToast('Labor ticket submitted');
        }
      },
    }
  );

  const submitLaborTicket = async (input, laborTicketRef, reasonRef) => {
    try {
      if (input) {
        if (reasonRef) {
          const columns = Object.keys(input);
          await requestUpdateViaInsertLaborTicket({
            variables: {
              input: {
                ...input,
                laborTicketRef,
                reasons: {
                  data: {
                    reasonRef,
                  },
                  on_conflict: {
                    constraint: 'rel_labor_ticket_reason_pk',
                    update_columns: ['laborTicketRef', 'reasonRef'],
                  },
                },
              },
              columns,
            },
          });
        } else {
          await requestUpdateLaborTicket({
            variables: {
              laborTicketRef,
              input,
            },
          });
        }
      }
      dispatch(actionSetLaborTicketActionLoading(false));
      dispatch(actionCloseLaborTicketModal());
    } catch (e) {
      dispatch(actionSetLaborTicketActionLoading(false));
      throwErrorToast(`Failed to update labor ticket: ${e}`);
    }
  };

  return (input, laborTicketRef, reasonRef) => {
    submitLaborTicket(input, laborTicketRef, reasonRef);
  };
};

export default useEditLaborTicket;
