import moment from 'moment';

import {
  diff,
  isBefore,
  isAfter,
  isSameOrBefore,
  isSameOrAfter,
  now,
  toISO,
} from 'lib/utils/date';
import { chopAfter, chopBefore } from 'lib/utils/intervals';
import _last from 'lodash/last';

const startsDuringSlice = (start, sliceStart, sliceEnd) => {
  return isSameOrBefore(start, sliceEnd) && isSameOrAfter(start, sliceStart);
};

const endsDuringSlice = (end, sliceStart, sliceEnd) => {
  return isSameOrBefore(end, sliceEnd) && isAfter(end, sliceStart);
};

const goesOverSlice = (start, end, sliceStart, sliceEnd) => {
  return isBefore(start, sliceStart) && isSameOrAfter(end, sliceEnd);
};

const getIntervalSeconds = (intervals, sliceStart, sliceEnd) => {
  const activeIntervals = intervals.filter(({ label }) => {
    return label === 'ACTIVE';
  });

  return activeIntervals.reduce((seconds, { start, end: endRaw }) => {
    const end = endRaw || moment();

    if (startsDuringSlice(start, sliceStart, sliceEnd)) {
      const intervalSlicedEnd = isAfter(end, sliceEnd) ? sliceEnd : end;
      return seconds + diff(intervalSlicedEnd, start, 'seconds');
    }

    if (endsDuringSlice(end, sliceStart, sliceEnd)) {
      const intervalSlicedStart = isBefore(start, sliceStart)
        ? sliceStart
        : start;
      return seconds + diff(end, intervalSlicedStart, 'seconds');
    }

    if (goesOverSlice(start, end, sliceStart, sliceEnd)) {
      return seconds + diff(sliceEnd, sliceStart, 'seconds');
    }

    return seconds;
  }, 0);
};

export const updatedSlices = (
  statusIntervals,
  generatedAt,
  hourlies,
  scopeStart,
  scopeEnd
) => {
  const nextGeneratedAt = toISO();
  const lastHourlyTime = _last(hourlies)?.time;
  if (!lastHourlyTime || isSameOrAfter(scopeStart, lastHourlyTime)) {
    const shiftIntervals = chopBefore(statusIntervals, scopeStart);
    return utilizationSlices(scopeStart, scopeEnd, shiftIntervals);
  }
  return {
    generatedAt: nextGeneratedAt,
    hourlies: hourlies.map((hour) => {
      const sliceStart = hour.time;
      let sliceEnd = moment(hour.time).add(1, 'hours');
      if (
        isBefore(sliceEnd, generatedAt) ||
        isAfter(sliceStart, nextGeneratedAt)
      ) {
        return hour;
      }
      if (sliceEnd.isAfter(nextGeneratedAt)) {
        sliceEnd = nextGeneratedAt;
      }

      const sliceIntervals = chopBefore(
        chopAfter(statusIntervals, sliceEnd),
        sliceStart
      );
      const sliceActiveTime = getIntervalSeconds(
        sliceIntervals,
        sliceStart,
        sliceEnd
      );

      return {
        time: sliceStart,
        activeSeconds: sliceActiveTime,
        utilization: Math.round(
          (sliceActiveTime / diff(nextGeneratedAt, sliceStart, 'seconds')) * 100
        ),
      };
    }),
  };
};

export const utilizationSlices = (startDate, endDate, statusIntervals) => {
  const generatedAt = toISO();

  const momentizedStartDate = moment(startDate);
  const momentizedEndDate = moment(endDate);
  const slices = [];

  while (isBefore(momentizedStartDate, momentizedEndDate)) {
    const sliceStart = momentizedStartDate.clone();
    let sliceEnd = momentizedStartDate.clone().endOf('hour');

    if (sliceEnd.isAfter(momentizedEndDate)) {
      sliceEnd = momentizedEndDate;
    }

    if (momentizedStartDate.isAfter(now())) {
      slices.push({
        time: toISO(sliceStart),
        utilization: null,
      });
    } else {
      const sliceSeconds = getIntervalSeconds(
        statusIntervals,
        sliceStart,
        sliceEnd
      );

      const sliceLength = diff(
        sliceEnd.isAfter(now()) ? now() : sliceEnd,
        sliceStart,
        'seconds'
      );

      slices.push({
        time: toISO(sliceStart),
        activeSeconds: sliceSeconds,
        utilization: Math.round((sliceSeconds / sliceLength) * 100),
      });
    }

    momentizedStartDate.add(1, 'hour');
    momentizedStartDate.startOf('hour');
  }
  return {
    hourlies: slices,
    generatedAt: statusIntervals.length ? generatedAt : null,
  };
};
