import styled from 'styled-components';
import { Icon, palette } from '@m12s/component-library';
import { ban } from 'lib/icons';

const NoJobIcon = styled(Icon).attrs(() => {
  return { icon: ban };
})`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => {
    return theme.colors.text.disabled;
  }};
`;

const Faded = styled.span`
  color: ${({ theme }) => {
    return theme.colors.text.disabled;
  }};
`;

const PartCountPerfStatusIcon = styled(Icon)`
  font-size: 3rem;
  color: ${palette.White};
  color: ${({ theme, fillMode, appearance }) => {
    return theme?.widgets?.wheel?.[fillMode]?.[appearance]?.label || 'none';
  }};
`;

export { NoJobIcon, PartCountPerfStatusIcon, Faded };
