import { createSelector } from 'reselect';
import _dropWhile from 'lodash/dropWhile';

import { isSameOrBefore } from 'lib/utils/date';
import { getDowntimeIntervals } from 'lib/selectors/getDowntimeIntervals';
import { getShiftStart } from 'lib/selectors/getShiftStart';

const defaultShiftDowntimeIntervals = [];

const getShiftDowntimeIntervals = createSelector(
  [getDowntimeIntervals, getShiftStart],
  (downtimes, shiftStart) => {
    return !shiftStart
      ? defaultShiftDowntimeIntervals
      : _dropWhile(downtimes, (downtime) => {
          return downtime.end && isSameOrBefore(downtime.end, shiftStart);
        });
  }
);

export { getShiftDowntimeIntervals };
