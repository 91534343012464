import styled from 'styled-components';
import { Small } from 'components/Text';

const SetupPartsSubtext = styled(Small).attrs({
  muted: true,
})`
  display: block;
  margin-top: 1rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export { SetupPartsSubtext, FlexRow };
