import React from 'react';
// careful when changing bugsnag export - see https://github.com/machinemetrics/OperatorView/pull/446
import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { RELEASE_STAGE } from 'lib/appConfig';

const BUGSNAG_API_KEY = '40d794ab5bed6586bdb42e344dff0607';

const bugsnag = Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  notifyReleaseStages: [RELEASE_STAGE],
  releaseStage: RELEASE_STAGE,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'log', 'request', 'user'],
  onError: (event) => {
    if (RELEASE_STAGE !== 'production') {
      return false;
    }

    if (
      event.errors.some(({ errorMessage }) => {
        return errorMessage?.includes('JWTExpired');
      })
    ) {
      return false;
    }

    return event;
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export { bugsnag, ErrorBoundary };
