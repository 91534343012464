import moment from 'moment';
import { createSelector } from 'reselect';

import { getScopeHourlyIntervalKeys } from 'lib/selectors/getScopeHourlyIntervalKeys';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getPartAdjustments } from 'lib/selectors/getPartAdjustments';
import { isBefore, toISO } from 'lib/utils/date';

// init with empty data to be populated during pass through adjustment data
const getBaseNetPartAdjustmentsByHour = (hourlyIntervalKeys = []) => {
  return hourlyIntervalKeys.reduce((baseNetPartAdjustmentsByHour, hourKey) => {
    return {
      ...baseNetPartAdjustmentsByHour,
      [hourKey]: 0,
    };
  }, {});
};

const getScopePartAdjustmentData = createSelector(
  [getScopeHourlyIntervalKeys, getScopeStart, getPartAdjustments],
  (hourlyIntervalKeys, scopeStart, partAdjustments = []) => {
    if (!scopeStart) {
      return {
        scopeTotalNetPartAdjustments: 0,
        scopeNetPartAdjustments: [],
        scopeNetPartAdjustmentsByHour: [], // include for offset calcs against partCounts in separate selector
      };
    }
    let firstScopedAdjustmentIdx = null;
    const netPartAdjustmentsByHour = getBaseNetPartAdjustmentsByHour(
      hourlyIntervalKeys
    );
    let currentHourISO = scopeStart;
    let upperHourlyBound = toISO(
      moment
        .utc(scopeStart)
        .startOf('hour')
        .add(1, 'hour')
    );
    let totalNetPartAdjustments = 0;

    [...partAdjustments]
      .sort((a, b) => {
        return isBefore(a.adjustmenttime, b.adjustmenttime) ? -1 : 1;
      })
      .forEach(({ adjustmenttime, count, scrapcount }, idx) => {
        if (adjustmenttime < scopeStart) {
          return; // bail early for unscoped part adjustments
        }
        if (firstScopedAdjustmentIdx === null) {
          // only set when uninitialized
          firstScopedAdjustmentIdx = idx;
        }
        if (adjustmenttime >= upperHourlyBound) {
          // ratchet hourly pointers when event passes boundary
          currentHourISO = toISO(moment.utc(adjustmenttime).startOf('hour'));
          upperHourlyBound = toISO(
            moment
              .utc(currentHourISO)
              .startOf('hour')
              .add(1, 'hour')
          );
        }
        const netPartAdjustment = count - scrapcount;
        totalNetPartAdjustments += netPartAdjustment;
        netPartAdjustmentsByHour[currentHourISO] += netPartAdjustment;
      });

    return {
      scopeTotalNetPartAdjustments: totalNetPartAdjustments,
      scopeNetPartAdjustments:
        firstScopedAdjustmentIdx === null
          ? []
          : partAdjustments.slice(firstScopedAdjustmentIdx),
      scopeNetPartAdjustmentsByHour: netPartAdjustmentsByHour, // include for offset calcs against partCounts in separate selector
    };
  }
);

export { getScopePartAdjustmentData };
