import { createSelector } from 'reselect';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';

const getOpenActivity = createSelector(
  [getAreActivitiesEnabled, getLatestActivity],
  (areActivitiesEnabled, latestActivity) => {
    if (
      !areActivitiesEnabled ||
      !latestActivity ||
      latestActivity.end !== null
    ) {
      return null;
    }
    return latestActivity;
  }
);

export { getOpenActivity };
