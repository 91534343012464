import { createSelector } from 'reselect';

export const getScheduledTime = (state) => {
  return state?.scheduledTime;
};

export const getScheduledTimeEnabled = createSelector(
  [getScheduledTime],
  (scheduledTime) => {
    return scheduledTime.isEnabled;
  }
);

export const getScheduledTimeMs = createSelector(
  [getScheduledTime, getScheduledTimeEnabled],
  (scheduledTime, isEnabled) => {
    return isEnabled && !scheduledTime.loading
      ? scheduledTime.data.stats.scheduledTimeMs
      : null;
  }
);
