import React, { useMemo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CounterBlock,
  Wheel,
  WheelLabel,
  WheelSublabel,
} from '@m12s/component-library';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';

import {
  formatDuration,
  diff,
  getUnitDurationFromString,
  dateUTC,
  millis,
} from 'lib/utils/date';
import { DurationTicker } from 'components/DurationTicker';
import { Display } from 'components/Text';
import { getMachine } from 'lib/selectors/getMachine';
import { TickerContext } from 'components/TickerProvider';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getAdjustedSetupDurationDates } from 'lib/selectors/getAdjustedSetupDurationDates';
import { getLatestSetupActivity } from 'lib/selectors/getLatestSetupActivity';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { WheelContentOpRunOverride } from 'components/TabsCommon/WheelContentOpRunOverride';
import { OperatorPill } from 'components/OperatorPill';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { WheelCounterPadding, WheelLayout } from 'components/WheelLayout';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getSetupModeSpansShift } from 'lib/selectors/getSetupModeSpansShift';
import { getSetupRejectCount } from 'lib/selectors/getSetupParts';
import { getShouldUseOpRunOverrideScope } from 'lib/selectors/getShouldUseOpRunOverrideScope';
import { AdjustSetupPartsModal } from './AdjustSetupPartsModal';
import { SetupPartsDisplay, ShiftSetupPartsDisplay } from './SetupPartsDisplay';

const setupFormat = {
  formatOptions: {
    largest: 2,
    trim: false,
  },
  format: 'd[d] h[h] m[m] ss[s]',
};

const WheelContentStandard = ({ isOvertime }) => {
  const { t } = useTranslation();
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestSetupActivity = useSelector(getLatestSetupActivity);
  const { expectedSetupDuration } = latestActivitySet;
  const expectedSetupDurationMs = getUnitDurationFromString(
    expectedSetupDuration,
    'milliseconds'
  );
  const { expectedSetupEndDate } = useSelector(getAdjustedSetupDurationDates);
  const isPaused = useSelector(getIsPaused);
  const setupRejectCount = useSelector(getSetupRejectCount);
  return (
    <>
      {!!expectedSetupDurationMs && (
        <>
          <WheelLabel>
            <DurationTicker
              reverse={!isOvertime}
              startDate={expectedSetupEndDate}
              endDateOverride={isPaused ? latestSetupActivity.end : null}
              {...setupFormat}
            />
          </WheelLabel>
          <WheelSublabel>
            {_startCase(t(isOvertime ? 'overtime' : 'remaining'))}
          </WheelSublabel>
        </>
      )}
      <WheelLabel secondary>{setupRejectCount || '-'}</WheelLabel>
      <WheelSublabel>{_capitalize(t('rejects'))}</WheelSublabel>
    </>
  );
};

const MachineSetup = () => {
  const { t } = useTranslation();
  const machine = useSelector(getMachine);
  const nowMs = useContext(TickerContext);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestSetupActivity = useSelector(getLatestSetupActivity);
  const { adjustedSetupStartDate, expectedSetupEndDate } = useSelector(
    getAdjustedSetupDurationDates
  );
  const shouldUseOpRunOverrideScope = useSelector(
    getShouldUseOpRunOverrideScope
  );
  const isPaused = useSelector(getIsPaused);
  const isOperatorTrackingEnabled = useSelector(getIsOperatorTrackingEnabled);
  const operator = useSelector(getCurrentOperator);
  const shiftStart = useSelector(getShiftStart);
  const setupModeSpansShift = useSelector(getSetupModeSpansShift);
  const { expectedSetupDuration } = latestActivitySet;
  const expectedSetupDurationMs = getUnitDurationFromString(
    expectedSetupDuration,
    'milliseconds'
  );
  const endMs = isPaused ? millis(latestSetupActivity.end) : nowMs;
  const isOvertime = useMemo(() => {
    return dateUTC(endMs).isAfter(expectedSetupEndDate);
    /* when unpaused, this updates to 'now' every second */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [expectedSetupEndDate, endMs]);
  const wheelAppearance = !expectedSetupDurationMs
    ? shouldUseOpRunOverrideScope
      ? 'setup'
      : 'empty'
    : isOvertime
    ? 'failure'
    : 'setup';

  const progressBarPercentage = useMemo(() => {
    const elapsedMs = Math.max(
      diff(endMs, adjustedSetupStartDate, 'milliseconds'),
      0
    );
    return Math.round((elapsedMs / expectedSetupDurationMs) * 100) || 1; // default so bar never starts empty
    /* when unpaused, this updates to 'now' every second */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [endMs, adjustedSetupStartDate, expectedSetupDurationMs]);

  const [adjustPartsModalOpen, setAdjustPartsModalOpen] = useState(false);

  return (
    <PauseDetailsOverlayContainer>
      <WheelLayout
        id="part-count-page"
        topLeft={
          <WheelCounterPadding top left>
            {setupModeSpansShift ? (
              <ShiftSetupPartsDisplay
                setAdjustPartsModalOpen={setAdjustPartsModalOpen}
                t={t}
                shiftStart={shiftStart}
              />
            ) : (
              <SetupPartsDisplay
                setAdjustPartsModalOpen={setAdjustPartsModalOpen}
                t={t}
                setupModeSpansShift={setupModeSpansShift}
              />
            )}
          </WheelCounterPadding>
        }
        topRight={
          setupModeSpansShift && (
            <WheelCounterPadding top right>
              <SetupPartsDisplay
                setAdjustPartsModalOpen={setAdjustPartsModalOpen}
                setupModeSpansShift={setupModeSpansShift}
                t={t}
              />
            </WheelCounterPadding>
          )
        }
        bottomLeft={
          <WheelCounterPadding bottom left>
            <CounterBlock
              label={t('Total Elapsed Setup')}
              value={
                <Display>
                  {adjustedSetupStartDate && (
                    <DurationTicker
                      startDate={adjustedSetupStartDate}
                      endDateOverride={
                        isPaused ? latestSetupActivity.end : null
                      }
                      {...setupFormat}
                    />
                  )}
                </Display>
              }
            />
          </WheelCounterPadding>
        }
        bottomRight={
          <WheelCounterPadding bottom right>
            <CounterBlock
              align="right"
              label={t('Total Expected Setup')}
              value={
                <Display>
                  {expectedSetupDurationMs
                    ? formatDuration(expectedSetupDurationMs / 1000, 'short', {
                        trim: 'both',
                      })
                    : _startCase(t('none'))}
                </Display>
              }
            />
          </WheelCounterPadding>
        }
        wheel={
          <div>
            <Wheel
              shape="rounded"
              value={
                !!expectedSetupDurationMs &&
                Math.min(progressBarPercentage, 100)
              }
              appearance={wheelAppearance}
              fillMode="full"
              withMarkers
              Contents={
                shouldUseOpRunOverrideScope ? (
                  <WheelContentOpRunOverride
                    appearance={wheelAppearance}
                    fillMode="full"
                  />
                ) : (
                  <WheelContentStandard />
                )
              }
            />
          </div>
        }
        login={
          isOperatorTrackingEnabled && (
            <OperatorPill operator={operator} machineId={machine.id} />
          )
        }
      />
      <PauseDetailsToggle />
      <AdjustSetupPartsModal
        open={adjustPartsModalOpen}
        onClose={() => {
          setAdjustPartsModalOpen(false);
        }}
      />
    </PauseDetailsOverlayContainer>
  );
};

export { MachineSetup };
