import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import Echart from 'echarts-for-react/lib/core';
import echarts from 'echarts';
import 'echarts/lib/chart/line';
import { zLayer } from 'lib/utils/styled';
import { loadingFade } from 'components/LoadingTextPlaceholder';
import { chartTheme } from './chartTheme';

echarts.registerTheme(chartTheme.themeName, chartTheme());

const containerStyle = {
  height: '100%',
  width: '100%',
};

const cssLoadingFade = css`
  ${loadingFade} background-color: #fafbfc;
`;

const Root = styled.div`
  flex: 1;
  margin: -16px;
  z-index: ${zLayer.lowered};
  max-width: 100%;
  ${({ loading }) => {
    return loading && cssLoadingFade;
  }}
`;

const chartPropsAreEqual = (prev, next) => {
  return (
    prev.loading === next.loading &&
    JSON.stringify(prev.option) === JSON.stringify(next.option)
  );
};

const Chart = memo(({ option, loading }) => {
  return (
    <Root loading={loading}>
      <Echart
        style={containerStyle}
        echarts={echarts}
        notMerge
        theme={chartTheme.themeName}
        {...{ option }} // eslint-disable-line react/jsx-props-no-spreading
      />
    </Root>
  );
}, chartPropsAreEqual);

export { Chart };
