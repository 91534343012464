import { createSelector } from 'reselect';
import { getOperatorRuns } from 'lib/selectors/getOperatorRuns';

// this boundary will be recalc'ed on every heavy update - this is to ensure that we quickly switch
// scope when crossing an hour boundary, but will otherwise return the bottom of the hour, 24 hours ago

const getLatestOperatorRun = createSelector(
  [getOperatorRuns],
  (operatorRuns) => {
    if (operatorRuns && operatorRuns?.length > 0) {
      return operatorRuns[0];
    }
    return null;
  }
);

export { getLatestOperatorRun };
