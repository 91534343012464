import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getHasOpenActivity } from './getHasOpenActivity';
import { getIsAPMEnabled } from './getIsAPMEnabled';

const getLatestJobId = createSelector(
  [getLatestActivitySet, getIsAPMEnabled, getHasOpenActivity],
  (latestActivitySet, isAPMEnabled, hasOpenActivity) => {
    if (!hasOpenActivity && isAPMEnabled) {
      return null;
    }
    return _get(latestActivitySet, 'jobId');
  }
);

export { getLatestJobId };
