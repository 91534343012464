import { createSelector } from 'reselect';

import { takeLatest } from 'lib/utils/date';
import { getScopeStart } from './getScopeStart';
import { getScopeCurrentCycleStart } from './getScopeCurrentCycleStart';

const getEffectiveCurrentCycleStart = createSelector(
  [getScopeCurrentCycleStart, getScopeStart],
  (scopeCurrentCycleStart, scopeStart) => {
    if (scopeCurrentCycleStart) {
      return takeLatest(scopeCurrentCycleStart, scopeStart);
    }
    return scopeStart;
  }
);

export { getEffectiveCurrentCycleStart };
