import { createSelector } from 'reselect';
import { getLatestIdealUnitDuration } from 'lib/selectors/getLatestIdealUnitDuration';
import { getIsActivitiesStatusLoading } from 'lib/selectors/getActivitiesStatus';
import { getScopeScheduledTimeInCycleMs } from 'lib/selectors/getScopeScheduledTimeInCycleMs';
import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';

import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { getUnitDurationFromString } from 'lib/utils/date';
import { isNumber } from 'lib/utils/validation';
import { getTotalScheduledParts } from './getNetScheduledParts';
/*
  calculating perf: https://machinemetrics.quip.com/bDM9AOj7yo6q
  performance: idealPartTime / (scheduledTimeInCycle / scheduledTotalParts)

  Also see canonical performance definition in report builder @
    https://github.com/machinemetrics/Dashboard/blob/master/apis/warehouse/production/production.data.js)
*/

const getPerformanceDecimal = createSelector(
  [
    getScopeScheduledTimeInCycleMs,
    getTotalScheduledParts,
    getLatestIdealUnitDuration,
    getIsActivitiesStatusLoading,
    getHasOpenActivity,
    getHasOpenJobRun,
    getOpenActivitySetHasNoStandards,
  ],
  (
    scopeScheduledTimeInCycleMs,
    totalScheduledParts,
    idealUnitDuration,
    isActivitiesStatusLoading,
    hasOpenActivity,
    hasOpenJobRun,
    openActivitySetHasNoStandards
  ) => {
    if (
      // metric is only relevant when active / job is running and unpaused
      !(hasOpenActivity || hasOpenJobRun) ||
      !idealUnitDuration ||
      openActivitySetHasNoStandards ||
      !isNumber(scopeScheduledTimeInCycleMs) ||
      !isNumber(totalScheduledParts) ||
      scopeScheduledTimeInCycleMs === 0 ||
      totalScheduledParts === 0 ||
      isActivitiesStatusLoading
    ) {
      return null;
    }

    const idealUnitDurationMs = getUnitDurationFromString(idealUnitDuration);

    return (
      idealUnitDurationMs / (scopeScheduledTimeInCycleMs / totalScheduledParts)
    );
  }
);

export { getPerformanceDecimal };
