import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TickerContext } from 'components/TickerProvider';
import { getCompany } from 'lib/selectors/getCompany';
import { palette } from '@m12s/component-library';

const Bar = styled.div`
  height: 0.375rem;
  background-color: ${palette.Blue400};
  width: ${({ progress }) => {
    return progress;
  }}%;
  position: absolute;
  top: 2rem;
  left: 0;
  transition: width 1s linear;
`;

const FlyupProgressBar = ({ downtimeStart = 0 }) => {
  const nowMs = useContext(TickerContext);
  const { microstopDuration, downtimeUnderlayDelay } = useSelector(getCompany);

  const progress = useMemo(() => {
    const rawProgress =
      (nowMs - (downtimeStart + downtimeUnderlayDelay)) /
        (microstopDuration - downtimeUnderlayDelay) || 0;

    return Math.max(0, Math.min(rawProgress, 1)) * 100;
  }, [nowMs, microstopDuration, downtimeUnderlayDelay, downtimeStart]);

  return <Bar progress={progress} />;
};

export { FlyupProgressBar };
