import { createSelector } from 'reselect';
import { getLatestProductionActivity } from './getLatestProductionActivity';

const getLatestProductionActivityStartAt = createSelector(
  [getLatestProductionActivity],
  ({ start }) => {
    return start || null;
  }
);

export { getLatestProductionActivityStartAt };
