import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bugsnag } from 'lib/external/bugsnag';

import { CREATE_ACTIVITY } from 'lib/api/mutations';
import { TEMP_REFS } from 'lib/constants';
import { getPendingActivityTypeRef } from 'lib/selectors/getPendingActivityTypeRef';
import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import {
  actionCanonicalActivityDelete,
  actionCanonicalActivityCreate,
  actionOptimisticActivityCreate,
  actionSetLaborTicketActionLoading,
  actionSetPendingActivityTypeRef,
} from 'lib/actions';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
import { throwErrorToast } from 'lib/utils/toast';
import { useCreateActivityRequestParams } from 'lib/hooks';

const { ACTIVITY_REF: optimisticActivityRef } = TEMP_REFS;

const useChangeActivities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createActivityRequestParams = useCreateActivityRequestParams();
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);

  const activityTypes = useSelector(getActivityTypes);
  const pendingActivityTypeRef = useSelector(getPendingActivityTypeRef);
  const pendingActivityTypeName = activityTypes.find(({ activityTypeRef }) => {
    return activityTypeRef === pendingActivityTypeRef;
  })?.name;

  const [requestCreateActivity] = useMutation(CREATE_ACTIVITY, {
    fetchPolicy: 'no-cache', // this ensures update will only be called once for mutation
    update: (
      _cache,
      {
        data: {
          createActivityResponse: { activity },
        },
      }
    ) => {
      dispatch(actionOptimisticActivityCreate({ activity }));
    },
    onCompleted: ({ createActivityResponse: { activity } }) => {
      dispatch(
        actionCanonicalActivityCreate({ activity, optimisticActivityRef })
      );
      dispatch(actionSetPendingActivityTypeRef(null));
    },
    onError: (err) => {
      bugsnag.notify(err, (event) => {
        event.addMetadata('user', bugsnagUserInfo);
      });
      dispatch(actionSetLaborTicketActionLoading(false));
      dispatch(
        actionCanonicalActivityDelete({ activityRef: optimisticActivityRef })
      );
      dispatch(actionSetPendingActivityTypeRef(null));
      throwErrorToast(t(`${t('Could not enter:')} ${pendingActivityTypeName}`));
    },
  });

  return async () => {
    await requestCreateActivity(
      createActivityRequestParams(pendingActivityTypeRef)
    );
  };
};

export default useChangeActivities;
