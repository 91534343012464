import { createSelector } from 'reselect';
import { getUtilPageScopeStart } from 'lib/selectors/getUtilPageScopeStart';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { diff, toISO } from 'lib/utils/date';

const getMsSinceUtilPageScopeStart = createSelector(
  [getUtilPageScopeStart, getHeavyUpdate],
  (
    utilPageScopeStart,
    _heavyUpdate // eslint-disable-line no-unused-vars
  ) => {
    if (!utilPageScopeStart) {
      return 0;
    }
    return diff(toISO(), utilPageScopeStart, 'milliseconds');
  }
);

export { getMsSinceUtilPageScopeStart };
