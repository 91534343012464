import React from 'react';
import _capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';

import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getProgramName } from 'lib/selectors/getProgramName';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { TouchArea } from './TouchArea';
import { Text, BottomLeftInfoGroup } from './styled';

const MachineHeaderBottomLeft = ({
  variant,
  onClickToOverview,
  setTouchPointRight,
  t,
}) => {
  const hasOpenJobRun = useSelector(getHasOpenJobRun);
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const isPaused = useSelector(getIsPaused);
  const programName = useSelector(getProgramName);

  const showTouchArea = hasOpenActivity || hasOpenJobRun || isPaused;

  return (
    <>
      {showTouchArea && (
        <BottomLeftInfoGroup>
          <TouchArea
            isPaused={isPaused}
            onClickToOverview={onClickToOverview}
            variant={variant}
            setTouchPointRight={setTouchPointRight}
          />
        </BottomLeftInfoGroup>
      )}
      {programName && !showTouchArea && (
        <>
          <Text variant={variant}>{_capitalize(t('Program'))}:</Text>
          &nbsp;
          <Text variant={variant} shadow>
            {programName}
          </Text>
        </>
      )}
      {!programName && !showTouchArea && (
        <Text variant={variant} shadow muted>
          {t('No Program Running')}
        </Text>
      )}
    </>
  );
};

export { MachineHeaderBottomLeft };
