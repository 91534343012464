import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import {
  PopupButtonGroup,
  Button,
  Icon,
  palette,
} from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';

import { gift } from 'lib/icons';
import { Small } from 'components/Text';
import {
  Popup,
  Header,
  HeaderText,
  Body,
  Overlay,
  Title,
  Description,
} from 'components/Popup/styled';
import { MoreAlertMessage } from 'components/Popup/MoreAlertMessage';
import { refreshHard } from 'lib/utils/refresh';
import { Countdown } from 'components/Countdown';
import { actionDismissUpdatePopup } from 'lib/actions';
import { getPopupStackLength } from 'lib/selectors/getPopupStackLength';
import { getNextPopup } from 'lib/selectors/getNextPopup';
import { useUpdateWatcher } from 'components/DataProvider/useUpdateWatcher';

const UpdatePopup = () => {
  useUpdateWatcher();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nextPopup = useSelector(getNextPopup);
  const popupLen = useSelector(getPopupStackLength);

  return nextPopup?.popupType === 'update' ? (
    <>
      <Overlay zIndex={zLayer.acrossAppPopup} />
      <Popup
        id="update-popup"
        zIndex={zLayer.acrossAppPopup}
        moreThanOnePopup={popupLen > 1}
      >
        <Header>
          <HeaderText>
            <Icon icon={gift} />
            &nbsp;&nbsp;
            {_startCase(t('software update'))}
          </HeaderText>
        </Header>
        <Body>
          <Title>
            {_capitalize(t('updating in'))}...{' '}
            <Countdown from={10} then={refreshHard} />
          </Title>
          <Description>
            <Small color={palette.Grey600}>
              {t(
                'Our nightly update will only take a few seconds. The tablet will automatically refresh to apply the update.'
              )}
            </Small>
          </Description>
          <PopupButtonGroup>
            <Button
              onClick={() => {
                dispatch(actionDismissUpdatePopup());
              }}
              id="dismiss"
              variant="inferior"
            >
              {_capitalize(t('snooze'))}
            </Button>
            <Button id="refresh" onClick={refreshHard} variant="primary">
              {_startCase(t('refresh now'))}
            </Button>
          </PopupButtonGroup>
        </Body>
      </Popup>
      {popupLen > 1 && <MoreAlertMessage popupLen={popupLen} />}
    </>
  ) : null;
};

export { UpdatePopup };
