import { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Context } from './Context';

const Portal = ({ children }) => {
  const [el] = useState(document.createElement('div'));
  const container = useContext(Context);

  useEffect(() => {
    el.style.display = 'flex';
    el.style.flex = '1';
    el.style.position = 'absolute';
    el.style.top = 0;
    el.style.left = 0;
    el.style.width = '100%';
    el.style.height = '100%';
    el.style.pointerEvents = 'none';
  }, [el]);

  useEffect(() => {
    const { current } = container;
    if (current) {
      current.appendChild(el);
    }

    return () => {
      return current && current.removeChild(el);
    };
  }, [el, container]);

  return ReactDOM.createPortal(children, el);
};

export { Portal };
