import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { palette, Icon } from '@m12s/component-library';
import { farSyncAlt } from 'lib/icons';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(Icon)`
  animation: ${rotate} 2s linear infinite;
  will-change: transform;
`;

const WorkOrderCardHeader = styled.h5`
  font-weight: 600;
  color: ${({ disabled }) => {
    return disabled ? palette.Indigo400 : palette.Indigo600;
  }};
  font-size: 0.875rem;
  margin: 0;
`;

const WorkOrderSubHeader = styled.h6`
  font-weight: 600;
  font-size: 0.625rem;
  margin: 0;
  color: ${({ disabled }) => {
    return disabled ? palette.Grey600 : palette.Grey700;
  }};
`;

const WorkOrderHeader = ({ workOrderOp, active }) => {
  const { sequenceNumber, workOrder, workOrderId } = workOrderOp || {};

  const subheader = workOrder
    ? `${workOrder?.partNumber} Op ${sequenceNumber}`
    : 'Manually entered';
  const header = workOrderId || 'Unknown';

  return (
    <div
      style={{
        padding: '0.75rem',
        borderBottom: `1px solid ${palette.Grey300}`,
      }}
    >
      <WorkOrderCardHeader disabled={active}>
        {active && (
          <SpinningIcon icon={farSyncAlt} style={{ marginRight: '.5rem' }} />
        )}
        {header}
      </WorkOrderCardHeader>
      <WorkOrderSubHeader disabled={active}>{subheader}</WorkOrderSubHeader>
    </div>
  );
};

WorkOrderHeader.propTypes = {
  workOrderOp: PropTypes.object,
  active: PropTypes.bool,
};

export default WorkOrderHeader;
