import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';

const useDocumentTitle = () => {
  const machine = useSelector(getMachine);

  useEffect(() => {
    /* eslint-disable no-irregular-whitespace */
    document.title = machine.name
      ? `${machine.name} • MachineMetrics`
      : 'MachineMetrics';
  }, [machine.name]);
};

export { useDocumentTitle };
