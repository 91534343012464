import { createSelector } from 'reselect';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';
import { toISO } from 'lib/utils/date';
import { adjustPartCountBuckets } from 'lib/utils/parts';
import { getPartCountBuckets } from './getPartCountBuckets';

const getAdjustedPartCountBuckets = createSelector(
  [getPartCountBuckets, getScopeReadTimePCM],
  (partCountBuckets = [], scopeReadTimePCM = 1) => {
    const partCountByBucket = partCountBuckets.reduce((agg, bucket) => {
      return {
        ...agg,
        [toISO(bucket.bucket)]: (agg[toISO(bucket.bucket)] || 0) + bucket.sum,
      };
    }, {});
    return adjustPartCountBuckets(partCountByBucket, scopeReadTimePCM); // include for offset calcs against partAdjustments in separate selector
  }
);

export { getAdjustedPartCountBuckets };
