import { useMemo } from 'react';
import _takeRight from 'lodash/takeRight';
import _last from 'lodash/last';
import _get from 'lodash/get';
import { diff, getUnitDurationFromString } from 'lib/utils/date';
import { useSelector } from 'react-redux';
import { getTotalCycles } from 'lib/selectors/getTotalCycles';

const calculateAverageDuration = (
  partCountEvents,
  scopeFirstPartTimestamp,
  totalCycles
) => {
  if (
    partCountEvents.length < 2 ||
    !scopeFirstPartTimestamp ||
    !totalCycles ||
    totalCycles <= 1
  )
    return 0;

  const lastPartCountEvent = _get(_last(partCountEvents), 'eventTime');
  const totalCycleTimeSecs = diff(
    lastPartCountEvent,
    scopeFirstPartTimestamp,
    'seconds'
  );
  // note that we are using time between first and last cycle event, which is 1 less cycle than our total # of cycle events
  const numOfCyclesBetweenFirstAndLastPart = totalCycles - 1;

  return totalCycleTimeSecs / numOfCyclesBetweenFirstAndLastPart;
};

const calculateLastFew = (partCountEvents) => {
  if (partCountEvents.length < 2) return { lastFew: [], lastFewAvg: 0 };

  const [firstPart, ...tail] = _takeRight(partCountEvents, 4);

  const { durations, starts, sum, count } = tail.reduce(
    (memo, event) => {
      const cycleTime = diff(event.eventTime, memo.last.eventTime, 'seconds');

      return {
        last: event,
        sum: memo.sum + cycleTime,
        count: memo.count + 1,
        durations: [cycleTime, ...memo.durations],
        starts: [memo.last.eventTime, ...memo.starts],
      };
    },
    { last: firstPart, count: 0, sum: 0, durations: [], starts: [] }
  );

  const avg = sum / count;

  return {
    lastFew: durations,
    lastFewAvg: avg,
    lastFewStarts: starts,
  };
};

const calculateExpectedCycleInSecs = (expectedUnitDuration, actualParts) => {
  return (getUnitDurationFromString(expectedUnitDuration) * actualParts) / 1000;
};

const useCycleTime = ({
  scopeFirstPartTimestamp,
  partCountEvents,
  scopePartCountEvents,
  expectedUnitDuration,
  actualParts,
}) => {
  const totalCycles = useSelector(getTotalCycles);
  const { lastFew, lastFewAvg, lastFewStarts } = calculateLastFew(
    partCountEvents
  );
  return useMemo(() => {
    return {
      lastFew,
      lastFewStarts,
      lastFewAvg,
      average: calculateAverageDuration(
        scopePartCountEvents,
        scopeFirstPartTimestamp,
        totalCycles
      ),
      expected: calculateExpectedCycleInSecs(expectedUnitDuration, actualParts),
    };
  }, [
    lastFewAvg,
    lastFew,
    scopePartCountEvents,
    expectedUnitDuration,
    lastFewStarts,
    actualParts,
    scopeFirstPartTimestamp,
    totalCycles,
  ]);
};

export {
  useCycleTime,
  calculateLastFew,
  calculateAverageDuration,
  calculateExpectedCycleInSecs,
};
