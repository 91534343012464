import { createSelector } from 'reselect';
import { getScopeTimeInCycleMs } from 'lib/selectors/getScopeTimeInCycleMs';
import { getTotalScopeTimeMs } from 'lib/selectors/getTotalScopeTimeMs';
import { isNumber } from 'lib/utils/validation';

// utilizationRate = timeInCycle / allTime

// should only update with heavy interval change, as these "live" selector
// dependencies both use heavy interval for refresh strategy

const getScopeUtilizationDecimal = createSelector(
  [getScopeTimeInCycleMs, getTotalScopeTimeMs],
  (scopeTimeInCycleMs, totalScopeTimeMs) => {
    if (
      !isNumber(scopeTimeInCycleMs) ||
      !isNumber(totalScopeTimeMs) ||
      totalScopeTimeMs === 0
    ) {
      return null;
    }
    return Math.min(
      scopeTimeInCycleMs / totalScopeTimeMs,
      1 // cannot exceed 100% utilization, use fallback here
    );
  }
);

export { getScopeUtilizationDecimal };
