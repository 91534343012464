import { useMemo } from 'react';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getSetupActivityTypes } from 'lib/selectors/getSetupActivityTypes';
import { getProductionActivityTypes } from 'lib/selectors/getProductionActivityTypes';

const useStartJobInMode = (startJob, startJobAsJSG, job, workOrderId) => {
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);

  const [setupActivityType] = useSelector(getSetupActivityTypes);
  const [productionActivityType] = useSelector(getProductionActivityTypes);

  const setupActivityTypeRef = useMemo(() => {
    return _get(setupActivityType, 'activityTypeRef', undefined);
  }, [setupActivityType]);

  const productionActivityTypeRef = useMemo(() => {
    return _get(productionActivityType, 'activityTypeRef', undefined);
  }, [productionActivityType]);

  const startSetup = useMemo(() => {
    return job.setupTime === null
      ? null
      : () => {
          return areActivitiesEnabled
            ? startJob({ activityTypeRef: setupActivityTypeRef, workOrderId })
            : startJobAsJSG({ setup: true });
        };
  }, [
    job,
    startJobAsJSG,
    startJob,
    setupActivityTypeRef,
    areActivitiesEnabled,
    workOrderId,
  ]);

  const startProduction = useMemo(() => {
    return () => {
      return areActivitiesEnabled
        ? startJob({ activityTypeRef: productionActivityTypeRef, workOrderId })
        : startJobAsJSG();
    };
  }, [
    startJobAsJSG,
    startJob,
    productionActivityTypeRef,
    areActivitiesEnabled,
    workOrderId,
  ]);

  return { startSetup, startProduction };
};

export default useStartJobInMode;
