import styled, { css } from 'styled-components';
import { palette, PopupContainer } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';

import { H1, H5, Small } from 'components/Text';

const Popup = styled(PopupContainer)`
  width: 21.5rem;
  position: fixed;
  ${({ moreThanOnePopup }) => {
    return moreThanOnePopup
      ? css`
          bottom: 9rem;
        `
      : css`
          bottom: 6rem;
        `;
  }}

  right: 2rem;
  z-index: ${({ zIndex }) => {
    return zIndex || zLayer.popup;
  }};

  ${({ padding }) => {
    return (
      padding &&
      css`
        padding: 2rem;
      `
    );
  }};
`;

const Header = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 1rem;
  background-color: ${palette.Blue500};
  border-radius: 4px 4px 0 0;
`;

const HeaderText = styled(Small).attrs({ color: palette.Yellow300 })`
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
  font-weight: 700;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
`;

const Title = styled(H5).attrs({ color: palette.Grey700 })`
  margin-bottom: 0.5rem;
`;

const Description = styled.div`
  width: 18rem;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${palette.Grey800};
  opacity: 0.6;
  z-index: ${({ zIndex }) => {
    return zIndex || zLayer.popup;
  }};
`;

const LargeTicker = styled(H1)`
  font-size: 4rem;
`;

export {
  Popup,
  Header,
  HeaderText,
  Body,
  Title,
  Description,
  Overlay,
  LargeTicker,
};
