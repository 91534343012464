import styled from 'styled-components';
import React from 'react';
import { ControlBar, Badge, Icon, palette } from '@m12s/component-library';
import {
  LoadingTextPlaceholder,
  loadingFade,
} from 'components/LoadingTextPlaceholder';

const iconsOnlyBreakpoint = 56;
const fullTextBreakpoint = 90;

const ButtonBadge = styled(Badge)`
  @media (min-width: ${iconsOnlyBreakpoint}rem) {
    margin-right: 0.75rem;
  }
`;

const ControlBarResponsive = styled(ControlBar)`
  @media (orientation: portrait) {
    button {
      flex: unset;
      flex-grow: 1;
    }
  }
`;

const Scrollable = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
`;

const ControlBarContent = styled.div`
  @media (max-width: 30rem) {
    span {
    }
  }
`;

const MobileIcon = styled(Icon)`
  color: ${({ disabled }) => {
    return disabled ? palette.Grey400 : palette.Grey500;
  }};
  font-size: 2rem;
  margin: 0;

  @media (min-width: ${iconsOnlyBreakpoint}rem) {
    margin-right: 0.75rem;
    font-size: inherit;
  }
`;

const ResponsiveText = ({ full, medium, short }) => {
  return (
    <>
      <TextMedia max={iconsOnlyBreakpoint}>{short}</TextMedia>
      <TextMedia min={iconsOnlyBreakpoint} max={fullTextBreakpoint}>
        {medium}
      </TextMedia>
      <TextMedia min={fullTextBreakpoint}>{full}</TextMedia>
    </>
  );
};

const TextMedia = styled.span`
  display: none;
  @media (min-width: ${({ min }) => {
      return min || 0;
    }}rem) and (max-width: ${({ max }) => {
      return max - 0.01 || 1000;
    }}rem) {
    display: inline;
  }
`;

const LoadingStatTabLabel = styled.div`
  ${({ loading }) => {
    return loading && loadingFade;
  }}
`;

const LoadingStatTabSecondaryLabel = styled(LoadingTextPlaceholder)`
  height: 1rem;
  width: 5rem;
  border-radius: 2px;

  @media (orientation: portrait) {
    width: 3rem;
  }
`;

export {
  ButtonBadge,
  ControlBarResponsive,
  Scrollable,
  ControlBarContent,
  MobileIcon,
  ResponsiveText,
  TextMedia,
  LoadingStatTabLabel,
  LoadingStatTabSecondaryLabel,
};
