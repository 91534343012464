import { createSelector } from 'reselect';
import _sumBy from 'lodash/sumBy';

import { getShopDayDowntimeIntervals } from 'lib/selectors/getShopDayDowntimeIntervals';

const getTotalShopDayDowntime = createSelector(
  [getShopDayDowntimeIntervals],
  (shopDayDowntimeIntervals) => {
    if (!shopDayDowntimeIntervals || shopDayDowntimeIntervals.length <= 0) {
      return null;
    }

    return _sumBy(shopDayDowntimeIntervals, (downtime) => {
      return downtime.duration;
    });
  }
);

export { getTotalShopDayDowntime };
