import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

import MainLayout from 'components/Layouts/MainLayout';
import { EmptyWithGraphic } from 'components/Empty/EmptyWithGraphic';

import { Card, palette } from '@m12s/component-library';
import { questionCircle } from 'lib/icons';
import useWorkflowTrigger from 'pages/WorkflowTrigger/useWorkflowTrigger';

import {
  Grid,
  ExtendedTitle,
  ExtendedDetail,
  CardText,
  CardContainer,
} from './styled';

const WorkflowGrid = ({ data, machine, t }) => {
  const { submit } = useWorkflowTrigger(machine);

  return (
    <MainLayout.Container direction="column" bg={palette.Grey100}>
      {data.length > 0 ? (
        <Grid>
          {_orderBy(data, 'name').map((workflow, idx) => {
            const { name, id } = workflow;
            const { description } = workflow.evaluation;

            return (
              <CardContainer
                key={id}
                role="button"
                onClick={() => {
                  submit(workflow, machine);
                }}
                onKeyDown={() => {
                  submit(workflow, machine);
                }}
                tabIndex={idx}
              >
                <Card
                  type="extended"
                  name={name}
                  color={palette.Grey300}
                  style={{ height: '8rem' }}
                >
                  <CardText>
                    <ExtendedTitle>{name}</ExtendedTitle>
                    {description && (
                      <ExtendedDetail>{description}</ExtendedDetail>
                    )}
                  </CardText>
                </Card>
              </CardContainer>
            );
          })}
        </Grid>
      ) : (
        <EmptyWithGraphic
          icon={questionCircle}
          text={t('No Workflows Found')}
          subtext={t('noWorkflowsDesc')}
        />
      )}
    </MainLayout.Container>
  );
};

export { WorkflowGrid };

WorkflowGrid.propTypes = {
  data: PropTypes.array,
  machine: PropTypes.object,
  t: PropTypes.func,
};
