import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { OverlayButton, OverlayContainer } from '@m12s/component-library';

import { times, questionCircle, exclamationCircle } from 'lib/icons';
import { CycleTimeChart } from 'components/charts/CycleTimeChart';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { Empty } from 'components/Empty';
import { getPartCountEvents } from 'lib/selectors/getPartCountEvents';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { DefaultLoading } from 'components/Loading';
import { Root, Header, Chart, EmptyChart } from './styled';
import { Key } from './Key';

const CycleTime = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const partCountEvents = useSelector(getPartCountEvents);

  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  if (arePartCountBucketsLoading) return <DefaultLoading />;

  return (
    <OverlayContainer id="current-cycle-page">
      <PauseDetailsOverlayContainer>
        <Root>
          <Header>
            <Key />
            <OverlayButton
              open={open}
              onToggle={(state) => {
                return setOpen(state);
              }}
              openLabel={_startCase(t('learn more about this metric'))}
              closeLabel={_startCase(t('close overlay'))}
              openIcon={questionCircle}
              closeIcon={times}
            >
              <p>
                {t(
                  "Current Cycle Time is a metric that indicates the amount of time spent in the current cycle on this Machine. A Cycle is determined by how your Machine counts Parts and also by the way your organization's System Settings are configured."
                )}
              </p>
            </OverlayButton>
          </Header>
          <Chart>
            {partCountEvents.length >= 2 ? (
              <CycleTimeChart />
            ) : (
              <EmptyChart>
                <Empty
                  icon={exclamationCircle}
                  title={t('No Cycles')}
                  subtitle={t('No available cycle data')}
                />
              </EmptyChart>
            )}
          </Chart>
        </Root>
        <PauseDetailsToggle />
      </PauseDetailsOverlayContainer>
    </OverlayContainer>
  );
};

export { CycleTime };
