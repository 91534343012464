import React from 'react';
import { Button, CounterBlock, Icon } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';

import { formatTime, formatTimeAndDate } from 'lib/utils/date';
import { getNetSetupParts } from 'lib/selectors/getSetupParts';
import { pencil } from 'lib/icons';
import { getSetupModeStart } from 'lib/selectors/getSetupModeStart';
import { getNetShiftSetupParts } from 'lib/selectors/getShiftSetupParts';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { FlexRow, SetupPartsSubtext } from './styled';

const AdjustButton = ({ t, setAdjustPartsModalOpen }) => {
  const isPaused = useSelector(getIsPaused);
  return (
    <Button
      variant="inferior"
      rounded
      onClick={() => {
        setAdjustPartsModalOpen(true);
      }}
      style={{ marginLeft: '1rem' }}
      disabled={isPaused}
    >
      <Icon icon={pencil} style={{ marginRight: '0.5rem' }} />
      {t('Adjust')}
    </Button>
  );
};

/* Shows part total since start of setup mode.
Can extend before scope. */
const SetupPartsDisplay = ({
  setAdjustPartsModalOpen,
  setupModeSpansShift,
  t,
}) => {
  const flags = useFlags();
  const laborTicketsEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];

  const setupModeStart = useSelector(getSetupModeStart);
  const netSetupParts = useSelector(getNetSetupParts)?.toLocaleString();
  const onLeft = !setupModeSpansShift;

  return (
    <CounterBlock
      align={onLeft ? 'left' : 'right'}
      label={t(onLeft ? 'Setup Parts' : 'Total Setup Parts')}
      value={
        <>
          {onLeft ? (
            <FlexRow>
              {netSetupParts}
              {!laborTicketsEnabled && (
                <AdjustButton
                  t={t}
                  setAdjustPartsModalOpen={setAdjustPartsModalOpen}
                />
              )}
            </FlexRow>
          ) : (
            netSetupParts
          )}
          <SetupPartsSubtext>
            {t('Since entering setup')}:{' '}
            {onLeft
              ? formatTime(setupModeStart)
              : formatTimeAndDate(setupModeStart)}
          </SetupPartsSubtext>
        </>
      }
    />
  );
};

/* Shows part total only since start of shift. 
This is only shown if setup extends before shift start. */
const ShiftSetupPartsDisplay = ({ setAdjustPartsModalOpen, t, shiftStart }) => {
  const flags = useFlags();
  const laborTicketsEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];
  const netShiftSetupParts = useSelector(getNetShiftSetupParts);

  return (
    <CounterBlock
      label={t('Setup Parts This Shift')}
      value={
        <>
          <FlexRow>
            {netShiftSetupParts?.toLocaleString()}
            {!laborTicketsEnabled && (
              <AdjustButton
                t={t}
                setAdjustPartsModalOpen={setAdjustPartsModalOpen}
              />
            )}
          </FlexRow>
          <SetupPartsSubtext>
            {t('Since start of shift')}: {formatTime(shiftStart)}
          </SetupPartsSubtext>
        </>
      }
    />
  );
};

export { SetupPartsDisplay, ShiftSetupPartsDisplay };
