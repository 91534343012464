import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';

const getStartOfNextShopDay = createSelector(
  [getStartOfCurrentShopDay],
  (startOfCurrentShopDay) => {
    if (startOfCurrentShopDay === null) {
      return null;
    }
    return moment(startOfCurrentShopDay)
      .add(1, 'day')
      .toISOString();
  }
);

export { getStartOfNextShopDay };
