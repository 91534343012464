import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components/Loading';
import { ErrorDialog } from 'components/ErrorDialog';
import { Sections } from './constants';
import {
  LoadingBackground,
  Buttons,
  CloseButton,
  NextButton,
  BackButton,
} from './styled';

const Document = lazy(() => {
  return import('react-pdf/dist/esm/entry.webpack').then((module) => {
    return { default: module.Document };
  });
});
const Page = lazy(() => {
  return import('react-pdf/dist/esm/entry.webpack').then((module) => {
    return { default: module.Page };
  });
});

const Section = ({ section, setSection }) => {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages: numPagesOnDocument }) {
    setNumPages(numPagesOnDocument);
  }

  useEffect(() => {
    const reset = () => {
      return setSection(-1);
    };
    window.addEventListener('orientationchange', reset);
    return () => {
      return window.removeEventListener('orientationchange', reset);
    };
  }, [setSection]);

  return (
    <Suspense
      fallback={
        <LoadingBackground>
          <Loading />
        </LoadingBackground>
      }
    >
      <Document
        file={Sections[section].url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => {
          return setLoading(false);
        }}
        error={<ErrorDialog message={t('Error loading Quick Start Guide')} />}
        width={window.innerWidth}
        loading=""
      >
        <Page
          pageNumber={page}
          width={window.innerWidth}
          onLoadError={() => {
            return setLoading(false);
          }}
          onRenderError={() => {
            return setLoading(false);
          }}
          error={<ErrorDialog message={t('Error loading Quick Start Guide')} />}
          onRenderSuccess={() => {
            return setLoading(false);
          }}
        />
        {loading && (
          <LoadingBackground>
            <Loading />
          </LoadingBackground>
        )}
      </Document>
      <Buttons>
        {page < numPages && (
          <NextButton
            onBack={() => {
              setPage(page + 1);
              setLoading(true);
            }}
          />
        )}
        {page > 1 && (
          <BackButton
            onBack={() => {
              setPage(page - 1);
              setLoading(true);
            }}
          />
        )}
      </Buttons>
      <CloseButton
        onBack={() => {
          return setSection(-1);
        }}
      />
    </Suspense>
  );
};

export { Section };
