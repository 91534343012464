import React from 'react';
import { useSelector } from 'react-redux';

import { FullScreenLoading } from 'components/Loading';
import {
  getIsBaseAppDataLoading,
  getBaseAppDataLoadingStates,
} from 'lib/selectors/getIsBaseAppDataLoading';

const PendingStates = ({ children }) => {
  const isBaseAppDataLoading = useSelector(getIsBaseAppDataLoading);
  const baseAppDataLoadingStates = useSelector(getBaseAppDataLoadingStates);

  return (
    <>
      {isBaseAppDataLoading && (
        <FullScreenLoading loadingStates={baseAppDataLoadingStates} />
      )}
      {children}
    </>
  );
};

export { PendingStates };
