import { createSelector } from 'reselect';

import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivitySetActivities } from 'lib/selectors/getLatestActivitySetActivities';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getSetupActivityTypeRefs } from 'lib/selectors/getSetupActivityTypeRefs';
import { getCheckIsSetupActivityTypeRef } from 'lib/selectors/getCheckIsSetupActivityTypeRef';
import { getJobRun } from 'lib/selectors/getJobRun';
import { getAggClosedActivitiesMs } from 'lib/utils/activities';
import { diff } from 'lib/utils/date';

/*
  note that this selector returns agg for ENTIRE set, not just those within
  scope... this is an important distinction for when we need entire setup agg (ie
  showing setup in progress in bar); if you need scoped activity agg ms, use the
  scoped agg selectors
*/

const getClosedSetupAggMs = createSelector(
  [
    getAreActivitiesEnabled,
    getLatestActivitySetActivities,
    getIsInitialActivitySetPollLoading,
    getSetupActivityTypeRefs,
    getCheckIsSetupActivityTypeRef,
    getJobRun,
  ],
  (
    areActivitiesEnabled,
    latestActivitySetActivities,
    isInitialActivitySetPollLoading,
    setupActivityTypeRefs,
    checkIsSetupActivityTypeRef,
    jobRun
  ) => {
    if (areActivitiesEnabled) {
      if (
        isInitialActivitySetPollLoading ||
        (setupActivityTypeRefs && !setupActivityTypeRefs.length) ||
        (latestActivitySetActivities && !latestActivitySetActivities.length)
      ) {
        return 0;
      }
      return getAggClosedActivitiesMs({
        activities: latestActivitySetActivities,
        filterCb: ({ activityTypeRef }) => {
          return checkIsSetupActivityTypeRef(activityTypeRef);
        },
      });
    }
    if (!jobRun || !jobRun.setupEnd) {
      return 0;
    }
    return diff(jobRun.setupEnd, jobRun.startDate, 'milliseconds');
  }
);

export { getClosedSetupAggMs };
