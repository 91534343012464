import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { now } from 'lib/utils/date';
import {
  socketConnection as conn,
  wsLink as link,
} from 'lib/api/links/graphql';
import {
  actionUpdateConnectionStatus,
  actionSetLatestPingTime,
} from 'lib/actions';

const useConnectionStatus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let timedOut;
    const removeOnPing = link.client.on('ping', (received) => {
      // !received = ping sent
      if (!received) {
        dispatch(actionSetLatestPingTime(now()));

        // wait 15 seconds for pong to be received
        timedOut = setTimeout(() => {
          dispatch(actionUpdateConnectionStatus('disconnected'));
          conn.close(4408, 'Request Timeout');
        }, 15000);
      }
    });

    const removeOnPong = link.client.on('pong', (received) => {
      if (received) {
        dispatch(actionUpdateConnectionStatus('connected'));
        clearTimeout(timedOut);
      }
    });

    const removeOnConnected = link.client.on('connected', () => {
      clearTimeout(timedOut);
      dispatch(actionUpdateConnectionStatus('connected'));
    });

    const removeOnConnecting = link.client.on('connecting', () => {
      dispatch(actionUpdateConnectionStatus('connecting'));
    });

    return () => {
      removeOnPing();
      removeOnPong();
      removeOnConnected();
      removeOnConnecting();
    };
  }, [dispatch]);
};

export { useConnectionStatus };
