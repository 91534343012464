import moment from 'moment';
import _mapValues from 'lodash/mapValues';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeHourlyIntervalKeys } from 'lib/selectors/getScopeHourlyIntervalKeys';
import { getScopeReadTimePCM } from 'lib/selectors/getScopeReadTimePCM';
import { adjustPartCountBuckets } from 'lib/utils/parts';
import { getPartCountBuckets } from 'lib/selectors/getPartCountBuckets';
import { getLastPartBucketQueryStartDate } from 'lib/selectors/getLastPartBucketQueryStartDate';
import { getIsPartBucketQueryLoading } from 'lib/selectors/getIsPartBucketQueryLoading';
import { isSameOrBefore } from 'lib/utils/date';
import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';

const getBasePartCountByHour = (hourlyIntervalKeys = []) => {
  return hourlyIntervalKeys.reduce((partCountByHour, hourKey) => {
    return {
      ...partCountByHour,
      [hourKey]: 0, // init with empty values for each hour
    };
  }, {});
};

// fills base part count lookup with corresponding bucket values
const fillPartCountByHour = (
  basePartCountByHour = {},
  partCountBuckets = []
) => {
  return _mapValues(basePartCountByHour, (_value, hourKey) => {
    const hourStart = moment.utc(hourKey).startOf('hour');
    const hourAggParts = partCountBuckets.reduce((agg, bucket) => {
      return moment
        .utc(bucket.bucket)
        .startOf('hour')
        .isSame(hourStart)
        ? agg + bucket.sum
        : agg;
    }, 0);
    return hourAggParts;
  });
};

const defaultScopePartCountByHour = {};

const getScopePartCountByHour = createDeepEqualitySelector(
  [
    getScopeStart,
    getScopeHourlyIntervalKeys,
    getPartCountBuckets,
    getScopeReadTimePCM,
    getLastPartBucketQueryStartDate,
    getIsPartBucketQueryLoading,
  ],
  (
    scopeStart,
    hourlyIntervalKeys,
    partCountBuckets = [],
    scopeReadTimePCM = 1,
    lastPartBucketQueryStartDate,
    isPartBucketQueryLoading
  ) => {
    if (!scopeStart) {
      // default case for invalid scope
      return defaultScopePartCountByHour;
    }
    const basePartCountByHour = getBasePartCountByHour(hourlyIntervalKeys);
    if (
      !isPartBucketQueryLoading &&
      isSameOrBefore(
        // ensure returned parts data from store is up to date on scope change
        scopeStart,
        lastPartBucketQueryStartDate
      )
    ) {
      const filledPartCountByHour = fillPartCountByHour(
        basePartCountByHour,
        partCountBuckets
      );
      const adjustedPartCountBuckets = adjustPartCountBuckets(
        filledPartCountByHour,
        scopeReadTimePCM
      );
      return adjustedPartCountBuckets; // include for offset calcs against partAdjustments in separate selector
    }
    // default to hourly scope lookup with empty values
    return basePartCountByHour;
  }
);

export { getScopePartCountByHour };
