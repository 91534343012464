import { createSelector } from 'reselect';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { getPerformanceDecimal } from 'lib/selectors/getPerformanceDecimal';
import { getQualityDecimal } from 'lib/selectors/getQualityDecimal';
import { getAvailabilityDecimal } from 'lib/selectors/getAvailabilityDecimal';
import { isNumber } from 'lib/utils/validation';

/*
  calculating OEE: https://machinemetrics.quip.com/bDM9AOj7yo6q
  OEE: availability * performance * quality
*/

const getOEEDecimal = createSelector(
  [
    getAvailabilityDecimal,
    getPerformanceDecimal,
    getQualityDecimal,
    getHasOpenActivity,
    getHasOpenJobRun,
  ],
  (
    availabilityDecimal,
    performanceDecimal,
    qualityDecimal,
    hasOpenActivity,
    hasOpenJobRun
  ) => {
    if (
      // metric is only relevant when active / job is running and unpaused
      !(hasOpenActivity || hasOpenJobRun) ||
      !isNumber(performanceDecimal) ||
      !isNumber(availabilityDecimal) ||
      !isNumber(qualityDecimal)
    ) {
      return null;
    }
    const oee = availabilityDecimal * performanceDecimal * qualityDecimal;
    return oee;
  }
);

export { getOEEDecimal };
