import { chopBefore } from 'lib/utils/intervals';
import { getStatusIntervals } from 'lib/selectors/getStatusIntervals';
import { getUtilPageScopeStart } from 'lib/selectors/getUtilPageScopeStart';
import { createDeepEqualitySelector } from 'lib/utils/createDeepEqualitySelector';

const getUtilPageScopeStatusIntervals = createDeepEqualitySelector(
  [getStatusIntervals, getUtilPageScopeStart],
  (intervals, utilPageScopeStart) => {
    return chopBefore(intervals, utilPageScopeStart, true);
  }
);

export { getUtilPageScopeStatusIntervals };
