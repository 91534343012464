import { createSelector } from 'reselect';
import { getUtilPageScopeTimeInCycleMs } from 'lib/selectors/getUtilPageScopeTimeInCycleMs';
import { getMsSinceUtilPageScopeStart } from 'lib/selectors/getMsSinceUtilPageScopeStart';
import { isNumber } from 'lib/utils/validation';

// utilizationRate = timeInCycle / allTime

// should only update with heavy interval change, as these "live" selector
// dependencies both use heavy interval for refresh strategy

const getUtilPageScopeUtilizationDecimal = createSelector(
  [getUtilPageScopeTimeInCycleMs, getMsSinceUtilPageScopeStart],
  (utilPageScopeTimeInCycleMs, msSinceUtilPageScopeStart) => {
    if (
      !isNumber(utilPageScopeTimeInCycleMs) ||
      !isNumber(msSinceUtilPageScopeStart) ||
      msSinceUtilPageScopeStart === 0
    ) {
      return null;
    }
    return Math.min(
      utilPageScopeTimeInCycleMs / msSinceUtilPageScopeStart,
      1 // cannot exceed 100% utilization, use fallback here
    );
  }
);

export { getUtilPageScopeUtilizationDecimal };
