import { createSelector } from 'reselect';
import { getLatestExpectedUnitDuration } from 'lib/selectors/getLatestExpectedUnitDuration';
import { getUnitDurationFromString } from 'lib/utils/date';
import { getHasOpenActivitySet } from './getHasOpenActivitySet';

const getOpenActivitySetHasNoStandards = createSelector(
  [getLatestExpectedUnitDuration, getHasOpenActivitySet],
  (expectedUnitDuration, hasOpenActivitySet) => {
    const expectedUnitDurationMs = getUnitDurationFromString(
      expectedUnitDuration
    );
    return hasOpenActivitySet && !expectedUnitDurationMs;
  }
);

export { getOpenActivitySetHasNoStandards };
