/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { palette } from '@m12s/component-library';

const getColor = ({ color, muted, link }) => {
  return muted ? palette.Grey500 : link ? palette.Blue500 : color;
};

const getTextDecoration = ({ link }) => {
  return link ? 'underline' : 'none';
};

const includeBaseStyle = css`
  color: ${getColor};
  margin: 0;
  padding: 0;
  text-decoration: ${getTextDecoration};

  ${({ align }) => {
    return (
      align &&
      css`
        text-align: ${align};
      `
    );
  }}

  ${({ shadow }) => {
    return (
      shadow &&
      css`
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
      `
    );
  }}

  ${({ theme, defaultWeight, normal, medium, semibold, bold }) => {
    let weight = defaultWeight;

    if (bold) {
      weight = 'bold';
    } else if (semibold) {
      weight = 'semibold';
    } else if (medium) {
      weight = 'medium';
    } else if (normal) {
      weight = 'normal';
    }

    return (
      weight &&
      css`
        font-weight: ${theme.fontWeights[weight]};
      `
    );
  }}
`;

const textPropTypes = {
  defaultWeight: PropTypes.string,
  color: PropTypes.string,
};

const textDefaultProps = {
  color: palette.Grey700,
};

const WithProps = (Component) => {
  Component.propTypes = textPropTypes;
  Component.defaultProps = textDefaultProps;
  return Component;
};

export { includeBaseStyle, WithProps };
