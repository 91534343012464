import { useMemo } from 'react';
import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { actionRetryNetworkError } from 'lib/actions';
import { getNetworkErrors } from 'lib/selectors/getNetworkErrors';

export const useErrorStatus = (queryKey) => {
  const dispatch = useDispatch();
  const networkErrors = useSelector(getNetworkErrors);

  const errorStatus = useMemo(() => {
    const queryError = _get(networkErrors, queryKey, {});

    const refetch = () => {
      return dispatch(actionRetryNetworkError(queryKey));
    };

    return {
      queryKey,
      ...queryError,
      refetch,
    };
  }, [networkErrors, dispatch, queryKey]);

  return errorStatus;
};
