import styled, { css } from 'styled-components';
import { Icon, palette, Button } from '@m12s/component-library';
import { Input } from 'components/SearchInput';

const Cell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => {
    return center ? 'center' : 'flex-start';
  }};
  padding-left: ${({ paddingLeft }) => {
    return paddingLeft ? '0.5rem' : null;
  }};
  padding-right: ${({ paddingRight }) => {
    return paddingRight ? '0.5rem' : null;
  }};
  white-space: nowrap;
`;

const EmptyWrapper = styled.div`
  margin-top: 10rem;
  margin-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreateSubmitButton = styled(Button)`
  background-color: ${palette.Teal500};
  color: Palette.White;
  text-shadow: true;

  &:hover,
  &:active {
    background-color: ${palette.Teal600};
  }
`;

const CreateOperationButton = styled(CreateSubmitButton)`
  border-radius: 5rem;
`;

const ModalRow = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.Purple500};
  margin-bottom: 2rem;
`;

const ModalInputText = styled.div`
  color: ${palette.Grey600};
  font-weight: 700;
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

const ModalHeaderText = styled.div`
  font-weight: 700;
  margin-left: 0.625rem;
`;

const CreateOperationInput = styled(Input)`
  margin: 0.625rem 0 1rem 0;
`;

const InfoIconWrap = styled.div`
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const InfoTextWrap = styled.div`
  font-size: 0.75rem;
  max-width: 40rem;
  color: ${palette.Orange600};
`;

const InfoIconAndTextWrap = styled.div`
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoBoxWrap = styled.div`
  min-width: 100%;
  min-height: 2rem;
  background-color: ${palette.Orange100};
  border-radius: 0.25rem;
  border: solid 0.03rem ${palette.Orange600};
`;

const CreateOperationResultWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  text-align: center;
`;

const MessageWrapper = styled.div`
  margin: 0.5rem 0;
`;

const DividerBox = styled.div`
  ${({ heightSize }) => {
    return css`
      height: ${heightSize}rem;
    `;
  }}
`;

const ErrorIcon = styled(Icon)`
  color: ${palette.Red600};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const IconWrapper = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

export {
  Cell,
  EmptyWrapper,
  ButtonWrapper,
  CreateOperationButton,
  ModalRow,
  ModalInputText,
  ModalHeaderText,
  CreateOperationInput,
  InfoIconWrap,
  InfoTextWrap,
  InfoIconAndTextWrap,
  InfoBoxWrap,
  CreateOperationResultWrapper,
  MessageWrapper,
  DividerBox,
  ErrorIcon,
  CreateSubmitButton,
  IconWrapper,
};
