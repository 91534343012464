import React, { useMemo, useReducer } from 'react';
import _get from 'lodash/get';
import { palette } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { P } from 'components/Text';
import { DefaultLoading, Loading } from 'components/Loading';
import ErrorPage from 'pages/Error';
import MainLayout from 'components/Layouts/MainLayout';
import { getWorkOrderOpActionLoading } from 'lib/selectors/getWorkOrderOpActionLoading';

import { getMachine } from 'lib/selectors/getMachine';
import { MultiStep } from './MultiStep';
import useStartJob from './useStartJob';

const ScreenOverlay = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${palette.Grey100};
  width: 100vw;
  height: 100vh;
`;

const initialState = {
  filter: '',
  jobs: [],
  job: null,
  workOrderId: null,
  workOrderOp: null,
  partOperation: null,
  workOrderOperationRef: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, filter: action.filter };
    case 'SET_JOBS':
      return { ...state, jobs: action.jobs };
    case 'SELECT_JOB':
      return { ...state, job: action.job, workOrderId: action.workOrderId };
    case 'SET_WORK_ORDER_OPERATION':
      return {
        ...state,
        workOrderOp: action.workOrderOp,
      };
    case 'SET_PART_OPERATION':
      return { ...state, partOperation: action.partOperation };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

const SelectJob = () => {
  const machine = useSelector(getMachine);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { startJob, startJobAsJSG } = useStartJob(state.job);
  const workOrderOpActionLoading = useSelector(getWorkOrderOpActionLoading);

  const jobName = useMemo(() => {
    return _get(state, ['job', 'displayName'], 'Unknown Operation');
  }, [state]);

  const loading = false;
  const error = false;

  if (loading) return <DefaultLoading />;
  if (error) return <ErrorPage error={error} />;

  return (
    <MainLayout.Container direction="column">
      {workOrderOpActionLoading && (
        <ScreenOverlay>
          <Loading
            text={
              <>
                {state.workOrderOp && (
                  <P
                    style={{
                      fontWeight: '600',
                      fontSize: '1.25rem',
                      marginTop: '1rem',
                    }}
                  >
                    {`Starting "${state.workOrderOp.workOrderId} | ${state.workOrderOp.workOrder.partNumber} Op ${state.workOrderOp.sequenceNumber}"`}
                  </P>
                )}
                <P style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                  Please wait while we start the production order
                </P>
              </>
            }
          />
        </ScreenOverlay>
      )}
      <MultiStep
        workOrderId={state?.workOrderId}
        machine={machine}
        dispatch={dispatch}
        handleOnBack={() => {}}
        submit={() => {}}
        startJob={startJob}
        startJobAsJSG={startJobAsJSG}
        jobName={jobName}
        {...state}
      />
    </MainLayout.Container>
  );
};

export default SelectJob;
