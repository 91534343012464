import { isNumber } from 'lib/utils/validation';

function formatValue(value, cb) {
  if (!isNumber(value)) {
    return '-';
  }

  return cb ? cb(value) : value;
}

function formatValueWithinLimit(value, limit) {
  return formatValue(value, (v) => {
    return limit && v >= limit
      ? `${(limit - 1).toLocaleString()}+`
      : v.toLocaleString();
  });
}

function formatPercentFromDecimal(value) {
  return formatValue(value, (v) => {
    return `${Math.round(v * 100)}%`;
  });
}

export { formatValue, formatValueWithinLimit, formatPercentFromDecimal };
