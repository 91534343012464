import { createSelector } from 'reselect';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeEnd } from 'lib/selectors/getScopeEnd';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import {
  getScopeScheduledActivityIntervals,
  getScopeUnscheduledActivityIntervals,
} from 'lib/selectors/getScopeScheduledActivityIntervals';

const getPartCountQueryIntervals = createSelector(
  [
    getScopeStart,
    getScopeEnd,
    getHasOpenActivitySet,
    getScopeScheduledActivityIntervals,
    getScopeUnscheduledActivityIntervals,
  ],
  (
    scopeStart,
    scopeEnd,
    hasOpenActivitySet,
    scopeScheduledActivityIntervals,
    scopeUnscheduledActivityIntervals
  ) => {
    if (!scopeStart || !scopeEnd) {
      return null;
    }

    if (!hasOpenActivitySet) {
      return {
        scopeScheduledActivityIntervals: [],
        scopeUnscheduledActivityIntervals: [
          {
            start: scopeStart,
            end: scopeEnd,
          },
        ],
      };
    }

    return {
      scopeScheduledActivityIntervals,
      scopeUnscheduledActivityIntervals,
    };
  }
);

export { getPartCountQueryIntervals };
