import { createSelector } from 'reselect';
import { getWorkOrderManagement } from './getWorkOrderManagement';

export const getLaborTickets = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement.laborTickets;
  }
);

export const getCurrentLaborTicket = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement.currentLaborTicket;
  }
);
