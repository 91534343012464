import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import { useHistory } from 'react-router-dom';
import {
  PopupButtonGroup,
  Button,
  Icon,
  palette,
} from '@m12s/component-library';

import { getActiveUncategorizedInterval } from 'lib/selectors/getActiveUncategorizedInterval';
import { exclamationCircle } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { formatTime } from 'lib/utils/date';
import { P } from 'components/Text';
import { DurationTicker } from 'components/DurationTicker';
import {
  Popup,
  Header,
  HeaderText,
  Body,
  Overlay,
  LargeTicker,
} from 'components/Popup/styled';
import { MoreAlertMessage } from 'components/Popup/MoreAlertMessage';
import { FlyupProgressBar } from 'components/Popup/FlyupProgressBar';
import { getPopupStackLength } from 'lib/selectors/getPopupStackLength';

const DowntimePopupTriggered = ({ dismiss, close, machineId, startAt }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentDowntime = useSelector(getActiveUncategorizedInterval);
  const popupLen = useSelector(getPopupStackLength);
  if (!currentDowntime) return null;

  return (
    <>
      <Overlay />
      <Popup id="downtime-popup" moreThanOnePopup={popupLen > 1}>
        <Header>
          <HeaderText>
            <Icon icon={exclamationCircle} />
            &nbsp;&nbsp;
            {_startCase(t('new downtime'))}
          </HeaderText>
        </Header>
        <Body>
          <FlyupProgressBar downtimeStart={currentDowntime.timestamp} />
          <P color={palette.Grey600}>
            {_capitalize(t('started'))}: {formatTime(startAt)}
            <br />
          </P>
          <br />
          <LargeTicker color={palette.Grey700} bold>
            <DurationTicker
              startDate={new Date(startAt)}
              format="d[d] h[h] m[m] s[s]"
              formatOptions={{ largest: 2 }}
            />
          </LargeTicker>
          <br />
          <PopupButtonGroup>
            <Button onClick={dismiss} id="dismiss" variant="inferior">
              {_capitalize(t('dismiss'))}
            </Button>
            <Button
              id="categorize"
              onClick={() => {
                close();
                history.push({
                  pathname: Routes.machineIdCategorizePath(machineId),
                  state: {
                    preselected: currentDowntime,
                    returnPath: history.location.pathname,
                  },
                });
              }}
              variant="primary"
            >
              {t('categorize|full')}
            </Button>
          </PopupButtonGroup>
        </Body>
      </Popup>
      {popupLen > 1 && <MoreAlertMessage popupLen={popupLen} />}
    </>
  );
};

DowntimePopupTriggered.propTypes = {
  close: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  machineId: PropTypes.string.isRequired,
  startAt: PropTypes.string,
};

export { DowntimePopupTriggered };
