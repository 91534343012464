import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';

const MOBILE_WIDTH = '720px';

const ContentContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 7.875rem 8.75rem 8.75rem;
  gap: 1rem;
  padding: 1.5rem;
  padding-bottom: 5rem;
  background-color: ${palette.Grey200};
  height: 100%;
  overflow: scroll;
  width: 100%;
  @media (max-width: ${MOBILE_WIDTH}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const TopCardContainer = styled.div`
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${palette.Grey300};
  background-color: ${palette.White};
  width: 100%;
  color: ${palette.Grey700};
  box-shadow: 0px 1px 1px 0px rgba(26, 32, 44, 0.08),
    0px 1px 3px 1px rgba(26, 32, 44, 0.16),
    0px -1px 4px 0px rgba(26, 32, 44, 0.05);
`;

const Label = styled.h5`
  font-weight: 400;
  color: ${palette.Grey700};
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0;
  margin-bottom: 2px;
`;

const Value = styled.div`
  color: ${palette.Grey700};
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 0.5rem;
  white-space: nowrap;
`;

const SubValue = styled.div`
  margin-top: auto;
`;

const OpButton = styled.span`
  color: ${palette.Indigo600};
  font-size: 0.75rem;
  font-weight: 400;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TopCard = ({ label, value, subValue }) => {
  return (
    <TopCardContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
      <SubValue>{subValue}</SubValue>
    </TopCardContainer>
  );
};

TopCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const BottomCardContainer = styled(TopCardContainer)`
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  align-items: space-between;
  gap: 2.5rem;
  color: ${palette.Grey700};
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-template-rows: 1fr 1px 1fr 1px 1fr;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const InfoGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${MOBILE_WIDTH}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Info = ({ label, value }) => {
  return (
    <div
      style={{
        minWidth: '3rem',
      }}
    >
      <Label>{label}</Label>
      <SubValue>{value}</SubValue>
    </div>
  );
};

Info.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const InfoGroup = ({ top, bottom }) => {
  const bottomIsArray = Array.isArray(bottom);

  return (
    <InfoGroupContainer>
      <Info {...top} />
      {bottomIsArray ? (
        <div style={{ display: 'flex', gap: '.5rem' }}>
          {bottom.map((item) => {
            return <Info {...item} isArray key={item.label} />;
          })}
        </div>
      ) : (
        <Info {...bottom} />
      )}
    </InfoGroupContainer>
  );
};

InfoGroup.propTypes = {
  top: PropTypes.object,
  bottom: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${palette.Grey300};
  @media (max-width: ${MOBILE_WIDTH}) {
    width: 100%;
    height: 1px;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  background-color: ${palette.White};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 1.5rem;
  border-top: 1px solid ${palette.Grey400};
`;

export {
  OpButton,
  Footer,
  ContentContainer,
  TopCard,
  BottomCardContainer,
  InfoGroup,
  Divider,
};
