import React from 'react';
import { useSelector } from 'react-redux';

import { MachineSetup } from 'components/MachineSetup';
import { JOB_STATUSES } from 'lib/constants';
import { getJobStatus } from 'lib/selectors/getJobStatus';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';

import { PartCount } from './PartCount';

const MachineStatus = () => {
  const jobStatus = useSelector(getJobStatus);
  const hasOpenActivitySetInSetup = useSelector(getHasOpenActivitySetInSetup);
  const isSetup = hasOpenActivitySetInSetup || jobStatus === JOB_STATUSES.SETUP;

  if (isSetup) {
    return <MachineSetup />;
  }

  return <PartCount />;
};

export { MachineStatus };
