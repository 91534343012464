import React, { useState } from 'react';
import { StatTab } from '@m12s/component-library';
import { useEmbeddedEffect } from '@machinemetrics/mm-react-embeddable';

export const CustomTab = ({ tab }) => {
  const [secondaryLabel, setSecondaryLabel] = useState('-');

  useEmbeddedEffect(tab.id, 'set-summary', ({ summary }) => {
    setSecondaryLabel(summary);
  });

  return (
    <StatTab
      value={tab.id}
      label={tab.name}
      secondaryLabel={secondaryLabel}
      url={tab.url}
    />
  );
};
