import React from 'react';
import Modal from 'react-modal';
import { Button, palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';

import { P } from 'components/Text';
import { Buttons, HeaderIcon, Title, Header } from './styled';

const customStyles = {
  overlay: {
    background: 'rgb(26, 32, 44, 0.7)',
  },
  content: {
    border: 0,
    borderRadius: '0.25rem',
    height: 'fit-content',
    padding: 0,
    left: '50%',
    top: '50%',
    bottom: '0%',
    transform: 'translate(-50%, -50%)',
    width: '34rem',
    maxWidth: '100%',
    overflow: 'hidden',
    boxShadow:
      '0px 1px 1px rgba(42, 54, 57, 0.04), 0px 1px 3px rgba(42, 54, 57, 0.08)',
  },
};

const AlertModal = ({
  isOpen,
  onClose,
  onConfirm,
  headerColor,
  promptText,
  descriptionText,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <Header color={headerColor}>
        <Title color={palette.White}>{promptText && t(promptText)}</Title>
        <HeaderIcon color={palette.White} icon={icon} />
      </Header>
      {descriptionText && (
        <P style={{ margin: '1.5rem 2rem 0rem' }}>{t(descriptionText)}</P>
      )}
      <Buttons>
        <Button
          variant="tertiary"
          onClick={() => {
            return onClose && onClose();
          }}
        >
          {_capitalize(t('no'))}
        </Button>
        <Button
          onClick={() => {
            return onConfirm && onConfirm();
          }}
        >
          {_capitalize(t('yes'))}
        </Button>
      </Buttons>
    </Modal>
  );
};

export { AlertModal };
