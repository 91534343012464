import styled from 'styled-components';

import { palette } from '@m12s/component-library';

const Button = styled.button`
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  line-height: 2rem;
  background: ${palette.Grey600};
  border-radius: 4px;
  color: ${palette.White};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  ${({ disabled }) => {
    return (
      disabled &&
      `
      background: ${palette.Grey200};
    `
    );
  }}
`;

const Input = styled.input`
  height: 3.5rem;
  width: 10rem;
  background: ${palette.White};
  border: 2px solid ${palette.Grey300};
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  color: ${palette.Grey500};
  border-radius: 2px;
  font-weight: 700;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  grid-column-gap: 0.5rem;
  align-items: center;
`;

export { Wrapper, Button, Input };
