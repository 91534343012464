import React from 'react';
import PropTypes from 'prop-types';
import {
  ControlBarButton,
  ControlBar,
  palette,
  Radio,
} from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';

import MainLayout from 'components/Layouts/MainLayout';
import { CategorizeHeader } from 'components/CategorizeHeader';
import { CategoryHeader } from 'components/CategoryHeader';
import { paperPlane } from 'lib/icons';
import { Container, RadioControl, TextArea } from './styled';

const DowntimeSubmit = ({
  annotationType,
  dispatch,
  downtimes,
  note,
  planned,
  plannedEnabled,
  previousStep,
  submit,
  forced,
  splitting,
  returnPath,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout.Column id="submit-step">
      <CategorizeHeader
        selected={downtimes}
        handleOnBack={previousStep}
        hideClose={forced}
        splitting={splitting}
        returnPath={returnPath}
      />
      <MainLayout.Container
        alignItems="center"
        direction="column"
        bgColor={palette.Grey100}
      >
        <CategoryHeader path={annotationType.name.split('|')} />
        <Container>
          <RadioControl>
            <Radio
              disabled={!plannedEnabled && planned}
              inputProps={{
                checked: !planned,
                onChange: () => {
                  return dispatch({ type: 'SET_PLANNED', planned: false });
                },
              }}
            >
              {_capitalize(t('unplanned'))}
            </Radio>
            <Radio
              disabled={!plannedEnabled && !planned}
              inputProps={{
                checked: planned,
                onChange: () => {
                  return dispatch({ type: 'SET_PLANNED', planned: true });
                },
              }}
            >
              {_capitalize(t('planned'))}
            </Radio>
          </RadioControl>
          <TextArea
            placeholder={_capitalize(t('add any additional notes here'))}
            value={note}
            onChange={(evt) => {
              return dispatch({ type: 'SET_NOTE', note: evt.target.value });
            }}
            expand
          />
        </Container>
      </MainLayout.Container>
      <ControlBar>
        <ControlBarButton
          icon={paperPlane}
          onClick={() => {
            return submit();
          }}
        >
          {_startCase(t('submit now'))}
        </ControlBarButton>
      </ControlBar>
    </MainLayout.Column>
  );
};

DowntimeSubmit.propTypes = {
  annotationType: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  downtimes: PropTypes.object.isRequired,
  note: PropTypes.string.isRequired,
  planned: PropTypes.number.isRequired,
  previousStep: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export { DowntimeSubmit };
