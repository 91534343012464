import React from 'react';
import PropTypes from 'prop-types';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { LAUNCH_DARKLY_CLIENT_ID, isDev } from 'lib/appConfig';

const LaunchDarklyProvider = ({ children }) => {
  return (
    <LDProvider
      clientSideID={LAUNCH_DARKLY_CLIENT_ID}
      context={{
        // necessary so we don't waste our MAU quota on unauthenticated users
        kind: 'user',
        anonymous: true,
        key: 'anonymous-user',
      }}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
      options={{
        // see https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html
        allAttributesPrivate: true,
        bootstrap: 'localStorage',
        useReport: true,
        // debugging opts
        ...(isDev && {
          evaluationReasons: true,
          logger: basicLogger({ level: 'warn' }),
        }),
      }}
    >
      {children}
    </LDProvider>
  );
};

LaunchDarklyProvider.propTypes = {
  children: PropTypes.element,
};

export default LaunchDarklyProvider;
