import React from 'react';
import _capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';

const DefaultLoading = () => {
  const { t } = useTranslation();
  return <Loading text={`${_capitalize(t('loading'))}...`} />;
};

export default DefaultLoading;
