import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ControlBarButton, ControlBar, palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import _flow from 'lodash/flow';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import _get from 'lodash/get';
import _values from 'lodash/values';
import _filter from 'lodash/fp/filter';
import _orderBy from 'lodash/fp/orderBy';
import { useSelector } from 'react-redux';

import { ERROR_KEYS } from 'lib/constants';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getShiftDowntimeIntervals } from 'lib/selectors/getShiftDowntimeIntervals';
import { getShopDayDowntimeIntervals } from 'lib/selectors/getShopDayDowntimeIntervals';
import MainLayout from 'components/Layouts/MainLayout';
import { CategorizeHeader } from 'components/CategorizeHeader';
import { DowntimeCard } from 'components/DowntimeCard';
import { check } from 'lib/icons';
import { getActiveCategorizedInterval } from 'lib/selectors/getActiveCategorizedInterval';
import { ErrorInline } from 'components/ErrorInline';
import { useErrorStatus } from 'lib/hooks';
import { getLongDurationCutoff } from 'lib/api/getLongDurationCutoff';
import { Grid } from './styled';
import { FiltersHeader } from './FiltersHeader';

const applyFilters = (filters, longDurationCutoff) => {
  return (downtime) => {
    if (filters.uncategorized && downtime.categorized) {
      return false;
    }

    if (
      filters.long &&
      !!downtime.end &&
      downtime.duration < longDurationCutoff
    ) {
      return false;
    }

    return true;
  };
};

const DowntimeSelector = ({
  dispatch,
  downtimes: selectedDowntimes,
  nextStep,
  filters,
  setFilters,
  splitting,
  returnPath,
}) => {
  const { t } = useTranslation();
  const shiftDowntimeIntervals = useSelector(getShiftDowntimeIntervals);
  const shopDayDowntimeIntervals = useSelector(getShopDayDowntimeIntervals);
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const downtimeIntervals = isLatestShiftActive
    ? shiftDowntimeIntervals
    : shopDayDowntimeIntervals;
  const activeCategorizedInterval = useSelector(getActiveCategorizedInterval);
  const longDurationCutoff = useSelector(getLongDurationCutoff);
  const notInSetup =
    _get(activeCategorizedInterval, 'annotationType.name', null) !== 'Setup';
  const downtimeError = useErrorStatus(ERROR_KEYS.DOWNTIME);
  const annotationError = useErrorStatus(ERROR_KEYS.ANNOTATION);

  const downtimes = useMemo(() => {
    return _flow(
      _filter(applyFilters(filters, longDurationCutoff)),
      _orderBy(['timestamp'], ['desc'])
    )(downtimeIntervals);
  }, [downtimeIntervals, filters, longDurationCutoff]);

  const split = () => {
    if (activeCategorizedInterval) {
      dispatch({
        type: 'SELECT_DOWNTIME_TO_SPLIT',
        downtime: activeCategorizedInterval,
      });
      nextStep();
    }
  };

  useEffect(() => {
    if (splitting) {
      dispatch({
        type: 'CLEAR_DOWNTIMES',
      });
    }
    /* This is an onComponentMount */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <MainLayout.Column id="select-downtime-step">
      <CategorizeHeader selected={selectedDowntimes} returnPath={returnPath} />
      {downtimeError.message || annotationError.message ? (
        <ErrorInline
          title={t('There was an error loading downtimes')}
          retrying
          reload
        />
      ) : (
        <>
          <MainLayout.Container direction="column" bgColor={palette.Grey100}>
            <FiltersHeader
              filters={filters}
              setFilters={setFilters}
              split={activeCategorizedInterval && notInSetup ? split : null}
            />
            <Grid>
              {downtimes.map((downtime) => {
                const categorizedAndAnotherSelected = !!(
                  !_isEmpty(selectedDowntimes) && downtime.categorized
                );
                const aCategorizedIsSelected = _get(
                  _values(selectedDowntimes),
                  0,
                  {}
                ).categorized;
                const selected = !!selectedDowntimes[downtime.start];
                const isSetup = downtime.setup;
                const disabled =
                  (categorizedAndAnotherSelected || aCategorizedIsSelected) &&
                  !selected;
                return (
                  <DowntimeCard
                    key={downtime.start}
                    downtime={downtime}
                    selected={selected}
                    disabled={disabled}
                    onClick={() => {
                      if (!disabled && !isSetup) {
                        dispatch({ type: 'TOGGLE_DOWNTIME', downtime });
                      }
                    }}
                  />
                );
              })}
            </Grid>
          </MainLayout.Container>
          <ControlBar>
            <ControlBarButton
              id={
                Object.keys(selectedDowntimes).length > 1
                  ? 'categorizing-multiple'
                  : 'categorizing-single'
              }
              icon={check}
              onClick={() => {
                return nextStep();
              }}
              disabled={_isEmpty(selectedDowntimes)}
            >
              {_startCase(t('categorize selected events'))}
            </ControlBarButton>
          </ControlBar>
        </>
      )}
    </MainLayout.Column>
  );
};

DowntimeSelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  downtimes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export { DowntimeSelector };
