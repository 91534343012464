import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, palette, LoadingCircle } from '@m12s/component-library';
import { search, spinner } from 'lib/icons';

const InputWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  position: relative;
`;

export const Input = styled.input`
  background: #ffffff;
  border: 2px solid #e2e8f0;
  border-radius: 2px;
  height: 2.25rem;
  min-height: 38px;
  width: 100%;
  padding: 0 0.5rem;

  ::placeholder {
    color: ${palette.Grey500};
    letter-spacing: 0.005em;
  }
`;

const Submit = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: ${palette.Grey600};
  outline: none;
  border: none;
  background-color: ${palette.White};
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const SearchInput = ({
  rootProps,
  inputProps,
  onSubmit,
  autofocus,
  loading,
}) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (autofocus) {
      ref.current.focus();
    }
  }, [autofocus]);

  return (
    <InputWrapper {...rootProps}>
      <Input className="search" ref={ref} {...inputProps} />
      <Submit
        onClick={() => {
          return onSubmit && onSubmit();
        }}
      >
        {loading && (
          <LoadingCircle
            icon={spinner}
            style={{ display: 'inline-block', marginRight: '.5rem' }}
            size="1rem"
            color={palette.Grey400}
          />
        )}
        <Icon icon={search} size="1rem" color={palette.Grey600} />
      </Submit>
    </InputWrapper>
  );
};

SearchInput.propTypes = {
  autofocus: PropTypes.bool,
  rootProps: PropTypes.object,
  inputProps: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

SearchInput.defaultProps = {
  autofocus: false,
  loading: false,
};

export { SearchInput };
