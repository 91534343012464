import { useDispatch } from 'react-redux';
import { useInterval } from 'lib/hooks';
import { actionRecalculate } from 'lib/actions';
import { HEAVY_UPDATE_INTERVAL } from 'lib/constants';

const useHeavyUpdateInterval = () => {
  const dispatch = useDispatch();

  useInterval(() => {
    return dispatch(actionRecalculate());
  }, HEAVY_UPDATE_INTERVAL);
};

export { useHeavyUpdateInterval };
