import { createSelector } from 'reselect';
import { getScopeActiveStatusIntervals } from 'lib/selectors/getScopeActiveStatusIntervals';
import { getScopeScheduledActivityIntervals } from 'lib/selectors/getScopeScheduledActivityIntervals';
import { getHeavyUpdate } from 'lib/selectors/getUpdate';
import { diff, toISO } from 'lib/utils/date';
import _get from 'lodash/get';
import _last from 'lodash/last';
import { getIntervalSetsIntersection } from 'lib/utils/intervals';

const defaultActiveIntervals = [];

const getScopeScheduledActiveIntervals = createSelector(
  [getScopeActiveStatusIntervals, getScopeScheduledActivityIntervals],
  (activeStatusIntervals = [], scheduledActivityIntervals = []) => {
    const isEitherSetEmpty =
      !_get(activeStatusIntervals, 'length') ||
      !_get(scheduledActivityIntervals, 'length');
    if (isEitherSetEmpty) {
      // prevent recalc overhead below and in any subscribers, since an empty set
      // will never have any overlap / intersect with another set
      return defaultActiveIntervals;
    }
    const scheduledActiveIntervals = getIntervalSetsIntersection(
      activeStatusIntervals,
      scheduledActivityIntervals
    );
    return scheduledActiveIntervals;
  }
);

// returns cached result of agg closed scheduled active time
const getScopeClosedScheduledTimeInCycleMs = createSelector(
  [getScopeScheduledActiveIntervals],
  (scopeScheduledActiveIntervals = []) => {
    if (!_get(scopeScheduledActiveIntervals, 'length')) {
      return 0;
    }
    const closedScheduledTimeInCycleMs = scopeScheduledActiveIntervals.reduce(
      (aggTimeMs, interval) => {
        const isIntervalOpen = interval.end === null;
        if (isIntervalOpen) {
          // handle open interval in forced recalc below
          return aggTimeMs;
        }
        const intervalDuration = diff(interval.end, interval.start);
        return aggTimeMs + intervalDuration;
      },
      0
    );
    return closedScheduledTimeInCycleMs;
  }
);

/*
  scheduledTimeInCycle:
  scheduled time is basically the agg activity time when not in "planned" setup..
  "unplanned" setup is any agg setup that exceeds expected setup duration boundary. note that this
  selector only applies for activities customers, so it will need to be shimmed if
  there is a valid app state with jsg support and scheduledTimeInCycle (we have been using
  totalTimeInCycle for jsgs in the meanwhile)
*/
const getScopeScheduledTimeInCycleMs = createSelector(
  // this is split into multiple cache layers to prevent redundant passes through data
  [
    getScopeScheduledActiveIntervals,
    getScopeClosedScheduledTimeInCycleMs,
    getHeavyUpdate, // force recalc every fifteen seconds
  ],
  (
    scopeScheduledActiveIntervals = [],
    closedScheduledTimeInCycleMs = 0,
    _heavyUpdate // eslint-disable-line no-unused-vars
  ) => {
    if (!_get(scopeScheduledActiveIntervals, 'length')) {
      return 0;
    }
    const lastInterval = _last(scopeScheduledActiveIntervals);
    const isLastIntervalOpen = !!(lastInterval && lastInterval.end === null);
    if (isLastIntervalOpen) {
      const truncatedIntervalDuration = diff(toISO(), lastInterval.start);
      return closedScheduledTimeInCycleMs + truncatedIntervalDuration;
    } // otherwise, use cached calc for all closed intervals
    return closedScheduledTimeInCycleMs;
  }
);

export { getScopeScheduledTimeInCycleMs };
