import moment from 'moment';
import { createSelector } from 'reselect';
import _last from 'lodash/last';
import { getPartCountEvents } from 'lib/selectors/getPartCountEvents';

const getCurrentCycleStart = createSelector(
  [getPartCountEvents],
  (partCountEvents) => {
    if (partCountEvents.length === 0) {
      return null;
    }
    return moment.utc(_last(partCountEvents).eventTime);
  }
);

export { getCurrentCycleStart };
