import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  actionSetNetworkError,
  actionUnsetNetworkError,
  actionRetryNetworkError,
} from 'lib/actions';
import { getNetworkErrors } from 'lib/selectors/getNetworkErrors';

const useNetworkError = (
  { networkStatus, refetch, startPolling, stopPolling, error },
  name,
  { pollInterval } = {}
) => {
  const { [name]: networkError } = useSelector(getNetworkErrors);
  const [refetching, setRefetching] = useState(false);
  const timer = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { retries } = networkError || {};

  useEffect(() => {
    if (networkStatus <= 6) return;

    if (networkStatus === 8) {
      if (pollInterval) stopPolling();
      if (!retries || retries < 0) {
        dispatch(actionSetNetworkError(name, error.message));
        setRefetching(true);
      }
    } else if (networkStatus === 7) {
      if (pollInterval) startPolling(pollInterval);
      if (networkError) {
        dispatch(actionUnsetNetworkError(name));
      }
      setRefetching(false);
    } else if (!networkStatus && networkError) {
      dispatch(actionUnsetNetworkError(name));
    }
  }, [
    networkStatus,
    pollInterval,
    startPolling,
    stopPolling,
    error,
    name,
    dispatch,
    retries,
    networkError,
  ]);

  useEffect(() => {
    if (refetching) {
      timer.current = setTimeout(() => {
        return dispatch(actionRetryNetworkError(name));
      }, Math.min(retries * retries * 1000, 5 * 60 * 1000));
    }
    return () => {
      return clearTimeout(timer.current);
    };
  }, [refetching, retries, dispatch, name]);

  useEffect(() => {
    if (retries) {
      refetch();
    }
  }, [retries, refetch, t, name]);
};

export { useNetworkError };
