import React from 'react';
import { palette } from '@m12s/component-library';

export const Production = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={palette.Teal500}
      style={{ marginRight: '.75rem', height: '1.5rem' }}
    >
      <path d="M5.714 1.905h-3.81v3.619H0v-3.62C0 .855.853 0 1.905 0h3.81v1.905zM0 10.095h1.905v4h3.81V16h-3.81A1.905 1.905 0 010 14.095v-4zM14.095 0h-3.81v1.905h3.81v3.619H16v-3.62A1.905 1.905 0 0014.095 0zM10.286 14.095h3.81v-4H16v4A1.905 1.905 0 0114.095 16h-3.81v-1.905z" />
      <path d="M12.188 6.805c.04.103.062.21.062.32v4.125a1 1 0 01-1 1h-6.5c-.56 0-1-.44-1-1V7.115c0-.072 0-.189.046-.31l.794-2.367a.991.991 0 01.941-.688h4.922c.44 0 .815.292.94.688l.795 2.367zM8.625 6.75h2.231L10.273 5H8.625v1.75zM5.711 5l-.583 1.75h2.247V5H5.711zM5 8v3h6V8H5z" />
    </svg>
  );
};

export const Setup = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={palette.Yellow500}
      style={{ marginRight: '.75rem', height: '1.5rem' }}
    >
      <path d="M1.905 10.095H0v4C0 15.147.853 16 1.905 16h3.81v-1.905h-3.81v-4zM1.905 1.905h3.81V0h-3.81A1.905 1.905 0 000 1.905v3.619h1.905v-3.62zM14.095 0h-3.81v1.905h3.81v3.619H16v-3.62A1.905 1.905 0 0014.095 0zM14.095 14.095h-3.81V16h3.81A1.905 1.905 0 0016 14.095v-4h-1.905v4zM4.693 12h6.614c.383 0 .693-.31.693-.693V4.693A.693.693 0 0011.307 4H4.693A.693.693 0 004 4.693v6.616c0 .38.31.691.693.691zM8 6.545a1.5 1.5 0 011.498 1.498A1.5 1.5 0 018 9.54a1.5 1.5 0 01-1.498-1.498A1.5 1.5 0 018 6.545z" />
    </svg>
  );
};
