import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { getCompany } from 'lib/selectors/getCompany';

const getShouldShowWorkOrderIdField = createSelector(
  [getCompany],
  (company) => {
    return _get(company, 'enableOpdashWorkOrder', false);
  }
);

export { getShouldShowWorkOrderIdField };
