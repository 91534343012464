import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from 'lib/reducers';
import initReactFastclick from 'react-fastclick';
import 'moment-duration-format';
import 'moment-timezone';

import ErrorHandler from 'components/ErrorHandler';
import App from './App';
import './lib/translations/i18n';
import './index.css';

initReactFastclick();

const store = createStore(
  rootReducer,
  /* eslint-disable no-underscore-dangle */
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={store}>
    <ErrorHandler>
      <App />
    </ErrorHandler>
  </Provider>,
  document.getElementById('root')
);
Modal.setAppElement('#root');
