import React from 'react';
import PropTypes from 'prop-types';
import { palette, IsolatedHeader } from '@m12s/component-library';

import { arrowLeft, times } from 'lib/icons';
import { HeaderButton } from './styled';

const AppNav = ({
  children,
  onBack,
  onClose,
  color,
  iconColor,
  style = {},
}) => {
  return (
    <IsolatedHeader
      style={style}
      background={color}
      textColor={palette.Grey}
      leftIcon={
        onBack && (
          <HeaderButton
            icon={arrowLeft}
            iconColor={iconColor}
            onClick={onBack}
          />
        )
      }
      rightIcon={
        onClose && (
          <HeaderButton
            icon={times}
            iconColor={iconColor}
            onClick={onClose}
            alignRight
          />
        )
      }
    >
      {children}
    </IsolatedHeader>
  );
};

AppNav.defaultProps = {
  color: palette.Grey700,
  iconColor: palette.White,
};

AppNav.propTypes = {
  color: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.node,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  style: PropTypes.object,
};

export { AppNav };
