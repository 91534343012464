import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Modal, palette } from '@m12s/component-library';
import { falTimes } from 'lib/icons';

import { actionCloseLaborTicketModal } from 'lib/actions';
import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getLaborTicketActionLoading } from 'lib/selectors/getLaborTicketActionLoading';
import { usePartAdjustmentType } from 'pages/RejectParts/usePartAdjustmentType';

import { ModalDetailsContainer, IconWrapper, VerticalDivider } from './styled';
import LaborTicketCard from './LaborTicketCard';
import LaborTicketForm from './LaborTicketForm';

const modalStyles = {
  width: '100vw',
  height: '100vh',
  background: palette.Grey200,
  padding: 0,
};

const headerStyles = {
  color: palette.Grey700,
  background: palette.White,
  borderBottom: `1px solid ${palette.Grey400}`,
  fontWeight: '500',
};
const detailsStyles = {
  height: '100%',
  padding: '1.5rem',
  overflow: 'auto',
};
const buttonsStyles = {
  width: '100%',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${palette.Grey400}`,
  background: palette.White,
  padding: '2rem',
};

const formConfig = {
  totalParts: {
    value: 0,
  },
  rejectedParts: {
    value: 0,
  },
  rejectReason: {
    value: null,
  },
  comment: {
    value: null,
  },
  workOrderComplete: {
    value: false,
  },
};

const LaborTicket = ({ isOpen }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(formConfig);

  usePartAdjustmentType();

  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);
  const laborTicketActionLoading = useSelector(getLaborTicketActionLoading);

  useEffect(() => {
    if (laborTicketPartsMade) {
      setForm({
        ...formConfig,
        totalParts: { value: laborTicketPartsMade || 0 },
      });
    }
  }, [laborTicketPartsMade]);

  useEffect(() => {
    return () => {
      return setForm(formConfig);
    };
  }, []);

  const handleFormChange = useCallback((name, value) => {
    return setForm((prevForm) => {
      return {
        ...prevForm,
        [name]: {
          ...prevForm[name],
          value,
        },
      };
    });
  }, []);

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          header="Confirm work produced"
          headerIcon={
            <IconWrapper
              onClick={() => {
                if (!laborTicketActionLoading) {
                  dispatch(actionCloseLaborTicketModal());
                }
              }}
            >
              <Icon
                size="2rem"
                color={palette.Grey700}
                icon={falTimes}
                cursor="pointer"
              />
            </IconWrapper>
          }
          details={
            <ModalDetailsContainer>
              <LaborTicketForm
                form={form}
                handleFormChange={handleFormChange}
                loading={laborTicketActionLoading}
              />
              <VerticalDivider />
              <LaborTicketCard form={form} loading={laborTicketActionLoading} />
            </ModalDetailsContainer>
          }
          modalStyles={modalStyles}
          headerStyles={headerStyles}
          detailsStyles={detailsStyles}
          buttonsStyles={buttonsStyles}
        />
      )}
    </>
  );
};

export default LaborTicket;
