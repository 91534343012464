import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { Notification, toast } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Routes from 'lib/utils/routes';
import { now, toISO } from 'lib/utils/date';
import { DefaultLoading } from 'components/Loading';
import { UserSelect } from 'components/UserSelect';
import { getMachine } from 'lib/selectors/getMachine';
import { GET_OPERATORS } from 'lib/api/queries';
import { START_OPERATOR } from 'lib/api/mutations';
import { exclamationTriangle } from 'lib/icons';
import {
  actionOpenLaborTicketModal,
  actionSetOperators,
  actionSetRecentOperatorRuns,
} from 'lib/actions';

import { FLAG_KEYS, LABOR_TICKET_ACTIONS } from 'lib/constants';
import useSubmitLaborTicket from 'components/LaborTicket/useSubmitLaborTicket';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAPMEnabled = useSelector(getIsAPMEnabled);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  const { loading } = useQuery(GET_OPERATORS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ Operator: operators }) => {
      dispatch(actionSetOperators(operators));
    },
  });

  const [startOperator] = useMutation(START_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ startOperatorResponse }) => {
      if (startOperatorResponse) {
        const { machine, operator, ...operatorRun } = startOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: machine?.id,
              operatorId: operator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
      }
    },
  });
  const machine = useSelector(getMachine);
  const { t } = useTranslation();
  const flags = useFlags();

  const laborTicketEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);

  const requestLaborTicketAction = useSubmitLaborTicket();
  const nowISO = toISO(now().valueOf());

  if (loading) return <DefaultLoading />;

  const onSubmit = async (operator, opts = {}) => {
    try {
      const { errors } = await startOperator({
        variables: {
          input: {
            machineId: machine.id,
            operatorId: operator.id,
            pinToPreviousEvent: opts.pinToPreviousEvent,
          },
        },
      });

      if (errors) {
        toast(
          <Notification
            icon={exclamationTriangle}
            text={t('Something went wrong')}
            status="error"
          />,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
          }
        );
      } else {
        if (laborTicketEnabled && !isAPMEnabled) {
          if (canCloseLaborTicket) {
            dispatch(
              actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.OPERATOR_LOGIN)
            );
            return history.push(Routes.machineIdHomePath(machine.id));
          }
          if (operator.erpId && currentWorkOrderOp) {
            return requestLaborTicketAction({
              action: LABOR_TICKET_ACTIONS.OPERATOR_LOGIN,
              opts: {
                startAt: nowISO,
                loggingInOperator: operator.id,
              },
            });
          }
        }
        return history.push(Routes.machineIdHomePath(machine.id));
      }
    } catch (e) {
      if (e?.networkError) {
        if (e?.networkError?.result?.errors?.length > 0) {
          return e?.networkError?.result?.errors?.map(({ message }) => {
            return toast(
              <Notification
                icon={exclamationTriangle}
                text={message}
                status="error"
              />,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500,
              }
            );
          });
        }
        if (e?.networkError?.result?.message) {
          return toast(
            <Notification
              icon={exclamationTriangle}
              text={e?.networkError?.result?.message}
              status="error"
            />,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            }
          );
        }
      }
    }
    return null;
  };

  return <UserSelect onSubmit={onSubmit} />;
};

export default LoginPage;
