import React, { useCallback } from 'react';
import { getNextPopup } from 'lib/selectors/getNextPopup';
import { useSelector, useDispatch } from 'react-redux';
import { actionDismissPopup } from 'lib/actions';
import { WorkflowPopupTriggered } from './WorkflowPopupTriggered';

const WorkflowPopup = () => {
  const dispatch = useDispatch();
  const nextPopup = useSelector(getNextPopup);
  const dismiss = useCallback(() => {
    return dispatch(actionDismissPopup({ params: 'workflow' }));
  }, [dispatch]);

  if (nextPopup?.popupType !== 'workflow') return null;

  return <WorkflowPopupTriggered dismiss={dismiss} workflowData={nextPopup} />;
};

export { WorkflowPopup };
