import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { DurationTicker } from 'components/DurationTicker';
import { getCurrentCycleStart } from 'lib/selectors/getCurrentCycleStart';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import {
  TextMedia,
  LoadingStatTabLabel,
  LoadingStatTabSecondaryLabel,
} from '../styled';
import { DURATION_FORMAT, DURATION_OPTIONS } from './constants';

export const CyclesTab = () => {
  const { t } = useTranslation();
  const currentCycleStart = useSelector(getCurrentCycleStart);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  return (
    <StatTab
      id="cycle-tab"
      value="cycleTime"
      label={
        <LoadingStatTabLabel loading={arePartCountBucketsLoading}>
          <TextMedia max={60}>{_startCase(t('cycle'))}</TextMedia>
          <TextMedia min={60}>{_startCase(t('current cycle'))}</TextMedia>
        </LoadingStatTabLabel>
      }
      secondaryLabel={
        arePartCountBucketsLoading ? (
          <LoadingStatTabSecondaryLabel />
        ) : currentCycleStart ? (
          <DurationTicker
            startDate={currentCycleStart.toDate()}
            format={DURATION_FORMAT}
            formatOptions={DURATION_OPTIONS}
          />
        ) : (
          '-'
        )
      }
    />
  );
};
