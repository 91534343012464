import { createSelector } from 'reselect';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { getScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';
import { isNumber } from 'lib/utils/validation';

/*
  calculating quality: https://machinemetrics.quip.com/bDM9AOj7yo6q
  quality: good parts / total parts
*/

const getQualityDecimal = createSelector(
  [
    getScopeNetPartsMade,
    getScopeTotalParts,
    getHasOpenActivity,
    getHasOpenJobRun,
  ],
  (scopeNetPartsMade, scopeTotalParts, hasOpenActivity, hasOpenJobRun) => {
    if (
      // metric is only relevant when active / job is running and unpaused
      !(hasOpenActivity || hasOpenJobRun) ||
      !isNumber(scopeNetPartsMade) ||
      !isNumber(scopeTotalParts) ||
      scopeTotalParts === 0
    ) {
      return null;
    }
    return scopeNetPartsMade / scopeTotalParts;
  }
);

export { getQualityDecimal };
