import { createSelector } from 'reselect';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { ACTIVITY_MODES } from 'lib/constants';

const getIsLatestActivitySetup = createSelector(
  [getLatestActivityMode],
  (latestActivityMode) => {
    return latestActivityMode === ACTIVITY_MODES.SETUP;
  }
);

export { getIsLatestActivitySetup };
