import React from 'react';
import Modal from 'react-modal';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import TokenStore from 'lib/api/links/TokenStore';
import { LANGUAGE_NAMES } from 'lib/translations/locales';
import { Icon } from '@m12s/component-library';
import { globe } from 'lib/icons';
import { updateLanguage } from 'lib/hooks/useLocale';
import { useDispatch } from 'react-redux';
import { actionCloseLanguageModal } from 'lib/actions';
import { Content, customStyles, Item, List, Small } from './styled';

const LanguageModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const setLocaleToken = new TokenStore('locale');
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <Content>
        <Small>{t(_startCase('select your language'))}</Small>
        <List>
          {i18n.languages &&
            i18n.languages.sort().map((l) => {
              return (
                <Item
                  key={l}
                  selected={l === i18n.language}
                  onClick={() => {
                    setLocaleToken.set(l);
                    updateLanguage();
                    dispatch(actionCloseLanguageModal());
                  }}
                >
                  <Icon icon={globe} style={{ marginRight: '0.5rem' }} />
                  {LANGUAGE_NAMES[l]}
                </Item>
              );
            })}
        </List>
      </Content>
    </Modal>
  );
};

export { LanguageModal };
