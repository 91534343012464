import styled from 'styled-components';
import { palette } from '@m12s/component-library';

export const Container = styled.div`
  max-height: 8rem;
  padding-left: 2rem;
  padding-top: 1rem;

  @media (max-width: 720px) {
    padding-left: 0;
    padding-top: 2rem;
  }
`;

export const Description = styled.div`
  font-size: 0.875rem;
  color: ${palette.Grey600};
  font-weight: 400;
  line-height: 1;
  padding: 0.75rem 0;

  @media (orientation: portrait) {
    font-size: 0.75rem;
  }
`;

export const Quantity = styled.div`
  font-size: 1.75rem;
  margin-top: 0.25rem;
  line-height: 1.75;
  ${({ shrink }) => {
    return (
      shrink &&
      `
    font-size: 1.5rem;
  `
    );
  }}

  @media (orientation: portrait) {
    font-size: 1.5rem;
  }
`;

export const NoWrap = styled.span`
  white-space: no-wrap;

  @media (orientation: portrait) {
    display: block;
    font-size: 1.25rem;
  }
`;
