import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'lib/utils/routes';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';

import { Button, Modal, palette, Icon } from '@m12s/component-library';
import { H5 } from 'components/Text';
import { getWorkOrderId } from 'lib/selectors/getWorkOrderId';
import { getMachine } from 'lib/selectors/getMachine';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getIsLatestActivitySetClosed } from 'lib/selectors/getIsLatestActivitySetClosed';
import { getLatestActivitySetRef } from 'lib/selectors/getLatestActivitySetRef';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { DELETE_LABOR_TICKET, UPDATE_ACTIVITY_SET } from 'lib/api/mutations';
import {
  actionOptimisticActivitySetUpdate,
  actionCanonicalActivitySetUpdate,
  actionSetDefaultManualEntry,
} from 'lib/actions';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';
import useStartJob from 'pages/SelectJob/useStartJob';
import { getDefaultManualEntry } from 'lib/selectors/getDefaultManualEntry';

import {
  ModalHeaderText,
  CreateOperationInput,
  IconWrapper,
} from 'components/JobTable/styled';
import { fileAltRegular } from 'lib/icons';

import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';

const AddWorkOrderModal = ({
  handleOnClose,
  selectedJob,
  onJobClick,
  activityTypeRef,
  forEditWorkOrderId = false,
  isStopStartNewRun = false,
  disableStartRunCondition = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const workOrderFlowEnabled = useIsWorkOrderFlow();
  const laborTicketEnabled = useIsLaborTicketFlow();

  const currentLaborTicket = useSelector(getCurrentLaborTicket);
  const defaultManualEntry = useSelector(getDefaultManualEntry);
  const isLatestActivitySetClosed = useSelector(getIsLatestActivitySetClosed);
  const latestWorkOrderId = useSelector(getWorkOrderId);
  const [workOrderId, setWorkOrderId] = useState(
    defaultManualEntry ||
      (isLatestActivitySetClosed || isStopStartNewRun ? '' : latestWorkOrderId)
  );

  const machine = useSelector(getMachine);
  const currentJob = useSelector(getLatestJob);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestActivityType = useSelector(getLatestActivityType);
  const activitySetRef = useSelector(getLatestActivitySetRef);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const { startJob: startRunWithNewWorkOrder } = useStartJob({
    ...currentJob,
    workOrderOperationRef: null,
  });
  const [requestUpdateActivitySet] = useMutation(UPDATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          updateActivitySetResponse: { activitySet },
        },
      }
    ) => {
      dispatch(actionOptimisticActivitySetUpdate({ activitySet }));
      return history.push({
        pathname: Routes.machineIdHomePath(machine.id),
      });
    },
    onCompleted: ({ updateActivitySetResponse: { activitySet } }) => {
      dispatch(actionCanonicalActivitySetUpdate({ activitySet }));
    },
    onError: () => {
      dispatch(
        actionCanonicalActivitySetUpdate({
          activitySet: {
            ...latestActivitySet,
            workOrderId: latestWorkOrderId,
          },
        })
      );
      throwErrorToast('Failed to update production order');
    },
  });

  const onChange = (e) => {
    setWorkOrderId(e.target.value);
  };

  const onSubmitEditWorkOrderId = () => {
    // shim to nullify workOrderId in db if field is empty string
    const workOrderIdShim = workOrderId || null;
    requestUpdateActivitySet({
      variables: {
        input: {
          machineRef: machine?.machineRef,
          activitySetRef,
          workOrderId: workOrderIdShim,
          workOrderOperationRef: null,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateActivitySetResponse: {
          __typename: 'ActivitySet',
          activitySet: {
            ...latestActivitySet,
            workOrderId: workOrderIdShim,
            workOrderOperationRef: null,
          },
        },
      },
    });
  };

  const preSelectedActivityType =
    workOrderFlowEnabled && queryParams.activityTypeRef;

  const onSubmitStopAndStartRun = () => {
    if (isAPMEnabled || preSelectedActivityType) {
      return startRunWithNewWorkOrder({
        activityTypeRef:
          preSelectedActivityType || latestActivityType.activityTypeRef,
        workOrderId,
        shouldCloseLatestSet: true,
      });
    }
    return history.push({
      pathname: Routes.machineIdStartActivityNewOperationPath(machine.id),
      state: {
        job: { ...currentJob, workOrderOperationRef: null },
        jobName: currentJob?.name,
        newWorkOrderId: workOrderId,
        isStopAndStartRun: true,
      },
    });
  };

  const [
    deleteLaborTicket,
    { loading: laborTicketDeleteInProgress },
  ] = useMutation(DELETE_LABOR_TICKET);
  const handleDeleteLaborTicket = async () => {
    try {
      await deleteLaborTicket({
        variables: { ref: currentLaborTicket.laborTicketRef },
      });
      throwSuccessToast('Deleted open labor ticket');
    } catch (e) {
      throwErrorToast('Failed deleting open labor ticket');
    }
  };

  useEffect(() => {
    return () => {
      return dispatch(actionSetDefaultManualEntry(''));
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Modal
      isOpen
      header={
        <H5 color={palette.White}>
          <ModalHeaderText>
            {isStopStartNewRun
              ? 'Please enter a Production Order for the new Production Run.'
              : 'Please enter a Production Order.'}
          </ModalHeaderText>
        </H5>
      }
      headerIcon={
        <IconWrapper>
          <Icon size="2rem" color={palette.White} icon={fileAltRegular} />
        </IconWrapper>
      }
      headerStyles={{
        background: `linear-gradient(180deg, ${palette.Purple500} 0%, #9272DB 100%)`,
        minHeight: '5.5rem',
      }}
      details={
        <div style={{ padding: '0px 0px 0px 0px' }}>
          <CreateOperationInput
            name="workOrderId"
            value={workOrderId}
            onChange={onChange}
            placeholder={t('Ex. #1234-789')}
          />
        </div>
      }
      buttons={
        <>
          <Button onClick={handleOnClose} variant="inferior">
            {t('Cancel')}
          </Button>
          {forEditWorkOrderId ? (
            <Button
              onClick={async () => {
                if (currentLaborTicket && laborTicketEnabled) {
                  await handleDeleteLaborTicket();
                }
                return onSubmitEditWorkOrderId();
              }}
              variant="primary"
              disabled={
                workOrderId === latestWorkOrderId || laborTicketDeleteInProgress
              }
            >
              {t('Submit')}
            </Button>
          ) : (
            <Button
              onClick={() => {
                // called via activity switcher flow
                if (onSubmit) {
                  return onSubmit(activityTypeRef, workOrderId);
                }
                // for adding a work order id to current production run
                if (!isStopStartNewRun) {
                  return onJobClick(selectedJob, workOrderId);
                }
                // for stopping and starting a new run with a new work order id
                return onSubmitStopAndStartRun();
              }}
              variant="inferior"
              disabled={isStopStartNewRun && disableStartRunCondition}
            >
              {t(isStopStartNewRun || workOrderId ? 'Submit' : 'Skip')}
            </Button>
          )}
        </>
      }
    />
  );
};

export { AddWorkOrderModal };
