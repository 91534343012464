import { createSelector } from 'reselect';
import { getSetupActivityTypeRefs } from 'lib/selectors/getSetupActivityTypeRefs';
import { getCheckIsTargetActivityTypeRef } from 'lib/utils/activities';

// returns memoized check func with target type refs "baked" in
const getCheckIsSetupActivityTypeRef = createSelector(
  [getSetupActivityTypeRefs],
  (setupActivityTypeRefs = []) => {
    return getCheckIsTargetActivityTypeRef(setupActivityTypeRefs);
  }
);

export { getCheckIsSetupActivityTypeRef };
